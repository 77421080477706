import React from 'react'
import DataTable from 'react-data-table-component'

export function Grid(props) {
	return (
		<DataTable
			columns={props.columns}
			data={props.data}
			pagination
			highlightOnHover
			noHeader
			subHeader
			subHeaderComponent={props.subHeaderComponent}
			subHeaderAlign={'left'}
		/>
	)
}
