import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function getFullLessonsReducer(
  state = initialState.fullClassrooms,
  action
) {
  switch (action.type) {
    case actionTypes.GET_FULL_CLASSROOM_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        extra: action.payload.extra,
      }

    case actionTypes.RESET_FULL_STATUSS_CLASSROOM:
      return {
        ...state,
        success: "",
      }

    default:
      return state
  }
}
