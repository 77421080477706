import {
	CREATE_QUESTIONTYPE_SUCCESS,
	GET_FULL_QUESTIONTYPE_SUCCESS,
	GET_QUESTIONTYPE_SUCCESS,
	DELETE_QUESTIONTYPE_SUCCESS,
	UPDATE_QUESTIONTYPE_SUCCESS,
	DELETE_QUESTIONTYPE_SUCCESS_STATUSS,
	RESET_STATUSS_QUESTIONTYPE,
	ONFAULT_QUESTIONTYPE,
	RESET_GET_FULL_QUESTIONTYPE_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createQuestionTypeSuccess = (QuestionTypeData) => {
	return {
		type: CREATE_QUESTIONTYPE_SUCCESS,
		payload: QuestionTypeData,
	}
}

export const getQuestionTypeSuccess = (QuestionTypeData) => {
	return {
		type: GET_QUESTIONTYPE_SUCCESS,
		payload: QuestionTypeData,
	}
}

export const getFullQuestionTypesSuccess = (QuestionTypeData) => {
	return {
		type: GET_FULL_QUESTIONTYPE_SUCCESS,
		payload: QuestionTypeData,
	}
}

export const resetGetFullQuestionTypesSuccess = (QuestionTypeData) => {
	return {
		type: RESET_GET_FULL_QUESTIONTYPE_SUCCESS,
		payload: QuestionTypeData,
	}
}

export const deleteQuestionTypeSuccess = (QuestionTypeData) => {
	return {
		type: DELETE_QUESTIONTYPE_SUCCESS,
		payload: QuestionTypeData,
	}
}

export const updateQuestionTypeSuccess = (QuestionTypeData) => {
	return {
		type: UPDATE_QUESTIONTYPE_SUCCESS,
		payload: QuestionTypeData,
	}
}

export const changeDeletedQuestionTypeSuccessStatus = (data) => {
	return {
		type: DELETE_QUESTIONTYPE_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_QUESTIONTYPE,
		payload: data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_QUESTIONTYPE,
		payload: data,
	}
}

export const createQuestionType = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/QuestionTypes/Add', data)
		.then((res) => {
			const AddedQuestionType = res.data
			dispatch(createQuestionTypeSuccess(AddedQuestionType))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getQuestionTypes = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/QuestionTypes/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const QuestionTypeData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getQuestionTypeSuccess(QuestionTypeData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullQuestionTypes = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/QuestionTypes/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullQuestionTypessData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullQuestionTypesSuccess(fullQuestionTypessData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteQuestionType = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/QuestionTypes/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedQuestionType = res.data

			dispatch(deleteQuestionTypeSuccess(deletedQuestionType))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteQuestionType = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/QuestionTypes/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(deleteQuestionTypeSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateQuestionType = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/QuestionTypes/Update'
	// if (data.id) {
	//     url = url + "?id=" + data.id;
	// }
	axios
		.put(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(updateQuestionTypeSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
