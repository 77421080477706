import { GET_USER_SUCCESS } from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'
axios.defaults.baseURL = mainConfigStates.host

var token = localStorage.getItem('jwtToken')
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

export const getUserDatas = (userdatas) => {
	return {
		type: GET_USER_SUCCESS,
		payload: userdatas,
	}
}

export const getUser = () => (dispatch) => {
	axios
		.get('/api/Users/GetUser')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			//const { token } = res.data

			// Token'i localStorage'da saklıyoruz
			localStorage.setItem('jwtToken', token)

			const userData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getUserDatas(userData))
		})
		.catch((err) => alert(err))
}

export async function handleResponse(response) {
	if (response.ok) {
		return response.json()
	}

	const error = await response.text()
	throw new Error(error)
}

export function handleError(error) {
	throw error
}
