import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function lessonsToClassReducer(
  state = initialState.gettedExamsById,
  action
) {
  switch (action.type) {
    case actionTypes.ADD_LESSONS_ASSIGNED_TOCLASS_SUCCESS:
      return {
        ...state,
        successAdded: action.payload.success,
      }

    case actionTypes.REMOVE_LESSONS_FROM_TOCLASS_SUCCESS:
      return {
        ...state,
        successRemoved: action.payload.success,
      }

    case actionTypes.GET_LESSONS_BY_CLASSROOM_SUCCESS:
      return {
        ...state,
        successAssigned: action.payload.success,
        extraAssigned: action.payload.extra,
      }

    case actionTypes.GET_LESSONS_NOTASSIGNED_SUCCESS:
      return {
        ...state,
        successNotAssigned: action.payload.success,
        extraNotAssigned: action.payload.extra,
      }

    case actionTypes.RESET_LESSONS_TOCLASS:
      return {
        ...state,
        successAdded: "",
        successRemoved: "",
        successNotAssigned: "",
        successAssigned: "",
      }

    case actionTypes.ONFAULT_LESSONS_TOCLASS:
      return {
        ...state,
        successAdded: false,
        successRemoved: false,
        successNotAssigned: false,
        successAssigned: false,
        messages: action.payload.messages,
      }

    default:
      return state
  }
}
