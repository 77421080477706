import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function realExamReducer(
  state = initialState.RealExamInitialState,
  action
) {
  switch (action.type) {
    case actionTypes.GET_ALL_REALEXAM_START_SUCCESS:
      return {
        ...state,
        successGetRealExam: action.payload.success,
        extraGetRealExam: action.payload.extra,
      }

    case actionTypes.POST_ANSWER_REALEXAM_SUCCESS:
      return {
        ...state,
        successPostAnswer: action.payload.success,
        extraPostAnswer: action.payload.extra,
      }

    case actionTypes.FINISH_REALEXAM_SUCCESS:
      return {
        ...state,
        successFinishRealEXam: action.payload.success,
      }

    case actionTypes.AGAIN_REALEXAM_SUCCESS:
      return {
        ...state,
        successAgainExam: action.payload.success,
        extraAgainExam: action.payload.extra,
      }

    case actionTypes.GET_EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        successDetailsExam: action.payload.success,
        extraDetailsExam: action.payload.extra,
      }

    //******************************************************* */
    case actionTypes.RESET_GET_ALL_REALEXAM_START_SUCCESS:
      return {
        ...state,
        successGetRealExam: "",
      }

    case actionTypes.RESET_POST_ANSWER_REALEXAM_SUCCESS:
      return {
        ...state,
        successPostAnswer: "",
      }

    case actionTypes.RESET_FINISH_REALEXAM_SUCCESS:
      return {
        ...state,
        successFinishRealEXam: "",
      }

    case actionTypes.FULL_RESET_REALEXAM:
      return {
        ...state,
        successGetRealExam: "",
        successPostAnswer: "",
        successFinishRealEXam: "",
        successDetailsExam: "",
      }

    case actionTypes.RESET_AGAIN_REALEXAM_SUCCESS:
      return {
        ...state,
        successAgainExam: "",
      }

    case actionTypes.RESET_GET_EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        successDetailsExam: "",
      }
    //******************************************************* */

    case actionTypes.FAULT_GET_ALL_REALEXAM_START_SUCCESS:
      return {
        ...state,
        successGetRealExam: false,
        messages: action.payload.messages,
      }

    case actionTypes.FAULT_POST_ANSWER_REALEXAM_SUCCESS:
      return {
        ...state,
        successPostAnswer: false,
        messages: action.payload.messages,
      }

    case actionTypes.FAULT_FINISH_REALEXAM_SUCCESS:
      return {
        ...state,
        successFinishRealEXam: false,
        messages: action.payload.messages,
      }

    case actionTypes.FAULT_AGAIN_REALEXAM_SUCCESS:
      return {
        ...state,
        successAgainExam: false,
        messages: action.payload.messages,
      }

    case actionTypes.FAULT_GET_EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        successDetailsExam: false,
        messages: action.payload.messages,
      }
    //******************************************************* */
    default:
      return state
  }
}
