/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import jsPDF from 'jspdf'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as examActions from '../../../redux/actions/examActions'
import * as topicActions from '../../../redux/actions/topicActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'






class Exams extends Component {
	find_in_object(array1, my_criteria) {
		return array1.map((obj) => {
			if (obj.id === my_criteria) {
				return obj.topicName
			}
		})
	}

	constructor(props) {
		super(props)
		this.state = {
			dataChart: [
				{
					name: 'Matematik',
					Adet: 5,
				},
				{
					name: 'İngilizce',
					Adet: 10,
				},
				{
					name: 'Almanca',
					Adet: 50,
				},
				{
					name: 'Fransızca',
					Adet: 20,
				},
			],

			ExamsColumns: [
				{
					name: 'Test Adı',
					selector: 'examName',
					sortable: true,
				},
				{
					name: 'Konu',
					selector: 'topicId',
					sortable: true,
					cell: (row) =>
						this.find_in_object(this.props.topicReducerData.extra, row.topicId),
				},
				{
					name: 'Oluşturan',
					selector: 'createdByName',
					sortable: true,
					cell: (row) => `${row.createdByName} - ${row.createdByRoleName}`,
					width: '150px',
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
					width: '75px',
				},
				{
					name: 'Text',
					selector: 'textCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Resimli',
					selector: 'imageCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Ses',
					selector: 'audioCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Video',
					selector: 'videoCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Toplam',
					selector: 'totalCount',
					sortable: true,
					width: '60px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateExam
						) == true ? (
								<Link
									className="btn btn-dark"
									to={{
										pathname: '/ExamDetails',
										state: row,
									}}
								>
								Detaylar
								</Link>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateExam
						) == true
							? '95px'
							: '0',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateExam
						) == true &&
						(this.props.userAuthData.permissions.includes(
							initialState.staticPerms.adminPermsId
						) ||
							row.createdBy == localStorage.getItem('id')) ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateExams')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateExam
						) == true
							? '95px'
							: '0',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteExam
						) == true &&
						(this.props.userAuthData.permissions.includes(
							initialState.staticPerms.adminPermsId
						) ||
							row.createdBy == localStorage.getItem('id')) ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteExam
						) == true
							? '50px'
							: '0px',
				},
			],

			FullExamColumns: [
				{
					name: 'Test Adı',
					selector: 'examName',
					sortable: true,
				},
				{
					name: 'Konu',
					selector: 'topicName',
					sortable: true,
				},
				{
					name: 'Oluşturan',
					selector: 'createdByName',
					sortable: true,
					cell: (row) => `${row.createdByName} - ${row.createdByRoleName}`,
					width: '150px',
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
					width: '75px',
				},
				{
					name: 'Text',
					selector: 'textCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Resimli',
					selector: 'imageCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Ses',
					selector: 'audioCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Video',
					selector: 'videoCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Toplam',
					selector: 'totalCount',
					sortable: true,
					width: '60px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamDetails
						) == true ? (
								<Link
									className="btn btn-dark"
									to={{
										pathname: '/ExamDetails',
										state: {
											...row,
										},
									}}
								>
								Detaylar
								</Link>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamDetails
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateExam
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateExams')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateExam
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteExam
						) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteExam
						) == true
							? '60px'
							: '0px',
				},
			],
		}
	}

	componentDidMount() {
		this.props.actions.getTopics()
		this.props.actions.getExam()
		this.props.actions.getFullExam()

		this.setState({ ExamListData_onQuery: this.props.exams.extra })
		this.setState({ ExamFullListData_onQuery: this.props.fullExams.extra })

		this.props.actions.changeDeletedExamSuccessStatus('')
		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.exams.deleted) {
			alertifyjs.success('Test Başarılı Olarak Silindi!')
			newProps.actions.changeDeletedExamSuccessStatus('')
			newProps.actions.resetAllStatuss('')
			newProps.actions.getExam()
			newProps.actions.getFullExam()
		} else if (newProps.exams.deleted === false) {
			alertifyjs.error('Konu Silinirken Hatalar Oluştu.')
			newProps.actions.changeDeletedExamSuccessStatus('')
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.exams.success) {
			this.setState({ ExamListData_onQuery: newProps.exams.extra })

			newProps.actions.resetAllStatuss('')
		} else if (newProps.exams.success === false) {
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.fullExams.success) {
			this.setState({ ExamFullListData_onQuery: newProps.fullExams.extra })

			newProps.actions.resetGetFullExamSuccess('')
		} else if (newProps.fullExams.success === false) {
			newProps.actions.resetGetFullExamSuccess('')
		}
	}

	exportPDF = (dataGElen) => {
		const unit = 'pt'
		const size = 'A4' // Use A1, A2, A3 or A4
		const orientation = 'portrait' // portrait or landscape

		const marginLeft = 40
		const doc = new jsPDF(orientation, unit, size)

		doc.setFontSize(10)

		const title = 'TEST RAPORLAMALARI'
		const headers = [['TEST ADI', 'KONU ADI']]

		const data = dataGElen.map((elt) => [
			elt.examName,
			this.find_in_object(this.props.topicReducerData.extra, elt.topicId),
		])

		let content = {
			startY: 50,
			head: headers,
			body: data,
		}

		doc.text(title, marginLeft, 40)
		doc.autoTable(content)
		doc.save('report.pdf')
	}

	selectNavigation = (navigation) => {
		this.props.actions.changeDeletedExamSuccessStatus('')
		this.props.actions.resetAllStatuss('')

		var location = {
			pathname: navigation,
		}

		this.props.history.push(location)
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var ExamListData_onQuery = this.props.exams.extra.filter(
				(item) =>
					item.examName.like(e.target.value) ||
					item.createdByRoleName.like(e.target.value) ||
					item.createdByName.like(e.target.value) ||
					item.topicName.like(e.target.value)
			)
			this.setState({ ExamListData_onQuery })
		} else {
			this.setState({ ExamListData_onQuery: this.props.exams.extra })
		}
	}

	searchOnFullList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var ExamFullListData_onQuery = this.props.fullExams.extra.filter(
				(item) =>
					item.examName.like(e.target.value) ||
					item.createdByRoleName.like(e.target.value) ||
					item.createdByName.like(e.target.value) ||
					item.topicName.like(e.target.value)
			)
			this.setState({ ExamFullListData_onQuery })
		} else {
			this.setState({ ExamFullListData_onQuery: this.props.fullExams.extra })
		}
	}

	handleClick = (state) => {}

	onRowClickUpdate = (row, navigation) => {
		this.props.actions.resetAllStatuss('')
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteExam(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteExam(data)
		}
	}

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Testler</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.ExamsColumns}
						data={this.state.ExamListData_onQuery}
						noHeader
						pagination={true}
						highlightOnHover={true}
						progressPending={this.props.exams.extra.length > 0 ? false : true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddExam
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateExams')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindExam
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.ExamListData_onQuery)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Testler</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullExamColumns}
						data={this.state.ExamFullListData_onQuery}
						noHeader
						pagination={true}
						highlightOnHover={true}
						progressPending={this.props.fullExams.extra.length > 0 ? false : true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddExam
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateExams')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindFullListExam
								) === true ? (
										<TextField
											onChange={this.searchOnFullList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.ExamFullListData_onQuery)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<div className="row">
					{/* <div className="col-lg-12">

                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    <FormGroup row>
                                        <h4>Ders Atama Oranları</h4>
                                    </FormGroup>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="0">



                                    <ResponsiveContainer width="100%" height={250}>
                                        <AreaChart data={this.state.dataChart}
                                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip />
                                            <ReferenceLine x="Page C" stroke="red" label="Min PAGE" />
                                            <ReferenceLine y={6000} label="Max" stroke="orange" strokeDasharray="3 3" />
                                            <Area type="monotone" dataKey="Adet" stroke="black" fill="#db3d44" />
                                        </AreaChart>
                                    </ResponsiveContainer>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>


                    </div> */}

					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListExam
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListExam
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		exams: state.examReducer,
		fullExams: state.fullExamReducer,

		topicReducerData: state.topicReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getExam: bindActionCreators(examActions.getExam, dispatch),

			getFullExam: bindActionCreators(examActions.getFullExam, dispatch),
			resetGetFullExamSuccess: bindActionCreators(
				examActions.resetGetFullExamSuccess,
				dispatch
			),

			deleteExam: bindActionCreators(examActions.deleteExam, dispatch),
			fullDeleteExam: bindActionCreators(examActions.fullDeleteExam, dispatch),
			createExam: bindActionCreators(examActions.createExam, dispatch),
			changeDeletedExamSuccessStatus: bindActionCreators(
				examActions.changeDeletedExamSuccessStatus,
				dispatch
			),
			resetAllStatuss: bindActionCreators(examActions.resetAllStatuss, dispatch),

			getTopics: bindActionCreators(topicActions.getTopics, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exams))
