/* eslint-disable */
import DeleteSweep from '@material-ui/icons/DeleteSweep'
import Icon3 from '@material-ui/icons/ImportExport'
import React, { Component } from 'react'
import { Accordion, Card, Tab, Tabs } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Button, FormGroup } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as examDetailsActions from '../../../redux/actions/examDetailsActions'
import * as examResultActions from '../../../redux/actions/examResultActions'
import initialState from '../../../redux/reducers/initialState'
import { formatDate } from '../../../utils/helpers/date'
import { QuestionCardItem } from '../base/question'
import { ScrollView } from '../base/scroll'


class ExamResults extends Component {
	find_in_object(array1, my_criteria) {
		return array1.map((obj) => {
			if (obj.id === my_criteria) {
				return obj.topicName
			}
		})
	}

	constructor(props) {
		super(props)

		this.state = {
			id: props.location.state.id,
			classroomId: props.location.state.classroomId,
			studentId: props.location.state.studentId,
			studentName: props.location.state.studentName,
			joinedUsers: [],
			notJoinedUsers: [],
			QuestionUserColumns: [
				{
					name: 'Öğrenci',
					selector: 'fullName',
					sortable: true,
				},
				{
					name: 'Çözme Süresi',
					selector: 'duration',
					sortable: true,
				},
			],
			UnJoinedUsersColumns: [
				{
					name: 'Öğrenci',
					selector: 'Student',
					sortable: true,
					cell: (row) => row.userDto.fullName,
				},
				{
					name: 'Doğru Sayısı',
					selector: 'correctQuestionCount',
					sortable: true,
					width: '120px',
				},
				{
					name: 'Pas Sayısı',
					selector: 'skippedQuestionCount',
					sortable: true,
					width: '120px',
				},
				{
					name: 'Yanlış Sayısı',
					selector: 'wrongQuestionCount',
					sortable: true,
					width: '120px',
				},
				{
					name: 'Toplam Puan',
					selector: 'totalPoint',
					sortable: true,
					width: '120px',
				},
				{
					name: 'Süre',
					selector: 'examDuration',
					sortable: true,
					width: '120px',
				},
			],
		}

		this.state['JoinedUsersColumns'] = [
			...this.state.UnJoinedUsersColumns,
			{
				cell: (row) =>
					this.props.userAuthData.permissions.includes(
						initialState.staticPerms.removeStudentTestResult
					) === true ? (
							<Button color="danger" onClick={() => this.studentTestReset(row)}>
								<DeleteSweep /> Sıfırla
							</Button>
						) : null,
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
				width: '105px',
			},
		]
	}

	studentTestReset(row) {
		if (
			confirm(
				`'${row.userDto.fullName}' adlı öğrencinizin test sonuçlarını silmek istediğinize emin misiniz?`
			)
		) {
			this.props.actions.studentTestReset(row)
		}
	}

	componentDidMount() {
		if (this.state.id) {
			this.getAllData()
		}
	}

	getAllData() {
		this.props.actions.getAssignedUsers({
			ExamId: this.state.id,
		})
		this.props.actions.getJoinedUsers({
			ExamId: this.state.id,
			ClassRoomId: this.state.classroomId,
		})
		this.props.actions.getExamsWithQuestions({
			ExamId: this.state.id,
		})
		this.props.actions.getExamQuestionUserReport({
			ExamId: this.state.id,
			ClassRoomId: this.state.classroomId,
		})
	}

	componentWillReceiveProps(props) {
		if (props.examResult.successGetJoinedUsers) {
			this.setState({
				joinedUsers: props.examResult.extraGetJoinedUsers.filter((i) => i.solved),
				notJoinedUsers: props.examResult.extraGetJoinedUsers.filter((i) => !i.solved),
			})
		}
		if (props.examResult.successStudentTestReset) {
			this.getAllData()
			this.props.actions.resetAllExamResultStatus('')
		}
	}

	render() {
		let joinedUsers = this.props.examResult.extraGetJoinedUsers.filter((i) => i.solved)
		let joinedUserCount = joinedUsers.length
		let unJoinedUserCount = this.props.examResult.extraGetJoinedUsers.filter(
			(i) => !i.solved
		).length
		let correctQuestionCount = joinedUsers.reduce(
			(total, current) => total + current.correctQuestionCount,
			0
		)
		let skippedQuestionCount = joinedUsers.reduce(
			(total, current) => total + current.skippedQuestionCount,
			0
		)
		let wrongQuestionCount = joinedUsers.reduce(
			(total, current) => total + current.wrongQuestionCount,
			0
		)
		let questionCount = this.props.examResult.extraGetQuestionUsers.length
		let totalQuestionCount = (questionCount || 1) * (joinedUserCount || 1)
		let correctQuestionCountPercent = (
			(correctQuestionCount / totalQuestionCount) * 100 || 0
		).toFixed(2)
		let skippedQuestionCountPercent = (
			(skippedQuestionCount / totalQuestionCount) * 100 || 0
		).toFixed(2)
		let wrongQuestionCountPercent = (
			(wrongQuestionCount / totalQuestionCount) * 100 || 0
		).toFixed(2)

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<div>
							<Breadcrumb>
								{this.props.examDetail &&
									this.props.examDetail.extraGetExams &&
									this.props.examDetail.extraGetExams.exam && (
									<BreadcrumbItem active>
										{this.props.examDetail.extraGetExams.exam.examName} /{' '}
										{formatDate(this.props.examDetail.extraGetExams.exam.deadLine)} /{' '}
									</BreadcrumbItem>
								)}
							</Breadcrumb>
						</div>
					</div>
				</div>
				{this.state.studentId && (
					<p className="alert alert-warning">
						Bu sayfadaki bazı değerler {this.state.studentName} öğrencisine göre
						süzülmüştür. Tüm öğrencilerin sonuçlarını görmek için "Tümünü Gör" butonuna
						tıklayınız.
						<br />
						<br />
						<Button onClick={() => this.setState({ studentId: '', studentName: '' })}>
							Tümünü Gör
						</Button>
					</p>
				)}
				<Tabs
					defaultActiveKey={this.state.studentId ? 'questions' : 'statistics'}
					id="uncontrolled-tab-example"
				>
					<Tab eventKey="statistics" title="Özet Rapor">
						<div className="row" style={{ marginTop: 10 }}>
							<div className="col">
								<div className="small-box bg-info">
									<div className="inner">
										<h3>{questionCount}</h3>
										<p>Soru</p>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="small-box bg-info">
									<div className="inner">
										<h3>{joinedUserCount}</h3>
										<p>Katılan Kişi</p>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="small-box bg-info">
									<div className="inner">
										<h3>{unJoinedUserCount}</h3>
										<p>Katılmayan Kişi</p>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="small-box bg-success">
									<div className="inner">
										<h3>{correctQuestionCountPercent}%</h3>
										<p>Ortalama Doğru Sayısı</p>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="small-box bg-warning">
									<div className="inner">
										<h3>{skippedQuestionCountPercent}%</h3>
										<p>Ortalama Pas Sayısı</p>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="small-box bg-danger">
									<div className="inner">
										<h3>{wrongQuestionCountPercent}%</h3>
										<p>Ortalama Yanlış Sayısı</p>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<Accordion defaultActiveKey="0">
									<Card>
										<Accordion.Toggle eventKey="0" as={Card.Header}>
											<div className="d-flex justify-content-between">
												<FormGroup row>
													<h4>Katılanlar</h4>
												</FormGroup>

												<FormGroup row>
													<Icon3 style={{ margin: '5px' }} color="action" />
												</FormGroup>
											</div>
										</Accordion.Toggle>

										<Accordion.Collapse eventKey="0">
											<DataTable
												columns={this.state.JoinedUsersColumns}
												data={this.state.joinedUsers}
												pagination={true}
												highlightOnHover={true}
												noHeader
											/>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<Accordion defaultActiveKey="1">
									<Card>
										<Accordion.Toggle eventKey="1" as={Card.Header}>
											<div className="d-flex justify-content-between">
												<FormGroup row>
													<h4>Katılmayanlar</h4>
												</FormGroup>

												<FormGroup row>
													<Icon3 style={{ margin: '5px' }} color="action" />
												</FormGroup>
											</div>
										</Accordion.Toggle>

										<Accordion.Collapse eventKey="1">
											<DataTable
												columns={this.state.UnJoinedUsersColumns}
												data={this.state.notJoinedUsers}
												pagination={true}
												highlightOnHover={true}
												noHeader
											/>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</div>
					</Tab>
					<Tab eventKey="questions" title="Sorular">
						<div style={{ marginTop: 20 }}>
							{this.props.examResult.extraGetQuestionUsers.map((question, i) => {
								let bottomComponent = (
									<Tabs defaultActiveKey="correctAnsweredUsers" id="uncontrolled-tab-example">
										<Tab
											eventKey="correctAnsweredUsers"
											title={`Doğru Cevaplayanlar (${
												question.correctUsers.filter((i) =>
													this.state.studentId ? i.id == this.state.studentId : 1 == 1
												).length
											})`}
										>
											<DataTable
												columns={this.state.QuestionUserColumns}
												data={question.correctUsers.filter((i) =>
													this.state.studentId ? i.id == this.state.studentId : 1 == 1
												)}
												pagination={true}
												highlightOnHover={true}
												paginationComponentOptions={{
													rowsPerPageText: 'Sayfa başına gösterilen kayıt sayısı: ',
													rangeSeparatorText: '/',
													selectAllRowsItemText: 'Tümü',
												}}
												noDataComponent={
													<div className="alert alert-info m-5">Kayıt bulunamadı.</div>
												}
												noHeader
											/>
										</Tab>
										<Tab
											eventKey="skippedUsers"
											title={`Pas Geçenler (${
												question.skippedUsers.filter((i) =>
													this.state.studentId ? i.id == this.state.studentId : 1 == 1
												).length
											})`}
										>
											<DataTable
												columns={this.state.QuestionUserColumns}
												data={question.skippedUsers.filter((i) =>
													this.state.studentId ? i.id == this.state.studentId : 1 == 1
												)}
												pagination={true}
												paginationComponentOptions={{
													rowsPerPageText: 'Sayfa başına gösterilen kayıt sayısı: ',
													rangeSeparatorText: '/',
													selectAllRowsItemText: 'Tümü',
												}}
												noDataComponent={
													<div className="alert alert-info m-5">Kayıt bulunamadı.</div>
												}
												highlightOnHover={true}
												noHeader
											/>
										</Tab>
										<Tab
											eventKey="wrongAnsweredUsers"
											title={`Yanlış Cevaplayanlar (${
												question.wrongUsers.filter((i) =>
													this.state.studentId ? i.id == this.state.studentId : 1 == 1
												).length
											})`}
										>
											<DataTable
												columns={this.state.QuestionUserColumns}
												data={question.wrongUsers.filter((i) =>
													this.state.studentId ? i.id == this.state.studentId : 1 == 1
												)}
												pagination={true}
												highlightOnHover={true}
												paginationComponentOptions={{
													rowsPerPageText: 'Sayfa başına gösterilen kayıt sayısı: ',
													rangeSeparatorText: '/',
													selectAllRowsItemText: 'Tümü',
												}}
												noDataComponent={
													<div className="alert alert-info m-5">Kayıt bulunamadı.</div>
												}
												noHeader
											/>
										</Tab>
									</Tabs>
								)
								return (
									<div>
										<QuestionCardItem
											question={question}
											order={i}
											bottomComponentPosition="right"
											bottomComponent={bottomComponent}
										/>
									</div>
								)
							})}
						</div>
					</Tab>
				</Tabs>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		examResult: state.examResultReducer,
		examDetail: state.examDetailsReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			getAssignedUsers: bindActionCreators(
				examDetailsActions.getUsersGetAssignedUsers,
				dispatch
			),
			getExamsWithQuestions: bindActionCreators(
				examDetailsActions.getExamsWithQuestions,
				dispatch
			),
			getJoinedUsers: bindActionCreators(examResultActions.getGetJoinedUsers, dispatch),
			getExamQuestionUserReport: bindActionCreators(
				examResultActions.getExamQuestionUserReport,
				dispatch
			),
			studentTestReset: bindActionCreators(
				examResultActions.studentTestReset,
				dispatch
			),
			resetAllExamResultStatus: bindActionCreators(
				examResultActions.resetAllExamResultStatus,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamResults))
