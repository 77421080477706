import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function examResultReducer(
	state = initialState.ExamResultInitialState,
	action
) {
	switch (action.type) {
		case actionTypes.GET_USERS_GET_ASSIGNED_USERS_SUCCESS:
			return {
				...state,
				successGetAssignedUsers: action.payload.success,
				extraGetAssignedUsers: action.payload.extra,
			}
		case actionTypes.GET_EXAM_JOINED_USERS_SUCCESS:
			return {
				...state,
				successGetJoinedUsers: action.payload.success,
				extraGetJoinedUsers: action.payload.extra,
			}
		case actionTypes.GET_EXAM_QUESTION_USER_REPORT_SUCCESS:
			return {
				...state,
				successGetQuestionUsers: action.payload.success,
				extraGetQuestionUsers: action.payload.extra,
			}
		case actionTypes.REMOVE_STUDENT_TEST_RESULT_SUCCESS:
			return {
				...state,
				successStudentTestReset: action.payload.success,
				extraStudentTestReset: action.payload.extra,
			}

		case actionTypes.RESET_STATUSS_EXAM_RESULT:
			return {
				...state,
				successStudentTestReset: false,
				successGetAssignedUsers: false,
				successGetExams: false,
				successGetQuestionUsers: false,
			}
		case actionTypes.GET_EXAM_RESULT_SUCCESS:
			return {
				...state,
				successGetExams: action.payload.success,
				extraGetExams: action.payload.extra,
			}
		default:
			return state
	}
}
