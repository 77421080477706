import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function changeNavigationReducer(
  state = initialState.currentNvigation,
  action
) {
  switch (action.type) {
    case actionTypes.CHANGE_NAVIGATION:
      return action.payload //categoryActionstan gelen payload icinde category değeri var yani category i return eder

    default:
      return state
  }
}
