// import React, { Component } from 'react';
import React, { useEffect, useState } from "react"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import styled from "styled-components"
import { useDropzone } from "react-dropzone"
// import alertifyjs from 'alertifyjs';

// import CloseIcon from '@material-ui/icons/Close';

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
}

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginLeft: 8,
  marginRight: 8,
  width: "100%",
  height: "30vh",
  padding: 4,
  boxSizing: "border-box",
}

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
}

const img = {
  display: "block",
  width: "auto",
  height: "100%",
}

/********************************************************* */

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676"
  }
  if (props.isDragReject) {
    return "#ff1744"
  }
  if (props.isDragActive) {
    return "#2196f3"
  }
  return "#eeeeee"
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

function StyledDropzone(props) {
  const [files, setFiles] = useState([])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,

    acceptedFiles,
  } = useDropzone({
    accept: "image/*",

    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const files2 = acceptedFiles.map((file2) => (
    <div>
      <div className="textOnUpload">
        {file2.path}
        {/* {file2.size} bytes */}
      </div>
    </div>
  ))

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ))

  const sendDataFalse = () => {
    setFiles([])
  }

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  useEffect(() => {
    if (files.length > 0) {
      props.callback(true, files[files.length - 1])
    } else {
      props.callback(false, files[files.length - 1])
    }
  }, [files])

  return (
    <div className="container">
      {files.length > 0 ? null : (
        <Container
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          id="DragDropArea"
        >
          <input {...getInputProps()} />
          <p>
            <CloudUploadIcon fontSize="large" />
          </p>
        </Container>
      )}

      <aside>
        {/* <h4>Files</h4>
                <ul>{files2}</ul> */}
        {files.length > 0 ? (
          <div>
            <div className="overlay" onClick={sendDataFalse}>
              {files2}
            </div>
          </div>
        ) : null}

        {files.length > 0 ? <ul style={thumbsContainer}> {thumbs}</ul> : null}
      </aside>
    </div>
  )
}

export default StyledDropzone
