export default {
	config: {
		datetimeFormat: 'DD-MM-YYYY HH:mm:ss',
		dateFormat: 'DD-MM-YYYY',
	},
	currentNvigation: { naviName: 'home', data: '' },
	navigations: [
		{
			id: '2',
			naviName: 'Roller',
			seoUrl: 'roles',
			FullList: '1b37575c-208c-4b4d-95b1-07ffa6f235e4',
			List: 'b7af76d4-a0c1-4d16-9ff7-4cb65841469e',
			data: {},
			actionType: '',
		},
		{
			id: '4',
			naviName: 'Sorutipi',
			seoUrl: 'questions',
			FullList: '8ef87ce8-a6dd-4385-8de6-325bd9216b8f',
			List: 'a499c50f-f0c9-4875-a06a-186dd62b2e66',
			data: {},
			actionType: '',
		},
		{
			id: '6',
			naviName: 'Okullar',
			seoUrl: 'schools',
			FullList: 'a496182e-2b49-4b21-a20c-c378ce6ab5f9',
			List: '85db52ce-ae1b-40f9-b811-4ff34ab16a58',
			data: {},
			actionType: '',
		},
		{
			id: '8',
			naviName: 'Sınıflar',
			seoUrl: 'class',
			FullList: '3f1f8c85-fd3f-442c-b218-bee9f31e746c',
			List: '6409a0af-a247-4821-b266-30b7436187f3',
			data: {},
			actionType: '',
		},
		{
			id: '7',
			naviName: 'Kullanıcılar',
			seoUrl: 'users',
			FullList: 'd26ccdc1-b84d-4393-9c19-4482ebdf894b',
			List: '0cde5284-02db-4650-bf80-2c2d2cfda7ed',
			data: {},
			actionType: '',
		},

		{
			id: '3',
			naviName: 'Dersler',
			seoUrl: 'lessons',
			FullList: '372b2e3c-7b62-4420-b7a9-0eb0b52d9208',
			List: '1735ca72-8258-468c-9bb7-8a573fcedb27',
			data: {},
			actionType: '',
		},
		{
			id: '10',
			naviName: 'Konular',
			seoUrl: 'topics',
			FullList: '22ff0a60-ac9a-4210-8e39-908a5cc0e15c',
			List: '132b4132-3958-4006-91b6-9e218b1bc829',
			data: {},
			actionType: '',
		},
		{
			id: '9',
			naviName: 'Testler',
			seoUrl: 'exams',
			FullList: 'c2b01602-146f-4987-85e2-54b154862146',
			List: '8d66db88-05b5-427e-b978-eff28078c0fb',
			data: {},
			actionType: '',
		},
		{
			id: '11',
			naviName: 'Sorular',
			seoUrl: 'addQuestion',
			FullList: '9e4754f9-38e4-432e-952a-52c789595aa0',
			List: 'a761ff07-9ce7-4b1b-a844-a011a70f64a8',
			data: {},
			actionType: '',
		},
	],
	//************   TOKENS   ********** */
	userAuthData: {
		isAuthenticated: false,
		jwtToken: '',
		jwtDecodedToken: {},
		refreshToken: '',
		expiration: '',
		permissions: [],

		messages: '',
		success: '',
		settings: {
			font: 'Marmelad',
			color: 'Red',
			fontnamefull: "'Armata', sans-serif",
		},
	},

	//************   TOKENS   ********** */
	user: {},

	permissions: {},

	toggleStatuss: false,

	//************   TOKENS   ********** */
	staticPerms: {
		CanAskTpoicsByLessons: '8A06689B-AF05-4B91-AB21-5CF974300EAE', // Derse Ait Konu Sorgulama //*

		ExamAskByLesson: '94554D46-463B-4F3E-9CEF-9FB90049D122', //Derse Göre Test Sorgulama  // eklememişiz

		FindLesson: 'e1330905-ca22-4bb7-a070-074a627805da', //*
		FindFullListLesson: '4b76ed41-0c31-4905-94e0-1b095d816eef', //*
		DeleteLesson: 'aae57259-aa3f-4a9a-8fb0-cac2a185e804', //*
		UpdateLesson: 'd4655953-e64f-4994-9975-67498a4363cb', //*
		AddLesson: 'ff8834df-4c8b-4226-bf0f-ec88ec658c9e', //*
		FullDeleteLesson: '01492830-918c-45ba-8b70-e84b78a43dc3', //*
		FullListLesson: '372b2e3c-7b62-4420-b7a9-0eb0b52d9208', //*
		ListLesson: '1735ca72-8258-468c-9bb7-8a573fcedb27', //*
		//**************************************************************** */

		UpdatePermission: '6904fa03-7dbb-4939-a2eb-0ff25033f1ed',
		DeletePermission: '1e184def-f2ac-4d57-a32e-2f2d475a50f8',
		FindPermission: 'c72f0af4-d3fd-4287-b3b1-fe540129a2c3',
		AddPermission: '61ab7e33-0ae9-40b3-884e-81a140885941',
		FullDeletePermission: 'f7eeda55-75df-4b9b-8c9d-e505ae51a199',
		FindFullListPermission: 'c6485ba6-1005-46e9-8e11-fe822704a80f',

		//**************************************************************** */

		ExamAskByTopic: '80A22D8F-0F82-414E-963B-7592A3F164C9'.toLowerCase(), //Konuya Göre Test Sorgulama //*

		FullDeleteTopic: '399961d3-4051-4eee-b672-1126086b2f7c', //*
		FindTopic: '03294570-879b-4958-892d-ee9d5c96e337', //*
		DeleteTopic: '3e11136d-5d96-42aa-9119-f9f1dde3f8b1', //*
		FindFullListTopic: '7F180674-9504-40D0-AE99-CF033BB0D59B'.toLowerCase(), //*
		UpdateTopic: 'b034333b-41d2-4295-adcf-9d475e3ebf0b', //*
		DetailTopic: '42de53ff-10bf-44c6-9f77-06e8e3bc4bc2', //*
		AddTopic: '7ef7655e-4a50-4d39-a125-2dc200de0e35', //*
		FullListTopic: '22ff0a60-ac9a-4210-8e39-908a5cc0e15c', //*
		ListTopic: '132b4132-3958-4006-91b6-9e218b1bc829', //*
		//**************************************************************** */

		//**************************************************************** */ Topic Materials
		ListTopicMaterial: 'EAD0CEC2-A6FD-4C38-BF88-976E8EBCA42D'.toLowerCase(),
		AddTopicMaterial: 'C9CF1297-7D75-40CB-9086-5A11AD109402'.toLowerCase(),
		UpdateTopicMaterial: '677DDA99-EE10-4438-8864-7644897B811A'.toLowerCase(),
		DeleteTopicMaterial: 'E51565E1-5C26-45F0-A90A-86AD8AAA5929'.toLowerCase(),
		//**************************************************************** */

		CanAskRolePermissions: '7CB72B0F-5ECE-4D4B-AAB9-9EAD3D28292D'.toLowerCase(), // Rol Yetkisi Sorgulama //*
		CanUpdateRolePermissions: '51796E74-4B6C-4DD9-A469-DCF9E64D5750'.toLowerCase(), // Rol Yetkisi Güncelleme //*

		FindRole: '2f32c7df-7ee4-4463-9add-14ddacba9570', //*
		UpdateRole: '9ed0f042-4a6c-4b01-9b26-5c3d334f672b', //*
		FindFullListRole: '44c92d7b-16a9-48b9-b307-559b429f4699', //*
		AddRole: '317f500e-9f8b-446a-a18e-6bb051fc19b7', //*
		FullDeleteRole: '4d0ae9a1-3cde-4094-816b-fdf5f54049ef', //*
		DeleteRole: 'b560c1f4-1776-43be-aae5-7588faeb234b', //*
		FullListRole: '1b37575c-208c-4b4d-95b1-07ffa6f235e4', //*
		ListRole: 'b7af76d4-a0c1-4d16-9ff7-4cb65841469e', //*

		//**************************************************************** */
		UpdateExam: '5d179f83-c689-4e7d-a425-244c0ff189e9', //*
		FullDeleteExam: '6afc1076-468f-42ab-bf6c-14e631628afe', //*
		FindExam: '7d04524e-ef87-40c4-8735-6ae8a54df1d8', //*
		DeleteExam: '5cb3f2e2-ad20-4f09-8069-f8ff36f4b875', //*
		FindFullListExam: '22c1b83c-356e-4432-860b-fa21aafd62f5', //*
		AddExam: 'a66338ba-b455-49e8-95fa-ff6233dda787', //*
		FullListExam: 'c2b01602-146f-4987-85e2-54b154862146', //*
		ListExam: '8d66db88-05b5-427e-b978-eff28078c0fb', //*

		//**************************************************************** */

		CanSeeUserDetails: '8A5BE6BE-5451-4DF8-BB35-B1D4F0B12D15'.toLowerCase(), //Kullanıcı detay görme //*
		CanAddTeacher: 'A2BDF210-4D3F-476D-AF8A-0493961C1E30'.toLowerCase(), //Kullanıcıya test atama //*
		UserAssignExam: 'E034ED78-5BDD-49D0-B1ED-5D3B65FC9168'.toLowerCase(), //Kullanıcıya test atama //*
		UserNotAssignExam: 'AF5CA57E-7E26-497D-9DF2-73B2C9152497'.toLowerCase(), //Kullanıcıdan Test Kaldırma //*
		NotAssignedExamsByUser: '8D870AAF-6666-4A61-AB70-8E2A0EF7A7DB'.toLowerCase(), //Kullanıcıya Göre Atanmayan Test Sorgulama //*

		CanAskUserRoles: '14EDC09C-1B5A-4ABE-A833-462C24D7F311'.toLowerCase(), // Kullanıcı Rolü Sorgulama
		CanAssignRoleToUser: 'A4113EB3-65E5-479D-8C0C-4B939F76706B'.toLowerCase(), // Kullanıcıya Rol Atama //*

		FindUser: 'eafce107-abb1-49d3-bbc9-1920fe2dc58b', //*
		DeleteUser: 'ca44e363-b0d9-4efb-9fc7-d5eea8bafca0', //*
		UpdateUser: '9b5cd2f7-61a6-4563-b659-97eb67594170', //*
		FindFullListUser: '25cc07dd-85d1-4cb7-bd78-f4d6685ff78a', //*
		FullDeleteUser: '54b70b8a-c872-40ef-b0e7-f2809327a77a', //*
		AddUser: 'fbb9170d-bdce-48be-981b-925e651a4d63', //*
		FullListUser: 'd26ccdc1-b84d-4393-9c19-4482ebdf894b', //*
		ListUser: '0cde5284-02db-4650-bf80-2c2d2cfda7ed', //*

		CanAskUserByClassroom: '2B67D0ED-F9EC-4E9B-9F1E-BC2D2FBCDCA9'.toLowerCase(), // Sınıfa Ait Kullanıcı Sorgulama //*
		ExamAskByClassroom: 'E0DC191D-9417-4912-BE2E-64ED50B7F435'.toLowerCase(), //Sınıfa Göre Test Sorgulama  //*
		NotAssignedExamsByClassRoom: '690596C5-B0BE-44B8-9A9A-183654402E70'.toLowerCase(), //Sınıfa Göre Atanmayan Test Sorgulama  //*

		AssignLessonToClass: 'AEE399BF-E758-4557-A77F-D3B6559C2934'.toLowerCase(), //Sınıfa Ders Atama   //*
		NotAssignLessonToClass: 'F1634EDB-9DE3-4BF9-A83C-B9B14A58E845'.toLowerCase(), //Sınıftan Ders Kaldırma' //*
		ListAssignedLessonsByClass: 'F18FECAE-EA6A-4452-9FE6-11C6C4BA2DBF'.toLowerCase(), //Sınıfa Ait Ders Listesi Görüntüleme //*
		ListNotAssignedLessonsByClass: 'F403C274-0AB4-4650-B8DF-A30C6088193F'.toLowerCase(), //Sınıfa Atanmamış Ders Listesi Görüntüleme  //*
		ClassAssignExam: 'C5A5E801-DE8B-4865-891E-A05C1F33113C'.toLowerCase(), //Sınıfa Test Atama
		ClassNotAssignExam: '125C710D-59FE-4168-9F48-A64538CA6F45'.toLowerCase(), //sınıftan Test kaldırma

		AddClassroom: '5733742c-e949-4ab4-8825-198ceca199bb', //*
		DeleteClassroom: '615fcd9b-29fc-4bb7-b1c2-6108f7647baa', //*
		FullDeleteClassroom: 'fe879fa1-63a4-444b-9020-dfa37aa68040', //*
		UpdateClassroom: '35c71fce-6b1a-4ce3-ad63-8eacf29623ac', //*
		FindFullListClassroom: 'c204cc24-6282-46ca-aabe-54ac9c9f115c', //*
		FindClassroom: '97a164b7-75c1-4250-842c-751c36fa56d8', //*
		FullListClassroom: '3f1f8c85-fd3f-442c-b218-bee9f31e746c', //*
		ListClassroom: '6409a0af-a247-4821-b266-30b7436187f3', //*

		canAddManagerToSchool: 'C0C2B801-9148-4B00-BD2F-961D87C1E76E'.toLowerCase(), //*
		canRemoveManagerToSchool: 'ED7AEDBF-8F81-4EA2-90FA-D810657F75F4'.toLowerCase(), //*
		DeleteSchool: 'edda3d4d-cef0-474c-9a3b-5510675d1c19', //*
		FindSchool: 'cb6c5fbd-2244-4791-ba27-bab3970d01ed', //*
		FullDeleteSchool: 'c7fcb62d-e475-404f-9a46-40d17c6b8fe3', //*
		UpdateSchool: '9c941f19-4af0-4b35-8160-74b23858a38f', //*
		FindFullListSchool: '7a11c68a-0ccf-4350-82b7-291a37cf5d26', //*
		AddSchool: 'df5b1707-c390-4088-b76c-6cca122efceb', //*
		FullListSchool: 'a496182e-2b49-4b21-a20c-c378ce6ab5f9', //*
		ListSchool: '85db52ce-ae1b-40f9-b811-4ff34ab16a58', //*

		FullDeleteAnswer: 'fe89483e-d7e9-4f1d-a6b9-6b2eb8139afd', //*
		AddAnswer: '08fceb70-a4d1-424b-97bf-fa90b959386f', //*
		UpdateAnswer: 'd065fb0b-f2d6-41d9-aa65-b06cf2b8a534', //*
		FindAnswer: 'c4512ba2-25f0-4d97-a440-ecbc33cd9fff', //*
		FindFullListAnswer: 'e0fda07f-dc55-4988-9931-6c405dd70317', //*
		DeleteAnswer: 'e32da081-4c32-4c66-9349-7993c5b23f57', //*
		FullListAnswer: 'db4154d1-6bb9-4b9f-b4c6-29157d1696ea', //*
		ListAnswer: 'a5b3d938-9907-4349-a12c-223855df3b88', //*

		FullDeleteQuestion: 'ADAD001A-283B-46D9-8273-218E69A85084',

		FullDeleteQuestionType: 'ce683761-887f-426c-8c39-c23d3ae0de55', ///*
		AddQuestionType: '7bd51b28-82c2-434a-b55a-566bef795889', //*
		DeleteQuestionType: '39f3a517-9f2f-4676-bb23-58e182a1641c', //*
		FindFullListQuestionType: '9d12ab37-e908-430e-b2e8-69fd35fd15c5', //*
		UpdateQuestionType: '9caa1654-535a-4b78-819b-6dba373b4165', //*
		FindQuestionType: '3596f706-cb2d-49aa-ae37-dff0a930e8e5', //*
		FullListQuestionType: '8ef87ce8-a6dd-4385-8de6-325bd9216b8f', //*
		ListQuestionType: 'a499c50f-f0c9-4875-a06a-186dd62b2e66', //*

		getQuestionWithAnswers: '6FAEEACA-101D-4DCA-850B-96BC20541A29'.toLowerCase(),
		AddQuestion: '6FAEEACA-101D-4DCA-850B-96BC20541A29'.toLowerCase(), //** */
		FullDeleteQuestion: 'ADAD001A-283B-46D9-8273-218E69A85084'.toLowerCase(), //** */
		FindFullListQuestion: '8358C50D-3475-4CE8-907A-3E101DD3B2CB'.toLowerCase(), //** */
		UpdateQuestion: '03ABB407-FED7-4FDA-966B-3F487A508101'.toLowerCase(), //** */

		FindQuestion: '5AE1BEBE-A5D8-480E-A8FC-35AC4BECF2E0'.toLowerCase(), //** */
		FullListQuestion: '9E4754F9-38E4-432E-952A-52C789595AA0'.toLowerCase(), //*
		ListQuestion: 'A761FF07-9CE7-4B1B-A844-A011A70F64A8'.toLowerCase(), //*

		StartExam: '85A129C9-34D7-4056-81B4-239178CA967A'.toLowerCase(), //'Teste Başlama //*
		StartExamAgain: '4A20B020-0CCD-4BB3-AD3F-5AA14D1BC59A'.toLowerCase(), //'Testi Tekrarlama //*
		ViewExamDetails: 'E978E18E-C644-4301-B031-31728939D8A4'.toLowerCase(), // Test Detayı Görüntüleme //*
		ViewExamResults: '006EF07F-4819-424F-8B84-3E963E5B4A66'.toLowerCase(), // Test Sonucu Görüntüleme //*

		SolveQuetion: '7BDC90DD-1458-4473-96FC-3DA8C73E4542'.toLowerCase(), //Soru Cevaplama
		EndExam: '78A6A019-3E0A-4FC0-8DC7-28D20AE9E01A'.toLowerCase(), //Testi Tamamlama

		// find received messages yetkisi
		// find sended messages yetkisi
		CanSendMessage: 'E7CD2E99-0ED3-4F89-9BD4-9A66A456CF86'.toLowerCase(), //Mesaj Gönderebilme //*
		CanListSendedMessages: 'E0B588AC-EC57-4954-98F2-6E5AA4EDF5BA'.toLowerCase(), //Gönderilen Mesaj Listesi //*
		CanListReceivedMessages: 'CE8BF3B4-B80A-4A37-8219-623D0660F9AF'.toLowerCase(), //Alınan Mesaj Listesi //*
		CanReadMessage: '1E398734-7802-4FFE-8743-F1A3548CBFBF'.toLowerCase(), // Mesaj Okuma //*

		ExamAskByUser: '0C6EE96D-9C81-4BB7-9864-8C3EAD99D34C'.toLowerCase(), //Kullanıcıya Göre Test Sorgulama
		CanAskUSerByRealExam: 'AB786673-A165-4911-8E06-E6C91FEF5EAD'.toLowerCase(), // Teste Girecek Kullanıcı Listesi
		CanASkUserBySchool: '04F62CFB-BF27-41E0-8496-C7339CCB881B'.toLowerCase(), // Okuldaki Kullanıcı Listesi

		CanUploadDoc: '73BAA52D-249C-43CD-B583-DA78BACCD60B'.toLowerCase(), // Dosya Yükleyebilme
		CanDownloadDoc: 'DF3DE6C3-8D28-4D89-87B0-FF6F27A2F294'.toLowerCase(), // Dosya İndirebilme

		canAssignTeacher: '3407C7BF-7E73-4993-9AD6-CF66EDE241DD'.toLowerCase(), // öğretmen ata
		removeAssignTeacher: '461167D8-ECBB-4FB4-BD51-7A6B09A4E47B'.toLowerCase(), // öğretmen çıkar

		removeQuestionFromExam: '0D95BFF4-E5C3-4C7B-A160-B9C212F0816F'.toLowerCase(),
		addQuestionToExam: '578D77D0-8576-464F-8361-DE098F013086'.toLowerCase(),
		removeStudentTestResult: '295391BE-D4E9-40AB-9D05-CB9804411CE3'.toLowerCase(),

		adminPermsId: '0D2A6274-E48F-407F-B79F-07A632D5158B'.toLowerCase(),
	},

	Initialroles: {
		successCreated: '',
		successDeleted: '',
		successGettedRoles: '',
		successUpdated: '',
		successChanged: '',
		successGettedRole: '',
		successAsignedRole: '',
		successGetUSerRole: '',
		successSetUSerRole: '',

		extraGettedRoles: [],
		dataUserRole: [],
		extraUserRole: [],

		mesages: [],
	},

	InitialfullRoles: {
		successGettedFullRoles: '',
		extraGettedFullRoles: [],
		messages: [],
	},
	//********************* */
	Initiallessons: {
		successCreated: '',
		SuccessDeleted: '',
		successGettedLesson: '',
		successUpdated: '',

		extraGetted: [],

		messages: [],
	},

	InitialfullLessons: {
		extra: [],
		successFullGettedLesson: '',
		extraFullGettedLesson: [],
		messages: '',
	},
	//********************* */
	schools: { extra: [], deleted: '', messages: '', success: '' },
	fullSchools: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	ussers: { extra: [], deleted: '', messages: '', success: '' },
	fullUssers: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	classrooms: { extra: [], deleted: '', messages: '', success: '' },
	fullClassrooms: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	questions: { extra: [], deleted: '', messages: '', success: '' },
	fullQuestions: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	questionTypes: { extra: [], deleted: '', messages: '', success: '' },
	fullQuestionTypes: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	answers: { extra: [], deleted: '', messages: '', success: '' },
	fullAnswers: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	topics: { extra: [], deleted: '', messages: '', success: '' },
	topicMaterials: { extra: [], deleted: '', messages: '', success: '' },
	fullTopics: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	exams: {
		extra: [],
		deleted: '',
		messages: '',
		success: '',
		extraNotSolved: [],
		extraNotSolvedLAstFive: [],
	},
	fullExams: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	questionsWithAnswer: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	uploadFiles: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	gettedExamsById: {
		extra: [],
		deleted: '',
		messages: '',
		success: '',
		Updatedsuccess: '',
	},
	//********************* */
	//********************* */
	usersAssignedNot: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	usersOnClassroom: {
		extra: [],
		deleted: '',
		messages: '',
		success: '',
		Addedsuccess: '',
		removedSucces: '',
	},
	//********************* */
	ManagersAssignedNot: { extra: [], deleted: '', messages: '', success: '' },
	//********************* */
	ManagersOnClassroom: {
		extra: [],
		deleted: '',
		messages: '',
		success: '',
		Addedsuccess: '',
		removedSucces: '',
	},
	//********************* */
	UserDetailsExamInitialState: {
		extra: [],
		successGet: '',
		successAdd: '',
		successRemove: '',
		messages: [],
	},

	//********************* */
	ExamDetailsInitialState: {
		successGetExams: '',
		successGetUsers: '',
		successGetClassrooms: '',

		extraGetExams: { questions: [], exams: {} },
		extraGetUsers: [],
		extraGetClassrooms: [],

		messages: [],
	},
	ExamResultInitialState: {
		successGetExams: '',
		successGetAssignedUsers: '',
		successGetJoinedUsers: '',
		successGetQuestionUsers: '',

		extraGetExams: { questions: [], exams: {} },
		extraGetAssignedUsers: [],
		extraGetJoinedUsers: [],
		extraGetQuestionUsers: [],

		messages: [],
	},
	//********************* */
	messagesInitial: {
		successGettedMessages: '',
		successSendedMessages: '',
		successUpdateMessages: '',
		successCreateMessage: '',
		successReceivers: '',

		extraGettedMessages: [],
		extraSendedMessages: [],
		extraReceivers: [],
	},
	//********************* */
	RealExamInitialState: {
		successGetRealExam: '',
		successPostAnswer: '',
		successFinishRealEXam: '',
		successDetailsExam: '',

		extraPostAnswer: {
			message: '',
		},

		extraGetRealExam: {
			school: {
				id: '',
				schoolName: '',
				isActive: '',
			},
			classroom: {
				id: '',
				className: '',
				schoolId: '',
				isActive: '',
			},
			lesson: {
				id: '',
				lessonName: '',
				isActive: '',
			},
			topic: {
				id: '',
				topicName: '',
				lessonId: '',
				isActive: '',
			},
			exam: {
				id: '',
				examName: '',
				topicId: '',
				adminDescription: '',
				userDescription: '',
				resultType: '',
				resultDate: '',
				deadLine: '',
				topic: '',
				lesson: '',
				isActive: '',
			},
			isClassExam: '',
			userDto: '',
			questions: [],
		},

		extraDetailsExam: {
			school: {
				id: '',
				schoolName: '',
				isActive: '',
			},
			classroom: {
				id: '',
				className: '',
				schoolId: '',
				isActive: '',
			},
			lesson: {
				id: '',
				lessonName: '',
				isActive: '',
			},
			topic: {
				id: '',
				topicName: '',
				lessonId: '',
				isActive: '',
			},
			exam: {
				id: '',
				examName: '',
				topicId: '',
				adminDescription: '',
				userDescription: '',
				resultType: '',
				resultDate: '',
				deadLine: '',
				topic: '',
				lesson: '',
				isActive: '',
			},
			isClassExam: '',
			userDto: '',
			questions: [],
		},

		messages: [],
	},
	//************************** */ dashboard
	DashboardInitialState: {
		extraGetted: {
			box4: 0, //unreadMessageCount
			box3: 0, //userCountInClass
			box1: 0, //notAssignedExamsCount
			box2: 0.0, //rightPercent
		},
	},
}
