import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as classroomActions from '../../../redux/actions/classroomActions'
import * as schoolActions from '../../../redux/actions/schoolActions'
import { ScrollView } from '../base/scroll'



class SchoolsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			ClassName: '',
			schoolSelection: '-1',
		}
	}

	componentDidMount() {
		this.props.actions.getSchool()

		this.props?.location?.state?.actionType === 'update'
			? this.setState({
				ClassName: this.props.location.state.data.className,
				schoolSelection: this.props.location.state.data.schoolId,
			  })
			: this.setState({ ClassName: '', schoolSelection: '-1' })

		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.classroomData.success) {
			var location = {
				pathname: 'Sınıflar',
			}

			this.props.history.push(location)
		} else if (newProps.classroomData.success === false) {
			alertifyjs.error('Hata: ' + newProps.classroomData.errorMessage)
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.props.actions.resetAllStatuss('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateClassroom({
						ClassName: this.state.ClassName,
						SchoolId: this.state.schoolSelection,
						id: this.props.location.state.data.id,
					  })
					: this.props.actions.createClassroom({
						ClassName: this.state.ClassName,
						SchoolId: this.state.schoolSelection,
					  })
			}
		}
	}

	validationFunc() {
		if (this.state.schoolSelection !== '-1') {
			if (this.state.ClassName.length > 1) {
				return true
			} else {
				alertifyjs.error('Sınıf Adı 2 Haneden Kısa Olamaz!')
				return false
			}
		} else {
			alertifyjs.error('Okul Seçimini yapmış olmalısınız!')
			return false
		}
	}

	onChange(event) {
		const { name, value } = event.target
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>

					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="schoolSelection">Okul Seçimi</Label>
										<Input
											type="select"
											name="schoolSelection"
											id="schoolSelection"
											onChange={this.setOnChange}
											value={this.state.schoolSelection}
										>
											<option value="-1" key="-1">
												Seçiniz
											</option>
											{this.props.schoolsData.extra.map((school) => {
												{
													return (
														<option value={school.id} key={school.id}>
															{school.schoolName}{' '}
														</option>
													)
												}
											})}
										</Input>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="ClassName">Sınıf Adı</Label>
										<Input
											type="text"
											name="ClassName"
											id="ClassName"
											placeholder="Sınıf Adı Belirleyiniz!"
											value={this.state.ClassName}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Sınıfı Güncelle
								</Button>
							) : (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Sınıfı Oluştur
								</Button>
							)}
						</Form>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		classroomData: state.classroomReducer,

		schoolsData: state.schoolReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			createClassroom: bindActionCreators(classroomActions.createClassroom, dispatch),
			setClassroomRegStatuss: bindActionCreators(
				classroomActions.setClassroomRegStatuss,
				dispatch
			),
			getSchool: bindActionCreators(schoolActions.getSchool, dispatch),
			resetAllStatuss: bindActionCreators(classroomActions.resetAllStatuss, dispatch),
			updateClassroom: bindActionCreators(classroomActions.updateClassroom, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolsForm))
