/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as lessonActions from '../../../redux/actions/lessonActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'






class Lessons extends Component {
	constructor(props) {
		super(props)

		this.state = {
			dataChart: [
				{
					name: 'Matematik',
					Adet: 5,
				},
				{
					name: 'İngilizce',
					Adet: 10,
				},
				{
					name: 'Almanca',
					Adet: 50,
				},
				{
					name: 'Fransızca',
					Adet: 20,
				},
			],

			rolesColumns: [
				{
					name: 'Ders Adı',
					selector: 'lessonName',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.onRowClickDetails(row, 'LessonDetails')}
								>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateLessons')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteLesson
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteLesson
						) === true ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteLesson
						) == true
							? '50px'
							: '0px',
				},
			],

			FullRolesColumns: [
				{
					name: 'Ders Adı',
					selector: 'lessonName',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.onRowClickDetails(row, 'LessonDetails')}
								>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateLessons')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteLesson
						) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteLesson
						) == true
							? '65px'
							: '0px',
				},
			],
		}
	}

	componentWillMount() {
		this.props.actions.resetAllStatuss('')

		this.props.actions.getLessons()
		this.props.actions.getFullLessons()
	}

	componentDidMount() {
		this.setState({ lessonListData_onQuery: this.props.lessons.extraGetted })
		this.setState({
			lessonFullListData_onQuery: this.props.fullLessons.extraFullGettedLesson,
		})
	}

	componentWillReceiveProps(newProps) {
		if (newProps.lessons.successUpdated === true) {
			newProps.actions.resetAllStatuss('')
			newProps.actions.getLessons()
			newProps.actions.getFullLessons()
		} else if (newProps.fullLessons.successUpdated === false) {
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.lessons.SuccessDeleted === true) {
			alertifyjs.success('Ders Başarılı Olarak Silindi!')

			newProps.actions.resetAllStatuss('')
			newProps.actions.getLessons()
			newProps.actions.getFullLessons()
		} else if (newProps.lessons.SuccessDeleted === false) {
			alertifyjs.error('Ders Silinirken Hatalar Oluştu.')

			newProps.actions.resetAllStatuss('')
		}

		if (newProps.lessons.successGettedLesson === true) {
			this.setState({ lessonListData_onQuery: newProps.lessons.extraGetted })
			newProps.actions.resetGetLessonSuccess('')
		} else if (newProps.lessons.successGettedLesson === false) {
			this.setState({ lessonListData_onQuery: newProps.lessons.extraGetted })
			newProps.actions.resetGetLessonSuccess('')
		}

		if (newProps.fullLessons.successFullGettedLesson === true) {
			this.setState({
				lessonFullListData_onQuery: newProps.fullLessons.extraFullGettedLesson,
			})
			newProps.actions.resetGetFullLessonSuccess('')
		} else if (newProps.fullLessons.successFullGettedLesson === false) {
			newProps.actions.resetGetFullLessonSuccess('')
		}
	}

	selectNavigation = (navigation) => {
		this.props.actions.resetAllStatuss('')

		var location = {
			pathname: navigation,
		}

		this.props.history.push(location)
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var lessonListData_onQuery = this.props.lessons.extraGetted.filter((item) =>
				item.lessonName.like(e.target.value)
			)
			this.setState({ lessonListData_onQuery })
		} else {
			this.setState({ lessonListData_onQuery: this.props.lessons.extraGetted })
		}
	}

	searchOnFullList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var lessonFullListData_onQuery = this.props.fullLessons.extraFullGettedLesson.filter(
				(item) => item.lessonName.like(e.target.value)
			)
			this.setState({ lessonFullListData_onQuery })
		} else {
			this.setState({
				lessonFullListData_onQuery: this.props.fullLessons.extraFullGettedLesson,
			})
		}
	}

	handleClick = (state) => {}

	onRowClickUpdate = (row, navigation) => {
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	onRowClickDetails = (row, navigation) => {
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'details',
			},
		}

		this.props.history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteLesson(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteLesson(data)
		}
	}

	//**************************************************** */
	//**************************************************** */
	//**************************************************** */

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Dersler</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.rolesColumns}
						data={this.state.lessonListData_onQuery}
						pagination
						highlightOnHover
						noHeader
						progressPending={this.props.lessons.extraGetted.length > 0 ? false : true}
						subHeader
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddLesson
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateLessons')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindLesson
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Dersler</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullRolesColumns}
						data={this.state.lessonFullListData_onQuery}
						pagination
						highlightOnHover
						progressPending={
							this.props.fullLessons.extraFullGettedLesson.length > 0 ? false : true
						}
						subHeader
						noHeader
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddLesson
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateLessons')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindFullListLesson
								) === true ? (
										<TextField
											onChange={this.searchOnFullList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		//************************************************** */
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListLesson
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListLesson
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>

				{/* ********************************************************************************************** */}
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		lessons: state.lessonsReducer,
		fullLessons: state.fullLessonsReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getLessons: bindActionCreators(lessonActions.getLessons, dispatch),
			resetGetLessonSuccess: bindActionCreators(
				lessonActions.resetGetLessonSuccess,
				dispatch
			),

			getFullLessons: bindActionCreators(lessonActions.getFullLessons, dispatch),
			resetGetFullLessonSuccess: bindActionCreators(
				lessonActions.resetGetFullLessonSuccess,
				dispatch
			),

			deleteLesson: bindActionCreators(lessonActions.deleteLesson, dispatch),
			fullDeleteLesson: bindActionCreators(lessonActions.fullDeleteLesson, dispatch),
			resetDeleteLessonSuccess: bindActionCreators(
				lessonActions.resetDeleteLessonSuccess,
				dispatch
			),

			createLesson: bindActionCreators(lessonActions.createLesson, dispatch),
			resetCreateLessonSuccess: bindActionCreators(
				lessonActions.resetCreateLessonSuccess,
				dispatch
			),

			resetAllStatuss: bindActionCreators(lessonActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lessons))
