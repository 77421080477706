import { OverlayTrigger, Popover } from 'react-bootstrap'
import React from 'react'

export function QuestionPopover(props) {
	let question = props.question

	const checkRightAnswer = (answer) => {
		return answer.id == question.rightAnswer
	}

	const getRightAnswerCssClass = (answer) =>
		checkRightAnswer(answer) ? 'alert-success' : ''

	return (
		<OverlayTrigger
			trigger="hover"
			placement="right"
			overlay={
				<Popover id="popover-basic">
					<Popover.Title as="h3">Soru Seçenekleri</Popover.Title>
					<Popover.Content>
						{question.fileUrl && <img src={question.fileUrl} className="mb-1" />}
						{question.answers.map((answer, i) => (
							<div className="input-group mb-1">
								<div className="input-group-prepend">
									<span className={`input-group-text ${getRightAnswerCssClass(answer)}`}>
										{i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : i === 3 ? 'D' : '?'}
									</span>
								</div>
								<label
									className={`question-answer form-control ${getRightAnswerCssClass(answer)}`}
								>
									<img src={answer.fileUrl} style={{ maxHeight: 100 }} />
									{answer.answerText}
								</label>
							</div>
						))}
						<span className="badge badge-secondary">{question.questionType}</span>
					</Popover.Content>
				</Popover>
			}
		>
			<div dangerouslySetInnerHTML={{ __html: question.questionText }}></div>
		</OverlayTrigger>
	)
}
