import {
	GET_QUESTION_WTH_ANSWERS_SUCCESS,
	GET_QUESTION_WTH_ANSWERS_RESET_STATUSS,
	ONFAULT_EXAM_QUESTION_WTH_ANSWERS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const getQuestionWithAnswersSuccess = (Data) => {
	return {
		type: GET_QUESTION_WTH_ANSWERS_SUCCESS,
		payload: Data,
	}
}

export const resetQuestionWithAnswersStatuss = (Data) => {
	return {
		type: GET_QUESTION_WTH_ANSWERS_RESET_STATUSS,
		payload: Data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_EXAM_QUESTION_WTH_ANSWERS,
		payload: data,
	}
}

// {
//     "success": false,
//     "errorMessage": "Soru bulunurken bir hata meydana geldi:Object reference not set to an instance of an object.",
//     "extra": null
// }

export const getQuestionWithAnswers = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Questions/GetQuestionWithAnswer'
	if (data.id) {
		url = url + '?id=' + data.id
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getQuestionWithAnswersSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
