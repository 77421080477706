/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import jsPDF from 'jspdf'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as lessonActions from '../../../redux/actions/lessonActions'
import * as topicActions from '../../../redux/actions/topicActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'







class Topics extends Component {
	find_in_object(array1, my_criteria) {
		return array1.map((obj) => {
			if (obj.id === my_criteria) {
				return obj.lessonName
			}
		})
	}

	constructor(props) {
		super(props)

		this.state = {
			dataChart: [
				{
					name: 'Matematik',
					Adet: 5,
				},
				{
					name: 'İngilizce',
					Adet: 10,
				},
				{
					name: 'Almanca',
					Adet: 50,
				},
				{
					name: 'Fransızca',
					Adet: 20,
				},
			],

			QuestionsColumns: [
				{
					name: 'Konu Adı',
					selector: 'topicName',
					sortable: true,
					width: 'auto',
				},
				{
					name: 'Ders',
					selector: 'lessonId',
					sortable: true,
					width: 'auto',
					cell: (row) =>
						this.find_in_object(
							this.props.fullLessons.extraFullGettedLesson,
							row.lessonId
						),
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					width: 'auto',
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DetailTopic
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.onRowClickDetails(row, 'TopicDetails')}
								>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DetailTopic
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateTopic
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateTopics')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateTopic
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteTopic
						) === true ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteTopic
						) == true
							? '50px'
							: '0px',
				},
			],

			FullQuestionColumns: [
				{
					name: 'Konu Adı',
					selector: 'topicName',
					sortable: true,
					width: 'auto',
				},
				{
					name: 'Ders Id',
					selector: 'lessonId',
					sortable: true,
					cell: (row) =>
						this.find_in_object(
							this.props.fullLessons.extraFullGettedLesson,
							row.lessonId
						),
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					width: 'auto',
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.onRowClickDetails(row, 'TopicDetails')}
								>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateLesson
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateTopic
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateTopics')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateTopic
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteTopic
						) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteTopic
						) == true
							? '65px'
							: '0px',
				},
			],
		}
	}

	exportPDF = (dataGElen) => {
		const unit = 'pt'
		const size = 'A4' // Use A1, A2, A3 or A4
		const orientation = 'portrait' // portrait or landscape

		const marginLeft = 40
		const doc = new jsPDF(orientation, unit, size)

		doc.setFontSize(10)

		const title = 'KONU RAPORLAMALARI'
		const headers = [['KONU ADI', 'DERS ADI']]

		const data = dataGElen.map((elt) => [
			elt.topicName,
			this.find_in_object(this.props.fullLessons.extraFullGettedLesson, elt.lessonId),
		])

		let content = {
			startY: 50,
			head: headers,
			body: data,
		}

		doc.text(title, marginLeft, 40)
		doc.autoTable(content)
		doc.save('report.pdf')
	}

	componentDidMount() {
		this.props.actions.getFullLessons()
		this.props.actions.getTopics()
		this.props.actions.getFullTopics()

		this.setState({ TopicListData_onQuery: this.props.topics.extra })
		this.setState({ TopicFullListData_onQuery: this.props.fullTopics.extra })

		this.props.actions.changeDeletedTopicSuccessStatus('')
		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.topics.deleted) {
			alertifyjs.success('Konu Başarılı Olarak Silindi!')
			newProps.actions.changeDeletedTopicSuccessStatus('')
			newProps.actions.resetAllStatuss('')
			newProps.actions.getTopics()
			newProps.actions.getFullTopics()
		} else if (newProps.topics.deleted === false) {
			alertifyjs.error('Konu Silinirken Hatalar Oluştu.')
			newProps.actions.changeDeletedTopicSuccessStatus('')
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.topics.success) {
			this.setState({ TopicListData_onQuery: newProps.topics.extra })
			newProps.actions.resetAllStatuss('')
		} else if (newProps.topics.success === false) {
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.fullTopics.success) {
			this.setState({ TopicFullListData_onQuery: newProps.fullTopics.extra })
			newProps.actions.resetGetFullTopicSuccess('')
		} else if (newProps.fullTopics.success === false) {
			newProps.actions.resetGetFullTopicSuccess('')
		}
	}

	selectNavigation = (pathname) => {
		this.props.actions.changeDeletedTopicSuccessStatus('')
		this.props.actions.resetAllStatuss('')

		var location = {
			pathname,
		}

		this.props.history.push(location)
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var TopicListData_onQuery = this.props.topics.extra.filter((item) =>
				item.topicName.like(e.target.value)
			)
			this.setState({ TopicListData_onQuery })
		} else {
			this.setState({ TopicListData_onQuery: this.props.topics.extra })
		}
	}

	searchOnFullList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var TopicFullListData_onQuery = this.props.fullTopics.extra.filter((item) =>
				item.topicName.like(e.target.value)
			)
			this.setState({ TopicFullListData_onQuery })
		} else {
			this.setState({ TopicFullListData_onQuery: this.props.fullTopics.extra })
		}
	}

	handleClick = (state) => {}

	onRowClickDetails = (data, pathname) => {
		var location = {
			pathname,
			state: {
				topicId: data.id,
				actionType: 'topicDetails',
			},
		}

		this.props.history.push(location)
	}

	onRowClickUpdate = (data, pathname) => {
		var location = {
			pathname,
			state: {
				data,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteTopic(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteTopic(data)
		}
	}

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Konular</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						id="table1"
						name="table1"
						columns={this.state.QuestionsColumns}
						data={this.state.TopicListData_onQuery}
						pagination={true}
						noHeader
						highlightOnHover={true}
						progressPending={this.props.topics.extra.length > 0 ? false : true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddTopic
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateTopics')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindTopic
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.TopicListData_onQuery)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Konular</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullQuestionColumns}
						data={this.state.TopicFullListData_onQuery}
						noHeader
						pagination={true}
						highlightOnHover={true}
						progressPending={this.props.fullTopics.extra.length > 0 ? false : true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddTopic
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateTopics')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindFullListTopic
								) === true ? (
										<TextField
											onChange={this.searchOnFullList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.TopicFullListData_onQuery)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListTopic
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListTopic
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>

				{/* ********************************************************************************************** */}
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		topics: state.topicReducer,
		fullTopics: state.fullTopicReducer,

		fullLessons: state.fullLessonsReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getTopics: bindActionCreators(topicActions.getTopics, dispatch),

			getFullTopics: bindActionCreators(topicActions.getFullTopics, dispatch),
			resetGetFullTopicSuccess: bindActionCreators(
				topicActions.resetGetFullTopicSuccess,
				dispatch
			),

			deleteTopic: bindActionCreators(topicActions.deleteTopic, dispatch),
			fullDeleteTopic: bindActionCreators(topicActions.fullDeleteTopic, dispatch),
			createTopic: bindActionCreators(topicActions.createTopic, dispatch),
			changeDeletedTopicSuccessStatus: bindActionCreators(
				topicActions.changeDeletedTopicSuccessStatus,
				dispatch
			),
			resetAllStatuss: bindActionCreators(topicActions.resetAllStatuss, dispatch),

			getFullLessons: bindActionCreators(lessonActions.getFullLessons, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topics))
