import ScrollContainer from 'react-indiana-drag-scroll'
import React from 'react'

export function ScrollView(props) {
	return (
		<ScrollContainer
			className="scroll-container"
			ignoreElements={'*'}
			horizontal={true}
			vertical={true}
			style={{
				backgroundColor: props.isLogin ? '' : 'white',
				height: props.isLogin ? '100vh' : '90vh',
				overflowY: 'scroll',
				overflowX: 'hidden',
			}}
		>
			{props.children}
		</ScrollContainer>
	)
}
