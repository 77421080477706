import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Button,





    Col, Form,
    FormGroup,

    Input, Label,



    Row
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as lessonActions from '../../../redux/actions/lessonActions'
import { ScrollView } from '../base/scroll'




class LessonForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			lessonName: '',
		}
	}

	componentDidMount() {
		this.props.actions.resetAllStatuss('')

		{
			this.props?.location?.state?.actionType === 'update'
				? this.setState({ lessonName: this.props.location.state.data.lessonName })
				: this.setState({ lessonName: '' })
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.lessonData.successUpdated) {
			var location = {
				pathname: 'Dersler',
			}

			this.props.history.push(location)

			newProps.actions.resetUpdateLessonSuccess()
		} else if (newProps.lessonData.successUpdated === false) {
			alertifyjs.error('Hata: ' + newProps.lessonData.messages)
			newProps.actions.resetUpdateLessonSuccess()
		}

		if (newProps.lessonData.successCreated) {
			var location = {
				pathname: 'Dersler',
			}

			this.props.history.push(location)
			newProps.actions.resetCreateLessonSuccess()
		} else if (newProps.lessonData.successCreated === false) {
			alertifyjs.error('Hata: ' + newProps.lessonData.messages)
			newProps.actions.resetCreateLessonSuccess()
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.props.actions.resetAllStatuss('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.UpdateLesson({
						LessonName: this.state.lessonName,
						id: this.props.location.state.data.id,
					  })
					: this.props.actions.createLesson({ LessonName: this.state.lessonName })
			}
		}
	}

	validationFunc() {
		if (this.state.lessonName.length < 4) {
			alertifyjs.error('Ders Adı 5 Haneden Kısa Olamaz!')
			return false
		} else {
			return true
		}
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>
					{this.props.fullLessonData}

					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Ders Adı</Label>
										<Input
											type="text"
											name="lessonName"
											id="lessonName"
											placeholder="Ders Adı Belirleyiniz!"
											value={this.state.lessonName}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Dersi Güncelle
								</Button>
							) : (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Dersi Oluştur
								</Button>
							)}
						</Form>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		lessonData: state.lessonsReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			createLesson: bindActionCreators(lessonActions.createLesson, dispatch),
			resetCreateLessonSuccess: bindActionCreators(
				lessonActions.resetCreateLessonSuccess,
				dispatch
			),

			resetAllStatuss: bindActionCreators(lessonActions.resetAllStatuss, dispatch),

			UpdateLesson: bindActionCreators(lessonActions.UpdateLesson, dispatch),
			resetUpdateLessonSuccess: bindActionCreators(
				lessonActions.resetUpdateLessonSuccess,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonForm))
