import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function getFullExamsReducer(state = initialState.fullExams, action) {
  switch (action.type) {
    case actionTypes.GET_FULL_EXAM_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        extra: action.payload.extra,
      }

    case actionTypes.RESET_GET_FULL_EXAM_SUCCESS:
      return {
        ...state,
        success: "",
      }

    default:
      return state
  }
}
