/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import jwt_decode from 'jwt-decode'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import {
	Button,


	CardText, CardTitle, Container,









	Form, Input, Label, Modal,

	ModalBody,
	ModalFooter
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as messageActions from '../../../redux/actions/messageActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'



class Messages extends Component {
	constructor(props) {
		super(props)

		this.state = {
			uuid: '',

			ModalStatuss: false,
			MessageHeader: '',
			MessageBody: '',

			userSelection: '',

			gettedData: [],
			sendedData: [],
			readeingRow: '',
			receiversDara: [],

			ColumnsOnMessages: [
				{
					name: 'Başlık',
					selector: 'header',
					sortable: true,
				},
				{
					name: 'Durumu',
					selector: 'isRead',
					sortable: true,
					right: true,
					cell: (row) => (row.isRead === true ? 'Okundu' : 'Okunmadı'),
				},
			],

			ColumnsOnMessages2: [
				{
					name: 'Başlık',
					selector: 'header',
					sortable: true,
				},
			],
		}
	}

	componentDidMount() {
		var token = jwt_decode(this.props.userAuthData.jwtToken)

		var uuid =
			token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
		this.setState({ uuid })

		this.props.actions.gettedAllMessage()
		this.props.actions.sendedAllMessage()
		this.props.actions.getAllReceivers()
	}

	componentWillReceiveProps(newProps) {
		if (newProps.messageReducerData.successGettedMessages === true) {
			this.setState({ gettedData: newProps.messageReducerData.extraGettedMessages })
			newProps.actions.resetGettedAllMessageSuccess()
		}

		if (newProps.messageReducerData.successSendedMessages === true) {
			this.setState({ sendedData: newProps.messageReducerData.extraSendedMessages })
			newProps.actions.resetSendedAllMessageSuccess()
		}

		if (newProps.messageReducerData.successReceivers === true) {
			newProps.actions.ResetGetAllReceiversSuccess()

			var newdataaa = newProps.messageReducerData.extraReceivers.map((cc) => {
				return { value: cc.id, label: cc.fullName }
			})
			this.setState({ receiversDara: newdataaa })
		} else if (newProps.messageReducerData.successReceivers === false) {
			newProps.actions.ResetGetAllReceiversSuccess()
		}

		if (newProps.messageReducerData.successUpdateMessages === true) {
			var asaaaa = this.state.gettedData.map((aa) => {
				if (aa.id === event.id) {
					var dd = {
						id: event.id,
						senderId: event.senderId,
						sender: {
							id: event.sender.id,
							firstName: event.sender.firstName,
							lastName: event.sender.lastName,
							email: event.sender.email,
							phone: event.sender.phone,
							password: event.sender.password,
							refreshToken: event.sender.refreshToken,
							refreshTokenEndDate: event.sender.refreshTokenEndDate,
							isActive: event.sender.isActive,
						},
						receiverId: event.receiverId,
						receiver: {
							id: event.receiver.id,
							firstName: event.receiver.firstName,
							lastName: event.receiver.lastName,
							email: event.receiver.email,
							phone: event.receiver.phone,
							password: event.receiver.password,
							refreshToken: event.receiver.refreshToken,
							refreshTokenEndDate: event.receiver.refreshTokenEndDate,
							isActive: event.receiver.isActive,
						},
						header: event.header,
						detailText: event.detailText,
						isRead: true,
						createDate: event.createDate,
					}

					return dd
				} else {
					return aa
				}
			})

			this.setState({ gettedData: asaaaa })

			newProps.actions.resetUpdateMessageSuccess()
		} else if (newProps.messageReducerData.successUpdateMessages === false) {
			newProps.actions.resetUpdateMessageSuccess()
		}

		if (newProps.messageReducerData.successCreateMessage === true) {
			newProps.actions.resetCreateMessageSuccess()
			this.toggleModalPermissionDirect()
		} else if (newProps.messageReducerData.successCreateMessage === false) {
			newProps.actions.resetCreateMessageSuccess()
		}
	}

	setRead = (event) => {
		if (event.isRead === false) {
			this.setState({ readeingRow: event })
			this.props.actions.updateMessage({ MessageId: event.id })
		}
	}

	receivedMessages() {
		const ExpanableComponent = ({ data }) => (
			<Container fluid>
				<div className="row">
					<Card body style={{ width: '100%' }}>
						<CardTitle>
							<b>Başlık:</b> {data.header} <br></br>
							<b>Gönderen:</b> {data.receiver.firstName} {data.receiver.lastName} <br></br>
							<b>Alan:</b> {data.sender.firstName} {data.sender.lastName} <br></br>
							<b>Gönderim Zamanı:</b> {data.createDate.substring(0, 10)} -{' '}
							{data.createDate.substring(11, 19)}
						</CardTitle>
						<CardText>
							<b>Mesajınız:</b> <br></br> {data.detailText}{' '}
						</CardText>
					</Card>
				</div>
			</Container>
		)

		return (
			<div>
				<Card>
					<Accordion.Toggle eventKey="0" as={Card.Header}>
						<div className="d-flex justify-content-between">
							<FormGroup row>
								<h4>Alınmış Mesajlar</h4>
							</FormGroup>

							<FormGroup row>
								<Icon3 style={{ margin: '5px' }} color="action" />
							</FormGroup>
						</div>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="0">
						<DataTable
							columns={this.state.ColumnsOnMessages}
							data={this.state.gettedData}
							onRowClicked={
								this.props.userAuthData.permissions.includes(
									initialState.staticPerms.CanReadMessage
								) === true
									? this.setRead
									: null
							}
							pagination
							highlightOnHover
							expandableRows={
								this.props.userAuthData.permissions.includes(
									initialState.staticPerms.CanReadMessage
								) === true
									? true
									: false
							}
							expandOnRowClicked
							expandableRowsComponent={<ExpanableComponent />}
							subHeader
							subHeaderComponent={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{this.props.userAuthData.permissions.includes(
										initialState.staticPerms.CanSendMessage
									) === true ? (
											<button
												type="button"
												className="btn btn-outline-success"
												onClick={this.toggleModalPermissionDirect}
											>
												<i className="fa fa-plus"></i>
											</button>
										) : null}

									{this.props.userAuthData.permissions.includes(
										initialState.staticPerms.FindFullListLesson
									) === true ? (
											<TextField
												onChange={this.searchOnFullList}
												id="outlined-basic"
												label="Arama"
												variant="outlined"
												size="small"
												style={{ margin: '5px' }}
											/>
										) : null}
								</div>
							}
							subHeaderAlign={'left'}
						/>
					</Accordion.Collapse>
				</Card>
			</div>
		)
	}

	sendedMessages() {
		const ExpanableComponent = ({ data }) => (
			<Container fluid>
				<div className="row">
					<Card body style={{ width: '100%' }}>
						<CardTitle>
							<b>Başlık:</b> {data.header} <br></br>
							<b>Gönderen:</b> {data.receiver.firstName} {data.receiver.lastName} <br></br>
							<b>Alan:</b> {data.sender.firstName} {data.sender.lastName} <br></br>
							<b>Gönderim Zamanı:</b> {data.createDate.substring(0, 10)} -{' '}
							{data.createDate.substring(11, 19)}
						</CardTitle>
						<CardText>
							<b>Mesajınız:</b> <br></br> {data.detailText}{' '}
						</CardText>
					</Card>
				</div>
			</Container>
		)

		return (
			<div>
				<Card>
					<Accordion.Toggle eventKey="1" as={Card.Header}>
						<div className="d-flex justify-content-between">
							<FormGroup row>
								<h4>Gönderilmiş Mesajlar</h4>
							</FormGroup>

							<FormGroup row>
								<Icon3 style={{ margin: '5px' }} color="action" />
							</FormGroup>
						</div>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="1">
						<DataTable
							columns={this.state.ColumnsOnMessages2}
							data={this.state.sendedData}
							pagination
							highlightOnHover
							expandableRows={
								this.props.userAuthData.permissions.includes(
									initialState.staticPerms.CanReadMessage
								) === true
									? true
									: false
							}
							expandOnRowClicked
							expandableRowsComponent={<ExpanableComponent />}
							subHeader
							subHeaderComponent={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{this.props.userAuthData.permissions.includes(
										initialState.staticPerms.CanSendMessage
									) === true ? (
											<button
												type="button"
												className="btn btn-outline-success"
												onClick={this.toggleModalPermissionDirect}
											>
												<i className="fa fa-plus"></i>
											</button>
										) : null}

									{this.props.userAuthData.permissions.includes(
										initialState.staticPerms.FindFullListLesson
									) === true ? (
											<TextField
												onChange={this.searchOnFullList}
												id="outlined-basic"
												label="Arama"
												variant="outlined"
												size="small"
												style={{ margin: '5px' }}
											/>
										) : null}
								</div>
							}
							subHeaderAlign={'left'}
						/>
					</Accordion.Collapse>
				</Card>
			</div>
		)
	}

	sendMessage = (e) => {
		this.props.actions.createMessage({
			SenderId: this.state.uuid,
			ReceiverId: this.state.userSelection.value,
			Header: this.state.MessageHeader,
			DetailText: this.state.MessageBody,
		})
	}

	handleChange = (userSelection) => {
		this.setState({ userSelection })
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	toggleModalPermissionDirect = (e) => {
		this.setState({
			ModalStatuss: !this.state.ModalStatuss,
			userSelection: '',
			MessageHeader: '',
			MessageBody: '',
		})
	}

	sendMessageModal() {
		return (
			<Modal
				isOpen={this.state.ModalStatuss}
				toggle={this.toggleModalPermissionDirect}
				size="lg"
			>
				<Form onSubmit={this.sendMessage}>
					<ModalBody>
						<div className="row">
							<div className="col-lg-12">
								<Label for="userSelection">Alıcı</Label>
								<Select
									name="userSelection"
									id="userSelection"
									value={this.state.userSelection}
									onChange={this.handleChange}
									options={this.state.receiversDara}
								/>
							</div>

							<div className="col-lg-12">
								<FormGroup>
									<Label for="MessageHeader">Mesaj Başlığı</Label>
									<Input
										type="text"
										name="MessageHeader"
										id="MessageHeader"
										placeholder="Mesaj Başlığı Giriniz!"
										value={this.state.MessageHeader}
										onChange={this.setOnChange}
									/>
								</FormGroup>
							</div>
							<div className="col-lg-12">
								<FormGroup>
									<Label for="MessageBody">Mesaj Başlığı</Label>
									<Input
										type="textarea"
										name="MessageBody"
										id="MessageBody"
										placeholder="Mesaj İçeriği Giriniz!"
										value={this.state.MessageBody}
										onChange={this.setOnChange}
									/>
								</FormGroup>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="success" type="submit" onClick={this.sendMessage}>
							Gönder
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}

	render() {
		return (
			<ScrollView>
				{this.sendMessageModal()}

				<Accordion defaultActiveKey="0">
					{this.props.userAuthData.permissions.includes(
						initialState.staticPerms.CanListReceivedMessages
					) === true
						? this.receivedMessages()
						: null}

					{this.props.userAuthData.permissions.includes(
						initialState.staticPerms.CanListSendedMessages
					) === true
						? this.sendedMessages()
						: null}
				</Accordion>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		messageReducerData: state.messageReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			gettedAllMessage: bindActionCreators(messageActions.gettedAllMessage, dispatch),
			resetGettedAllMessageSuccess: bindActionCreators(
				messageActions.resetGettedAllMessageSuccess,
				dispatch
			),

			sendedAllMessage: bindActionCreators(messageActions.sendedAllMessage, dispatch),
			resetSendedAllMessageSuccess: bindActionCreators(
				messageActions.resetSendedAllMessageSuccess,
				dispatch
			),

			createMessage: bindActionCreators(messageActions.createMessage, dispatch),
			resetCreateMessageSuccess: bindActionCreators(
				messageActions.resetCreateMessageSuccess,
				dispatch
			),

			updateMessage: bindActionCreators(messageActions.updateMessage, dispatch),
			resetUpdateMessageSuccess: bindActionCreators(
				messageActions.resetUpdateMessageSuccess,
				dispatch
			),

			getAllReceivers: bindActionCreators(messageActions.getAllReceivers, dispatch),
			ResetGetAllReceiversSuccess: bindActionCreators(
				messageActions.ResetGetAllReceiversSuccess,
				dispatch
			),

			fullResetMessages: bindActionCreators(messageActions.fullResetMessages, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messages))
