import { combineReducers } from 'redux'
import changeNavigationReducer from './changeNavigationReducer'
import leftNavReducer from './leftNavReducer'
import userReducer from './userReducer'
import authReducer from './authReducer'
import permissionReducer from './permissonReducer'
import toggleReducer from './toggleReducer'
import roleReducer from './roleReducer'
import fullRoleReducer from './fullRoleReducer'
import lessonsReducer from './lessonsReducer'
import fullLessonsReducer from './fullLessonsReducer'
import schoolReducer from './schoolReducer'
import fullSchoolReducer from './fullSchoolReducer'
import usserReducer from './usserReducer'
import fullUsserReducer from './fullUsserReducer'
import classroomReducer from './classroomReducer'
import fullClassroomReducer from './fullClassroomReducer'
import questionTypeReducer from './questionTypeReducer'
import fullQuestionTypeReducer from './fullQuestionTypeReducer'
import answerReducer from './answerReducer'
import fullAnswerReducer from './fullAnswerReducer'
import topicReducer from './topicReducer'
import fullTopicReducer from './fullTopicReducer'
import examReducer from './examReducer'
import fullExamReducer from './fullExamReducer'
import questionsReducer from './questionsReducer'
import fullQuestionsReducer from './fullQuestionsReducer'
import getQuestionsWithAnswersReducer from './getQuestionsWithAnswersReducer'
import uploadReducer from './uploadReducer'
import getExamByIdReducer from './getExamByIdReducer'
import usersNotAssignedReducer from './usersNotAssignedReducer'
import usersOnClassroomReducer from './usersOnClassroomReducer'
import managerNotAssignedReduces from './managerNotAssignedReduces'
import managerOnSchoolsReducers from './managerOnSchoolsReducers'
import lessonsToClassReducer from './lessonsToClassReducer'
import lessonDetailsReducer from './lessonDetailsReducer'
import topicDetailReducers from './topicDetailReducers'
import userDetailsExamReducer from './userDetailsExamReducer'
import examDetailsReducer from './examDetailsReducer'
import realExamReducer from './realExamReducer'
import messageReducer from './messageReducer'
import dashboardReducer from './dashboardReducer'
import examResultReducer from './examResultReducer'
import topicMaterialReducer from './topicMaterialReducer'

const rootreducer = combineReducers({
	changeNavigationReducer, //changeCategoryReducer:changeCategoryReducer --esx7 make it auto baska reducerlari , ile ekle
	leftNavReducer,
	userReducer,
	authReducer,
	permissionReducer,
	toggleReducer,
	//***************************** */
	lessonsReducer,
	fullLessonsReducer,
	roleReducer,
	fullRoleReducer,
	schoolReducer,
	fullSchoolReducer,
	usserReducer,
	fullUsserReducer,
	classroomReducer,
	fullClassroomReducer,
	questionTypeReducer,
	fullQuestionTypeReducer,
	answerReducer,
	fullAnswerReducer,
	topicReducer,
	topicMaterialReducer,
	fullTopicReducer,
	examReducer,
	examResultReducer,
	fullExamReducer,
	questionsReducer,
	fullQuestionsReducer,
	getQuestionsWithAnswersReducer,
	uploadReducer,
	getExamByIdReducer,
	usersOnClassroomReducer,
	usersNotAssignedReducer,
	managerNotAssignedReduces,
	managerOnSchoolsReducers,
	lessonsToClassReducer,
	lessonDetailsReducer,
	topicDetailReducers,
	userDetailsExamReducer,
	examDetailsReducer,
	realExamReducer,
	messageReducer,
	dashboardReducer,
	//***************************** */
})

export default rootreducer
