/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import jsPDF from 'jspdf'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	Button,





	Col,

	Input, Label, Modal,

	ModalBody,
	ModalFooter, ModalHeader,


	Row
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as roleActions from '../../../redux/actions/roleActions'
import * as usserActions from '../../../redux/actions/usserActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'







class Users extends Component {
	constructor(props) {
		super(props)

		this.state = {
			ModalStatuss: false,
			RoleSelection: '',
			activeUserId: '',

			dataChart: [
				{
					name: 'Ocak',
					Adet: 5,
				},
				{
					name: 'Şubat',
					Adet: 8,
				},
				{
					name: 'Mart',
					Adet: 10,
				},
				{
					name: 'Nisan',
					Adet: 12,
				},
				{
					name: 'Mayıs',
					Adet: 8,
				},
				{
					name: 'Haziran',
					Adet: 40,
				},
				{
					name: 'Temmuz',
					Adet: 60,
				},
				{
					name: 'Ağustos',
					Adet: 20,
				},
				{
					name: 'Eylül',
					Adet: 25,
				},
				{
					name: 'Ekim',
					Adet: 27,
				},
				{
					name: 'Kasım',
					Adet: 32,
				},
				{
					name: 'Aralık',
					Adet: 44,
				},
			],

			UssersColumns: [
				{
					name: 'Ad',
					selector: 'firstName',
					sortable: true,
				},

				{
					name: 'Soyad',
					selector: 'lastName',
					sortable: true,
				},

				{
					name: 'Email',
					selector: 'email',
					sortable: true,
				},

				{
					name: 'Telefon',
					selector: 'phone',
					sortable: true,
					width: '125px',
				},

				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
					width: '75px',
				},

				{
					name: 'Rol',
					selector: 'roleName',
					sortable: true,
					width: '100px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanSeeUserDetails
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.onRowClickUSerDetails(row, 'UserDetails')}
								>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanSeeUserDetails
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskUserRoles
						) == true ? (
								<Button color="danger" onClick={() => this.toggleModal(row)}>
								Rol Ata
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskUserRoles
						) == true
							? '85px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateUser
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateUssers')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateUser
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteUser
						) === true ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteUser
						) == true
							? '50px'
							: '0px',
				},
			],

			FullUssersColumns: [
				{
					name: 'Ad',
					selector: 'firstName',
					sortable: true,
				},

				{
					name: 'Soyad',
					selector: 'lastName',
					sortable: true,
					right: true,
				},

				{
					name: 'Email',
					selector: 'email',
					sortable: true,
					right: true,
				},

				{
					name: 'Telefon',
					selector: 'phone',
					sortable: true,
					right: true,
				},

				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanSeeUserDetails
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.onRowClickUSerDetails(row, 'UserDetails')}
								>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanSeeUserDetails
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskUserRoles
						) == true ? (
								<Button color="danger" onClick={() => this.toggleModal(row)}>
								Rol Ata
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskUserRoles
						) == true
							? '85px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateUser
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateUssers')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateUser
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteUser
						) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteUser
						) == true
							? '60px'
							: '0px',
				},
			],
		}
	}
	//************************************************************************************* */
	//************************************************************************************* */

	componentWillMount() {
		this.props.actions.getRoles()
		this.props.actions.getUssers()
		this.props.actions.getFullUssers()
	}

	componentDidMount() {
		//this.props.actions.createLesson("deneme");

		this.setState({ usserListData_onQuery: this.props.ussers.extra })
		this.setState({ usserFullListData_onQuery: this.props.fullUssers.extra })

		this.props.actions.changeDeletedUsserSuccessStatus('')
		this.props.actions.resetAllStatuss()
	}

	componentWillReceiveProps(newProps) {
		if (newProps.ussers.deleted) {
			alertifyjs.success('Kullanıcı Başarılı Olarak Silindi!')
			newProps.actions.changeDeletedUsserSuccessStatus('')
			newProps.actions.resetAllStatuss()
			newProps.actions.getUssers()
			newProps.actions.getFullUssers()
		} else if (newProps.ussers.deleted === false) {
			alertifyjs.error('Kullanıcı Silinirken Hatalar Oluştu.')
			newProps.actions.changeDeletedUsserSuccessStatus('')
			newProps.actions.resetAllStatuss()
		}

		//******************************************************************** */

		if (newProps.roleReducerData.successGettedRole === true) {
			try {
				this.setState({ RoleSelection: newProps.roleReducerData.dataUserRole[0].id })
			} catch (err) {
				this.setState({ RoleSelection: '-1' })
			}

			this.props.actions.resetAllStatussRole('')
		}

		if (newProps.roleReducerData.successAsignedRole) {
			this.props.actions.resetAllStatussRole('')
			this.props.actions.getUssers()
			this.props.actions.getFullUssers()
		}

		//******************************************************************** */

		if (newProps.ussers.success === true) {
			this.setState({ usserListData_onQuery: newProps.ussers.extra })

			this.props.actions.resetAllStatuss('')
		}

		//******************************************************************** */

		if (newProps.fullUssers.success === true) {
			this.setState({ usserFullListData_onQuery: newProps.fullUssers.extra })

			this.props.actions.resetGetFullUserSuccess('')
		}
	}

	//************************************************************************************* */
	//************************************************************************************* */

	exportPDF = (dataGElen) => {
		const unit = 'pt'
		const size = 'A4' // Use A1, A2, A3 or A4
		const orientation = 'portrait' // portrait or landscape

		const marginLeft = 40
		const doc = new jsPDF(orientation, unit, size)

		doc.setFontSize(10)

		const title = 'KULLANICI RAPORLAMALARI'
		const headers = [['AD', 'SOYAD', 'EMAıL', 'PHONE']]

		const data = dataGElen.map((elt) => [
			elt.firstName,
			elt.lastName,
			elt.email,
			elt.phone,
		])

		let content = {
			startY: 50,
			head: headers,
			body: data,
		}

		doc.text(title, marginLeft, 40)
		doc.autoTable(content)
		doc.save('report.pdf')
	}

	selectNavigation = (navigation) => {
		this.props.actions.changeDeletedUsserSuccessStatus('')
		this.props.actions.resetAllStatuss()

		var location = {
			pathname: navigation,
		}

		this.props.history.push(location)
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var usserListData_onQuery = this.props.ussers.extra.filter(
				(item) =>
					item.firstName.like(e.target.value) ||
					item.lastName.like(e.target.value) ||
					item.email.like(e.target.value) ||
					item.roleName.like(e.target.value) ||
					(item.phone > 0 && item.phone.like(e.target.value))
			)
			this.setState({ usserListData_onQuery })
		} else {
			this.setState({ usserListData_onQuery: this.props.ussers.extra })
		}
	}

	searchOnFullList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var usserFullListData_onQuery = this.props.fullUssers.extra.filter(
				(item) =>
					item.firstName.like(e.target.value) ||
					item.lastName.like(e.target.value) ||
					item.email.like(e.target.value) ||
					item.roleName.like(e.target.value) ||
					(item.phone > 0 && item.phone.like(e.target.value))
			)
			this.setState({ usserFullListData_onQuery })
		} else {
			this.setState({ usserFullListData_onQuery: this.props.fullUssers.extra })
		}
	}

	handleClick = (state) => {}

	onRowClickUSerDetails = (data, pathname) => {
		var location = {
			pathname,
			state: {
				data,
				actionType: 'userDetails',
			},
		}

		this.props.history.push(location)
	}

	onRowClickUpdate = (data, pathname) => {
		var location = {
			pathname,
			state: {
				data,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteUsser(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteUsser(data)
		}
	}

	assidnedRoleToUser = () => {
		if (this.state.activeUserId !== '') {
			this.props.actions.assignedRoleToUser({
				UserId: this.state.activeUserId,
				RoleId: this.state.RoleSelection,
			})
		}
		this.toggleModal()
	}

	toggleModal = (row) => {
		if (this.state.ModalStatuss !== true) {
			this.props.actions.getUserRole({ UserId: row.id })
			this.setState({ activeUserId: row.id })
		} else {
			this.setState({ activeUserId: '' })
		}

		this.setState({ ModalStatuss: !this.state.ModalStatuss })
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Kullanıcılar</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<DataTable
						id="table1"
						name="table1"
						columns={this.state.UssersColumns}
						data={this.state.usserListData_onQuery}
						noHeader
						pagination
						highlightOnHover
						progressPending={this.props.ussers.extra.length > 0 ? false : true}
						subHeader
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddUser
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateUssers')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindUser
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.usserListData_onQuery)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Kullanıcılar</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullUssersColumns}
						data={this.state.usserFullListData_onQuery}
						noHeader
						pagination
						highlightOnHover
						progressPending={this.props.fullUssers.extra.length > 0 ? false : true}
						subHeader
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddUser
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateUssers')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindFullListUser
								) === true ? (
										<TextField
											onChange={this.searchOnFullList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.usserFullListData_onQuery)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<Modal isOpen={this.state.ModalStatuss} toggle={this.toggleModal} size="lg">
					<ModalHeader toggle={this.toggleModal}>Rol İşlemleri</ModalHeader>
					<ModalBody>
						<Row>
							<Col>
								<FormGroup>
									<Label for="RoleSelection">Rol Seçimi</Label>

									<Input
										type="select"
										name="RoleSelection"
										id="RoleSelection"
										onChange={this.setOnChange}
										selected={this.state.RoleSelection}
										value={this.state.RoleSelection}
									>
										<option value="-1" key="-1">
											Seçiniz
										</option>
										{this.props.roleReducerData.extraGettedRoles.map((role) => {
											return (
												<option value={role.id} key={role.id}>
													{role.roleName}{' '}
												</option>
											)
										})}
									</Input>
								</FormGroup>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						{this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAssignRoleToUser
						) === true ? (
								<Button color="primary" onClick={this.assidnedRoleToUser}>
								Atamayı Onayla
								</Button>
							) : null}

						<Button color="secondary" onClick={this.toggleModal}>
							Vazgeç
						</Button>
					</ModalFooter>
				</Modal>

				<div className="row">
					{/* <div className="col-lg-12">

                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    <FormGroup row>
                                        <h4>Kullanıcı Oluşturma Oranları</h4>
                                    </FormGroup>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="0">



                                    <ResponsiveContainer width="100%" height={250}>
                                        <AreaChart data={this.state.dataChart}
                                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip />
                                            <ReferenceLine x="Page C" stroke="red" label="Min PAGE" />
                                            <ReferenceLine y={6000} label="Max" stroke="orange" strokeDasharray="3 3" />
                                            <Area type="monotone" dataKey="Adet" stroke="black" fill="#db3d44" />
                                        </AreaChart>
                                    </ResponsiveContainer>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>


                    </div> */}

					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListUser
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListUser
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		ussers: state.usserReducer,
		fullUssers: state.fullUsserReducer,

		roleReducerData: state.roleReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getUserRole: bindActionCreators(roleActions.getUserRole, dispatch),
			getRoles: bindActionCreators(roleActions.getRoles, dispatch),
			assignedRoleToUser: bindActionCreators(roleActions.assignedRoleToUser, dispatch),
			resetAllStatussRole: bindActionCreators(roleActions.resetAllStatuss, dispatch),

			getUssers: bindActionCreators(usserActions.getUssers, dispatch),

			getFullUssers: bindActionCreators(usserActions.getFullUssers, dispatch),
			resetGetFullUserSuccess: bindActionCreators(
				usserActions.resetGetFullUserSuccess,
				dispatch
			),

			deleteUsser: bindActionCreators(usserActions.deleteUsser, dispatch),
			fullDeleteUsser: bindActionCreators(usserActions.fullDeleteUsser, dispatch),
			createUsser: bindActionCreators(usserActions.createUsser, dispatch),
			setUsserRegStatuss: bindActionCreators(usserActions.setUsserRegStatuss, dispatch),
			changeDeletedUsserSuccessStatus: bindActionCreators(
				usserActions.changeDeletedUsserSuccessStatus,
				dispatch
			),
			resetAllStatuss: bindActionCreators(usserActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users))
