import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DataTable, { createTheme } from 'react-data-table-component'
import { Accordion, Card, CustomToggle } from 'react-bootstrap'
import { Breadcrumb, BreadcrumbItem, Badge, Button } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import { withRouter } from 'react-router-dom'
import { ScrollView } from '../base/scroll'

import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as examDetailsActions from '../../../redux/actions/examDetailsActions'

import { QuestionCard } from '../base/question'
import initialState from '../../../redux/reducers/initialState'
import { formatDate } from '../../../utils/helpers/date'
import { getQueryStringByName } from '../../../utils/helpers/url'

class ExamDetails extends Component {
	find_in_object(array1, my_criteria) {
		return array1.map((obj) => {
			if (obj.id === my_criteria) {
				return obj.topicName
			}
		})
	}

	constructor(props) {
		super(props)

		this.state = {
			id: props.location.state.id,
			addedExam: '',
			removedExam: '',

			extraGetExams: [],

			lessonTopicsData: [],
			LessonExamsDAta: [],

			QuestionsColumns: [
				{
					name: 'Id',
					selector: 'orderCount',
					sortable: true,
					width: '5%',
				},

				{
					name: 'Soru',
					selector: 'Question',
					sortable: true,
					width: '95%',
					cell: (row) => (
						<div dangerouslySetInnerHTML={{ __html: row.question.questionText }}></div>
					),
				},
			],

			StudentsColumns: [
				{
					name: 'Ad Soyad',
					selector: 'fullName',
					sortable: true,
					width: '100%',
				},
			],

			ClassroomsColumns: [
				{
					name: 'Sınıf',
					selector: 'className',
					sortable: true,
					width: '100%',
				},
			],
		}
	}

	componentDidMount() {
		this.props.actions.getClassroomsGetAssignedClassrooms({
			ExamId: this.state.id,
		})
		this.props.actions.getExamsWithQuestions({
			ExamId: this.state.id,
		})
		this.props.actions.getUsersGetAssignedUsers({
			ExamId: this.state.id,
		})

		this.props.actions.fullResetsOnActsExamDetails()
	}

	componentWillReceiveProps(newProps) {}

	navigateToExamUpdate(row, navigation) {
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	QuestionsList() {
		// this.props.examDetailsReducerData.extraGetExams.questions.map(aa=> {    return Object.assign(aa,{defaultExpanded:true}) } )
		// const data = this.props.examDetailsReducerData.extraGetExams.questions;
		// data[0].defaultExpanded = true;

		const ExpandedComponent = ({ data }) => {
			return (
				<div style={{ marginLeft: 150, marginTop: 20, marginBottom: 20 }}>
					{data.answers.map((answer, i) => (
						<p key={`answer_${answer.id}`}>
							{i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : i === 3 ? 'D' : '?'} -{' '}
							{answer.answerText}
						</p>
					))}
				</div>
			)
		}

		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<h4>
						Sorular{' '}
						<Badge>
							{this.props.examDetailsReducerData.extraGetExams.questions.length} Adet
						</Badge>{' '}
					</h4>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body>
						{this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateExam
						) == true &&
						(this.props.userAuthData.permissions.includes(
							initialState.staticPerms.adminPermsId
						) ||
							this.props.examDetailsReducerData.extraGetExams.exam.createdBy ==
								localStorage.getItem('id')) ? (
								<Button
									color="success"
									style={{ marginBottom: 20 }}
									onClick={() =>
										this.navigateToExamUpdate(
											this.props.examDetailsReducerData.extraGetExams.exam,
											'AddOrUpdateExams'
										)
									}
								>
								Soru Ekle / Sil
								</Button>
							) : null}
						<QuestionCard
							questions={this.props.examDetailsReducerData.extraGetExams.questions}
							history={this.props.history}
							actions={this.props.actions}
							updateQuestionPerm={this.props.userAuthData.permissions.includes(
								initialState.staticPerms.UpdateQuestion
							)}
						/>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		)
	}

	QuestionsListGraph() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<h4>Sorular </h4>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<Card.Body></Card.Body>
				</Accordion.Collapse>
			</Card>
		)
	}

	AssignStudents() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<h4>
						Atanan Öğrenciler{' '}
						<Badge>{this.props.examDetailsReducerData.extraGetUsers.length} Adet</Badge>{' '}
					</h4>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<Card.Body>
						<DataTable
							id="table1"
							name="table1"
							columns={this.state.StudentsColumns}
							data={this.props.examDetailsReducerData.extraGetUsers}
							pagination={true} // paging
							highlightOnHover={true}
							subHeader={true}
							subHeaderComponent={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<TextField
										onChange={this.searchOnList}
										id="outlined-basic"
										label="Arama"
										variant="outlined"
										size="small"
										style={{ margin: '5px' }}
									/>
								</div>
							}
							subHeaderAlign={'left'}
							fixedHeader={false}
							fixedHeaderScrollHeight="300px"
							noHeader
						/>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		)
	}

	AssignStudentsGraph() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<h4>Atanan Öğrenciler </h4>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<Card.Body></Card.Body>
				</Accordion.Collapse>
			</Card>
		)
	}

	AssignClass() {
		return (
			<Card>
				<Accordion.Toggle eventKey="2" as={Card.Header}>
					<h4>
						Sınıflar{' '}
						<Badge>
							{this.props.examDetailsReducerData.extraGetClassrooms.length} Adet
						</Badge>{' '}
					</h4>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="2">
					<Card.Body>
						<DataTable
							id="table1"
							name="table1"
							columns={this.state.ClassroomsColumns}
							data={this.props.examDetailsReducerData.extraGetClassrooms}
							pagination={true} // paging
							highlightOnHover={true}
							subHeader={true}
							subHeaderComponent={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<TextField
										onChange={this.searchOnList}
										id="outlined-basic"
										label="Arama"
										variant="outlined"
										size="small"
										style={{ margin: '5px' }}
									/>
								</div>
							}
							subHeaderAlign={'left'}
							fixedHeader={false}
							fixedHeaderScrollHeight="300px"
							noHeader
						/>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		)
	}

	AssignClassGraph() {
		return (
			<Card>
				<Accordion.Toggle eventKey="2" as={Card.Header}>
					<h4>Sınıflar</h4>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="2">
					<Card.Body></Card.Body>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<div>
							<Breadcrumb>
								{this.props.examDetailsReducerData.extraGetExams.exam && (
									<BreadcrumbItem active>
										{this.props.examDetailsReducerData.extraGetExams.exam.examName} /{' '}
										{formatDate(this.props.examDetailsReducerData.extraGetExams.exam.deadLine)}{' '}
										/{' '}
										{this.find_in_object(
											this.props.fullTopicReducerData.extra,
											this.props.examDetailsReducerData.extraGetExams.exam.topicId
										)}
									</BreadcrumbItem>
								)}
							</Breadcrumb>
						</div>
					</div>
				</div>

				<Accordion>
					<div className="row">
						<div className="col-lg-12">{this.QuestionsList()}</div>

						{/* <div className="col-lg-4">

                            {this.QuestionsListGraph()}
                        </div> */}
					</div>

					<div className="row">
						<div className="col-lg-12">{this.AssignStudents()}</div>

						{/* <div className="col-lg-4">

                            {this.AssignStudentsGraph()}
                        </div> */}
					</div>

					<div className="row">
						<div className="col-lg-12">{this.AssignClass()}</div>

						{/* <div className="col-lg-4">
                            {this.AssignClassGraph()}
                        </div> */}
					</div>
				</Accordion>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,
		fullTopicReducerData: state.fullTopicReducer,
		examDetailsReducerData: state.examDetailsReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getClassroomsGetAssignedClassrooms: bindActionCreators(
				examDetailsActions.getClassroomsGetAssignedClassrooms,
				dispatch
			),
			getExamsWithQuestions: bindActionCreators(
				examDetailsActions.getExamsWithQuestions,
				dispatch
			),
			getUsersGetAssignedUsers: bindActionCreators(
				examDetailsActions.getUsersGetAssignedUsers,
				dispatch
			),
			fullResetsOnActsExamDetails: bindActionCreators(
				examDetailsActions.fullResetsOnActsExamDetails,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamDetails))
