import {
	GET_EXAM_BY_ID_SUCCESS,
	RESET_EXAM_BY_ID_SUCCESS,
	ONFAULT_EXAM_BY_ID_SUCCESS,
	UPDATE_EXAM_BY_ID_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const getExamByIdSuccess = (examData) => {
	return {
		type: GET_EXAM_BY_ID_SUCCESS,
		payload: examData,
	}
}

export const UpdateExamByIdSuccess = (examData) => {
	return {
		type: UPDATE_EXAM_BY_ID_SUCCESS,
		payload: examData,
	}
}

export const resetExamByIdSuccess = (examData) => {
	return {
		type: RESET_EXAM_BY_ID_SUCCESS,
		payload: examData,
	}
}

export const onFaultExamByIdSuccess = (data) => {
	return {
		type: ONFAULT_EXAM_BY_ID_SUCCESS,
		payload: data,
	}
}

export const getExamDetailsById = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/getWithQuestions'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getExamByIdSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultExamByIdSuccess(ffData))
		})
}

export const updateExamDetailsById = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/updateWithQuestions'

	axios
		.put(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(UpdateExamByIdSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultExamByIdSuccess(ffData))
		})
}
