import {
	GET_DASHBOARD_SUCCESS,
	GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS,
	RESET_GET_DASHBOARD_SUCCESS,
	RESET_GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS,
	GET_DASHBOARD_FAULT,
	GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_FAULT,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const getDashboardSuccess = (examData) => {
	return {
		type: GET_DASHBOARD_SUCCESS,
		payload: examData,
	}
}

export const getDashboardTeachersAndClassroomsSuccess = (examData) => {
	return {
		type: GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS,
		payload: examData,
	}
}

//**************************************************** */

export const resetGetDashboardSuccess = (examData) => {
	return {
		type: RESET_GET_DASHBOARD_SUCCESS,
		payload: examData,
	}
}

export const resetGetDashboardTeachersAndClassroomsSuccess = (examData) => {
	return {
		type: RESET_GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS,
		payload: examData,
	}
}

//**************************************************** */

export const getDashboardFault = (examData) => {
	return {
		type: GET_DASHBOARD_FAULT,
		payload: examData,
	}
}

export const getDashboardTeachersAndClassroomsFault = (examData) => {
	return {
		type: GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_FAULT,
		payload: examData,
	}
}

//**************************************************** */

export const getDashboard = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Dashboard/GetBoxes')
		.then((res) => {
			const AddedLesson = res.data
			dispatch(getDashboardSuccess(AddedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(getDashboardFault(ffData))
		})
}

export const getDashboardTeachersAndClassrooms = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Dashboard/TeachersAndClassrooms')
		.then((res) => {
			var data = res.data

			dispatch(getDashboardTeachersAndClassroomsSuccess(data))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}

			dispatch(getDashboardTeachersAndClassroomsFault(ffData))
		})
}
