import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'

import DataTable from 'react-data-table-component'
import { ScrollView } from '../base/scroll'

import alertifyjs from 'alertifyjs'

import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as lessonsToClassActions from '../../../redux/actions/lessonsToClassActions'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import initialState from '../../../redux/reducers/initialState'
import { Accordion, Card, CustomToggle } from 'react-bootstrap'

class ExamForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: new Date().toISOString().substring(0, 10),

			FreeLessonsData: [],
			NonFreeLessonsData: [],

			ToggState: false,
			onActModal: { questionText: '', questionTypeId: '', fileUrl: '' },

			addedLesson: '',
			removedLesson: '',

			ClassroomHasUsersColumn: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.AssignLessonToClass
						) === true ? (
								<Button color="light" onClick={() => this.removeFromClassRoom(row)}>
									<HighlightOffIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
				{
					name: 'Soru Text',
					selector: 'lessonName',
					sortable: true,
					width: '20rem',
				},
			],

			allNOTAssignedUSersColumn: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.NotAssignLessonToClass
						) === true ? (
								<Button color="light" onClick={() => this.addToClassroom(row)}>
									<AddCircleOutlineIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
				{
					name: 'Soru Text',
					selector: 'lessonName',
					sortable: true,
					width: '20rem',
				},
			],
		}
	}

	componentDidMount() {
		this.props.actions.getLessonsAssignedToClass({
			ClassroomId: this.props.location.state.data.id,
		})
		this.props.actions.getLessonsNotAssignedToClass({
			ClassroomId: this.props.location.state.data.id,
		})

		this.props.actions.resetLessonToClassSuccess('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.lessonsToClassReducerData.successNotAssigned) {
			this.setState({
				FreeLessonsData: newProps.lessonsToClassReducerData.extraNotAssigned,
			})
			newProps.actions.resetLessonToClassSuccess('')
		}

		if (newProps.lessonsToClassReducerData.successAssigned) {
			this.setState({
				NonFreeLessonsData: newProps.lessonsToClassReducerData.extraAssigned,
			})
			newProps.actions.resetLessonToClassSuccess('')
		}

		if (newProps.lessonsToClassReducerData.successAdded) {
			var dataMid = this.state.NonFreeLessonsData.concat([this.state.addedLesson])
			this.setState({ NonFreeLessonsData: dataMid })

			var FreeLessonsData = this.state.FreeLessonsData.filter(
				(value) => value.id !== this.state.addedLesson.id
			)
			this.setState({ FreeLessonsData })
			newProps.actions.resetLessonToClassSuccess('')
		} else if (newProps.lessonsToClassReducerData.successAdded === false) {
			alertifyjs.error('Hata: ' + newProps.usersOnClassroomReducerData.messages)
			newProps.actions.resetLessonToClassSuccess('')
		}

		if (newProps.lessonsToClassReducerData.successRemoved) {
			var dataMid = this.state.FreeLessonsData.concat([this.state.removedLesson])
			this.setState({ FreeLessonsData: dataMid })

			var NonFreeLessonsData = this.state.NonFreeLessonsData.filter(
				(value) => value.id !== this.state.removedLesson.id
			)
			this.setState({ NonFreeLessonsData })
			newProps.actions.resetLessonToClassSuccess('')
		} else if (newProps.lessonsToClassReducerData.successRemoved === false) {
			alertifyjs.error('Hata: ' + newProps.usersOnClassroomReducerData.messages)
			newProps.actions.resetLessonToClassSuccess('')
		}
	}

	removeFromClassRoom = (data) => {
		var datdat = {
			LessonId: data.id,
			ClassroomId: this.props.location.state.data.id,
		}
		this.props.actions.removeLessonsAssignedToClass(datdat)
		this.setState({ removedLesson: data })
	}

	addToClassroom = (data) => {
		var datdat = {
			LessonId: data.id,
			ClassroomId: this.props.location.state.data.id,
		}
		this.props.actions.addLessonsAssignedToClass(datdat)
		this.setState({ addedLesson: data })
	}

	renderAllQuestions() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<h4>Eklenebilir Dersler</h4>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.allNOTAssignedUSersColumn}
						data={this.state.FreeLessonsData}
						pagination
						paginationPerPage={5}
						highlightOnHover
						grow={10}
						dense={false}
						direction={'auto'}
						fixedHeader
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderAddedQuestions() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<h4>Eklenen Dersler</h4>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.ClassroomHasUsersColumn}
						data={this.state.NonFreeLessonsData}
						pagination
						highlightOnHover
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListAssignedLessonsByClass
							) === true
								? this.renderAllQuestions()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListNotAssignedLessonsByClass
							) === true
								? this.renderAddedQuestions()
								: null}
						</Accordion>
					</div>

					{/* <div className="col-lg-1">
                        <Button size="lg" block color='success' style={{ height: '100%' }} ><PlaylistAddCheckIcon fontSize='large' /></Button>
                    </div> */}
					{/* <div className="col-lg-1"></div> */}
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		userAuthData: state.authReducer,

		lessonsToClassReducerData: state.lessonsToClassReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getLessonsAssignedToClass: bindActionCreators(
				lessonsToClassActions.getLessonsAssignedToClass,
				dispatch
			),
			getLessonsNotAssignedToClass: bindActionCreators(
				lessonsToClassActions.getLessonsNotAssignedToClass,
				dispatch
			),

			resetLessonToClassSuccess: bindActionCreators(
				lessonsToClassActions.resetLessonToClassSuccess,
				dispatch
			),
			addLessonsAssignedToClass: bindActionCreators(
				lessonsToClassActions.addLessonsAssignedToClass,
				dispatch
			),
			removeLessonsAssignedToClass: bindActionCreators(
				lessonsToClassActions.removeLessonsAssignedToClass,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamForm))
