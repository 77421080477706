import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function getUsserReducer(state = initialState.ussers, action) {
	switch (action.type) {
		case actionTypes.CREATE_USSER_SUCCESS:
			return {
				...state,
				success: action.payload.success,
				extra: action.payload.extra,
			}

		case actionTypes.DELETE_USSER_SUCCESS:
			if (action.payload == 'Başarıyla silindi.') {
				return {
					...state,
					deleted: true,
				}
			} else {
				return {
					...state,
					deleted: false,
				}
			}

		case actionTypes.GET_USSER_SUCCESS:
			return action.payload

		case actionTypes.GET_USSER_BY_ID_SUCCESS:
			return {
				...state,
				successUserByID: action.payload.success,
				extraUserByID: action.payload.extra,
			}

		case actionTypes.UPDATE_USSER_AVATAR_SUCCESS:
			return {
				...state,
				successUpdateAvatar: action.payload.success,
			}

		case actionTypes.UPDATE_USSER_SUCCESS:
			return action.payload

		case actionTypes.RESET_STATUSS_USSER:
			return {
				...state,
				success: '',
				deleted: '',
				successUserByID: '',
			}

		case actionTypes.ONFAULT_USSER:
			return {
				...state,
				success: false,
				messages: action.payload.messages,
			}

		default:
			return state
	}
}
