import {
	GET_USERDETAILS_ASSIGNED_EXAMS_SUCCESS,
	ADD_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS,
	REMOVE_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS,
	RESET_USERDETAILS_EXAMS,
	ONFAULT_USERDETAILS_EXAMS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

//*

export const getUSerDetailsAssignedExamsSuccess = (examData) => {
	return {
		type: GET_USERDETAILS_ASSIGNED_EXAMS_SUCCESS,
		payload: examData,
	}
}

//*

export const addUserDetailsAssignedExamsToNotSolvedSuccess = (examData) => {
	return {
		type: ADD_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS,
		payload: examData,
	}
}

//*

export const removeUserDetailsAssignedExamsToNotSolvedSuccess = (data) => {
	return {
		type: REMOVE_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS,
		payload: data,
	}
}

export const resetUSerDetailsExams = (data) => {
	return {
		type: RESET_USERDETAILS_EXAMS,
		payload: data,
	}
}

export const onFoultUserDetailsExams = (data) => {
	return {
		type: ONFAULT_USERDETAILS_EXAMS,
		payload: data,
	}
}

export const getUSerDetailsAssignedExams = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetNotAssignedForUser'

	if (data.UserId) {
		url = url + '?UserId=' + data.UserId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(getUSerDetailsAssignedExamsSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}

			dispatch(onFoultUserDetailsExams(ffData))
		})
}

export const addUserDetailsAssignedExamsToNotSolved = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/AssignToUser'

	if (data.UserId) {
		url = url + '?UserId=' + data.UserId + '&ExamId=' + data.ExamId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(addUserDetailsAssignedExamsToNotSolvedSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}

			dispatch(onFoultUserDetailsExams(ffData))
		})
}

export const removeUserDetailsAssignedExamsToNotSolved = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/RemoveFromUser'

	if (data.UserId) {
		url = url + '?UserId=' + data.UserId + '&ExamId=' + data.ExamId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(removeUserDetailsAssignedExamsToNotSolvedSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}

			dispatch(onFoultUserDetailsExams(ffData))
		})
}
