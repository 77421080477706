import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function usersOnClassroomReducer(
  state = initialState.usersOnClassroom,
  action
) {
  switch (action.type) {
    case actionTypes.GET_USERS_ON_CLASSROOM_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        extra: action.payload.extra,
      }

    case actionTypes.ON_CLASSROOM_RESET_ALL_STATUSS:
      return {
        ...state,
        success: "",
        deleted: "",
        Updatedsuccess: "",
        Addedsuccess: "",
        removedSucces: "",
      }

    //****************************************************************** */
    case actionTypes.CLASSROOM_ADD_USER_SUCCESS:
      return {
        ...state,
        Addedsuccess: action.payload.success,
      }

    case actionTypes.CLASSROOM_REMOVE_USER_SUCCESS:
      return {
        ...state,
        removedSucces: action.payload.success,
      }
    //****************************************************************** */

    case actionTypes.ONFAULT_USERS_ON_CLASSROOM:
      return {
        ...state,
        success: false,
        messages: action.payload.messages,
      }

    default:
      return state
  }
}
