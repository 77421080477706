import {
	CREATE_TOPIC_SUCCESS,
	GET_FULL_TOPIC_SUCCESS,
	GET_TOPIC_SUCCESS,
	DELETE_TOPIC_SUCCESS,
	UPDATE_TOPIC_SUCCESS,
	DELETE_TOPIC_SUCCESS_STATUSS,
	RESET_STATUSS_TOPIC,
	ONFAULT_TOPIC,
	RESET_GET_FULL_TOPIC_SUCCESS,
	FIND_TOPIC_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createTopicSuccess = (TopicData) => {
	return {
		type: CREATE_TOPIC_SUCCESS,
		payload: TopicData,
	}
}

export const getTopicSuccess = (TopicData) => {
	return {
		type: GET_TOPIC_SUCCESS,
		payload: TopicData,
	}
}

export const getFullTopicsSuccess = (TopicData) => {
	return {
		type: GET_FULL_TOPIC_SUCCESS,
		payload: TopicData,
	}
}

export const resetGetFullTopicSuccess = (TopicData) => {
	return {
		type: RESET_GET_FULL_TOPIC_SUCCESS,
		payload: TopicData,
	}
}

export const deleteTopicSuccess = (TopicData) => {
	return {
		type: DELETE_TOPIC_SUCCESS,
		payload: TopicData,
	}
}

export const updateTopicSuccess = (TopicData) => {
	return {
		type: UPDATE_TOPIC_SUCCESS,
		payload: TopicData,
	}
}

export const findTopicSuccess = (TopicData) => {
	return {
		type: FIND_TOPIC_SUCCESS,
		payload: TopicData,
	}
}

export const changeDeletedTopicSuccessStatus = (data) => {
	return {
		type: DELETE_TOPIC_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_TOPIC,
		payload: data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_TOPIC,
		payload: data,
	}
}

export const createTopic = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Topics/Add', data)
		.then((res) => {
			const AddedTopic = res.data
			dispatch(createTopicSuccess(AddedTopic))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getTopics = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Topics/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const TopicData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getTopicSuccess(TopicData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullTopics = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Topics/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullTopicssData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullTopicsSuccess(fullTopicssData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteTopic = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Topics/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedTopic = res.data

			dispatch(deleteTopicSuccess(deletedTopic))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteTopic = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Topics/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedTopic = res.data

			dispatch(deleteTopicSuccess(deletedTopic))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateTopic = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Topics/Update'
	axios
		.put(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(updateTopicSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const findTopic = (data) => (dispatch) => {
	setAuthHeader()
	var url = `/api/Topics/Find?id=${data.id}`
	axios
		.get(url)
		.then((res) => {
			const updated = res.data

			dispatch(findTopicSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
