import {
	GET_ALL_REALEXAM_START_SUCCESS,
	POST_ANSWER_REALEXAM_SUCCESS,
	FINISH_REALEXAM_SUCCESS,
	AGAIN_REALEXAM_SUCCESS,
	GET_EXAM_DETAILS_SUCCESS,
	RESET_GET_ALL_REALEXAM_START_SUCCESS,
	RESET_POST_ANSWER_REALEXAM_SUCCESS,
	RESET_FINISH_REALEXAM_SUCCESS,
	RESET_AGAIN_REALEXAM_SUCCESS,
	RESET_GET_EXAM_DETAILS_SUCCESS,
	FAULT_GET_ALL_REALEXAM_START_SUCCESS,
	FAULT_POST_ANSWER_REALEXAM_SUCCESS,
	FAULT_FINISH_REALEXAM_SUCCESS,
	FAULT_AGAIN_REALEXAM_SUCCESS,
	FAULT_GET_EXAM_DETAILS_SUCCESS,
	FULL_RESET_REALEXAM,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const getAllrealExamStartSuccess = (data) => {
	return {
		type: GET_ALL_REALEXAM_START_SUCCESS,
		payload: data,
	}
}

export const postAnswerRealEXamSuccess = (data) => {
	return {
		type: POST_ANSWER_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const finishRealExamSuccess = (data) => {
	return {
		type: FINISH_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const egeinRealExamSuccess = (data) => {
	return {
		type: AGAIN_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const getExamDetailsSuccess = (data) => {
	return {
		type: GET_EXAM_DETAILS_SUCCESS,
		payload: data,
	}
}

//************************************ */ RESETS
export const resetGetAllrealExamStartSuccess = (data) => {
	return {
		type: RESET_GET_ALL_REALEXAM_START_SUCCESS,
		payload: data,
	}
}

export const resetPostAnswerRealEXamSuccess = (data) => {
	return {
		type: RESET_POST_ANSWER_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const resetFinishRealExamSuccess = (data) => {
	return {
		type: RESET_FINISH_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const resetAgeinRealExamSuccess = (data) => {
	return {
		type: RESET_AGAIN_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const resetGetExamDetailsSuccess = (data) => {
	return {
		type: RESET_GET_EXAM_DETAILS_SUCCESS,
		payload: data,
	}
}

export const fullResetRealExam = (data) => {
	return {
		type: FULL_RESET_REALEXAM,
		payload: data,
	}
}

//************************************ */ Faults

export const faultGetAllRealExamStartSuccess = (data) => {
	return {
		type: FAULT_GET_ALL_REALEXAM_START_SUCCESS,
		payload: data,
	}
}

export const faultPostAnswerRealExamSuccess = (data) => {
	return {
		type: FAULT_POST_ANSWER_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const faultFinishRealExamSuccess = (data) => {
	return {
		type: FAULT_FINISH_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const faultAgainRealEXamSuccess = (data) => {
	return {
		type: FAULT_AGAIN_REALEXAM_SUCCESS,
		payload: data,
	}
}

export const faultGetExamDetailSuccess = (data) => {
	return {
		type: FAULT_GET_EXAM_DETAILS_SUCCESS,
		payload: data,
	}
}

export const getAllrealExamStart = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Exams/StartExam'

	if (data.ExamHistoryId) {
		url = url + '?ExamHistoryId=' + data.ExamHistoryId
	}

	axios
		.get(url, data)
		.then((res) => {
			const ResData = res.data
			dispatch(getAllrealExamStartSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultGetAllRealExamStartSuccess(ffData))
		})
}

export const postAnswerRealEXam = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Exams/Answer', data)
		.then((res) => {
			const ResData = res.data
			dispatch(postAnswerRealEXamSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultPostAnswerRealExamSuccess(ffData))
		})
}

export const finishRealExam = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Exams/FinishExam'

	if (data.ExamHistoryId) {
		url = url + '?ExamHistoryId=' + data.ExamHistoryId
	}

	axios
		.post(url, data)
		.then((res) => {
			const ResData = res.data
			dispatch(finishRealExamSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultFinishRealExamSuccess(ffData))
		})
}

//********************************************************************************** */

export const egeinRealExam = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Exams/ReExam'

	if (data.ExamHistoryId) {
		url = url + '?ExamHistoryId=' + data.ExamHistoryId
	}

	axios
		.post(url, data)
		.then((res) => {
			const ResData = res.data
			dispatch(egeinRealExamSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultAgainRealEXamSuccess(ffData))
		})
}

//********************************************************************************** */

export const getExamDetails = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Exams/ExamDetail'

	if (data.ExamHistoryId) {
		url = url + '?ExamHistoryId=' + data.ExamHistoryId
	}

	axios
		.get(url, data)
		.then((res) => {
			const ResData = res.data

			dispatch(getExamDetailsSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultGetExamDetailSuccess(ffData))
		})
}
