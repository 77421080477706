import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as answerActions from '../../../redux/actions/answerActions'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as questionTypeActions from '../../../redux/actions/questionTypeActions'
import { ScrollView } from '../base/scroll'



class AnswersForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			AnswerText: '',
			QuestionSelection: '-1',
		}
	}

	componentDidMount() {
		this.props.actions.getQuestionTypes()

		{
			this.props?.location?.state?.actionType === 'update'
				? this.setState({
					AnswerText: this.props.location.state.data.answerText,
					QuestionSelection: this.props.location.state.data.questionId,
				  })
				: this.setState({ AnswerText: '', QuestionSelection: '-1' })
		}

		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.classroomData.success) {
			var location = {
				pathname: 'Sınıflar',
				state: {},
			}

			this.props.history.push(location)
		} else if (newProps.classroomData.success === false) {
			alertifyjs.error('Hata: ' + newProps.classroomData.messages)
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.props.actions.resetAllStatuss('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateAnswer({
						AnswerText: this.state.AnswerText,
						QuestionId: this.state.QuestionSelection,
						id: this.props.location.state.data.id,
					  })
					: this.props.actions.createAnswer({
						AnswerText: this.state.AnswerText,
						QuestionId: this.state.QuestionSelection,
					  })
			}
		}
	}

	validationFunc() {
		if (this.state.QuestionSelection !== '-1') {
			if (this.state.AnswerText.length > 1) {
				return true
			} else {
				alertifyjs.error('Cevap 2 Haneden Kısa Olamaz!')
				return false
			}
		} else {
			alertifyjs.error('Soru Seçimini yapmış olmalısınız!')
			return false
		}
	}

	onChange(event) {
		const { name, value } = event.target
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>

					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="QuestionSelection">Soru Seçimi</Label>
										<Input
											type="select"
											name="QuestionSelection"
											id="QuestionSelection"
											onChange={this.setOnChange}
											selected={this.state.QuestionSelection}
											value={this.state.QuestionSelection}
										>
											<option value="-1" key="-1">
												Seçiniz
											</option>
											{this.props.questionsTypeData.extra.map((question) => {
												return (
													<option value={question.id} key={question.id}>
														{question.typeName}{' '}
													</option>
												)
											})}
										</Input>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="AnswerText">Cevap</Label>
										<Input
											type="text"
											name="AnswerText"
											id="AnswerText"
											placeholder="Cevap Belirleyiniz!"
											value={this.state.AnswerText}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									block
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Cevabı Güncelle
								</Button>
							) : (
								<Button
									block
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Cevabı Oluştur
								</Button>
							)}
						</Form>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		classroomData: state.classroomReducer,

		questionsTypeData: state.questionTypeReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			createAnswer: bindActionCreators(answerActions.createAnswer, dispatch),
			getQuestionTypes: bindActionCreators(
				questionTypeActions.getQuestionTypes,
				dispatch
			),
			resetAllStatuss: bindActionCreators(answerActions.resetAllStatuss, dispatch),
			updateAnswer: bindActionCreators(answerActions.updateAnswer, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnswersForm))
