import {
	GET_USERS_NOT_ASSIGNEDS_SUCCESS,
	USERS_NOT_ASSIGNEDS_RESET_ALL_STATUSS,
	ONFAULT_USERS_NOT_ASSIGNEDS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const getUsersNotAssignedSuccess = (examData) => {
	return {
		type: GET_USERS_NOT_ASSIGNEDS_SUCCESS,
		payload: examData,
	}
}

export const resetUSersNotAssignedSuccess = (examData) => {
	return {
		type: USERS_NOT_ASSIGNEDS_RESET_ALL_STATUSS,
		payload: examData,
	}
}

export const onFaultExamByIdSuccess = (data) => {
	return {
		type: ONFAULT_USERS_NOT_ASSIGNEDS,
		payload: data,
	}
}

export const getUsersNotAssigned = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/GetNotAssigneds'
	if (data.RoleId) {
		url = url + '?RoleId=' + data.RoleId
	}

	axios
		.get(url)
		.then((res) => {
			const getted = res.data

			dispatch(getUsersNotAssignedSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultExamByIdSuccess(ffData))
		})
}
