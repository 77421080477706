import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

function getLocalstoragedata() {
  var jwtToken = localStorage.getItem("jwtToken")
  var jwtDecodedToken = localStorage.getItem("jwtDecodedToken")
  var refreshToken = localStorage.getItem("refreshToken")
  var expiration = localStorage.getItem("expiration")
  var permissions = localStorage.getItem("Permissions")
  var email = localStorage.getItem("email")

  try {
    var settings = JSON.parse(localStorage.getItem("settings"))
  } catch (err) {
    var settings = null
  }

  var initialDataLoc = initialState.userAuthData

  if (jwtToken) {
    if (settings !== null) {
      return {
        ...initialDataLoc,
        isAuthenticated: true,
        jwtToken: jwtToken,
        jwtDecodedToken: jwtDecodedToken,
        refreshToken: refreshToken,
        expiration: expiration,
        permissions: permissions,
        email: email,
        settings: settings,
      }
    } else {
      return {
        ...initialDataLoc,
        isAuthenticated: true,
        jwtToken: jwtToken,
        jwtDecodedToken: jwtDecodedToken,
        refreshToken: refreshToken,
        expiration: expiration,
        permissions: permissions,
        email: email,
        settings: {
          font: "Marmelad",
          color: "Red",
          fontnamefull: "'Armata', sans-serif",
        }, //settings
      }
    }
  } else {
    return initialState.userAuthData
  }
}

var controlled_authData = getLocalstoragedata()

export default function authReducer(state = controlled_authData, action) {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        jwtToken: action.payload.jwtToken,
        jwtDecodedToken: action.payload.jwtDecodedToken,
        refreshToken: action.payload.refreshToken,
        expiration: action.payload.expiration,
        permissions: action.payload.permissions,
        email: action.payload.email,
        messages: "",
        success: true,
        settings: state.settings, //action.payload.settings
      }

    case actionTypes.SET_CURRENT_USER_ONFAULT:
      return {
        ...state,
        isAuthenticated: false,
        jwtToken: "",
        jwtDecodedToken: {},
        refreshToken: "",
        expiration: "",
        permissions: [],
        email: "",
        messages: action.payload.message,
        success: false,
        settings: state.settings,
      }

    case actionTypes.EXIT_ACTION:
      return {
        ...state,
        isAuthenticated: false,
        jwtToken: "",
        jwtDecodedToken: {},
        refreshToken: "",
        expiration: "",
        permissions: [],
        email: "",
        messages: "",
        settings: state.settings,
      }

    case actionTypes.CHANGE_SETTINGS_SUCCESS:
      return {
        ...state,
        successSetted: true,
        settings: action.payload,
      }

    case actionTypes.RESET_AUTH_SUCCESS_STATUSS:
      return {
        ...state,
        success: "",
        successSetted: "",
      }

    default:
      return state
  }
}
