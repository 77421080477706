import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import {
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
	NavLink,
	FormGroup,
	Label,
	Input,
	NavbarText,
	ButtonGroup,
	Button,
	Popover,
	PopoverHeader,
	PopoverBody,
} from 'reactstrap'

import SettingsIcon from '@material-ui/icons/Settings'

import { connect } from 'react-redux'
import { deleteToken, changeSettings } from '../../redux/actions/authActions'

import { changeNavigation } from '../../redux/actions/changeNavigationAction'
import { getToggleStatuss } from '../../redux/actions/toggleActions'

import jwt_decode from 'jwt-decode'

const Navi = (...props) => {
	const [selectedFont, setselectedFont] = useState(
		props[0].userAuthData.settings.font
	)

	const [isOpen, setIsOpen] = useState(false)

	const toggle = () => setIsOpen(!isOpen)

	const [popoverOpen, setPopoverOpen] = useState(false)
	const togglePOPO = () => setPopoverOpen(!popoverOpen)
	//******************************************************** */
	const [popoverOpenSettings, setPopoverOpenSettings] = useState(false)
	const togglePOPOSettings = () => setPopoverOpenSettings(!popoverOpenSettings)

	//********************************************************** */
	const dispatch = useDispatch()

	function handleExit() {
		dispatch(deleteToken())
		dispatch(getToggleStatuss(false))
		props[0].history.push('home')
		dispatch(changeNavigation('home'))
	}

	function onClickChangeNav(pathname) {
		var location = {
			pathname,
			state: {
				data: '',
				actionType: '',
			},
		}

		props[0].history.push(location)

		setPopoverOpen(!popoverOpen)
	}

	function changeSettingsFontColor(color) {
		dispatch(
			changeSettings({
				font: props[0].userAuthData.settings.font,
				color: color,
				fontnamefull: props[0].userAuthData.settings.fontnamefull,
			})
		)
		window.location.reload()
	}

	function changeSettingsFont(e) {
		setselectedFont(e.target.value)

		if (e.target.value === 'Andika') {
			dispatch(
				changeSettings({
					font: 'Andika',
					color: props[0].userAuthData.settings.color,
					fontnamefull: "'Andika', sans-serif",
				})
			)
		} else if (e.target.value === 'Armata') {
			dispatch(
				changeSettings({
					font: 'Armata',
					color: props[0].userAuthData.settings.color,
					fontnamefull: "'Armata', sans-serif",
				})
			)
		} else if (e.target.value === 'Gloria') {
			dispatch(
				changeSettings({
					font: 'Gloria',
					color: props[0].userAuthData.settings.color,
					fontnamefull: "'Gloria Hallelujah', cursive",
				})
			)
		} else if (e.target.value === 'Marmelad') {
			dispatch(
				changeSettings({
					font: 'Marmelad',
					color: props[0].userAuthData.settings.color,
					fontnamefull: "'Marmelad', sans-serif",
				})
			)
		} else if (e.target.value === 'Trochi') {
			dispatch(
				changeSettings({
					font: 'Trochi',
					color: props[0].userAuthData.settings.color,
					fontnamefull: "'Trocchi', serif",
				})
			)
		}

		window.location.reload()
	}

	return (
		<div>
			<Navbar color="dark" light expand="md">
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="mr-auto" navbar>
						<NavItem>
							<NavLink
								style={{
									fontFamily: props[0].userAuthData.settings.fontnamefull,
									fontSize: '18px',
									color: 'white',
								}}
							>
								<span>
									{' '}
									<i className="fa fa-angle-right" style={{ fontSize: '1.75em' }} />
								</span>{' '}
								Hoşgeldiniz,{' '}
								{
									jwt_decode(props[0].userAuthData.jwtToken)[
										'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
									]
								}
							</NavLink>
						</NavItem>
					</Nav>
					<NavbarText>
						{/* <Link to={"/Roller"}>Roller</Link> */}

						<ButtonGroup>
							<Button
								id="Popover2"
								type="button"
								style={{
									fontFamily: props[0].userAuthData.settings.fontnamefull,
									fontSize: '18px',
								}}
							>
								<SettingsIcon />
							</Button>

							<Button
								id="Popover1"
								type="button"
								style={{
									fontFamily: props[0].userAuthData.settings.fontnamefull,
									fontSize: '18px',
								}}
							>
								Mini Menu
							</Button>
						</ButtonGroup>

						<Popover
							placement="bottom"
							isOpen={popoverOpen}
							target="Popover1"
							toggle={togglePOPO}
							trigger="legacy"
						>
							<PopoverHeader>{props[0].userAuthData.email}</PopoverHeader>
							<PopoverBody>
								<button
									className="btn btn-block btn-dark"
									style={{
										fontFamily: props[0].userAuthData.settings.fontnamefull,
										fontSize: '18px',
									}}
									onClick={() => onClickChangeNav('Profile')}
								>
									Profil
								</button>

								<button
									className="btn btn-block btn-warning"
									style={{
										fontFamily: props[0].userAuthData.settings.fontnamefull,
										fontSize: '18px',
									}}
									onClick={() => onClickChangeNav('Messages')}
								>
									Mesajlar
								</button>
								<button
									onClick={handleExit}
									className="btn btn-block btn-danger"
									style={{
										fontFamily: props[0].userAuthData.settings.fontnamefull,
										fontSize: '18px',
									}}
								>
									Çıkış Yap
								</button>
							</PopoverBody>
						</Popover>

						<Popover
							placement="bottom"
							isOpen={popoverOpenSettings}
							target="Popover2"
							toggle={togglePOPOSettings}
							trigger="legacy"
						>
							<PopoverHeader>Ayarlar</PopoverHeader>

							<PopoverBody>
								<ButtonGroup>
									<Button color="danger" onClick={() => changeSettingsFontColor('Red')}>
										Kırmızı
									</Button>
									<Button color="warning" onClick={() => changeSettingsFontColor('Orange')}>
										Turuncu
									</Button>
									<Button color="primary" onClick={() => changeSettingsFontColor('Blue')}>
										Mavi
									</Button>
								</ButtonGroup>

								<FormGroup>
									<Label for="exampleSelect">Select</Label>
									<Input
										type="select"
										name="select"
										id="exampleSelect"
										onChange={changeSettingsFont}
										value={selectedFont}
									>
										<option
											style={{
												fontWeight: 'bold',
												fontFamily: "'Andika', sans-serif",
											}}
											value="Andika"
										>
											Andika
										</option>
										<option
											style={{
												fontWeight: 'bold',
												fontFamily: "'Armata', sans-serif",
											}}
											value="Armata"
										>
											Armata
										</option>
										<option
											style={{
												fontWeight: 'bold',
												fontFamily: "'Gloria Hallelujah', sans-serif",
											}}
											value="Gloria"
										>
											Gloria
										</option>
										<option
											style={{
												fontWeight: 'bold',
												fontFamily: "'Marmelad', sans-serif",
											}}
											value="Marmelad"
										>
											Marmelad
										</option>
										<option
											style={{
												fontWeight: 'bold',
												fontFamily: "'Trocchi', sans-serif",
											}}
											value="Trochi"
										>
											Trochi
										</option>
									</Input>
								</FormGroup>
							</PopoverBody>
						</Popover>
					</NavbarText>
				</Collapse>
			</Navbar>
		</div>
	)
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		userAuthData: state.authReducer,
		toggleStatus: state.toggleReducer,
		currentNavigation: state.changeNavigationReducer,
	}
}

export default withRouter(connect(mapStateToProps, { deleteToken })(Navi))
