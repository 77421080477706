import React, { Component } from 'react'
import LeftNav from '../navi/LeftNav'
import Navi from '../navi/Navi'
import { connect } from 'react-redux'
import $ from 'jquery'
import { Route, Switch } from 'react-router-dom'

import Answers from '../innerComponenets/answers/Answers'
import Classrooms from '../innerComponenets/classrooms/Classrooms'
import Default from '../innerComponenets/default/Default'
import Exams from '../innerComponenets/exams/Exams'
import Lessons from '../innerComponenets/lessons/Lessons'
import QuestionsType from '../innerComponenets/questionsType/QuestionsType'
import Questions from '../innerComponenets/questions/Qustions'
import Roles from '../innerComponenets/roller/Roles'
import Schools from '../innerComponenets/schools/Schools'
import Users from '../innerComponenets/users/Users'
import Topics from '../innerComponenets/topics/Topics'
import Profile from '../innerComponenets/profile/Profile'
import Messages from '../innerComponenets/messages/Messages'

import RolesForm from '../innerComponenets/roller/RolesForm'
import LessonForm from '../innerComponenets/lessons/LessonForm'
import SchoolsForm from '../innerComponenets/schools/SchoolsForm'
import UsersForm from '../innerComponenets/users/UsersForm'
import ClassroomsForm from '../innerComponenets/classrooms/ClassroomsForm'
import QuestionsTypeForm from '../innerComponenets/questionsType/QuestionsTypeForm'
import QuestionsForm from '../innerComponenets/questions/QuestionsForm'
import AnswersForm from '../innerComponenets/answers/AnswersForm'
import TopicsForm from '../innerComponenets/topics/TopicsForm'
import TopicMaterialsForm from '../innerComponenets/topics/TopicMaterialsForm'
import ExamForm from '../innerComponenets/exams/ExamForm'
import RealExam from '../innerComponenets/realexam/realexam'
import PreviewExam from '../innerComponenets/realexam/previewExam'

import ToClass from '../innerComponenets/classrooms/ToClass'
import ToClassLesson from '../innerComponenets/classrooms/ToClassLessons'

import ToSchool from '../innerComponenets/schools/ToSchool'
import ClassroomDetails from '../innerComponenets/classrooms/ClassroomDetails'
import UserDetails from '../innerComponenets/users/UserDetails'
import LessonDetails from '../innerComponenets/lessons/LessonDetails'
import TopicDetails from '../innerComponenets/topics/TopicDetails'
import ExamDetails from '../innerComponenets/exams/ExamDetails'

import ExamResult from '../innerComponenets/exams/ExamResult'

class Dashboard extends Component {
	constructor(props) {
		super(props)

		this.state = { width: window.innerWidth, height: window.innerHeight }
	}

	componentDidMount() {
		//************************* */
		var jqueryToThis = this
		$(document).ready(function () {
			$(document).on('click', '.overlay', function (e) {
				if (jqueryToThis.props.toggleStatus === true) {
					$("button[class='sidenav---sidenav-toggle---1KRjR']").click()
				}
			})
		})
		//************************* */
	}

	render() {
		const styleW1 = {
			marginLeft: '64px',
			padding: '15px',
		}

		const styleW2 = {
			marginLeft: '240px',
			padding: '15px',
		}
		//***************************************************** */

		const styleW1_topnav = {
			marginLeft: '64px',
		}

		const styleW2_topnav = {
			marginLeft: '240px',
		}
		//***************************************************** */

		return (
			<React.Fragment>
				<div
					id="container"
					style={{ width: '100%', overflow: 'hidden', backgroundColor: 'white' }}
				>
					<div
						style={
							this.state.width > 500
								? styleW2_topnav
								: this.props.toggleStatus === true
									? styleW2_topnav
									: styleW1_topnav
						}
					>
						<LeftNav />
					</div>

					{/* <div className={this.props.toggleStatus === true ? "overlay" : null} style={this.props.toggleStatus === true ? styleW2_topnav : styleW1_topnav}>
                    {/* <div id="text">{this.props.location.state.naviName}</div>
                    <div id="firmTextOverlay">www.tinksoftware.com Copyright<br></br>© 2002 by tinkSoftware Ltd.</div>

                </div> */}

					<div
						style={
							this.state.width > 500
								? styleW2_topnav
								: this.props.toggleStatus === true
									? styleW2_topnav
									: styleW1_topnav
						}
					>
						<Navi />
					</div>

					<div
						style={
							this.state.width > 500
								? styleW2
								: this.props.toggleStatus === true
									? styleW2
									: styleW1
						}
					>
						<Switch>
							<Route path="/" exact component={Default} />

							<Route path="/Cevaplar">
								<Answers />
							</Route>

							<Route path="/Sınıflar">
								<Classrooms />
							</Route>

							<Route path={`match.path/Sınıflar`}>
								<Profile />
							</Route>

							<Route path="/home">
								<Default />
							</Route>

							<Route path="/Testler">
								<Exams />
							</Route>

							<Route path="/Dersler">
								<Lessons />
							</Route>

							<Route path="/Sorutipi">
								<QuestionsType />
							</Route>

							<Route path="/Sorular">
								<Questions />
							</Route>

							<Route path="/Roller">
								<Roles />
							</Route>

							<Route path="/Okullar">
								<Schools />
							</Route>

							<Route path="/Kullanıcılar">
								<Users />
							</Route>

							<Route path="/Konular">
								<Topics />
							</Route>

							<Route path={`match.path/Profile`}>
								<Profile />
							</Route>

							<Route path="/Profile">
								<Profile />
							</Route>

							<Route path="/WelcomeExam">
								<RealExam />
							</Route>
							<Route path="/PreviewExam">
								<PreviewExam />
							</Route>
							<Route path="/Messages">
								<Messages />
							</Route>

							<Route path="/AddOrUpdateRoles">
								<RolesForm />
							</Route>
							<Route path="/AddOrUpdateLessons">
								<LessonForm />
							</Route>
							<Route path="/LessonDetails">
								<LessonDetails />
							</Route>
							<Route path="/AddOrUpdateSchools">
								<SchoolsForm />
							</Route>
							<Route path="/AddOrUpdateUssers">
								<UsersForm />
							</Route>
							<Route path="/AddOrUpdateTopicMaterials">
								<TopicMaterialsForm />
							</Route>
							<Route path="/UserDetails">
								<UserDetails />
							</Route>
							<Route path="/AddOrUpdateClassrooms">
								<ClassroomsForm />
							</Route>
							<Route path="/AddOrUpdateQuestionTypes">
								<QuestionsTypeForm />
							</Route>
							<Route path="/AddOrUpdateAnswers">
								<AnswersForm />
							</Route>
							<Route path="/AddOrUpdateTopics">
								<TopicsForm />
							</Route>
							<Route path="/TopicDetails">
								<TopicDetails />
							</Route>
							<Route path="/AddOrUpdateExams">
								<ExamForm />
							</Route>
							<Route path="/ExamDetails">
								<ExamDetails />
							</Route>
							<Route path="/ExamResults">
								<ExamResult />
							</Route>
							<Route path="/AddOrUpdateQuestions">
								<QuestionsForm />
							</Route>
							<Route path="/ToClass">
								<ToClass />
							</Route>
							<Route path="/ClassroomDetails">
								<ClassroomDetails />
							</Route>
							<Route path="/ToClassLesson">
								<ToClassLesson />
							</Route>
							<Route path="/ToSchool">
								<ToSchool />
							</Route>

							<Route path="*">
								<Default />
							</Route>
						</Switch>
					</div>
				</div>
				<div id="show_only_landscape_mode_alert" style={{ display: 'none' }}>
					<p className="alert alert-danger h4" style={{ margin: 10 }}>
						<img
							src="https://uxwing.com/wp-content/themes/uxwing/download/01-user_interface/mobile-landscape-mode.png"
							style={{ marginRight: 10, float: 'left', width: 55 }}
						/>
						Bu sayfa sadece yatay modda gösterilir o yüzden cihazınızı yatay çeviriniz.
					</p>
				</div>
			</React.Fragment>
		)
	}
}

// connect componenet with redux store
function mapStatetoProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
	}
}

export default connect(mapStatetoProps)(Dashboard)
