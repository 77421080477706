import { GET_PERMISSIONS_SUCCESS } from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

//setAuthToken("Bearer " +token);
//--------------------------------------------------------------------------

export const getPermissionDatas = (permissionDatas) => {
	return {
		type: GET_PERMISSIONS_SUCCESS,
		payload: permissionDatas,
	}
}

export const getPermissions = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Users/GetPermissions')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			//const { token } = res.data

			// Token'i localStorage'da saklıyoruz
			//localStorage.setItem('jwtToken', token)

			const permissiondata = res.data

			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getPermissionDatas(permissiondata))
		})
		.catch((err) => alert(err))
}
