import {
	ADD_LESSONS_ASSIGNED_TOCLASS_SUCCESS,
	REMOVE_LESSONS_FROM_TOCLASS_SUCCESS,
	GET_LESSONS_BY_CLASSROOM_SUCCESS,
	GET_LESSONS_NOTASSIGNED_SUCCESS,
	RESET_LESSONS_TOCLASS,
	ONFAULT_LESSONS_TOCLASS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

//*

export const addLessonsAssignedToClassSuccess = (examData) => {
	return {
		type: ADD_LESSONS_ASSIGNED_TOCLASS_SUCCESS,
		payload: examData,
	}
}

//*

export const removeLessonsAssignedToClassSuccess = (examData) => {
	return {
		type: REMOVE_LESSONS_FROM_TOCLASS_SUCCESS,
		payload: examData,
	}
}

//*

export const getLessonsAssignedToClassSuccess = (data) => {
	return {
		type: GET_LESSONS_BY_CLASSROOM_SUCCESS,
		payload: data,
	}
}

export const getLessonsNotAssignedToClassSuccess = (data) => {
	return {
		type: GET_LESSONS_NOTASSIGNED_SUCCESS,
		payload: data,
	}
}

export const resetLessonToClassSuccess = (data) => {
	return {
		type: RESET_LESSONS_TOCLASS,
		payload: data,
	}
}

export const onFaultLessonToClass = (data) => {
	return {
		type: ONFAULT_LESSONS_TOCLASS,
		payload: data,
	}
}

export const addLessonsAssignedToClass = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Lessons/AssignToClass'

	if (data.ClassroomId) {
		url = url + '?ClassroomId=' + data.ClassroomId + '&LessonId=' + data.LessonId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(addLessonsAssignedToClassSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultLessonToClass(ffData))
		})
}

export const removeLessonsAssignedToClass = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Lessons/RemoveFromClass'

	if (data.ClassroomId) {
		url = url + '?ClassroomId=' + data.ClassroomId + '&LessonId=' + data.LessonId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(removeLessonsAssignedToClassSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultLessonToClass(ffData))
		})
}

export const getLessonsAssignedToClass = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Lessons/GetByClassroom'

	if (data.ClassroomId) {
		url = url + '?ClassroomId=' + data.ClassroomId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(getLessonsAssignedToClassSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultLessonToClass(ffData))
		})
}

export const getLessonsNotAssignedToClass = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Lessons/GetNotAssigneds'

	if (data.ClassroomId) {
		url = url + '?ClassroomId=' + data.ClassroomId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(getLessonsNotAssignedToClassSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultLessonToClass(ffData))
		})
}
