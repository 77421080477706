import React, { Component } from 'react'
import { Plyr } from 'plyr-react'

export class VideoPlayer extends Component {
	refVideoElement

	constructor(props) {
		super()

		this.state = {
			visible: props.visible,
		}
	}

	componentDidMount() {
		let video = this.props.video
		this.props.materialType == 1 && this.setVideo(video)
	}

	componentWillReceiveProps(newProps) {
		if (this.state.video != newProps.video && newProps.materialType == 1) {
			this.setVideo(newProps.video)
		} else {
			if (this.refVideoElement) {
				this.refVideoElement.pause()
			}
		}

		if (newProps.visible != this.props.visible) {
			this.setState({
				visible: newProps.visible,
			})
		}
	}

	setVideo(video) {
		let regex = /(?:youtube\.com\/(?:[^\\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\\/\s]{11})/i
		let youtubeId = video.match(regex)[1].trim()
		this.setState({
			video,
			youtubeId,
			sources: {
				type: 'video',
				sources: [
					{
						src: youtubeId,
						provider: 'youtube',
					},
				],
			},
		})
	}

	render() {
		return this.state.sources ? (
			<div style={{ height: !this.state.visible ? 0 : 'auto' }}>
				<Plyr ref={(ref) => (this.refVideoElement = ref)} source={this.state.sources} />
			</div>
		) : (
			<p className="alert alert-danger">Hatalı video</p>
		)
	}
}
