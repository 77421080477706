/* eslint-disable */
import IconPdf from '@material-ui/icons/PictureAsPdf'
import IconVideo from '@material-ui/icons/VideoLibrary'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as topicMaterialActions from '../../../redux/actions/topicMaterialActions'
import { popupCenter } from '../../../utils/helpers/window'
import { VideoPlayer } from '../base/video'


class TopicMaterialView extends Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

	componentDidMount() {
		this.props.actions.getTopicMaterials({
			TopicId: this.props.topicId,
		})
	}

	componentWillReceiveProps(newProps) {
		if (newProps.topicMaterialReducersData.success === true) {
			this.changeMaterial(
				newProps.topicMaterialReducersData.extra.filter((i) => i.isActive)[0]
			)
		}
	}

	changeMaterial(video) {
		this.setState({
			selectedMaterial: video,
		})
	}

	getIcon(material) {
		switch (material.materialType) {
			case 1:
				return <IconVideo />
			case 2:
				return <IconPdf />
			default:
				null
		}
	}

	render() {
		return this.props.topicMaterialReducersData.extra.length > 0 ? (
			<div>
				<p className="alert alert-info">
					Teste başlamadan önce eğitim videolarını izlemeyi unutma!
				</p>
				<ul class="nav nav-tabs nav-fill" style={{ marginBottom: 10 }}>
					{this.props.topicMaterialReducersData.extra
						.filter((i) => i.isActive)
						.map((material) => (
							<li
								class="nav-item"
								key={material.id}
								onClick={() => this.changeMaterial(material)}
							>
								<a
									style={{ color: '#495057' }}
									class={`nav-link ${
										this.state.selectedMaterial == material ? 'active' : ''
									}`}
									href="javascript:;"
								>
									{this.getIcon(material)} {material.title}
								</a>
							</li>
						))}
				</ul>
				{this.state.selectedMaterial && (
					<VideoPlayer
						video={this.state.selectedMaterial.value}
						materialType={this.state.selectedMaterial.materialType}
						visible={this.state.selectedMaterial.materialType == 1}
					/>
				)}
				{this.state.selectedMaterial && this.state.selectedMaterial.materialType == 2 && (
					<Button
						variant="link"
						onClick={() =>
							popupCenter({
								url: this.state.selectedMaterial.value,
								title: this.state.selectedMaterial.title,
							})
						}
					>
						{this.state.selectedMaterial.title} Göster / İndir
					</Button>
				)}
			</div>
		) : null
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		topicMaterialReducersData: state.topicMaterialReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			getTopicMaterials: bindActionCreators(
				topicMaterialActions.getTopicMaterials,
				dispatch
			),
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicMaterialView)
