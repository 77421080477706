import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function getFullRolesReducer(
  state = initialState.InitialfullRoles,
  action
) {
  switch (action.type) {
    case actionTypes.GET_FULL_ROLE_SUCCESS:
      return {
        ...state,
        successGettedFullRoles: action.payload.success,
        extraGettedFullRoles: action.payload.extra,
      }

    case actionTypes.RESET_GET_FULL_ROLE_SUCCESS:
      return {
        ...state,
        successGettedFullRoles: "",
      }

    default:
      return state
  }
}
