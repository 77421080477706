import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function getLessonReducer(
	state = initialState.Initiallessons,
	action
) {
	switch (action.type) {
		case actionTypes.CREATE_LESSON_SUCCESS:
			return {
				...state,
				successCreated: action.payload.success,
				extraCreated: action.payload.extra,
			}

		case actionTypes.DELETE_LESSON_SUCCESS:
			if (action.payload == 'Başarıyla silindi.') {
				return {
					...state,
					SuccessDeleted: true,
				}
			} else {
				return {
					...state,
					SuccessDeleted: false,
				}
			}

		case actionTypes.GET_LESSON_SUCCESS:
			return {
				...state,
				successGettedLesson: action.payload.success,
				extraGetted: action.payload.extra,
			}

		case actionTypes.UPDATE_LESSON_SUCCESS:
			return {
				...state,
				successUpdated: action.payload.success,
			}

		//*********************************** */ RESETS
		case actionTypes.RESET_CREATE_LESSON_SUCCESS:
			return {
				...state,
				successCreated: '',
			}

		case actionTypes.RESET_GET_LESSON_SUCCESS:
			return {
				...state,
				successGettedLesson: '',
			}

		case actionTypes.RESET_UPDATE_LESSON_SUCCESS:
			return {
				...state,
				successUpdated: '',
			}

		case actionTypes.RESET_DELETE_LESSON_SUCCESS:
			return {
				...state,
				SuccessDeleted: '',
			}

		case actionTypes.RESET_DELETE_LESSON_SUCCESS_STATUSS:
			return {
				...state,
				SuccessDeleted: '',
			}

		case actionTypes.RESET_STATUSS_LESSON:
			return {
				...state,
				successCreated: '',
				SuccessDeleted: '',
				successGettedLesson: '',
				successUpdated: '',
			}

		//*********************************** */ FAULT
		case actionTypes.ONFAULT_LESSON:
			return {
				...state,
				success: false,
				messages: action.payload.messages,
			}

		default:
			return state
	}
}
