import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function examDetailsReducer(
	state = initialState.ExamDetailsInitialState,
	action
) {
	switch (action.type) {
		case actionTypes.GET_EXAMS_WİTH_QUESTİONS_SUCCESS:
			return {
				...state,
				successGetExams: action.payload.success,
				extraGetExams: action.payload.extra,
			}

		case actionTypes.GET_USERS_GET_ASSIGNED_USERS_SUCCESS:
			return {
				...state,
				successGetUsers: action.payload.success,
				extraGetUsers: action.payload.extra,
			}

		case actionTypes.GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS_SUCCESS:
			return {
				...state,
				successGetClassrooms: action.payload.success,
				extraGetClassrooms: action.payload.extra,
			}

		//********************************************************* */
		case actionTypes.RESET_GET_EXAMS_WİTH_QUESTİONS_SUCCESS:
			return {
				...state,
				successGetExams: '',
			}

		case actionTypes.RESET_GET_USERS_GET_ASSIGNED_USERS_SUCCESS:
			return {
				...state,
				successGetUsers: '',
			}

		case actionTypes.RESET_GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS:
			return {
				...state,
				successGetClassrooms: '',
			}

		case actionTypes.FULL_RESET_ON_ACTS_EXAM_DETAILS:
			return {
				...state,
				successGetExams: '',
				successGetUsers: '',
				successGetClassrooms: '',
			}

		//********************************************************* */

		case actionTypes.FAULT_ON_ACTS_EXAM_DETAILS:
			return {
				...state,
				successGetExams: false,
				successGetUsers: false,
				successGetClassrooms: false,
				messages: action.payload.messages,
			}

		default:
			return state
	}
}
