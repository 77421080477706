import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Button,





    Col, Form,
    FormGroup,

    Input, Label,



    Row
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as roleActions from '../../../redux/actions/roleActions'
import { ScrollView } from '../base/scroll'




class RolesForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			roleName: '',
		}
	}

	// before render
	// componentWillMount() {
	// }

	//after render
	componentDidMount() {
		this.props.actions.chageSuccessStatussRole('')
		this.props.actions.resetAllStatuss('')

		{
			this.props?.location?.state?.actionType === 'update'
				? this.setState({ roleName: this.props.location.state.data.roleName })
				: this.setState({ roleName: '' })
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.roleData.successUpdated) {
			var location = {
				pathname: 'Roller',
			}

			this.props.history.push(location)
			newProps.actions.resetUpdateRoleSuccess()
		} else if (newProps.roleData.successUpdated === false) {
			alertifyjs.error('Hata: ' + newProps.roleData.messages)
			newProps.actions.resetUpdateRoleSuccess()
		}

		if (newProps.roleData.successCreated) {
			var location = {
				id: '11',
				naviName: 'Roller',
				seoUrl: 'Roller',
			}

			//navigate("Roller");
			this.props.history.push('Roller')
			newProps.actions.changeNavigation(location)
			newProps.actions.resetCreateRoleSuccess()
		} else if (newProps.roleData.successCreated === false) {
			alertifyjs.error('Hata: ' + newProps.roleData.messages)
			newProps.actions.resetCreateRoleSuccess()
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()
		this.props.actions.chageSuccessStatussRole(false)
		this.props.actions.resetAllStatuss('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateRole({
						roleName: this.state.roleName,
						id: this.props.location.state.data.id,
					  })
					: this.props.actions.createRole({ roleName: this.state.roleName })
			}
		}
	}

	validationFunc() {
		if (this.state.roleName.length < 4) {
			alertifyjs.error('Rol Adı 5 Haneden Kısa Olamaz!')
			return false
		} else {
			return true
		}
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>
					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Role Adı</Label>
										<Input
											type="text"
											name="roleName"
											id="roleName"
											placeholder="Role Adı Belirleyiniz!"
											value={this.state.roleName}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Rolü Güncelle
								</Button>
							) : (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Rolü Oluştur
								</Button>
							)}
						</Form>
					</div>
					{/* </ScrollView> */}
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		roleData: state.roleReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			createRole: bindActionCreators(roleActions.createRole, dispatch),
			resetCreateRoleSuccess: bindActionCreators(
				roleActions.resetCreateRoleSuccess,
				dispatch
			),

			chageSuccessStatussRole: bindActionCreators(
				roleActions.chageSuccessStatussRole,
				dispatch
			),
			resetChangeSuccessStatussRole: bindActionCreators(
				roleActions.resetChangeSuccessStatussRole,
				dispatch
			),

			updateRole: bindActionCreators(roleActions.updateRole, dispatch),
			resetUpdateRoleSuccess: bindActionCreators(
				roleActions.resetUpdateRoleSuccess,
				dispatch
			),

			resetAllStatuss: bindActionCreators(roleActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RolesForm))
