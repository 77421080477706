import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, FormGroup } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as usersNotAssignedsActions from '../../../redux/actions/usersNotAssignedsActions'
import * as usersOnClassroomActions from '../../../redux/actions/usersOnClassroomActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'







class ExamForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: new Date().toISOString().substring(0, 10),

			StudentsListData_onQuery: [],
			AddedStudentsListData_onQuery: [],

			ToggState: false,
			onActModal: { questionText: '', questionTypeId: '', fileUrl: '' },

			addedUser: '',
			removedUser: '',

			ClassroomHasUsersColumn: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.removeAssignTeacher
						) === true ? (
								<Button color="light" onClick={() => this.removeFromClassRoom(row)}>
									<HighlightOffIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.removeAssignTeacher
						) === true
							? '10rem'
							: '0px',
				},
				{
					name: 'Soru Text',
					selector: 'firstName',
					sortable: true,
					width: '20rem',
				},
				{
					name: 'Soru Zamanı',
					selector: 'lastName',
					sortable: true,
					width: '20rem',
				},
			],

			allNOTAssignedUSersColumn: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.canAssignTeacher
						) === true ? (
								<Button color="light" onClick={() => this.addToClassroom(row)}>
									<AddCircleOutlineIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.canAssignTeacher
						) === true
							? '10rem'
							: '0px',
				},
				{
					name: 'Soru Text',
					selector: 'firstName',
					sortable: true,
					width: '20rem',
				},
				{
					name: 'Soru Zamanı',
					selector: 'lastName',
					sortable: true,
					width: '20rem',
				},
			],
		}
	}

	//after render
	componentDidMount() {
		this.props.actions.getUsersNotAssigned({
			RoleId: this.props.location.state.roleid,
		})

		this.props.actions.getUsersOnClassroom({
			id: this.props.location.state.data.id,
			RoleId: this.props.location.state.roleid,
		})

		this.props.actions.resetUSersNotAssignedSuccess('')
		this.props.actions.resetUSersOnClassroomSuccess('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.usersNotAssignedReducerData.success) {
			this.setState({
				StudentsListData_onQuery: newProps.usersNotAssignedReducerData.extra,
			})
			newProps.actions.resetUSersNotAssignedSuccess('')
		}

		if (newProps.usersOnClassroomReducerData.success) {
			this.setState({
				AddedStudentsListData_onQuery: newProps.usersOnClassroomReducerData.extra,
			})
			newProps.actions.resetUSersOnClassroomSuccess('')
		}

		if (newProps.usersOnClassroomReducerData.Addedsuccess) {
			var dataMid = this.state.AddedStudentsListData_onQuery.concat([
				this.state.addedUser,
			])
			this.setState({ AddedStudentsListData_onQuery: dataMid })

			var StudentsListData_onQuery = this.state.StudentsListData_onQuery.filter(
				(value) => value.id !== this.state.addedUser.id
			)
			this.setState({ StudentsListData_onQuery })
			newProps.actions.resetUSersOnClassroomSuccess('')
		} else if (newProps.usersOnClassroomReducerData.Addedsuccess === false) {
			alertifyjs.error('Hata: ' + newProps.usersOnClassroomReducerData.errorMessage)
			newProps.actions.resetUSersOnClassroomSuccess('')
		}

		if (newProps.usersOnClassroomReducerData.removedSucces) {
			var dataMid = this.state.StudentsListData_onQuery.concat([this.state.removedUser])
			this.setState({ StudentsListData_onQuery: dataMid })

			var AddedStudentsListData_onQuery = this.state.AddedStudentsListData_onQuery.filter(
				(value) => value.id !== this.state.removedUser.id
			)
			this.setState({ AddedStudentsListData_onQuery })
			newProps.actions.resetUSersOnClassroomSuccess('')
		} else if (newProps.usersOnClassroomReducerData.removedSucces === false) {
			alertifyjs.error('Hata: ' + newProps.usersOnClassroomReducerData.errorMessage)
			newProps.actions.resetUSersOnClassroomSuccess('')
		}
	}

	removeFromClassRoom = (data) => {
		var datdat = {
			RemoveUserId: data.id,
			ClassroomId: this.props.location.state.data.id,
		}
		this.props.actions.removeUSerFromClassroom(datdat)
		this.setState({ removedUser: data })
	}

	addToClassroom = (data) => {
		var datdat = {
			AddUserId: data.id,
			ClassroomId: this.props.location.state.data.id,
		}
		this.props.actions.addUserClassroom(datdat)
		this.setState({ addedUser: data })
	}

	renderAllQuestions() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row style={{ marginBottom: 0 }}>
							<h4>
								{this.props?.location?.state?.actionType === 'toClassTeacher'
									? 'Öğretmenler Tablosu'
									: 'Öğrenciler Tablosu'}
							</h4>
						</FormGroup>
						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<DataTable
						id="table2"
						name="table2"
						columns={this.state.allNOTAssignedUSersColumn}
						data={this.state.StudentsListData_onQuery}
						//selectableRows // add for checkbox selection

						//onRowClicked={this.handleClick}

						pagination={true} // paging
						paginationPerPage={5}
						highlightOnHover={true}
						grow={10}
						dense={false}
						direction={'auto'}
						fixedHeader={true}
						fixedHeaderScrollHeight="300px"

						//progressPending={this.props.exams.extra.length > 0 ? false : true}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderAddedQuestions() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>
								{this.props?.location?.state?.actionType === 'toClassTeacher'
									? 'Eklenen Öğretmenler'
									: 'Eklenen Öğrenciler'}
							</h4>
						</FormGroup>
						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						id="table1"
						name="table1"
						columns={this.state.ClassroomHasUsersColumn}
						data={this.state.AddedStudentsListData_onQuery}
						//onRowClicked={this.handleClick}

						pagination={true} // paging
						highlightOnHover={true}

						//progressPending={this.props.exams.extra.length > 0 ? false : true}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	//*************************************************************************** */

	render() {
		return (
			<ScrollView>
				<div className="row">
					{/* <div className="col-lg-1"></div> */}
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.renderAllQuestions()}

							{this.renderAddedQuestions()}
						</Accordion>
					</div>

					{/* <div className="col-lg-1">
                        <Button size="lg" block color='success' style={{ height: '100%' }} ><PlaylistAddCheckIcon fontSize='large' /></Button>
                    </div> */}
					{/* <div className="col-lg-1"></div> */}
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		userAuthData: state.authReducer,

		usersNotAssignedReducerData: state.usersNotAssignedReducer,
		usersOnClassroomReducerData: state.usersOnClassroomReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getUsersNotAssigned: bindActionCreators(
				usersNotAssignedsActions.getUsersNotAssigned,
				dispatch
			),
			getUsersOnClassroom: bindActionCreators(
				usersOnClassroomActions.getUsersOnClassroom,
				dispatch
			),

			resetUSersNotAssignedSuccess: bindActionCreators(
				usersNotAssignedsActions.resetUSersNotAssignedSuccess,
				dispatch
			),
			resetUSersOnClassroomSuccess: bindActionCreators(
				usersOnClassroomActions.resetUSersOnClassroomSuccess,
				dispatch
			),

			addUserClassroom: bindActionCreators(
				usersOnClassroomActions.addUserClassroom,
				dispatch
			),
			removeUSerFromClassroom: bindActionCreators(
				usersOnClassroomActions.removeUSerFromClassroom,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamForm))
