import React, { Component } from 'react'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	Button,
	Badge,
	Container,
	CardText,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap'
import { withRouter } from 'react-router-dom'
import DropZone from '../../toolbox/DropZone'
import { ScrollView } from '../base/scroll'

import queryString from 'query-string'

import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as examActions from '../../../redux/actions/examActions'
import * as realExamActions from '../../../redux/actions/realExamActions'
import * as uploadActions from '../../../redux/actions/uploadActions'
import * as usserActions from '../../../redux/actions/usserActions'

import jwt_decode from 'jwt-decode'

import { Accordion, Card, CustomToggle } from 'react-bootstrap'
import initialState from '../../../redux/reducers/initialState'
import TextField from '@material-ui/core/TextField'
import mainConfig from '../../../configs/mainConfig'
import alertifyjs from 'alertifyjs'
import { formatDateTime } from '../../../utils/helpers/date'

class Profile extends Component {
	constructor(props) {
		super(props)

		this.state = {
			token: {},
			toggle: false,
			NotSolvedExamColumns_onQuery: [],
			SolvedExamColumns_onQuery: [],

			currentRowForAgainExam: '',
			activeTableId: '1',

			madaltitle: 'Profil Resmini Yükleyiniz...',

			fileUrl: '',

			NotSolvedExamColumnsTeacher: [
				{
					name: 'Test',
					selector: 'exam',
					sortable: true,
					cell: (row) => row.exam.examName,
				},
				{
					name: 'Konu',
					selector: 'topic',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},

				{
					name: 'Ders',
					selector: 'lesson',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
				{
					name: 'Sınıf',
					selector: 'class',
					sortable: true,
					cell: (row) => row.classroom.className,
				},
				{
					name: 'Ad Soyad',
					selector: 'class',
					sortable: true,
					cell: (row) => row.userDto.fullName,
				},
			],

			NotSolvedExamColumnsStudent: [
				{
					name: 'Test',
					selector: 'exam',
					sortable: true,
					cell: (row) => row.exam.examName,
				},
				{
					name: 'Konu',
					selector: 'topic',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},

				{
					name: 'Ders',
					selector: 'lesson',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.StartExam
						) == true ? (
								<Button color="warning" onClick={() => this.goOnRealExam(row, 'WelcomeExam')}>
								Katıl
								</Button>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '65px',
				},
			],

			SolvedExamColumnsTeacher: [
				{
					name: 'Test',
					selector: 'exam',
					sortable: true,
					cell: (row) => row.exam.examName,
				},
				{
					name: 'Konu',
					selector: 'topic',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},

				{
					name: 'Ders',
					selector: 'lesson',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
				{
					name: 'Sınıf',
					selector: 'class',
					sortable: true,
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'daec2156-8e63-45b2-9c08-a4b7b72df24a'
						) == true
							? row.classroom.className
							: null,
				},
				{
					name: 'Ad Soyad',
					selector: 'class',
					sortable: true,
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'daec2156-8e63-45b2-9c08-a4b7b72df24a'
						) == true
							? row.userDto.fullName
							: null,
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamDetails
						) == true ? (
								<Button color="dark" onClick={() => this.goOnRealExam(row, 'PreviewExam')}>
								Detaylar
								</Button>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '65px',
				},
			],

			SolvedExamColumnsStudent: [
				{
					name: 'Test',
					selector: 'exam',
					sortable: true,
					cell: (row) => row.exam.examName,
				},
				{
					name: 'Konu',
					selector: 'topic',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},
				{
					name: 'Ders',
					selector: 'lesson',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
				{
					name: 'Katıldığı Tarih',
					selector: 'examStartDate',
					sortable: true,
					cell: (row) => formatDateTime(row.examStartDate),
					width: '170px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamDetails
						) == true ? (
								<Button color="dark" onClick={() => this.goOnRealExam(row, 'PreviewExam')}>
								Detaylar
								</Button>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '95px',
				},
				/*{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.StartExamAgain
						) == true ? (
								<Button color="warning" onClick={() => this.goOnAgainExam(row)}>
								Tekrar Katıl
								</Button>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '120px',
				},*/
			],
		}
	}

	componentWillMount() {
		var token = jwt_decode(this.props.userAuthData.jwtToken)
		var name = token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
		var uuid =
			token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
		var tokenRole =
			token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']

		this.setState({ token: token, fullname: name, role: tokenRole })
		this.props.actions.getExamsSolved({
			UserId: uuid,
			ExamId: '00000000-0000-0000-0000-000000000000',
		})
		this.props.actions.getExamsNotSolved({
			UserId: uuid,
			ExamId: '00000000-0000-0000-0000-000000000000',
		})

		this.props.actions.getUsserById({ id: uuid })

		const values = queryString.parse(this.props.location.search)
		this.setState({ activeTableId: values.Id })
	}

	componentDidMount() {}

	componentWillReceiveProps(newProps) {
		if (newProps.examsData.successNotSolvedGetted === true) {
			this.setState({
				NotSolvedExamColumns_onQuery: newProps.examsData.extraNotSolved,
			})
		}

		if (newProps.examsData.successSolvedGetted === true) {
			this.setState({ SolvedExamColumns_onQuery: newProps.examsData.extraSolved })
		}

		if (newProps.realExamReducerData.successAgainExam === true) {
			this.selectNavigation(
				{ id: newProps.realExamReducerData.extraAgainExam.message },
				'WelcomeExam'
			)
			newProps.actions.resetAgeinRealExamSuccess()
		}

		if (newProps.uploadsData.success === true) {
			this.setToggle()

			this.props.actions.updateUsserAvatar({
				url: mainConfig.imageUploaded + newProps.uploadsData.extra.fileNameList[0],
			})
			this.setState({
				fileUrl: mainConfig.imageUploaded + newProps.uploadsData.extra.fileNameList[0],
			})

			this.props.actions.resetAllPostUploadStatuss('')
		} else if (newProps.uploadsData.success === false) {
			alertifyjs.error('Hata3: ' + newProps.getQuestionsWithAnswersData.messages)
			this.props.actions.resetAllPostUploadStatuss('')
		}

		if (newProps.usserReducerData.successUserByID === true) {
			this.setState({
				fileUrl: newProps.usserReducerData.extraUserByID.avatarUrl,
			})
			this.props.actions.resetAllStatussUsser('')
		} else if (newProps.usserReducerData.successUserByID === false) {
			alertifyjs.error('Hata3: ' + newProps.getQuestionsWithAnswersData.messages)
			this.props.actions.resetAllStatussUsser('')
		}
	}

	goOnAgainExam = (row) => {
		this.setState({ currentRowForAgainExam: row })
		this.props.actions.egeinRealExam({ ExamHistoryId: row.id })
	}

	goOnRealExam = (row, navigation) => {
		this.selectNavigation(row, navigation)
	}

	selectNavigation = (state, pathname) => {
		var location = {
			pathname,
			state,
		}
		this.props.history.push(location)
	}

	mainCallbackFunction = (childData, file) => {
		this.setState({ uploadFireB: childData, uploadFile: file })
	}

	mmFunc = () => {
		this.props.actions.postDatasForUpload(this.state.uploadFile)
	}

	setToggle = (e) => {
		if (this.state.fileUrl === '' || this.state.fileUrl === null) {
			this.setState({ toggle: !this.state.toggle })
		} else {
			this.props.actions.updateUsserAvatar({ url: '' })
			this.setState({ fileUrl: '' })
		}
	}

	render() {
		const data = [
			{
				name: 'Page A',
				uv: 4000,
				pv: 2400,
			},
			{
				name: 'Page B',
				uv: 3000,
				pv: 1398,
			},
			{
				name: 'Page C',
				uv: 2000,
				pv: 9800,
			},
			{
				name: 'Page D',
				uv: 2780,
				pv: 3908,
			},
			{
				name: 'Page E',
				uv: 1890,
				pv: 4800,
			},
			{
				name: 'Page F',
				uv: 2390,
				pv: 3800,
			},
			{
				name: 'Page G',
				uv: 3490,
				pv: 4300,
			},
		]

		//***************************************************** */

		const data3 = [
			{
				name: 'Page A',
				uv: 4000,
				pv: 2400,
				amt: 2400,
			},
			{
				name: 'Page B',
				uv: 3000,
				pv: 1398,
				amt: 2210,
			},
			{
				name: 'Page C',
				uv: -1000,
				pv: 9800,
				amt: 2290,
			},
			{
				name: 'Page D',
				uv: 500,
				pv: 3908,
				amt: 2000,
			},
			{
				name: 'Page E',
				uv: -2000,
				pv: 4800,
				amt: 2181,
			},
			{
				name: 'Page F',
				uv: -250,
				pv: 3800,
				amt: 2500,
			},
			{
				name: 'Page G',
				uv: 3490,
				pv: 4300,
				amt: 2100,
			},
		]

		const gradientOffset = () => {
			const dataMax = Math.max(...data3.map((i) => i.uv))
			const dataMin = Math.min(...data3.map((i) => i.uv))

			if (dataMax <= 0) {
				return 0
			}
			if (dataMin >= 0) {
				return 1
			}

			return dataMax / (dataMax - dataMin)
		}

		const off = gradientOffset()

		const ExpanableComponent = ({ data }) => (
			<Container fluid={true}>
				<div className="row">
					<Card body style={{ width: '100%' }}>
						<CardText>
							<b>Doğru Cevap Sayısı:</b> {data.correctQuestionCount} <br />
							<b>Yanlış Cevap Sayısı:</b> {data.wrongQuestionCount} <br />
							<b>Boş Cevap Sayısı:</b> {data.skippedQuestionCount} <br />
							<b>Sınıf Sıralaması:</b> {data.orderInClass}
							<br />
							<b>Okul Sıralaması:</b> {data.orderInSchool}
							<br />
							<b>Genel Sıralama:</b> {data.orderInTotal}
							<br />
							<b>Alınan Puan:</b> {data.totalPoint}
							<br />
							{data.isClassExam === true ? 'Sınıfa Atanmış Test' : 'Kişiye Atanmış Test'}
							<br />
							{data.isFirstTry === true ? 'İlk Test' : 'Tekrar Testi'}
							<br />
						</CardText>
					</Card>
				</div>
			</Container>
		)

		return (
			<ScrollView>
				<div className="row" style={{ marginBottom: 60 }}>
					<div className="col-lg-3">
						<div className="card card-warning card-outline">
							<div className="card-body box-profile">
								<div className="text-center">
									<button
										type="button"
										className={'btn btn-light'}
										onClick={() => this.setToggle('QuestionText')}
									>
										<img
											className="profile-user-img img-fluid img-circle"
											src={
												this.state.fileUrl ||
												'https://clipartart.com/images/clipart-profile-2.jpg'
											}
											alt={this.state.fullname}
											style={{ height: '100px', width: '100px' }}
										/>
									</button>
								</div>

								<h3 className="profile-username text-center">{this.state.fullname}</h3>

								<p className="text-muted text-center">{this.state.role}</p>
								{this.props.usserReducerData && this.props.usserReducerData.extraUserByID && (
									<React.Fragment>
										<p className="text-muted text-center">
											{this.props.usserReducerData.extraUserByID.phone}
										</p>

										<p className="text-muted text-center">
											{this.props.usserReducerData.extraUserByID.email}
										</p>
									</React.Fragment>
								)}
								<ul className="list-group list-group-unbordered mb-3">
									<li className="list-group-item">
										<b>Çözülmemiş Test </b>
										<a className="float-right">
											{this.state.NotSolvedExamColumns_onQuery.length}
										</a>
									</li>
									<li className="list-group-item">
										<b>Çözülmüş Test </b>
										<a className="float-right">
											{this.state.SolvedExamColumns_onQuery.length}
										</a>
									</li>
									{/* <li className="list-group-item">
                                        <b>Following</b> <a className="float-right">543</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Friends</b> <a className="float-right">13,287</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Friends</b> <a className="float-right">13,287</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Friends</b> <a className="float-right">13,287</a>
                                    </li> */}
								</ul>

								{/* <a href="#" className="btn btn-warning btn-block"><b>Follow</b></a> */}
							</div>
						</div>
					</div>
					<div className="col-lg-9">
						<Accordion defaultActiveKey={this.state.activeTableId}>
							<Card>
								<Accordion.Toggle eventKey="2" as={Card.Header}>
									<h4>
										Çözülmemiş Testler
										<Badge style={{ marginLeft: 5 }}>
											{this.state.NotSolvedExamColumns_onQuery.length} Adet
										</Badge>{' '}
									</h4>
								</Accordion.Toggle>

								<Accordion.Collapse eventKey="2">
									<Card.Body>
										<DataTable
											id="table1"
											name="table1"
											columns={
												this.props.userAuthData.permissions.includes(
													'daec2156-8e63-45b2-9c08-a4b7b72df24a'
												) == true
													? this.state.NotSolvedExamColumnsTeacher
													: this.state.NotSolvedExamColumnsStudent
											}
											data={this.state.NotSolvedExamColumns_onQuery}
											pagination={true} // paging
											highlightOnHover={true}
											progressPending={false}
											subHeader={true}
											noHeader
											subHeaderComponent={
												<div style={{ display: 'flex', alignItems: 'center' }}>
													{this.props.userAuthData.permissions.includes(
														initialState.staticPerms.FindClassroom
													) === true ? (
															<TextField
																onChange={this.searchOnList}
																id="outlined-basic"
																label="Arama"
																variant="outlined"
																size="small"
																style={{ margin: '5px' }}
															/>
														) : null}
												</div>
											}
											subHeaderAlign={'left'}
											fixedHeader={false}
											fixedHeaderScrollHeight="300px"
										/>
									</Card.Body>
								</Accordion.Collapse>
							</Card>

							{/* *************************************************************************** */}

							<Card>
								<Accordion.Toggle eventKey="3" as={Card.Header}>
									<h4>
										Çözülmüş Testler{' '}
										<Badge>{this.state.SolvedExamColumns_onQuery.length} Adet</Badge>{' '}
									</h4>
								</Accordion.Toggle>

								<Accordion.Collapse eventKey="3">
									<Card.Body>
										<DataTable
											id="table1"
											name="table1"
											columns={
												this.props.userAuthData.permissions.includes(
													'daec2156-8e63-45b2-9c08-a4b7b72df24a'
												) == true
													? this.state.SolvedExamColumnsTeacher
													: this.state.SolvedExamColumnsStudent
											}
											data={this.state.SolvedExamColumns_onQuery}
											pagination
											highlightOnHover
											noHeader
											progressPending={false}
											expandableRows
											expandOnRowClicked
											expandableRowsComponent={<ExpanableComponent />}
											subHeader
											subHeaderComponent={
												<div style={{ display: 'flex', alignItems: 'center' }}>
													{this.props.userAuthData.permissions.includes(
														initialState.staticPerms.FindClassroom
													) === true ? (
															<TextField
																onChange={this.searchOnList}
																id="outlined-basic"
																label="Arama"
																variant="outlined"
																size="small"
																style={{ margin: '5px' }}
															/>
														) : null}
												</div>
											}
											subHeaderAlign={'left'}
											fixedHeader={false}
											fixedHeaderScrollHeight="300px"
										/>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
				</div>
				<Modal isOpen={this.state.toggle} toggle={this.setToggle}>
					<ModalHeader toggle={this.setToggle}>{this.state.madaltitle}</ModalHeader>
					<ModalBody>
						{/* <ImageUploader

                                withIcon={false}
                                buttonText={ this.state.QuestionTypeSelection === "da2decc7-db82-41fa-b3de-67774f168e3f" ? 'Resim Yükle' : 'Video Yükle'}
                                onChange={this.onDrop}
                                //imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={1048576}
                                singleImage={true}
                            /> */}
						<DropZone
							toggle={() => this.setToggle()}
							callback={this.mainCallbackFunction}
							callback2={this.mainCallbackFunction2}
						/>
					</ModalBody>
					{this.state.uploadFireB === true ? (
						<ModalFooter>
							<button
								type="button"
								className="btn btn-success btn-lg btn-block"
								onClick={this.mmFunc}
							>
								Yüklemeyi Onayla
							</button>
						</ModalFooter>
					) : null}
				</Modal>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		examsData: state.examReducer,
		realExamReducerData: state.realExamReducer,

		usserReducerData: state.usserReducer,

		uploadsData: state.uploadReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getExamsNotSolved: bindActionCreators(examActions.getExamsNotSolved, dispatch),
			getExamsSolved: bindActionCreators(examActions.getExamsSolved, dispatch),

			egeinRealExam: bindActionCreators(realExamActions.egeinRealExam, dispatch),
			resetAgeinRealExamSuccess: bindActionCreators(
				realExamActions.resetAgeinRealExamSuccess,
				dispatch
			),

			postDatasForUpload: bindActionCreators(
				uploadActions.postDatasForUpload,
				dispatch
			),
			resetAllPostUploadStatuss: bindActionCreators(
				uploadActions.resetAllPostUploadStatuss,
				dispatch
			),

			getUsserById: bindActionCreators(usserActions.getUsserById, dispatch),
			updateUsserAvatar: bindActionCreators(usserActions.updateUsserAvatar, dispatch),
			resetAllStatussUsser: bindActionCreators(usserActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
