import {
	GET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL,
	RESET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL,
	ONFAULT_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const getManagersNotAssignedSuccess = (examData) => {
	return {
		type: GET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL,
		payload: examData,
	}
}

export const resetManagersNotAssignedSuccess = (examData) => {
	return {
		type: RESET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL,
		payload: examData,
	}
}

export const onFaultExamByIdSuccess = (data) => {
	return {
		type: ONFAULT_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL,
		payload: data,
	}
}

export const getManagersNotAssigned = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/GetNotAssigneds'

	if (data.RoleId) {
		url = url + '?RoleId=' + data.RoleId
	}

	axios
		.get(url)
		.then((res) => {
			const getted = res.data

			dispatch(getManagersNotAssignedSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}

			dispatch(onFaultExamByIdSuccess(ffData))
		})
}
