import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'

//import jwt_decode from 'jwt-decode'

import * as leftNavActions from '../../redux/actions/leftNavActions'
import * as changeNavigationAction from '../../redux/actions/changeNavigationAction'
import * as userActions from '../../redux/actions/userActions'
import * as permissonActions from '../../redux/actions/permissonActions'
import * as authActions from '../../redux/actions/authActions'
import * as toggleActions from '../../redux/actions/toggleActions'

import 'font-awesome/css/font-awesome.min.css'
import SideNav, {
	NavItem,
	NavIcon,
	NavText,
	NavLink,
} from '@trendmicro/react-sidenav'

// import List from '@material-ui/core/List'
// import ListItem from '@material-ui/core/ListItem'
// import ListItemText from '@material-ui/core/ListItemText'

import alertifyjs from 'alertifyjs'
//import '../../../node_modules/alertifyjs/build/css/themes/default.css';

import { navigate } from '@reach/router'
import { Redirect } from 'react-router'

class LeftNav extends Component {
	constructor(props) {
		super(props)

		this.state = { width: window.innerWidth, height: window.innerHeight }
	}

	componentDidMount() {
		this.props.actions.getNavigations()
		if (this.state.width > 500) {
			var jqueryToThis = this
			if (jqueryToThis.props.toggleStatus === false) {
				$("button[class='sidenav---sidenav-toggle---1KRjR']").click()
			}
		}
	}

	selectNavigation = (navigation) => {
		this.props.actions.changeNavigation(navigation)
		//alertifyjs.notify("You are on " + navigation.naviName);

		var jqueryToThis = this
		if (jqueryToThis.props.toggleStatus === true) {
			$("button[class='sidenav---sidenav-toggle---1KRjR']").click()
		}
	}

	handeleDeleteToken = (event) => {
		this.props.actions.deleteToken()
		this.props.actions.getToggleStatuss(false)
		this.props.actions.changeNavigation('home')
		this.props.history.push('home')
	}

	onPush(to2) {
		navigate(to2)
	}

	toggled = (event) => {
		this.props.actions.getToggleStatuss(event)
	}

	//*************************** */ mobile sidenav

	mobileNav() {
		return (
			<SideNav
				onToggle={this.toggled}
				className="main-sidebar"
				onSelect={(selected) => {
					const to = '/' + selected
					if (window.location.pathname !== to) {
						//this.onPush(to);
						this.props.history.push(to)
					}
				}}
			>
				<SideNav.Toggle />

				<SideNav.Nav defaultSelected="home">
					<NavItem
						eventKey="home"
						onClick={() => this.selectNavigation({ naviName: 'home' })}
					>
						<NavIcon>
							{' '}
							<i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
						</NavIcon>
						<NavText> Home</NavText>
					</NavItem>

					<hr></hr>

					{this.props.navigations.map((navi) => {
						if (this.props.userAuthData.permissions) {
							var permissionsArray = JSON.parse(this.props.userAuthData.permissions)

							if (permissionsArray.includes(navi.FullList) === true) {
								return (
									<NavItem
										//to={navi.naviName}
										eventKey={navi.naviName}
										//active={navi.naviName === this.props.location.state.naviName ? true : false}
										onClick={() => this.selectNavigation(navi)}
										key={navi.id}
									>
										{/* <Link to={"/" + navi.naviName}>    </Link> */}

										<NavIcon>
											{' '}
											<i className="fa fa-angle-right" style={{ fontSize: '1.75em' }} />
										</NavIcon>
										<NavText> {navi.naviName}</NavText>
									</NavItem>
								)
							} else {
								if (permissionsArray.includes(navi.List) === true) {
									return (
										<NavItem
											//to={navi.naviName}
											eventKey={navi.naviName}
											//active={navi.naviName === this.props.location.state.naviName ? true : false}
											onClick={() => this.selectNavigation(navi)}
											key={navi.id}
										>
											{/* <Link to={"/" + navi.naviName}>    </Link> */}

											<NavIcon>
												{' '}
												<i className="fa fa-angle-right" style={{ fontSize: '1.75em' }} />
											</NavIcon>
											<NavText> {navi.naviName}</NavText>
										</NavItem>
									)
								} else {
									return null
								}
							}
						} else {
						}
					})}

					<hr></hr>

					<NavItem
						eventKey="home"
						style={{ top: '0px' }}
						onClick={() => this.handeleDeleteToken()}
					>
						<NavIcon>
							{' '}
							<i className="fa fa-fw fa-power-off" style={{ fontSize: '1.75em' }} />
						</NavIcon>
						<NavText> Çıkış Yap</NavText>
					</NavItem>
				</SideNav.Nav>
			</SideNav>
		)
	}

	//*************************** */ normal sidevav
	comNav() {
		return (
			<SideNav
				expanded={true}
				onToggle={this.toggled}
				className="main-sidebar"
				onSelect={(selected) => {
					const to = '/' + selected
					if (window.location.pathname !== to) {
						//this.onPush(to);
						this.props.history.push(to)
					}
				}}
			>
				<SideNav.Toggle />

				<SideNav.Nav defaultSelected="home">
					<NavItem
						eventKey="home"
						onClick={() => this.selectNavigation({ naviName: 'home' })}
					>
						<NavIcon>
							{' '}
							<i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
						</NavIcon>
						<NavText> Home</NavText>
					</NavItem>

					<hr></hr>

					{this.props.navigations.map((navi) => {
						if (this.props.userAuthData.permissions) {
							var permissionsArray = JSON.parse(this.props.userAuthData.permissions)

							if (permissionsArray.includes(navi.FullList) === true) {
								return (
									<NavItem
										//to={navi.naviName}
										eventKey={navi.naviName}
										//active={navi.naviName === this.props.location.state.naviName ? true : false}
										onClick={() => this.selectNavigation(navi)}
										key={navi.id}
									>
										{/* <Link to={"/" + navi.naviName}>    </Link> */}

										<NavIcon>
											{' '}
											<i className="fa fa-angle-right" style={{ fontSize: '1.75em' }} />
										</NavIcon>
										<NavText> {navi.naviName}</NavText>
									</NavItem>
								)
							} else {
								if (permissionsArray.includes(navi.List) === true) {
									return (
										<NavItem
											//to={navi.naviName}
											eventKey={navi.naviName}
											//active={navi.naviName === this.props.location.state.naviName ? true : false}
											onClick={() => this.selectNavigation(navi)}
											key={navi.id}
										>
											{/* <Link to={"/" + navi.naviName}>    </Link> */}

											<NavIcon>
												{' '}
												<i className="fa fa-angle-right" style={{ fontSize: '1.75em' }} />
											</NavIcon>
											<NavText> {navi.naviName}</NavText>
										</NavItem>
									)
								} else {
									return null
								}
							}
						} else {
						}
					})}

					<hr></hr>

					<NavItem
						eventKey="home"
						style={{ top: '0px' }}
						onClick={() => this.handeleDeleteToken()}
					>
						<NavIcon>
							{' '}
							<i className="fa fa-fw fa-power-off" style={{ fontSize: '1.75em' }} />
						</NavIcon>
						<NavText> Çıkış Yap</NavText>
					</NavItem>
				</SideNav.Nav>
			</SideNav>
		)
	}

	render() {
		return (
			<div style={{ width: '100%' }}>
				{this.state.width > 500 ? this.comNav() : this.mobileNav()}
			</div>
		)
	}
}

// connect componenet with redux store
function mapStatetoProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		navigations: state.leftNavReducer,
		userAuthData: state.authReducer,
		toggleStatus: state.toggleReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			getNavigations: bindActionCreators(leftNavActions.getNavigation, dispatch),
			deleteToken: bindActionCreators(authActions.deleteToken, dispatch),
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			getUserSuccess: bindActionCreators(userActions.getUser, dispatch),
			getPermissionSuccess: bindActionCreators(
				permissonActions.getPermissions,
				dispatch
			),
			getToggleStatuss: bindActionCreators(toggleActions.getToggleStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(LeftNav))
