import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function userDetailsExamReducer(
  state = initialState.UserDetailsExamInitialState,
  action
) {
  switch (action.type) {
    case actionTypes.GET_USERDETAILS_ASSIGNED_EXAMS_SUCCESS:
      return {
        ...state,
        successGet: action.payload.success,
        extra: action.payload.extra,
      }

    case actionTypes.ADD_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS:
      return {
        ...state,
        successAdd: action.payload.success,
      }

    case actionTypes.REMOVE_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS:
      return {
        ...state,
        successRemove: action.payload.success,
      }

    case actionTypes.RESET_USERDETAILS_EXAMS:
      return {
        ...state,
        successGet: "",
        successAdd: "",
        successRemove: "",
      }

    case actionTypes.ONFAULT_USERDETAILS_EXAMS:
      return {
        ...state,
        successGet: false,
        successAdd: false,
        successRemove: false,
        messages: action.payload.messages,
      }

    default:
      return state
  }
}
