import React, { Component } from 'react'
import { ScrollView } from '../../innerComponenets/base/scroll'

export default class Contact extends Component {
	render() {
		return (
			<div className="content-w3ls" style={{ minWidth: '80%' }}>
				<div id="formContent">
					<div className="col-lg-12" style={{ paddingBottom: '20px' }}>
						<ScrollView></ScrollView>
					</div>
				</div>
			</div>
		)
	}
}
