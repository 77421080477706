import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function getFullAnswersReducer(
  state = initialState.fullAnswers,
  action
) {
  switch (action.type) {
    case actionTypes.GET_FULL_ANSWER_SUCCESS:
      return action.payload

    default:
      return state
  }
}
