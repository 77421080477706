import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as questionActions from '../../../redux/actions/questionTypeActions'
import { ScrollView } from '../base/scroll'

class LessonForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			TypeName: '',
		}
	}

	componentDidMount() {
		this.props.actions.resetAllStatuss('')

		{
			this.props?.location?.state?.actionType === 'update'
				? this.setState({ TypeName: this.props.location.state.data.typeName })
				: this.setState({ TypeName: '' })
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.questionTypesData.success) {
			var location = {
				pathname: 'Sorutipi',
			}

			this.props.history.push(location)
		} else if (newProps.questionTypesData.success === false) {
			alertifyjs.error('Hata: ' + newProps.questionTypesData.messages)
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.props.actions.resetAllStatuss('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateQuestionType({
						TypeName: this.state.TypeName,
						id: this.props.location.state.data.id,
					  })
					: this.props.actions.createQuestionType({ TypeName: this.state.TypeName })
			}
		}
	}

	validationFunc() {
		if (this.state.TypeName.length < 4) {
			alertifyjs.error('Soru Tipi 4 Haneden Kısa Olamaz!')
			return false
		} else {
			return true
		}
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>

					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Soru Tipi</Label>
										<Input
											type="text"
											name="TypeName"
											id="TypeName"
											placeholder="Soru Tipi Belirleyiniz!"
											value={this.state.TypeName}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
								>
									Soru Tipi Güncelle
								</Button>
							) : (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
								>
									Soru Tipi Oluştur
								</Button>
							)}
						</Form>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		questionTypesData: state.questionTypeReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			createQuestionType: bindActionCreators(
				questionActions.createQuestionType,
				dispatch
			),
			resetAllStatuss: bindActionCreators(questionActions.resetAllStatuss, dispatch),
			updateQuestionType: bindActionCreators(
				questionActions.updateQuestionType,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonForm))
