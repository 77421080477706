import {
	CREATE_EXAM_SUCCESS,
	GET_FULL_EXAM_SUCCESS,
	GET_EXAM_SUCCESS,
	CHANGE_EXAM_STATUSS_SCHOOL,
	DELETE_EXAM_SUCCESS,
	UPDATE_EXAM_SUCCESS,
	DELETE_EXAM_SUCCESS_STATUSS,
	RESET_STATUSS_EXAM,
	ONFAULT_EXAM,
	CREATE_EXAM_WITH_QUESTIONS_SUCCESS,
	GET_EXAM_BY_CLASSROOM_SUCCESS,
	GET_NOTASSIGN_EXAM_BY_CLASSROOM_STATUSS,
	ADD_EXAM_BY_CLASSROOM_SUCCESS,
	REMOVE_EXAM_BY_CLASSROOM_SUCCESS,
	GET_EXAM_NOTSOLVED_SUCCESS,
	GET_EXAM_SOLVED_SUCCESS,
	RESET_GET_FULL_EXAM_SUCCESS,
	CHANGE_EXAM_SHOW_MATERIAL_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createExamSuccess = (examData) => {
	return {
		type: CREATE_EXAM_SUCCESS,
		payload: examData,
	}
}

export const createExamWithQuestionsSuccess = (examData) => {
	return {
		type: CREATE_EXAM_WITH_QUESTIONS_SUCCESS,
		payload: examData,
	}
}

export const resetGetFullExamSuccess = (examData) => {
	return {
		type: RESET_GET_FULL_EXAM_SUCCESS,
		payload: examData,
	}
}

export const getExamSuccess = (examData) => {
	return {
		type: GET_EXAM_SUCCESS,
		payload: examData,
	}
}

export const getFullExamSuccess = (examData) => {
	return {
		type: GET_FULL_EXAM_SUCCESS,
		payload: examData,
	}
}

export const setExamRegStatuss = (examData) => {
	return {
		type: CHANGE_EXAM_STATUSS_SCHOOL,
		payload: examData,
	}
}

export const deleteExamSuccess = (examData) => {
	return {
		type: DELETE_EXAM_SUCCESS,
		payload: examData,
	}
}

export const updateExamSuccess = (examData) => {
	return {
		type: UPDATE_EXAM_SUCCESS,
		payload: examData,
	}
}

export const changeDeletedExamSuccessStatus = (data) => {
	return {
		type: DELETE_EXAM_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_EXAM,
		payload: data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_EXAM,
		payload: data,
	}
}

export const getExamByClassroomSuccess = (data) => {
	return {
		type: GET_EXAM_BY_CLASSROOM_SUCCESS,
		payload: data,
	}
}

export const getExamByNotAssignSuccess = (data) => {
	return {
		type: GET_NOTASSIGN_EXAM_BY_CLASSROOM_STATUSS,
		payload: data,
	}
}

export const addExamToClassroomSuccess = (data) => {
	return {
		type: ADD_EXAM_BY_CLASSROOM_SUCCESS,
		payload: data,
	}
}

export const removeExamToClassroomSuccess = (data) => {
	return {
		type: REMOVE_EXAM_BY_CLASSROOM_SUCCESS,
		payload: data,
	}
}

export const changeExamShowMaterialSuccess = (data) => {
	return {
		type: CHANGE_EXAM_SHOW_MATERIAL_SUCCESS,
		payload: data,
	}
}

export const getExamsNotSolvedSuccess = (data) => {
	return {
		type: GET_EXAM_NOTSOLVED_SUCCESS,
		payload: data,
	}
}

export const getExamsSolvedSuccess = (data) => {
	return {
		type: GET_EXAM_SOLVED_SUCCESS,
		payload: data,
	}
}

export const createExam = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Exams/Add', data)
		.then((res) => {
			const AddedLesson = res.data
			dispatch(createExamSuccess(AddedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const createExamWithQuestion = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Exams/AddWithQuestions', data)
		.then((res) => {
			const data = res.data
			dispatch(createExamWithQuestionsSuccess(data))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getExam = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Exams/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const schooldata = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getExamSuccess(schooldata))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullExam = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Exams/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullExamssData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullExamSuccess(fullExamssData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteExam = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedLesson = res.data

			dispatch(deleteExamSuccess(deletedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteExam = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(deleteExamSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateExam = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/Update'
	// if (data.id) {
	//     url = url + "?id=" + data.id;
	// }
	axios
		.put(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(updateExamSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

//************************************************************************* */

export const getExamByClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetByClassroom'
	if (data.id) {
		url = url + '?ClassroomId=' + data.id //ClassroomId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getExamByClassroomSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getExamByNotAssign = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetNotAssigned'
	if (data.id) {
		url = url + '?ClassroomId=' + data.id //ClassroomId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getExamByNotAssignSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const removeExamToClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/RemoveFromClass'
	if (data.ClassroomId) {
		url = url + '?ClassroomId=' + data.ClassroomId + '&ExamId=' + data.ExamId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(removeExamToClassroomSuccess(getted))
		})
		.catch((err) => {
			try {
				var ffData2 = {
					success: false,
					messages: err.response.data.errorMessage,
				}
				dispatch(onFault(ffData2))
			} catch (err) {
				var ffDat3 = {
					success: false,
					messages: [{ hata: err }],
				}
				dispatch(onFault(ffDat3))
			}
		})
}

export const changeShowMaterial = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/ChangeShowMaterial'
	if (data.ClassroomId) {
		url =
			url +
			'?ClassroomId=' +
			data.ClassroomId +
			'&ExamId=' +
			data.ExamId +
			'&ShowMaterial=' +
			data.ShowMaterial
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(changeExamShowMaterialSuccess(getted))
		})
		.catch((err) => {
			try {
				var ffData2 = {
					success: false,
					messages: err.response.data.errorMessage,
				}
				dispatch(onFault(ffData2))
			} catch (err) {
				var ffDat3 = {
					success: false,
					messages: [{ hata: err }],
				}
				dispatch(onFault(ffDat3))
			}
		})
}

export const addExamToClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/AssignToClass'
	if (data.ClassroomId) {
		url =
			url +
			'?ClassroomId=' +
			data.ClassroomId +
			'&ExamId=' +
			data.ExamId +
			'&showMaterial=' +
			data.showMaterial
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(addExamToClassroomSuccess(getted))
		})
		.catch((err) => {
			try {
				var ffData4 = {
					success: false,
					messages: err.response.data.errorMessage,
				}
				dispatch(onFault(ffData4))
			} catch (err) {
				var ffData5 = {
					success: false,
					messages: [{ hata: err }],
				}
				dispatch(onFault(ffData5))
			}
		})
}

export const getExamsNotSolved = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetUserExam'
	// 00000000-0000-0000-0000-000000000000 - for all exams

	if (data.UserId) {
		url =
			url + '?UserId=' + data.UserId + '&ExamId=' + data.ExamId + '&Completed=false'
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getExamsNotSolvedSuccess(getted))
		})
		.catch((err) => {
			try {
				var ffData6 = {
					success: false,
					messages: err.response.data.errorMessage,
				}
				dispatch(onFault(ffData6))
			} catch (err) {
				var ffData7 = {
					success: false,
					messages: [{ hata: err }],
				}
				dispatch(onFault(ffData7))
			}
		})
}

export const getExamsSolved = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetUserExam'
	// 00000000-0000-0000-0000-000000000000 - for all exams

	if (data.UserId) {
		url = url + '?UserId=' + data.UserId + '&ExamId=' + data.ExamId + '&Completed=true'
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getExamsSolvedSuccess(getted))
		})
		.catch((err) => {
			try {
				var ffData8 = {
					success: false,
					messages: err.response.data.errorMessage,
				}
				dispatch(onFault(ffData8))
			} catch (err) {
				var ffData9 = {
					success: false,
					messages: [{ hata: err }],
				}
				dispatch(onFault(ffData9))
			}
		})
}
