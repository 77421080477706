import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function getSchoolReducer(
  state = initialState.DashboardInitialState,
  action
) {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        successGetted: action.payload.success,
        extraGetted: action.payload.extra,
      }

    case actionTypes.GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS:
      return {
        ...state,
        successManagerData: action.payload.success,
        extraManagerData: action.payload.extra,
      }

    //**************************************************************** */

    case actionTypes.RESET_GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        successGetted: "",
      }

    case actionTypes.RESET_GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS:
      return {
        ...state,
        successManagerData: "",
      }

    //**************************************************************** */
    case actionTypes.GET_DASHBOARD_FAULT:
      return {
        ...state,
        successGetted: false,
        messages: action.payload.messages,
      }

    case actionTypes.GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_FAULT:
      return {
        ...state,
        successManagerData: false,
        messages: action.payload.messages,
      }

    //**************************************************************** */

    default:
      return state
  }
}
