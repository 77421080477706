import {
	CREATE_USSER_SUCCESS,
	GET_FULL_USSER_SUCCESS,
	GET_USSER_SUCCESS,
	SET_USSER_REG_STATUSS,
	DELETE_USSER_SUCCESS,
	UPDATE_USSER_SUCCESS,
	DELETE_USSER_SUCCESS_STATUSS,
	RESET_STATUSS_USSER,
	ONFAULT_USSER,
	GET_USSER_BY_ID_SUCCESS,
	UPDATE_USSER_AVATAR_SUCCESS,
	RESET_GET_FULL_USSER_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createUsserSuccess = (lessonData) => {
	return {
		type: CREATE_USSER_SUCCESS,
		payload: lessonData,
	}
}

export const getUsserSuccess = (lessonData) => {
	return {
		type: GET_USSER_SUCCESS,
		payload: lessonData,
	}
}

export const updateUsserAvatarSuccess = (lessonData) => {
	return {
		type: UPDATE_USSER_AVATAR_SUCCESS,
		payload: lessonData,
	}
}

export const getFullUssersSuccess = (lessonData) => {
	return {
		type: GET_FULL_USSER_SUCCESS,
		payload: lessonData,
	}
}

export const setUsserRegStatuss = (lessonData) => {
	return {
		type: SET_USSER_REG_STATUSS,
		payload: lessonData,
	}
}

export const deleteUsserSuccess = (lessonData) => {
	return {
		type: DELETE_USSER_SUCCESS,
		payload: lessonData,
	}
}

export const updateUsserSuccess = (lessonData) => {
	return {
		type: UPDATE_USSER_SUCCESS,
		payload: lessonData,
	}
}

export const changeDeletedUsserSuccessStatus = (data) => {
	return {
		type: DELETE_USSER_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_USSER,
		payload: data,
	}
}

export const resetGetFullUserSuccess = (data) => {
	return {
		type: RESET_GET_FULL_USSER_SUCCESS,
		payload: data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_USSER,
		payload: data,
	}
}

export const getUsserByIdSuccess = (data) => {
	return {
		type: GET_USSER_BY_ID_SUCCESS,
		payload: data,
	}
}

export const createUsser = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Users/Add', data)
		.then((res) => {
			const AddedUsser = res.data
			dispatch(createUsserSuccess(AddedUsser))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getUssers = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Users/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const UsserData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getUsserSuccess(UsserData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullUssers = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Users/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullUsserssData = res.data

			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullUssersSuccess(fullUsserssData))
		})
		.catch((err) => {
			try {
				var ffData = {
					success: false,
					messages: err.response.data.errorMessage,
				}
			} catch (err) {
				var ffData = {
					success: false,
					messages: 'bilinemeyen hata!',
				}
			}

			dispatch(onFault(ffData))
		})
}

export const deleteUsser = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedUsser = res.data

			dispatch(deleteUsserSuccess(deletedUsser))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteUsser = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(deleteUsserSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateUsser = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/Update'
	// if (data.id) {
	//     url = url + "?id=" + data.id;
	// }
	axios
		.put(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(updateUsserSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getUsserById = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/GetUserById'
	if (data.id) {
		url = url + '?id=' + data.id //userid
	}

	axios
		.get(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(getUsserByIdSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateUsserAvatar = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/UpdateAvatar'

	if (data.url) {
		url = url + '?Url=' + data.url //userid
	}

	axios
		.post(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(updateUsserAvatarSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
