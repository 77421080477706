export const CHANGE_NAVIGATION = 'CHANGE_NAVIGATION'
export const GET_NAVIGATION = 'GET_NAVIGATION'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_CURRENT_USER_ONFAULT = 'SET_CURRENT_USER_ONFAULT'
export const RESET_AUTH_SUCCESS_STATUSS = 'RESET_AUTH_SUCCESS_STATUSS'

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS'

export const SET_LOCAL_TOKEN_IS_EXIST = 'SET_LOCAL_TOKEN_IS_EXIST'
export const EXIT_ACTION = 'EXIT_ACTION'

export const TOGGLE_STATUSS = 'TOGGLE_STATUSS'

export const CHANGE_SETTINGS_SUCCESS = 'CHANGE_SETTINGS_SUCCESS'

//************************************************************************* */ ROLES
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const RESET_CREATE_ROLE_SUCCESS = 'RESET_CREATE_ROLE_SUCCESS' /** */

export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'
export const RESET_GET_ROLE_SUCCESS = 'RESET_GET_ROLE_SUCCESS' /** */

export const GET_FULL_ROLE_SUCCESS = 'GET_FULL_ROLE_SUCCESS'
export const RESET_GET_FULL_ROLE_SUCCESS = 'RESET_GET_FULL_ROLE_SUCCESS' /** */

export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const RESET_UPDATE_ROLE_SUCCESS = 'RESET_UPDATE_ROLE_SUCCESS' /** */

export const GET_USER_ROLE = 'GET_USER_ROLE'
export const RESET_GET_USER_ROLE = 'RESET_GET_USER_ROLE' /** */

export const ASSIGNED_ROLE_TO_USER = 'ASSIGNED_ROLE_TO_USER'
export const RESET_ASSIGNED_ROLE_TO_USER = 'RESET_ASSIGNED_ROLE_TO_USER' /** */

export const GET_ROLES_PERMISSIONS = 'GET_ROLES_PERMISSIONS'
export const RESET_GET_ROLES_PERMISSIONS = 'RESET_GET_ROLES_PERMISSIONS'

export const SET_ROLES_PERMISSIONS = 'SET_ROLES_PERMISSIONS'
export const RESET_SET_ROLES_PERMISSIONS = 'RESET_SET_ROLES_PERMISSIONS'

export const CHANGE_SUCCESS_STATUSS_ROLE = 'CHANGE_SUCCESS_STATUSS_ROLE'
export const RESET_CHANGE_SUCCESS_STATUSS_ROLE =
	'RESET_CHANGE_SUCCESS_STATUSS_ROLE' /** */

export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const RESET_DELETE_ROLE_SUCCESS = 'RESET_DELETE_ROLE_SUCCESS' /** */

export const DELETE_ROLE_SUCCESS_STATUSS = 'DELETE_ROLE_SUCCESS_STATUSS'
export const RESET_DELETE_ROLE_SUCCESS_STATUSS =
	'RESET_DELETE_ROLE_SUCCESS_STATUSS' /** */

export const FULL_RESET_STATUSS_ROLE = 'FULL_RESET_STATUSS_ROLE'

export const ONFAULT_ROLE = 'ONFAULT_ROLE'

//************************************************************************* */
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS'
export const GET_LESSON_SUCCESS = 'GET_LESSON_SUCCESS'
export const GET_FULL_LESSON_SUCCESS = 'GET_FULL_LESSON_SUCCESS'
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS'
export const CHANGE_SUCCESS_STATUSS_LESSON = 'CHANGE_SUCCESS_STATUSS_LESSON'
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS'
export const DELETE_LESSON_SUCCESS_STATUSS = 'DELETE_LESSON_SUCCESS_STATUSS'

export const RESET_CREATE_LESSON_SUCCESS = 'RESET_CREATE_LESSON_SUCCESS'
export const RESET_GET_LESSON_SUCCESS = 'RESET_GET_LESSON_SUCCESS'
export const RESET_GET_FULL_LESSON_SUCCESS = 'RESET_GET_FULL_LESSON_SUCCESS'
export const RESET_UPDATE_LESSON_SUCCESS = 'RESET_UPDATE_LESSON_SUCCESS'
export const RESET_CHANGE_SUCCESS_STATUSS_LESSON =
	'RESET_CHANGE_SUCCESS_STATUSS_LESSON'
export const RESET_DELETE_LESSON_SUCCESS = 'RESET_DELETE_LESSON_SUCCESS'
export const RESET_DELETE_LESSON_SUCCESS_STATUSS =
	'RESET_DELETE_LESSON_SUCCESS_STATUSS'

export const RESET_STATUSS_LESSON = 'RESET_STATUSS_LESSON'
export const ONFAULT_LESSON = 'ONFAULT_LESSON'

//************************************************************************* */
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS'
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS'
export const SET_SCHOOL_REG_STATUSS = 'SET_SCHOOL_REG_STATUSS'
export const GET_FULL_SCHOOL_SUCCESS = 'GET_FULL_SCHOOL_SUCCESS'
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS'

export const CHANGE_SUCCESS_STATUSS_SCHOOL = 'CHANGE_SUCCESS_STATUSS_SCHOOL'
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS'
export const DELETE_SCHOOL_SUCCESS_STATUSS = 'DELETE_SCHOOL_SUCCESS_STATUSS'

export const RESET_GET_FULL_SCHOOL_SUCCESS = 'RESET_GET_FULL_SCHOOL_SUCCESS'
export const RESET_STATUSS_SCHOOL = 'RESET_STATUSS_SCHOOL'
export const ONFAULT_SCHOOL = 'ONFAULT_SCHOOL'

//************************************************************************* */
export const GET_USSER_BY_ID_SUCCESS = 'GET_USSER_BY_ID_SUCCESS'
export const UPDATE_USSER_AVATAR_SUCCESS = 'UPDATE_USSER_AVATAR_SUCCESS'
export const CREATE_USSER_SUCCESS = 'CREATE_USSER_SUCCESS'
export const GET_USSER_SUCCESS = 'GET_USSER_SUCCESS'
export const SET_USSER_REG_STATUSS = 'SET_USSER_REG_STATUSS'
export const GET_FULL_USSER_SUCCESS = 'GET_FULL_USSER_SUCCESS'
export const UPDATE_USSER_SUCCESS = 'UPDATE_USSER_SUCCESS'

export const CHANGE_USSER_STATUSS_SCHOOL = 'CHANGE_USSER_STATUSS_SCHOOL'
export const DELETE_USSER_SUCCESS = 'DELETE_USSER_SUCCESS'
export const DELETE_USSER_SUCCESS_STATUSS = 'DELETE_USSER_SUCCESS_STATUSS'

export const RESET_GET_FULL_USSER_SUCCESS = 'RESET_GET_FULL_USSER_SUCCESS'
export const RESET_STATUSS_USSER = 'RESET_STATUSS_USSER'
export const ONFAULT_USSER = 'ONFAULT_USSER'
//************************************************************************* */
export const CREATE_CLASSROOM_SUCCESS = 'CREATE_CLASSROOM_SUCCESS'
export const GET_CLASSROOM_SUCCESS = 'GET_CLASSROOM_SUCCESS'
export const FIND_CLASSROOM_SUCCESS = 'FIND_CLASSROOM_SUCCESS'
export const GET_FULL_CLASSROOM_SUCCESS = 'GET_FULL_CLASSROOM_SUCCESS'
export const UPDATE_CLASSROOM_SUCCESS = 'UPDATE_CLASSROOM_SUCCESS'
export const CHANGE_SUCCESS_STATUSS_CLASSROOM = 'CHANGE_SUCCESS_STATUSS_CLASSROOM'

export const CHANGE_CLASSROOM_STATUSS_SCHOOL = 'CHANGE_CLASSROOM_STATUSS_SCHOOL'
export const DELETE_CLASSROOM_SUCCESS = 'DELETE_CLASSROOM_SUCCESS'
export const DELETE_CLASSROOM_SUCCESS_STATUSS = 'DELETE_CLASSROOM_SUCCESS_STATUSS'

export const RESET_FULL_STATUSS_CLASSROOM = 'RESET_FULL_STATUSS_CLASSROOM'
export const RESET_STATUSS_CLASSROOM = 'RESET_STATUSS_CLASSROOM'
export const ONFAULT_CLASSROOM = 'ONFAULT_CLASSROOM'
//************************************************************************* */

export const CREATE_QUESTIONTYPE_SUCCESS = 'CREATE_QUESTIONTYPE_SUCCESS'
export const GET_QUESTIONTYPE_SUCCESS = 'GET_QUESTIONTYPE_SUCCESS'
export const GET_FULL_QUESTIONTYPE_SUCCESS = 'GET_FULL_QUESTIONTYPE_SUCCESS'
export const UPDATE_QUESTIONTYPE_SUCCESS = 'UPDATE_QUESTIONTYPE_SUCCESS'

export const CHANGE_QUESTIONTYPE_STATUSS_SCHOOL =
	'CHANGE_QUESTIONTYPE_STATUSS_SCHOOL'
export const DELETE_QUESTIONTYPE_SUCCESS = 'DELETE_QUESTIONTYPE_SUCCESS'
export const DELETE_QUESTIONTYPE_SUCCESS_STATUSS =
	'DELETE_QUESTIONTYPE_SUCCESS_STATUSS'

export const RESET_GET_FULL_QUESTIONTYPE_SUCCESS =
	'RESET_GET_FULL_QUESTIONTYPE_SUCCESS'
export const RESET_STATUSS_QUESTIONTYPE = 'RESET_STATUSS_QUESTIONTYPE'
export const ONFAULT_QUESTIONTYPE = 'ONFAULT_QUESTIONTYPE'

//************************************************************************* */

export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS'
export const GET_ANSWER_SUCCESS = 'GET_ANSWER_SUCCESS'
export const GET_FULL_ANSWER_SUCCESS = 'GET_FULL_ANSWER_SUCCESS'
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS'

export const CHANGE_ANSWER_STATUSS_SCHOOL = 'CHANGE_ANSWER_STATUSS_SCHOOL'
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS'
export const DELETE_ANSWER_SUCCESS_STATUSS = 'DELETE_ANSWER_SUCCESS_STATUSS'

export const RESET_STATUSS_ANSWER = 'RESET_STATUSS_ANSWER'
export const ONFAULT_ANSWER = 'ONFAULT_ANSWER'

//************************************************************************* */
export const CREATE_TOPIC_SUCCESS = 'CREATE_TOPIC_SUCCESS'
export const GET_TOPIC_SUCCESS = 'GET_TOPIC_SUCCESS'
export const FIND_TOPIC_SUCCESS = 'FIND_TOPIC_SUCCESS'
export const GET_FULL_TOPIC_SUCCESS = 'GET_FULL_TOPIC_SUCCESS'
export const UPDATE_TOPIC_SUCCESS = 'UPDATE_TOPIC_SUCCESS'

export const CHANGE_TOPIC_STATUSS_SCHOOL = 'CHANGE_TOPIC_STATUSS_SCHOOL'
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS'
export const DELETE_TOPIC_SUCCESS_STATUSS = 'DELETE_TOPIC_SUCCESS_STATUSS'

export const RESET_GET_FULL_TOPIC_SUCCESS = 'RESET_GET_FULL_TOPIC_SUCCESS'
export const RESET_STATUSS_TOPIC = 'RESET_STATUSS_TOPIC'
export const ONFAULT_TOPIC = 'ONFAULT_TOPIC'
//************************************************************************* */

//************************************************************************* */
export const CREATE_TOPIC_MATERIAL_SUCCESS = 'CREATE_TOPIC_MATERIAL_SUCCESS'
export const GET_TOPIC_MATERIAL_SUCCESS = 'GET_TOPIC_MATERIAL_SUCCESS'
export const DELETE_TOPIC_MATERIAL_SUCCESS = 'DELETE_TOPIC_MATERIAL_SUCCESS'
export const UPDATE_TOPIC_MATERIAL_SUCCESS = 'UPDATE_TOPIC_MATERIAL_SUCCESS'
export const DELETE_TOPIC_MATERIAL_SUCCESS_STATUSS =
	'DELETE_TOPIC_MATERIAL_SUCCESS_STATUSS'
export const RESET_STATUSS_TOPIC_MATERIAL = 'RESET_STATUSS_TOPIC_MATERIAL'
export const ONFAULT_TOPIC_MATERIAL = 'ONFAULT_TOPIC_MATERIAL'
export const CREATE_EXAM_SUCCESS = 'CREATE_EXAM_SUCCESS'
export const CREATE_EXAM_WITH_QUESTIONS_SUCCESS =
	'CREATE_EXAM_WITH_QUESTIONS_SUCCESS'
export const GET_EXAM_SUCCESS = 'GET_EXAM_SUCCESS'
export const GET_FULL_EXAM_SUCCESS = 'GET_FULL_EXAM_SUCCESS'
export const UPDATE_EXAM_SUCCESS = 'UPDATE_EXAM_SUCCESS'

export const CHANGE_EXAM_STATUSS_SCHOOL = 'CHANGE_EXAM_STATUSS_SCHOOL'
export const DELETE_EXAM_SUCCESS = 'DELETE_EXAM_SUCCESS'
export const DELETE_EXAM_SUCCESS_STATUSS = 'DELETE_EXAM_SUCCESS_STATUSS'

export const RESET_GET_FULL_EXAM_SUCCESS = 'RESET_GET_FULL_EXAM_SUCCESS'
export const RESET_STATUSS_EXAM = 'RESET_STATUSS_EXAM'
export const ONFAULT_EXAM = 'ONFAULT_EXAM'

export const GET_EXAM_BY_CLASSROOM_SUCCESS = 'GET_EXAM_BY_CLASSROOM_SUCCESS'
export const GET_NOTASSIGN_EXAM_BY_CLASSROOM_STATUSS =
	'GET_NOTASSIGN_EXAM_BY_CLASSROOM_STATUSS'

export const ADD_EXAM_BY_CLASSROOM_SUCCESS = 'ADD_EXAM_BY_CLASSROOM_SUCCESS'
export const REMOVE_EXAM_BY_CLASSROOM_SUCCESS = 'REMOVE_EXAM_BY_CLASSROOM_SUCCESS'

export const CHANGE_EXAM_SHOW_MATERIAL_SUCCESS = 'CHANGE_EXAM_SHOW_MATERIAL_SUCCESS'

export const GET_EXAM_NOTSOLVED_SUCCESS = 'GET_EXAM_NOTSOLVED_SUCCESS'
export const GET_EXAM_SOLVED_SUCCESS = 'GET_EXAM_SOLVED_SUCCESS'

//************************************************************************* */

export const GET_EXAM_BY_ID_SUCCESS = 'GET_EXAM_BY_ID_SUCCESS' // in exam act
export const RESET_EXAM_BY_ID_SUCCESS = 'RESET_EXAM_BY_ID_SUCCESS' // in exam act
export const ONFAULT_EXAM_BY_ID_SUCCESS = 'ONFAULT_EXAM_BY_ID_SUCCESS' // in exam act
export const UPDATE_EXAM_BY_ID_SUCCESS = 'UPDATE_EXAM_BY_ID_SUCCESS' // in exam act

//************************************************************************* */

export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS'
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS'
export const GET_FULL_QUESTION_SUCCESS = 'GET_FULL_QUESTION_SUCCESS'
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'

export const CHANGE_QUESTION_STATUSS_SCHOOL = 'CHANGE_QUESTION_STATUSS_SCHOOL'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_SUCCESS_STATUSS = 'DELETE_QUESTION_SUCCESS_STATUSS'

export const RESET_GET_FULL_QUESTION_SUCCESS = 'RESET_GET_FULL_QUESTION_SUCCESS'
export const RESET_STATUSS_QUESTION = 'RESET_STATUSS_QUESTION'
export const ONFAULT_QUESTION = 'ONFAULT_QUESTION'

//************************************************************************* */

export const GET_QUESTION_WTH_ANSWERS_SUCCESS = 'GET_QUESTION_WTH_ANSWERS_SUCCESS'
export const GET_QUESTION_WTH_ANSWERS_RESET_STATUSS =
	'GET_QUESTION_WTH_ANSWERS_RESET_STATUSS'
export const ONFAULT_EXAM_QUESTION_WTH_ANSWERS = 'ONFAULT_EXAM_QUESTION_WTH_ANSWERS'

//************************************************************************* */
export const POST_UPLOAD_SUCCESS = 'POST_UPLOAD_SUCCESS'
export const POST_UPLOAD_RESET_ALL_STATUSS = 'POST_UPLOAD_RESET_ALL_STATUSS'
export const ONFAULT_POST_UPLOAD = 'ONFAULT_POST_UPLOAD'

//************************************************************************* */
export const GET_USERS_NOT_ASSIGNEDS_SUCCESS = 'GET_USERS_NOT_ASSIGNEDS_SUCCESS'
export const USERS_NOT_ASSIGNEDS_RESET_ALL_STATUSS =
	'USERS_NOT_ASSIGNEDS_RESET_ALL_STATUSS'
export const ONFAULT_USERS_NOT_ASSIGNEDS = 'ONFAULT_USERS_NOT_ASSIGNEDS'

//************************************************************************* */
export const GET_USERS_ON_CLASSROOM_SUCCESS = 'GET_USERS_ON_CLASSROOM_SUCCESS'
export const ON_CLASSROOM_RESET_ALL_STATUSS = 'ON_CLASSROOM_RESET_ALL_STATUSS'
export const ONFAULT_USERS_ON_CLASSROOM = 'ONFAULT_USERS_ON_CLASSROOM'
export const CLASSROOM_ADD_USER_SUCCESS = 'CLASSROOM_ADD_USER_SUCCESS'
export const CLASSROOM_REMOVE_USER_SUCCESS = 'CLASSROOM_REMOVE_USER_SUCCESS'

//************************************************************************* */
export const GET_ALL_MANAGERS_ON_SCHOOL_SUCCESS =
	'GET_ALL_MANAGERS_ON_SCHOOL_SUCCESS'
export const RESET_ALL_MANAGERS_ON_SCHOOL = 'RESET_ALL_MANAGERS_ON_SCHOOL'
export const ONFAULT_ALL_MANAGERS_ON_SCHOOL = 'ONFAULT_ALL_MANAGERS_ON_SCHOOL'

export const GET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL =
	'GET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL'
export const RESET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL =
	'RESET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL'
export const ONFAULT_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL =
	'ONFAULT_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL'

export const MANAGERS_ON_SCHOOL_ADD_SUCCESS = 'MANAGERS_ON_SCHOOL_ADD_SUCCESS'
export const MANAGERS_ON_SCHOOL_REMOVE_SUCCESS = 'MANAGERS_ON_SCHOOL_REMOVE_SUCCESS'

//************************************************************************* */
export const ADD_LESSONS_ASSIGNED_TOCLASS_SUCCESS =
	'ADD_LESSONS_ASSIGNED_TOCLASS_SUCCESS'
export const REMOVE_LESSONS_FROM_TOCLASS_SUCCESS =
	'REMOVE_LESSONS_FROM_TOCLASS_SUCCESS'
export const GET_LESSONS_BY_CLASSROOM_SUCCESS = 'GET_LESSONS_BY_CLASSROOM_SUCCESS'
export const GET_LESSONS_NOTASSIGNED_SUCCESS = 'GET_LESSONS_NOTASSIGNED_SUCCESS'

export const RESET_LESSONS_TOCLASS = 'RESET_LESSONS_TOCLASS'
export const ONFAULT_LESSONS_TOCLASS = 'ONFAULT_LESSONS_TOCLASS'

//************************************************************************* */
export const GET_LESSONS_DETAILS_TOPICS_SUCCESS =
	'GET_LESSONS_DETAILS_TOPICS_SUCCESS'
export const GET_LESSONS_DETAILS_EXAM_SUCCESS = 'GET_LESSONS_DETAILS_EXAM_SUCCESS'
export const RESET_LESSONS_DETAILS = 'RESET_LESSONS_DETAILS'
export const ONFAULT_LESSONS_DETAILS = 'ONFAULT_LESSONS_DETAILS'

//************************************************************************* */
export const GET_TOPIC_DETAILS_SUCCESS = 'GET_TOPIC_DETAILS_SUCCESS'
export const RESET_TOPIC_DETAILS = 'RESET_TOPIC_DETAILS'
export const ONFAULT_TOPIC_DETAILS = 'ONFAULT_TOPIC_DETAILS'
export const RESET_STATUSS_TOPIC_DETAILS = 'RESET_STATUSS_TOPIC_DETAILS'

//************************************************************************* */
export const GET_USERDETAILS_ASSIGNED_EXAMS_SUCCESS =
	'GET_USERDETAILS_ASSIGNED_EXAMS_SUCCESS'
export const ADD_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS =
	'ADD_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS'
export const REMOVE_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS =
	'REMOVE_USERDETAILS_ASSIGNED_EXAMS_TO_NOTSOLVED_SUCCESS'
export const RESET_USERDETAILS_EXAMS = 'RESET_USERDETAILS_EXAMS'
export const ONFAULT_USERDETAILS_EXAMS = 'ONFAULT_USERDETAILS_EXAMS'

//************************************************************************* */ EXAM DETAILS
export const GET_EXAMS_WİTH_QUESTİONS_SUCCESS = 'GET_EXAMS_WİTH_QUESTİONS_SUCCESS'
export const GET_USERS_GET_ASSIGNED_USERS_SUCCESS =
	'GET_USERS_GET_ASSIGNED_USERS_SUCCESS'
export const GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS_SUCCESS =
	'GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS_SUCCESS'
export const RESET_GET_EXAMS_WİTH_QUESTİONS_SUCCESS =
	'RESET_GET_EXAMS_WİTH_QUESTİONS_SUCCESS'
export const RESET_GET_USERS_GET_ASSIGNED_USERS_SUCCESS =
	'RESET_GET_USERS_GET_ASSIGNED_USERS_SUCCESS'
export const RESET_GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS =
	'RESET_GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS'
export const FULL_RESET_ON_ACTS_EXAM_DETAILS = 'FULL_RESET_ON_ACTS_EXAM_DETAILS'
export const FAULT_ON_ACTS_EXAM_DETAILS = 'FAULT_ON_ACTS_EXAM_DETAILS'

//************************************************************************* */ EXAM RESULTS
export const GET_EXAM_RESULT_SUCCESS = 'GET_EXAM_RESULT_SUCCESS'
export const GET_EXAM_RESULT_FAULT = 'GET_EXAM_RESULT_FAULT'
export const GET_EXAM_JOINED_USERS_SUCCESS = 'GET_EXAM_JOINED_USERS_SUCCESS'
export const GET_EXAM_QUESTION_USER_REPORT_SUCCESS =
	'GET_EXAM_QUESTION_USER_REPORT_SUCCESS'
export const REMOVE_STUDENT_TEST_RESULT_SUCCESS =
	'REMOVE_STUDENT_TEST_RESULT_SUCCESS'
export const RESET_STATUSS_EXAM_RESULT = 'RESET_STATUSS_EXAM_RESULT'

//************************************************************************* */
export const GET_ALL_REALEXAM_START_SUCCESS = 'GET_ALL_REALEXAM_START_SUCCESS'
export const POST_ANSWER_REALEXAM_SUCCESS = 'POST_ANSWER_REALEXAM_SUCCESS'
export const FINISH_REALEXAM_SUCCESS = 'FINISH_REALEXAM_SUCCESS'
export const AGAIN_REALEXAM_SUCCESS = 'AGAIN_REALEXAM_SUCCESS'
export const GET_EXAM_DETAILS_SUCCESS = 'GET_EXAM_DETAILS_SUCCESS'

export const RESET_GET_ALL_REALEXAM_START_SUCCESS =
	'RESET_GET_ALL_REALEXAM_START_SUCCESS'
export const RESET_POST_ANSWER_REALEXAM_SUCCESS =
	'RESET_POST_ANSWER_REALEXAM_SUCCESS'
export const RESET_FINISH_REALEXAM_SUCCESS = 'RESET_FINISH_REALEXAM_SUCCESS'
export const RESET_AGAIN_REALEXAM_SUCCESS = 'RESET_AGAIN_REALEXAM_SUCCESS'
export const RESET_GET_EXAM_DETAILS_SUCCESS = 'RESET_GET_EXAM_DETAILS_SUCCESS'

export const FAULT_GET_ALL_REALEXAM_START_SUCCESS =
	'FAULT_GET_ALL_REALEXAM_START_SUCCESS'
export const FAULT_POST_ANSWER_REALEXAM_SUCCESS =
	'FAULT_POST_ANSWER_REALEXAM_SUCCESS'
export const FAULT_FINISH_REALEXAM_SUCCESS = 'FAULT_FINISH_REALEXAM_SUCCESS'
export const FAULT_AGAIN_REALEXAM_SUCCESS = 'FAULT_AGAIN_REALEXAM_SUCCESS'
export const FAULT_GET_EXAM_DETAILS_SUCCESS = 'FAULT_GET_EXAM_DETAILS_SUCCESS'

export const FULL_RESET_REALEXAM = 'FULL_RESET_REALEXAM'

//************************************************************************* */
export const GETED_ALL_MESSAGES_SUCCESS = 'GETED_ALL_MESSAGES_SUCCESS'
export const SENDED_ALL_MESSAGES_SUCCESS = 'SENDED_ALL_MESSAGES_SUCCESS'
export const UPDATE_MESSAGES_SUCCESS = 'UPDATE_MESSAGES_SUCCESS'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'
export const GET_ALL_RECEIVERS_SUCCESS = 'GET_ALL_RECEIVERS_SUCCESS'

export const RESET_GETED_ALL_MESSAGES_SUCCESS = 'RESET_GETED_ALL_MESSAGES_SUCCESS'
export const RESET_SENDED_ALL_MESSAGES_SUCCESS = 'RESET_SENDED_ALL_MESSAGES_SUCCESS'
export const RESET_UPDATE_MESSAGES_SUCCESS = 'RESET_UPDATE_MESSAGES_SUCCESS'
export const RESET_CREATE_MESSAGE_SUCCESS = 'RESET_CREATE_MESSAGE_SUCCESS'
export const RESET_GET_ALL_RECEIVERS_SUCCESS = 'RESET_GET_ALL_RECEIVERS_SUCCESS'

export const GETTED_ALL_MESSAGES_FAULT = 'GETTED_ALL_MESSAGES_FAULT'
export const SENDED_ALL_MESSAGES_FAULT = 'SENDED_ALL_MESSAGES_FAULT'
export const UPDATE_MESSAGES_FAULT = 'UPDATE_MESSAGES_FAULT'
export const CREATE_MESSAGE_FAULT = 'CREATE_MESSAGE_FAULT'
export const GET_ALL_RECEIVERS_FAULT = 'GET_ALL_RECEIVERS_FAULT'

export const FULL_RESET_MESSAGES = 'FULL_RESET_MESSAGES'
//************************************************************************* */

export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS =
	'GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS'

export const RESET_GET_DASHBOARD_SUCCESS = 'RESET_GET_DASHBOARD_SUCCESS'
export const RESET_GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS =
	'RESET_GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_SUCCESS'

export const GET_DASHBOARD_FAULT = 'GET_DASHBOARD_FAULT'
export const GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_FAULT =
	'GET_DASHBOARD_TEACHERS_AND_CLASSROOMS_FAULT'
//************************************************************************* */
