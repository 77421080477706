import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../../../redux/actions/authActions'


class LoginBOard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			username: '',
			password: '',
			rembeerMe: 0,
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.isAuthenticated.success === false) {
			alertifyjs.error(newProps.isAuthenticated.messages.toString())
			this.props.actions.resetAuthSuccessStatuss()
		}
	}

	/***************************************** */
	onSubmit = (e) => {
		e.preventDefault()
		// authActions dosyasında yazdığımız kısımda loginUser fonksiyonu
		// kullanıcı bilgisini parametre olarak alıyordu. Bu yüzden kullanıcı bilgisini state'ten alan objeyi yazalım
		const userData = {
			Email: this.state.username,
			password: this.state.password,
			RememberMe: this.state.rembeerMe,
		}

		// Bu satıra gelmeden önce en alt satıra inelim
		// Submit yapıldığında tetiklenmesini istediğimiz Action'u yazalım
		if (this.state.username.length > 0) {
			if (this.state.password.length > 0) {
				this.props.actions.loginUser(userData)
			} else {
				alertifyjs.error('Şifre Kısmı Boş Dırakılamaz!')
			}
		} else {
			alertifyjs.error('Kullanıcı Adı Boş Bırakılamaz!')
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}
	/***************************************** */
	onChangeRemeber = (e) => {
		if (this.state.rembeerMe === 1) {
			this.setState({ rembeerMe: 0 })
		} else {
			this.setState({ rembeerMe: 1 })
		}
	}

	render() {
		return (
			<div className="content-w3ls">
				<div id="formContent">
					<div className="text-center icon">
						<img src={require('../../../medias/logo.png')} width="140" />
					</div>

					<div className="content-bottom">
						<div className="wrapper fadeInDown">
							<form style={{ width: '100%' }}>
								<div className="field-group">
									<span className="fa fa-user" aria-hidden="true"></span>
									<div className="wthree-field">
										<input
											name="username"
											id="username"
											type="text"
											placeholder="Kullanıcı Adı"
											onChange={this.onChange}
											value={this.state.username}
										/>
									</div>
								</div>

								<div className="field-group">
									<span className="fa fa-lock" aria-hidden="true"></span>
									<div className="wthree-field">
										<input
											name="password"
											id="password"
											type="Password"
											placeholder="Parolanız"
											onChange={this.onChange}
											value={this.state.password}
										/>
									</div>
								</div>

								<div className="wthree-field">
									<button onClick={this.onSubmit} className="btn">
										Giriş Yap
									</button>
								</div>

								<ul className="list-login">
									<li className="switch-agileits">
										<label className="switch">
											<input
												type="checkbox"
												name="rembeerMe"
												id="rembeerMe"
												onChange={this.onChangeRemeber}
												value={this.state.rembeerMe}
											/>
											<span className="slider round"></span>
											Beni Hatırla
										</label>
									</li>
									{/* <li>
                                        <a className="text-right">Şifremi Unuttum?</a>
                                    </li> */}
									<li className="clearfix"></li>
								</ul>

								<ul className="list-login-bottom">
									{/* <li className="">
                        <a className="">Create Account</a>
                      </li> */}
									<li className="">
										<a className="text-right">Yardım?</a>
									</li>
									<li className="clearfix"></li>
								</ul>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			resetAuthSuccessStatuss: bindActionCreators(
				authActions.resetAuthSuccessStatuss,
				dispatch
			),
			loginUser: bindActionCreators(authActions.loginUser, dispatch),
		},
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.authReducer,
})

// Component'i dışarı aktarmadan önce Action'dan gelen
// fonksiyonları ve state'leri Component'e bağlamamız gerekiyor
// Şimdi 25. satıra dönebiliriz.

export default connect(mapStateToProps, mapDispatchToProps)(LoginBOard)
