/* eslint-disable */
import TextField from '@material-ui/core/TextField'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import History from '@material-ui/icons/History'
import Icon3 from '@material-ui/icons/ImportExport'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import {
	Accordion,
	Card
} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	Badge, Button,





	Col, Form,
	FormGroup,

	Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as examActions from '../../../redux/actions/examActions'
import * as getExamByIdAction from '../../../redux/actions/getExamByIdAction'
import * as questionActions from '../../../redux/actions/questionActions'
import * as topicActions from '../../../redux/actions/topicActions'
import initialState from '../../../redux/reducers/initialState'
import { QuestionCard } from '../base/question'
import { QuestionPopover } from '../base/question.popover'
import { ScrollView } from '../base/scroll'







class ExamForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: new Date().toISOString().substring(0, 10),

			ExamName: '',
			TopicSelection: '-1',
			UserDescription: '',
			AdminDescription: '',
			isActive: false,
			adminNote: '',
			ExamExplainationType: '',
			ExamExplainationDate: '',
			ExamLastParticipation: '',

			leftActisOk: false,
			modalFormAct: false,

			timeOut: '',
			point: '',
			orderCount: '',
			pointTotal: 0,
			timeOutTotal: 0,

			puanonSysem: '-1',
			editMode: false,

			QuestionListData_onQuery: this.props.questionsData.extra,
			NonselectedQuestionListData_onQueryOnTAble: [],
			AddedQuestionListData_onQuery: [],
			AddedQuestionListData_onQueryOnTable: [],
			ToggState: false,
			onActModal: { questionText: '', questionTypeId: '', fileUrl: '' },

			QuestionsColumns: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.removeQuestionFromExam
						) === true ? (
								<Button color="light" onClick={() => this.CToggle(row)}>
									<AddCircleOutlineIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
				{
					name: 'Soru Text',
					selector: 'TypeName',
					sortable: true,
					width: '50rem',
					cell: (row) => <QuestionPopover question={row} />,
				},
				{
					name: 'Soru Türü',
					selector: 'QuestionType',
					sortable: true,
					cell: (row) => <div>{row.questionType}</div>,
				},
				{
					name: 'Soru Etiketleri',
					selector: 'tags',
					sortable: true,
					cell: (row) => this.clearedTags(row),
				},
			],

			AddedQuestionsColumns: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.addQuestionToExam
						) === true ? (
								<Button color="light" onClick={() => this.removeAddedQuestion(row)}>
									<HighlightOffIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},

				{
					name: 'Soru Sırası',
					selector: 'orderCount',
					sortable: true,
					width: '5rem',
				},
				{
					name: 'Soru Text',
					selector: 'TypeName',
					sortable: true,
					width: '50rem',
					cell: (row) => <QuestionPopover question={row} />,
				},
				{
					name: 'Soru Zamanı',
					selector: 'timeOut',
					sortable: true,
					width: '5rem',
				},

				{
					name: 'Soru Puanı',
					selector: 'point',
					sortable: true,
					width: '5rem',
				},
				{
					name: 'Soru Türü',
					selector: 'questionType',
					sortable: true,
				},
				{
					name: 'Soru Etiketleri',
					selector: 'tags',
					sortable: true,
					cell: (row) => this.clearedTags(row),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.addQuestionToExam
						) === true ? (
								<Button color="btn btn-warning" onClick={() => this.editQuestionModal(row)}>
								Düzenle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
			],

			AddedQuestionsColumns2: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.addQuestionToExam
						) === true ? (
								<Button color="light" onClick={() => this.removeAddedQuestion(row)}>
									<HighlightOffIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},

				{
					name: 'Soru Sırası',
					selector: 'orderCount',
					sortable: true,
					width: '5rem',
				},
				{
					name: 'Soru Text',
					selector: 'TypeName',
					sortable: true,
					width: '50rem',
					cell: (row) => <QuestionPopover question={row} />,
				},
				{
					name: 'Soru Zamanı',
					selector: 'timeOut',
					sortable: true,
					width: '5rem',
				},
				{
					name: 'Soru Türü',
					selector: 'questionType',
					sortable: true,
				},
				{
					name: 'Soru Etiketleri',
					selector: 'tags',
					sortable: true,
					cell: (row) => this.clearedTags(row),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.addQuestionToExam
						) === true ? (
								<Button color="btn btn-danger" onClick={() => this.editQuestionModal(row)}>
								Düzenle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
			],
		}
	}

	editQuestionModal(row) {
		this.CToggle(row, true)
	}

	clearedTags(row) {
		return row.tagText && JSON.parse(row.tagText).join(', ')
	}

	componentDidMount() {
		this.props.actions.getQuestions()

		this.props.actions.getTopics()

		{
			this.props?.location?.state?.actionType === 'update'
				? this.setState({
					ExamName: this.props.location.state.data.examName,
					UserDescription: this.props.location.state.data.UserDescription,
					AdminDescription: this.props.location.state.data.AdminDescription,
					isActive: this.props.location.state.data.isActive,
					TopicSelection: this.props.location.state.data.topicId,
				  })
				: this.setState({
					ExamName: '',
					TopicSelection: '-1',
					UserDescription: '',
					AdminDescription: '',
					isActive: '',
				  })
		}

		{
			this.props?.location?.state?.actionType === 'update'
				? this.props.actions.getExamDetailsById({
					id: this.props.location.state.data.id,
				  })
				: null
		}

		this.props.actions.resetAllStatussExam('')
	}

	calculatedTotal(questions) {
		var totalpp = 0
		var timeOutTotal = 0
		questions &&
			questions.map((questSelected) => {
				totalpp += questSelected.point
				timeOutTotal += questSelected.timeOut
			})

		this.setState({ pointTotal: totalpp, timeOutTotal: timeOutTotal })
	}

	componentWillReceiveProps(newProps) {
		if (newProps.examData.success) {
			let location = {
				pathname: 'Testler',
			}

			this.props.history.push(location)
			this.props.actions.resetAllStatussExam('')
		} else if (newProps.examData.success === false) {
			alertifyjs.error('Hata: ' + newProps.examData.messages)
			this.props.actions.resetAllStatussExam('')
		}

		if (newProps.gettedExamByIdData.Updatedsuccess) {
			let location = {
				pathname: 'Testler',
			}

			this.props.history.push(location)
			newProps.actions.resetAllStatussExamById('')
		} else if (newProps.gettedExamByIdData.Updatedsuccess === false) {
			alertifyjs.error('Hata: ' + newProps.gettedExamByIdData.messages)
			newProps.actions.resetAllStatussExamById('')
		}

		if (newProps.questionsData.extra.length > 0) {
			var selectedsOnlyID = []
			newProps.gettedExamByIdData.extra &&
				newProps.gettedExamByIdData.extra.questions &&
				newProps.gettedExamByIdData.extra.questions.map((questSelected) => {
					selectedsOnlyID.push(questSelected.questionId)
				})

			var nonSelected = newProps.questionsData.extra.filter(
				(value) => -1 === selectedsOnlyID.indexOf(value.id)
			)

			this.setState(
				{
					QuestionListData_onQuery: [],
					NonselectedQuestionListData_onQueryOnTAble: [],
				},
				() => {
					this.setState({
						QuestionListData_onQuery: nonSelected,
						NonselectedQuestionListData_onQueryOnTAble: nonSelected,
					})
				}
			)

			var selectedrealArray = []
			newProps.gettedExamByIdData.extra &&
				newProps.gettedExamByIdData.extra.questions &&
				newProps.gettedExamByIdData.extra.questions.map((questSelected) => {
					selectedrealArray.push({
						id: questSelected.questionId,
						timeOut: questSelected.timeOut,
						point: questSelected.point,
						orderCount: questSelected.orderCount,
						questionText: questSelected.question.questionText,
						answers: questSelected.answers,
						questionType: questSelected.question.questionType,
						fileUrl: questSelected.question.fileUrl,
						tagText: questSelected.question.tagText,
						rightAnswer: questSelected.question.rightAnswer,
					})
				})

			this.setState({
				AddedQuestionListData_onQuery: selectedrealArray,
				AddedQuestionListData_onQueryOnTable: selectedrealArray,
				outlinedbasicSAdded: '',
			})

			this.calculateTotalPoint(newProps.gettedExamByIdData.extra.questions)
		}

		if (newProps.gettedExamByIdData.success) {
			this.setState({
				ExamName: newProps.gettedExamByIdData.extra.exam.examName,
				UserDescription: newProps.gettedExamByIdData.extra.exam.userDescription,
				AdminDescription: newProps.gettedExamByIdData.extra.exam.adminDescription,
				TopicSelection: newProps.gettedExamByIdData.extra.exam.topicId,
				ExamExplainationType: newProps.gettedExamByIdData.extra.exam.resultType,
				puanonSysem: newProps.gettedExamByIdData.extra.exam.scoringSystem,
				isActive: newProps.gettedExamByIdData.extra.exam.isActive,
			})

			newProps.gettedExamByIdData.extra.exam.resultDate !== null
				? this.setState({
					ExamExplainationDate: newProps.gettedExamByIdData.extra.exam.resultDate.substring(
						0,
						10
					),
				  })
				: this.setState({
					ExamExplainationDate: newProps.gettedExamByIdData.extra.exam.resultDate,
				  })

			newProps.gettedExamByIdData.extra.exam.deadLine !== null
				? this.setState({
					ExamLastParticipation: newProps.gettedExamByIdData.extra.exam.deadLine.substring(
						0,
						10
					),
				  })
				: this.setState({
					ExamLastParticipation: newProps.gettedExamByIdData.extra.exam.deadLine,
				  })
			newProps.actions.resetAllStatussExamById('')
		} else if (newProps.gettedExamByIdData.success === false) {
			alertifyjs.error('Hata: ' + newProps.gettedExamByIdData.messages)
			newProps.actions.resetAllStatussExamById('')
		}

		if (this.props?.location?.state?.actionType !== 'update') {
			if (newProps.questionsData.extra > 0) {
				this.setState({
					QuestionListData_onQuery: newProps.questionsData.extra,
					NonselectedQuestionListData_onQueryOnTAble: newProps.questionsData.extra,
				})
			}
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.props.actions.resetAllStatussExam('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			var lastQuestions = []
			this.state.AddedQuestionListData_onQuery.map((question) => {
				var midData = {
					questionId: question.id,
					timeOut: question.timeOut,
					point: question.point,
					orderCount: question.orderCount,
					question: null,
				}
				lastQuestions.push(midData)
			})
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateExamDetailsById({
						exam: {
							id: this.props.location.state.data.id,
							ExamName: this.state.ExamName,
							TopicId: this.state.TopicSelection,
							AdminDescription: this.state.AdminDescription,
							AdminDescription: this.state.AdminDescription,
							IsActive: this.state.isActive,
							ResultType: this.state.ExamExplainationType,
							ResultDate: this.state.ExamExplainationDate,
							DeadLine: this.state.ExamLastParticipation,
							ScoringSystem: this.state.puanonSysem,
						},
						questions: lastQuestions,
					  })
					: this.props.actions.createExamWithQuestion({
						exam: {
							ExamName: this.state.ExamName,
							TopicId: this.state.TopicSelection,
							UserDescription: this.state.UserDescription,
							AdminDescription: this.state.AdminDescription,
							IsActive: this.state.isActive,
							ResultType: this.state.ExamExplainationType,
							ResultDate: this.state.ExamExplainationDate,
							DeadLine: this.state.ExamLastParticipation,
						},
						questions: lastQuestions,
					  })
			}
		}
	}

	validationFunc() {
		if (this.state.AddedQuestionListData_onQuery.length > 0) {
			if (this.state.pointTotal === 100) {
				return true
			} else {
				alertifyjs.error('Test Puan Toplamı 100 Olmalıdır!')
				return false
			}
		} else {
			alertifyjs.error('Teste Mininmum 1 Adet Soru Eklenmiş Olmalıdır!')
			return false
		}
	}

	onChange(event) {
		const { name, value } = event.target
	}

	CToggle = (data, editMode = false) => {
		this.setState({
			editMode,
			ToggState: !this.state.ToggState,
			onActModal: data,
			timeOut: data.timeOut == 0 ? '' : data.timeOut,
			point: data.point == 0 ? '' : data.point,
			orderCount: data.orderCount == 0 ? '' : data.orderCount,
		})
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var NonselectedQuestionListData_onQueryOnTAble = this.state.QuestionListData_onQuery.filter(
				(item) =>
					item.questionText.like(e.target.value) ||
					item.tagText.like(e.target.value) ||
					item.questionType.like(e.target.value)
			)
			this.setState({ NonselectedQuestionListData_onQueryOnTAble })
		} else {
			this.setState({
				QuestionListData_onQuery: this.state.QuestionListData_onQuery,
				NonselectedQuestionListData_onQueryOnTAble: this.state.QuestionListData_onQuery,
				searchOnListone: '',
			})
		}
	}

	searchOnList2 = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var AddedQuestionListData_onQueryOnTable = this.state.AddedQuestionListData_onQuery.filter(
				(item) =>
					item.questionText.like(e.target.value) ||
					item.tagText.like(e.target.value) ||
					item.questionType.like(e.target.value)
			)
			this.setState({ AddedQuestionListData_onQueryOnTable })
		} else {
			this.setState({
				AddedQuestionListData_onQueryOnTable: this.state.AddedQuestionListData_onQuery,
				outlinedbasicSAdded: '',
			})
		}
	}

	modalController = () => {
		if (this.state.timeOut > 0) {
			if (this.state.orderCount > 0) {
				if (this.state.point > 0) {
				} else {
					alertifyjs.error('Soru Puanı 0 dan büyük olmalıdır!')
					this.setState({ leftActisOk: false })
				}
			} else {
				alertifyjs.error('Soru Sırası 0 dan büyük olmalıdır!')
				this.setState({ leftActisOk: false })
			}
		} else {
			alertifyjs.error('Soru Zamanı 0 dan büyük olmalıdır!')
			this.setState({ leftActisOk: false })
		}
	}

	calculatePointPerQuestion = () => {
		if (this.state.puanonSysem !== '1') {
			if (this.state.AddedQuestionListData_onQuery.length == 0) {
				return Math.floor((100 / 1).toFixed(2))
			} else {
				return Math.floor(
					(100 / (this.state.AddedQuestionListData_onQuery.length + 1)).toFixed(2)
				)
			}
		} else {
			return this.state.point
		}
	}

	calculateTotalPoint = (AddedQuestionListData_onQuery, perpoint) => {
		var countsPuant = 0
		AddedQuestionListData_onQuery &&
			AddedQuestionListData_onQuery.map((item) => {
				countsPuant += Number(item.point)
			})
		this.setState({ pointTotal: countsPuant })
	}

	addQuestionModal = () => {
		var isModalOk = this.constrolModal()

		if (isModalOk === true) {
			if (!this.state.editMode) {
				var data = this.state.QuestionListData_onQuery.filter(
					(item) => item.id === this.state.onActModal.id
				)
			}

			let perpoint = this.calculatePointPerQuestion()

			var AddedQuestionListData_onQuery = this.state.AddedQuestionListData_onQuery

			if (!this.state.editMode) {
				var data2 = [
					Object.assign(data[0], {
						timeOut: this.state.timeOut,
						point: perpoint,
						orderCount: this.state.orderCount,
					}),
				]
				AddedQuestionListData_onQuery = data2.concat(
					this.state.AddedQuestionListData_onQuery
				)

				var QuestionListData_onQuery = this.state.QuestionListData_onQuery.filter(
					(item) => item.id !== this.state.onActModal.id
				)
			} else {
				let question = AddedQuestionListData_onQuery.find(
					(i) => i.id == this.state.onActModal.id
				)
				question.timeOut = this.state.timeOut
				question.point = this.state.point
				question.orderCount = this.state.orderCount

				AddedQuestionListData_onQuery.splice(
					AddedQuestionListData_onQuery.indexOf(question),
					1,
					question
				)
			}

			if (this.state.puanonSysem !== '1' && !this.state.editMode) {
				var tt = 100
				var cc = 0
				AddedQuestionListData_onQuery.map((item) => {
					tt -= perpoint
					cc += 1

					if (AddedQuestionListData_onQuery.length === cc) {
						item.point = tt + perpoint
					} else {
						item.point = perpoint
					}
				})
			}

			this.calculateTotalPoint(AddedQuestionListData_onQuery, perpoint)

			var countsTimer = 0
			AddedQuestionListData_onQuery.map((item) => {
				countsTimer += Number(item.timeOut)
			})

			if (!this.state.editMode) {
				this.setState(
					{
						QuestionListData_onQuery,
						NonselectedQuestionListData_onQueryOnTAble: QuestionListData_onQuery,
					},
					() => {
						this.searchOnList({
							target: {
								name: 'searchOnListone',
								value: this.state.searchOnListone,
							},
						})

						this.searchOnList2({
							target: {
								name: 'outlinedbasicSAdded',
								value: this.state.outlinedbasicSAdded,
							},
						})
					}
				)
			}

			this.setState(
				{
					ToggState: !this.state.ToggState,
					timeOutTotal: countsTimer,
					AddedQuestionListData_onQuery,
					AddedQuestionListData_onQueryOnTable: [],
					outlinedbasicSAdded: '',
				},
				() => {
					this.setState({
						AddedQuestionListData_onQueryOnTable: AddedQuestionListData_onQuery,
					})
				}
			)

			localStorage.setItem('exam_add_question_order', this.state.orderCount)
			localStorage.setItem('exam_add_question_timeout', this.state.timeOut)
		}
	}

	removeAddedQuestion = (data) => {
		var AddedQuestionListData_onQuery = this.state.AddedQuestionListData_onQuery.filter(
			(item) => item.id !== data.id
		)

		if (this.state.puanonSysem !== '1') {
			var tt = 100
			var cc = 0
			var pp = parseInt((100 / AddedQuestionListData_onQuery.length).toFixed(0))
			AddedQuestionListData_onQuery.map((item) => {
				tt -= pp
				cc += 1
				if (AddedQuestionListData_onQuery.length === cc) {
					item.point = tt + pp
				} else {
					item.point = pp
				}
			})
		}
		this.setState({
			AddedQuestionListData_onQuery,
			AddedQuestionListData_onQueryOnTable: AddedQuestionListData_onQuery,
			outlinedbasicSAdded: '',
		})

		var QuestionListData_onQuery = this.state.QuestionListData_onQuery.concat([data])
		this.setState(
			{
				QuestionListData_onQuery,
				NonselectedQuestionListData_onQueryOnTAble: QuestionListData_onQuery,
			},
			() => {
				this.searchOnList({
					target: {
						name: 'searchOnListone',
						value: this.state.searchOnListone,
					},
				})

				this.searchOnList2({
					target: {
						name: 'outlinedbasicSAdded',
						value: this.state.outlinedbasicSAdded,
					},
				})
			}
		)

		var countsPuant = this.state.pointTotal - Number(data.point)
		var countsTimer = this.state.timeOutTotal - Number(data.timeOut)
		this.setState({ timeOutTotal: countsTimer, pointTotal: countsPuant }, () => {
			this.calculatedTotal(AddedQuestionListData_onQuery)
		})
	}

	constrolModal = () => {
		if (this.state.timeOut > 0) {
			if (this.state.puanonSysem === '1') {
				if (this.state.orderCount > 0) {
					if (this.state.point > 0) {
						var total = Number(this.state.pointTotal) + Number(this.state.point)

						if (total > 100) {
							alertifyjs.warning(
								"Puanlama Toplamları 100'den Büyük Olamaz! Şu an Toplam Hesaplanan " +
									this.state.pointTotal +
									' Puanlık Sorunuz Mevcut!  '
							)
							return false
						} else {
							return true
						}
					} else {
						alertifyjs.warning("Soru Puanı 0'dan Büyük Olmalıdır!")
						return false
					}
				} else {
					alertifyjs.warning("Soru Sırası 0'dan Büyük Olmalıdır!")
					return false
				}
			} else {
				return true
			}
		} else {
			alertifyjs.warning("Soru Zamanı 0'dan Büyük Olmalıdır!")
			return false
		}
	}

	controlLeftSide = () => {
		if (this.state.TopicSelection !== '-1') {
			if (this.state.puanonSysem !== '-1') {
				if (this.state.ExamName.length > 3) {
					if (this.state.ExamExplainationType !== '-1') {
						if (this.state.ExamExplainationType === '2') {
							if (this.state.ExamExplainationDate.length > 0) {
								if (
									Date.parse(this.state.ExamExplainationDate) <
									Date.parse(this.state.startDate)
								) {
									alertifyjs.warning(
										'Test Açıklanma Tarihi ' +
											this.state.startDate +
											' tarihinden önce olamaz!'
									)
									this.setState({ leftActisOk: false })
								} else {
									if (this.state.ExamLastParticipation.length > 0) {
										if (
											Date.parse(this.state.ExamLastParticipation) <
											Date.parse(this.state.startDate)
										) {
											alertifyjs.warning(
												'Son Katılım Tarihi ' + this.state.startDate + ' tarihinden önce olamaz!'
											)
											this.setState({ leftActisOk: false })
										} else {
											if (
												Date.parse(this.state.ExamLastParticipation) <
												Date.parse(this.state.ExamExplainationDate)
											) {
												this.setState({ leftActisOk: true })
											} else {
												alertifyjs.warning(
													'Son Katılım Tarihi Açıklanma tarihinden sonra olamaz!'
												)
												this.setState({ leftActisOk: false })
											}
										}
									} else {
										alertifyjs.warning('Test Son Katılım Tarihi Belirtilmiş Olmalıdır!')
										this.setState({ leftActisOk: false })
									}
								}
							} else {
								alertifyjs.warning('Test Açıklanma Tarihi Belirtilmiş Olmalıdır!')
								this.setState({ leftActisOk: false })
							}
						} else {
							if (this.state.ExamLastParticipation.length > 0) {
								if (
									Date.parse(this.state.ExamLastParticipation) <
									Date.parse(this.state.startDate)
								) {
									alertifyjs.warning(
										'Son Katılım Tarihi ' + this.state.startDate + ' tarihinden önce olamaz!'
									)
									this.setState({ leftActisOk: false })
								} else {
									let perPoint = this.calculatePointPerQuestion()
									this.calculateTotalPoint(this.state.AddedQuestionListData_onQuery, perPoint)
									this.setState({ leftActisOk: true })
								}
							} else {
								alertifyjs.warning('Test Son Katılım Tarihi Belirtilmiş Olmalıdır!')
								this.setState({ leftActisOk: false })
							}
						}
					} else {
						alertifyjs.warning('Test Açıklanma Opsiyonu Seçilmiş Olmalıdır!')
						this.setState({ leftActisOk: false })
					}
				} else {
					alertifyjs.warning('Test Adı Min 4 Hane olmalıdır!')
					this.setState({ leftActisOk: false })
				}
			} else {
				alertifyjs.warning('Puanlama Tipi Seçilmiş Olmalıdır!')
				this.setState({ leftActisOk: false })
			}
		} else {
			alertifyjs.warning('Konu Seçimi Yapılmış Olmalıdır!')
			this.setState({ leftActisOk: false })
		}
	}

	controlLeftSideFalse = () => {
		this.setState({ leftActisOk: false })
	}

	renderAllQuestions() {
		return (
			<Card>
				<Card.Header>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>
								Sorular{' '}
								<Badge color="danger">{this.state.QuestionListData_onQuery.length}</Badge>{' '}
							</h4>
						</FormGroup>

						<FormGroup row>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindTopic
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											value={this.state.searchOnListone}
											id="searchOnListone"
											name="searchOnListone"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
							</div>
							<Accordion.Toggle eventKey="0" className="btn btn-tool">
								<Icon3 style={{ margin: '5px' }} color="action" />
							</Accordion.Toggle>
						</FormGroup>
					</div>
				</Card.Header>

				<Accordion.Collapse eventKey="0">
					{this.state.NonselectedQuestionListData_onQueryOnTAble.length > 0 ? (
						<DataTable
							id="table1"
							name="table1"
							columns={this.state.QuestionsColumns}
							data={this.state.NonselectedQuestionListData_onQueryOnTAble}
							pagination
							paginationPerPage={10}
							highlightOnHover
							noHeader
							grow={10}
							dense={false}
							direction={'auto'}
						/>
					) : (
						<div></div>
					)}
				</Accordion.Collapse>
			</Card>
		)
	}

	renderAddedQuestions() {
		return (
			<Card>
				<Card.Header>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>
								Eklenmiş Sorular
								<Badge
									className="badge badge-pill badge-success"
									style={{ marginRight: 5, marginLeft: 5 }}
								>
									{this.state.AddedQuestionListData_onQuery.length} Adet
								</Badge>
								{this.state.puanonSysem === '1' ? (
									<Badge className="badge badge-pill" style={{ marginRight: 5 }}>
										{this.state.pointTotal} Puan
									</Badge>
								) : null}
								<Badge className="badge badge-pill  badge-danger">
									{this.state.timeOutTotal} Saniye
								</Badge>
							</h4>
						</FormGroup>

						<FormGroup row>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindTopic
								) === true ? (
										<TextField
											onChange={this.searchOnList2}
											value={this.state.outlinedbasicSAdded}
											id="outlinedbasicSAdded"
											name="outlinedbasicSAdded"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
							</div>
							<Accordion.Toggle eventKey="1" className="btn btn-tool">
								<Icon3 style={{ margin: '5px' }} color="action" />
							</Accordion.Toggle>
						</FormGroup>
					</div>
				</Card.Header>

				<Accordion.Collapse eventKey="1">
					<DataTable
						id="table1"
						name="table1"
						columns={
							this.state.puanonSysem === '0'
								? this.state.AddedQuestionsColumns2
								: this.state.AddedQuestionsColumns
						}
						data={this.state.AddedQuestionListData_onQueryOnTable}
						pagination={true} // paging
						highlightOnHover={true}
						noHeader
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	questionAddModal() {
		return (
			<div>
				<Modal isOpen={this.state.ToggState} toggle={this.CToggle} size="lg">
					<ModalHeader toggle={this.CToggle}>Teste Soru Atayınız!</ModalHeader>
					<ModalBody>
						<br className="my-2" />

						<Row>
							<Col>
								{this.state.onActModal && (
									<QuestionCard
										hideQuestionNumber={true}
										questions={[this.state.onActModal]}
									/>
								)}
							</Col>
						</Row>
						<hr className="my-2 m-1" />

						<Row>
							<Col>
								<FormGroup>
									<Label for="ExamName">Soru Süresi (Saniye)</Label>
									{localStorage.getItem('exam_add_question_timeout') && (
										<a
											href="javascript:;"
											title={`Daha önce bu alana '${localStorage.getItem(
												'exam_add_question_timeout'
											)}' değerini girdiniz.`}
											onClick={() =>
												this.setState({
													timeOut: localStorage.getItem('exam_add_question_timeout'),
												})
											}
										>
											<History style={{ marginLeft: 5 }} />
										</a>
									)}
									<Input
										type="number"
										name="timeOut"
										id="timeOut"
										placeholder="Soru Süresini Belirleyiniz!"
										value={this.state.timeOut}
										onChange={this.setOnChange}
									/>
								</FormGroup>
							</Col>

							<Col>
								<FormGroup>
									<Label for="ExamName">Soru Sırası</Label>
									{localStorage.getItem('exam_add_question_order') && (
										<a
											href="javascript:;"
											title={`Daha önce bu alana '${
												localStorage.getItem('exam_add_question_order') || ''
											}' değerini girdiniz.`}
											onClick={() =>
												this.setState({
													orderCount: localStorage.getItem('exam_add_question_order'),
												})
											}
										>
											<History style={{ marginLeft: 5 }} />
										</a>
									)}
									<Input
										type="number"
										name="orderCount"
										id="orderCount"
										placeholder="Soru Sırasını Belirleyiniz!"
										value={this.state.orderCount}
										onChange={this.setOnChange}
									/>
								</FormGroup>
							</Col>

							{this.state.puanonSysem === '1' ? (
								<Col>
									<FormGroup>
										<Label for="ExamName">Soru Puanı</Label>

										<Input
											type="number"
											name="point"
											id="point"
											placeholder="Test Puanını Belirleyiniz!"
											value={this.state.point}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							) : null}
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.addQuestionModal}>
							Soruyu {!this.state.editMode ? 'Ekle' : 'Güncelle'}
						</Button>
						<Button color="danger" onClick={this.CToggle}>
							Vazgeç
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	firstForm() {
		return (
			<div>
				<Form>
					<Row>
						<Col>
							<FormGroup>
								<Label for="TopicSelection">Konu Seçimi</Label>
								<Input
									type="select"
									name="TopicSelection"
									id="TopicSelection"
									onChange={this.setOnChange}
									value={this.state.TopicSelection}
								>
									<option value="-1" key="-1">
										Seçiniz
									</option>
									{this.props.topicReducerData.extra.map((topic) => {
										return (
											<option value={topic.id} key={topic.id}>
												{topic.topicName}{' '}
											</option>
										)
									})}
								</Input>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col>
							<FormGroup>
								<Label for="puanonSysem">Puanlama Tipi</Label>
								<Input
									type="select"
									name="puanonSysem"
									id="puanonSysem"
									onChange={this.setOnChange}
									value={this.state.puanonSysem}
								>
									<option value="-1" key="puanonSysemChoose">
										Seçiniz
									</option>
									<option value="0" key="OnSystem">
										Sistem Belirler
									</option>
									<option value="1" key="OnUser">
										Kullanıcı Belirler
									</option>
								</Input>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col>
							<FormGroup>
								<Label for="ExamName">Test Adı</Label>
								<Input
									type="text"
									name="ExamName"
									id="ExamName"
									placeholder="Test Adı Belirleyiniz!"
									value={this.state.ExamName}
									onChange={this.setOnChange}
								/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col>
							<FormGroup>
								<Label for="UserDescription">Öğretmen Açıklama</Label>
								<Input
									type="textarea"
									name="UserDescription"
									id="UserDescription"
									value={this.state.UserDescription}
									onChange={this.setOnChange}
								/>
							</FormGroup>
						</Col>
					</Row>

					{this.props.userAuthData.permissions.includes(
						initialState.staticPerms.adminPermsId
					) === true ? (
							<Row>
								<Col>
									<FormGroup>
										<Label for="AdminDescription">Yönetici Açıklama</Label>
										<Input
											type="textarea"
											name="AdminDescription"
											id="AdminDescription"
											value={this.state.AdminDescription}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
						) : null}

					<Row>
						<Col xs={this.state.ExamExplainationType === '2' ? 6 : 12}>
							<FormGroup>
								<Label for="ExamExplainationType">Sonuç Açıklanma Seçimi</Label>
								<Input
									type="select"
									name="ExamExplainationType"
									id="ExamExplainationType"
									onChange={this.setOnChange}
									value={this.state.ExamExplainationType}
								>
									<option value="-1" key="ExamExplainationTypeOnChoose">
										Seçiniz
									</option>
									<option value="0" key="afterQuestion">
										Sorudan Sonra
									</option>
									<option value="1" key="afterExam">
										Testten Sonra
									</option>
									<option value="2" key="afterDate">
										... Tarihten Sonra
									</option>
								</Input>
							</FormGroup>
						</Col>

						{this.state.ExamExplainationType === '2' ? (
							<Col xs={6}>
								<FormGroup>
									<Label for="ExamExplainationDate">Açıklanma Tarihi</Label>
									<Input
										type="date"
										name="ExamExplainationDate"
										id="ExamExplainationDate"
										placeholder="date placeholder"
										onChange={this.setOnChange}
										value={this.state.ExamExplainationDate}
										min={this.state.startDate}
									/>
								</FormGroup>
							</Col>
						) : null}
					</Row>

					<Row>
						<Col>
							<FormGroup>
								<Label for="adminNote">Son Katılım Tarihi</Label>

								<Input
									type="date"
									name="ExamLastParticipation"
									id="ExamLastParticipation"
									placeholder="date placeholder"
									onChange={this.setOnChange}
									value={this.state.ExamLastParticipation}
									min={this.state.startDate}
								/>
							</FormGroup>
						</Col>
					</Row>
					<div className="form-group form-check">
						<input
							type="checkbox"
							className="form-check-input"
							id="isActive"
							name="isActive"
							onChange={(e) => this.setState({isActive: e.target.value == 'on'})}
							checked={this.state.isActive}
						/>
						<label className="form-check-label" htmlFor="isActive">
							Aktif mi?
						</label>
					</div>
				</Form>
			</div>
		)
	}

	render() {
		return (
			<ScrollView>
				{this.questionAddModal()}

				{this.state.leftActisOk === true ? (
					<div className="row">
						<div className="col-lg-12">
							<Accordion defaultActiveKey="0">
								{this.renderAllQuestions()}

								{this.renderAddedQuestions()}
								<Button
									size="lg"
									onClick={this.controlLeftSideFalse}
									color="danger"
									className="float-left"
								>
									<KeyboardArrowLeftIcon fontSize="large" />
								</Button>
								{this.props?.location?.state?.actionType === 'update' ? (
									<Button
										size="lg"
										className="mb-4"
										onClick={this.onSubmit}
										className="float-right"
										style={{ width: '200px' }}
									>
										Test Güncelle
									</Button>
								) : (
									<Button
										size="lg"
										className="mb-4"
										onClick={this.onSubmit}
										className="float-right"
										style={{ width: '200px' }}
									>
										Test Oluştur
									</Button>
								)}
							</Accordion>
						</div>
					</div>
				) : (
					<div>
						<div className="row">
							<div className="col-lg-2"></div>
							<div className="col-lg-8">{this.firstForm()}</div>
							<div className="col-lg-2"></div>
						</div>
						<div className="row">
							<div className="col-lg-2"></div>
							<div className="col-lg-8">
								<Button
									size="lg"
									onClick={this.controlLeftSide}
									color="success"
									className="float-right"
								>
									<KeyboardArrowRightIcon fontSize="large" /> Devam Et
								</Button>
							</div>
							<div className="col-lg-2"></div>
						</div>
					</div>
				)}
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		examData: state.examReducer,
		topicReducerData: state.topicReducer,

		questionsData: state.questionsReducer,

		userAuthData: state.authReducer,

		gettedExamByIdData: state.getExamByIdReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			createExam: bindActionCreators(examActions.createExam, dispatch),

			createExamWithQuestion: bindActionCreators(
				examActions.createExamWithQuestion,
				dispatch
			),
			setExamRegStatuss: bindActionCreators(examActions.setExamRegStatuss, dispatch),
			getTopics: bindActionCreators(topicActions.getTopics, dispatch),
			resetAllStatussExam: bindActionCreators(examActions.resetAllStatuss, dispatch),
			updateExam: bindActionCreators(examActions.updateExam, dispatch),
			getQuestions: bindActionCreators(questionActions.getQuestions, dispatch),

			getExamDetailsById: bindActionCreators(
				getExamByIdAction.getExamDetailsById,
				dispatch
			),
			resetAllStatussExamById: bindActionCreators(
				getExamByIdAction.resetExamByIdSuccess,
				dispatch
			),
			updateExamDetailsById: bindActionCreators(
				getExamByIdAction.updateExamDetailsById,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamForm))
