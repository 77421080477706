import {
	GET_TOPIC_DETAILS_SUCCESS,
	RESET_TOPIC_DETAILS,
	ONFAULT_TOPIC_DETAILS,
	DELETE_EXAM_SUCCESS,
	RESET_STATUSS_TOPIC_DETAILS,
	DELETE_EXAM_SUCCESS_STATUSS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_TOPIC_DETAILS,
		payload: data,
	}
}

export const changeDeletedExamSuccessStatus = (data) => {
	return {
		type: DELETE_EXAM_SUCCESS_STATUSS,
		payload: data,
	}
}

export const getTopicDetailSuccess = (examData) => {
	return {
		type: GET_TOPIC_DETAILS_SUCCESS,
		payload: examData,
	}
}

export const resetTopicDetailSuccess = (examData) => {
	return {
		type: RESET_TOPIC_DETAILS,
		payload: examData,
	}
}

export const onFaultTopicDetails = (data) => {
	return {
		type: ONFAULT_TOPIC_DETAILS,
		payload: data,
	}
}

export const getTopicDetail = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetByTopic'

	if (data.TopicId) {
		url = url + '?TopicId=' + data.TopicId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(getTopicDetailSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultTopicDetails(ffData))
		})
}

export const deleteExamSuccess = (examData) => {
	return {
		type: DELETE_EXAM_SUCCESS,
		payload: examData,
	}
}

export const deleteExam = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedExam = res.data

			dispatch(deleteExamSuccess(deletedExam))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultTopicDetails(ffData))
		})
}
