import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import jwt_decode from 'jwt-decode'
import { ScrollView } from '../base/scroll'

import * as lessonActions from '../../../redux/actions/lessonActions'
import * as topicActions from '../../../redux/actions/topicActions'
import * as schoolActions from '../../../redux/actions/schoolActions'
import * as roleActions from '../../../redux/actions/roleActions'
import * as messageActions from '../../../redux/actions/messageActions'
import * as dashboardActions from '../../../redux/actions/dashboardActions'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as examActions from '../../../redux/actions/examActions'

import {
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Bar,
	BarChart,
	AreaChart,
	Area,
	ResponsiveContainer,
	Legend,
} from 'recharts'

class Default extends Component {
	constructor(props) {
		super(props)

		this.state = {
			trueOrFalseDAta: [],
			pointsChartData: [],
			orderInCLassChartData: [],

			classSolvedExams: [],
			classNotSolvedExams: [],
			teacherSolvedExams: [],
			teacherNotSolvedExams: [],

			ColumnsOnMessages: [
				{
					name: 'Başlık',
					selector: 'header',
					sortable: true,
				},
				{
					name: 'Durumu',
					selector: 'isRead',
					sortable: true,
					right: true,
					cell: (row) => (row.isRead === true ? 'Okundu' : 'Okunmadı'),
				},
			],

			colClass: [
				{
					name: 'Sınıf',
					selector: 'classroomName',
					sortable: true,
					right: false,
				},
				{
					name: 'Test',
					selector: 'examName',
					sortable: true,
					right: true,
				},
				{
					name: 'Katılım',
					selector: 'count',
					sortable: true,
					right: true,
				},
			],

			colUser: [
				{
					name: 'Öğretmen',
					selector: 'teacher',
					sortable: true,
					right: false,
					cell: (row) => row.fullName,
				},
				{
					name: 'Test',
					selector: 'examName',
					sortable: true,
					right: true,
				},
				{
					name: 'Katılım',
					selector: 'count',
					sortable: true,
					right: true,
				},
			],
		}
	}

	componentDidMount() {
		this.props.actions.getDashboardTeachersAndClassrooms()
		this.props.actions.getDashboard()

		var token = jwt_decode(this.props.userAuthData.jwtToken)
		var uuid =
			token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']

		this.props.actions.getExamsSolved({
			UserId: uuid,
			ExamId: '00000000-0000-0000-0000-000000000000',
		})

		this.props.actions.getRoles()
		this.props.actions.getFullLessons()
		this.props.actions.getFullTopics()
		this.props.actions.getFullSchool()
		this.props.actions.gettedAllMessage()
	}

	sendMeMessages(navigation) {
		var location = {
			pathname: navigation,
		}

		this.props.history.push(location)
	}

	componentWillReceiveProps(newProps) {
		if (newProps.examsData.successSolvedGetted) {
			var lastFiveExam = newProps.examsData.extraSolvedLastFive
			var data = lastFiveExam.map((cc) => {
				return {
					name: cc.exam.examName,
					Doğru: cc.correctQuestionCount,
					Yanlış: cc.wrongQuestionCount,
					Boş: cc.skippedQuestionCount,
					'Ad Soyad': cc.userDto.firstName + ' ' + cc.userDto.lastName,
				}
			})

			var data2 = lastFiveExam.map((cc) => {
				return {
					name: cc.exam.examName + '-' + cc.userDto.firstName + ' ' + cc.userDto.lastName,
					point: cc.totalPoint,
				}
			})

			var orderinlassList = []
			lastFiveExam.map((cc) => {
				if (cc.isFirstTry === true) {
					orderinlassList.push({
						name: cc.exam.examName,
						point: cc.orderInClass,
					})
				}
			})

			this.setState({
				trueOrFalseDAta: data,
				pointsChartData: data2,
				orderInCLassChartData: orderinlassList,
			})

			this.props.actions.resetAllStatuss()
		}

		if (newProps.dashboardReducerData.successManagerData) {
			var classSolvedExams = newProps.dashboardReducerData.extraManagerData.classroomList.filter(
				(a1) => a1.solved === true
			)
			var classNotSolvedExams = newProps.dashboardReducerData.extraManagerData.classroomList.filter(
				(a1) => a1.solved === false
			)

			var teacherSolvedExams = newProps.dashboardReducerData.extraManagerData.teacherList.filter(
				(a1) => a1.solved === true
			)
			var teacherNotSolvedExams = newProps.dashboardReducerData.extraManagerData.teacherList.filter(
				(a1) => a1.solved === false
			)

			this.setState({
				classSolvedExams,
				classNotSolvedExams,
				teacherSolvedExams,
				teacherNotSolvedExams,
			})

			this.props.actions.resetGetDashboardTeachersAndClassroomsSuccess()
		}
	}

	onlyManagerAndAdmin() {
		return (
			<div>
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header border-0">
								<div className="d-flex justify-content-between">
									<h3 className="card-title">Sınıfa Atanmış Testler</h3>
									{/* <a href="#">View Report</a> */}
								</div>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-lg-6">
										<DataTable
											columns={this.state.colClass}
											data={this.state.classSolvedExams}
											highlightOnHover
											noTableHead
											pagination
										/>
									</div>{' '}
									{/* end -- col-lg-6 */}
									<div className="col-lg-6">
										<DataTable
											columns={this.state.colClass}
											data={this.state.classNotSolvedExams}
											highlightOnHover
											noTableHead
											pagination
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header border-0">
								<div className="d-flex justify-content-between">
									<h3 className="card-title">Öğretmenin Atadığı Testler</h3>
								</div>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-lg-6">
										<DataTable
											columns={this.state.colUser}
											data={this.state.teacherSolvedExams}
											highlightOnHover
											noTableHead
											pagination
										/>
									</div>{' '}
									{/* end -- col-lg-6 */}
									<div className="col-lg-6">
										<DataTable
											columns={this.state.colUser}
											data={this.state.teacherNotSolvedExams}
											highlightOnHover
											noTableHead
											pagination
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	onlyStudentOrdenInClass() {
		const gradientOffset = () => {
			const dataMax = Math.max(...this.state.orderInCLassChartData.map((i) => i.uv))
			const dataMin = Math.min(...this.state.orderInCLassChartData.map((i) => i.uv))

			if (dataMax <= 0) {
				return 0
			}
			if (dataMin >= 0) {
				return 1
			}

			return dataMax / (dataMax - dataMin)
		}

		const off = gradientOffset()

		return (
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header border-0">
							<div className="d-flex justify-content-between">
								<h3 className="card-title">Sınıf Sıralaması</h3>
							</div>
						</div>

						<div className="card-body">
							<ResponsiveContainer width="100%" height={150}>
								<AreaChart
									width={this.props.toggleStatus ? 1600 : 1800}
									height={150}
									data={this.state.orderInCLassChartData}
									margin={{
										top: 10,
										right: 30,
										left: 0,
										bottom: 0,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis reversed />
									<Tooltip />
									<defs>
										<linearGradient id="table22" x1="0" y1="0" x2="0" y2="1">
											<stop offset={off} stopColor="red" stopOpacity={1} />
											<stop offset={off} stopColor="#fff" stopOpacity={1} />
										</linearGradient>
									</defs>
									<Area type="monotone" dataKey="point" stroke="red" fill="url(#table22)" />
								</AreaChart>
							</ResponsiveContainer>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const gradientOffset = () => {
			const dataMax = Math.max(...this.state.pointsChartData.map((i) => i.uv))
			const dataMin = Math.min(...this.state.pointsChartData.map((i) => i.uv))

			if (dataMax <= 0) {
				return 0
			}
			if (dataMin >= 0) {
				return 1
			}

			return dataMax / (dataMax - dataMin)
		}

		const off = gradientOffset()

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-3 col-6">
						<div className="small-box bg-light">
							<div className="inner">
								<h3>{this.props.dashboardReducerData.extraGetted.box1}</h3>

								<p>Girilmemiş Testler</p>
							</div>
							<div className="icon">
								<i className="fa fa-pie-chart"></i>
							</div>
							<a className="small-box-footer" href="Profile?Id=2">
								Testlere Git... <i className="fa fa-arrow-circle-right"></i>
							</a>
						</div>
					</div>

					<div className="col-lg-3 col-6">
						<div className="small-box bg-light">
							<div className="inner">
								<h3>
									{this.props.dashboardReducerData.extraGetted.box2}
									<sup style={{ fontSize: '20px' }}>%</sup>
								</h3>

								<p>Doğru Cevap Oranı</p>
							</div>
							<div className="icon">
								<i className="fa fa-slack"></i>
							</div>
							<a className="small-box-footer" href="Profile?Id=3">
								Testlere Git... <i className="fa fa-arrow-circle-right"></i>
							</a>
						</div>
					</div>

					<div className="col-lg-3 col-6">
						<div className="small-box bg-light">
							<div className="inner">
								<h3>{this.props.dashboardReducerData.extraGetted.box3}</h3>

								<p>Kullanıcı Sayısı</p>
							</div>
							<div className="icon">
								<i className="fa fa-user-circle"></i>
							</div>
							<a className="small-box-footer">
								Sınıf Detayına Git... <i className="fa fa-arrow-circle-right"></i>
							</a>
						</div>
					</div>

					<div className="col-lg-3 col-6">
						<div className="small-box bg-light">
							<div className="inner">
								<h3>{this.props.dashboardReducerData.extraGetted.box4}</h3>

								<p>Mesajlar</p>
							</div>
							<div className="icon">
								<i className="fa fa-envelope-open"></i>
							</div>
							<a className="small-box-footer" href="/Messages">
								Mesajlara Git... <i className="fa fa-arrow-circle-right"></i>
							</a>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-8">
						<div className="card">
							<div className="card-header border-0">
								<div className="d-flex justify-content-between">
									<h3 className="card-title">Son Testler</h3>
								</div>
							</div>
							<div className="card-body">
								<ResponsiveContainer width="95%" height={250}>
									<BarChart
										width={this.props.toggleStatus ? 1120 : 1250}
										height={250}
										data={this.state.trueOrFalseDAta}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis dataKey="name" />
										<YAxis />
										<Tooltip />
										<Legend />
										<Bar dataKey="Doğru" fill="#db3d44" />
										<Bar dataKey="Yanlış" fill="#2b2b2b" />
										<Bar dataKey="Boş" fill="#48989" />
										<Bar dataKey="Ad Soyad" fill="orange" />
									</BarChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>{' '}
					{/* end -- col-lg-8 */}
					<div className="col-lg-4">
						<div className="card">
							<div className="card-header border-0">
								<h3 className="card-title">Mesajlar</h3>
								<div className="card-tools">
									<a href="/Messages" className="btn btn-tool btn-sm">
										<i className="fa fa-arrow-circle-right"></i>
									</a>
								</div>
							</div>

							<div className="card-body">
								<DataTable
									columns={this.state.ColumnsOnMessages}
									data={this.props.messageReducerData.extraGettedMessages.slice(-4)}
									highlightOnHover
									noTableHead
									onRowClicked={() => this.sendMeMessages('Messages')}
								/>
							</div>
						</div>
					</div>{' '}
					{/* end -- col-lg-4 */}
				</div>{' '}
				{/* end -- row */}
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header border-0">
								<div className="d-flex justify-content-between">
									<h3 className="card-title">Puanlamalar</h3>
								</div>
							</div>
							<div className="card-body">
								<ResponsiveContainer width="100%" height={150}>
									<AreaChart
										width={this.props.toggleStatus ? 1600 : 1800}
										height={150}
										data={this.state.pointsChartData}
										margin={{
											top: 10,
											right: 30,
											left: 0,
											bottom: 0,
										}}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis dataKey="name" />
										<YAxis />
										<Tooltip />
										<defs>
											<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
												<stop offset={off} stopColor="#db3d44" stopOpacity={1} />
												<stop offset={off} stopColor="red" stopOpacity={1} />
											</linearGradient>
										</defs>
										<Area
											type="monotone"
											dataKey="point"
											stroke="#000"
											fill="url(#splitColor)"
										/>
									</AreaChart>
								</ResponsiveContainer>
							</div>
						</div>
					</div>
				</div>
				{this.props.userAuthData.permissions.includes(
					'1d079fa0-6cef-4b42-b6c5-9938612654ff'
				) == true
					? this.onlyManagerAndAdmin()
					: null}
				{this.props.userAuthData.permissions.includes(
					'10ee4ac9-b100-435c-b116-ba32de3d3f21'
				) == true
					? this.onlyStudentOrdenInClass()
					: null}
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,

		messageReducerData: state.messageReducer,
		dashboardReducerData: state.dashboardReducer,

		examsData: state.examReducer,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			getFullLessons: bindActionCreators(lessonActions.getFullLessons, dispatch),
			getFullTopics: bindActionCreators(topicActions.getFullTopics, dispatch),
			getFullSchool: bindActionCreators(schoolActions.getFullSchool, dispatch),
			getRoles: bindActionCreators(roleActions.getRoles, dispatch),
			gettedAllMessage: bindActionCreators(messageActions.gettedAllMessage, dispatch),

			getDashboard: bindActionCreators(dashboardActions.getDashboard, dispatch),
			getDashboardTeachersAndClassrooms: bindActionCreators(
				dashboardActions.getDashboardTeachersAndClassrooms,
				dispatch
			),
			resetGetDashboardTeachersAndClassroomsSuccess: bindActionCreators(
				dashboardActions.resetGetDashboardTeachersAndClassroomsSuccess,
				dispatch
			),

			getExamsSolved: bindActionCreators(examActions.getExamsSolved, dispatch),
			resetAllStatuss: bindActionCreators(examActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Default))
