import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function leftNavReducer(state = initialState.navigations, action) {
	switch (action.type) {
		case actionTypes.GET_NAVIGATION:
			return state

		default:
			return state
	}
}
