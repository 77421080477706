import {
	GET_LESSONS_DETAILS_TOPICS_SUCCESS,
	GET_LESSONS_DETAILS_EXAM_SUCCESS,
	RESET_LESSONS_DETAILS,
	ONFAULT_LESSONS_DETAILS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

//*

export const getLessonsDetailTopicSuccess = (examData) => {
	return {
		type: GET_LESSONS_DETAILS_TOPICS_SUCCESS,
		payload: examData,
	}
}

//*

export const getLessonsDetailExamSuccess = (examData) => {
	return {
		type: GET_LESSONS_DETAILS_EXAM_SUCCESS,
		payload: examData,
	}
}

export const resetLessonDetailsSuccess = (data) => {
	return {
		type: RESET_LESSONS_DETAILS,
		payload: data,
	}
}

export const onFaultLessonDetails = (data) => {
	return {
		type: ONFAULT_LESSONS_DETAILS,
		payload: data,
	}
}

export const getLessonsDetailTopic = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Topics/GetByLesson'

	if (data.LessonId) {
		url = url + '?LessonId=' + data.LessonId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(getLessonsDetailTopicSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultLessonDetails(ffData))
		})
}

export const getLessonsDetailExam = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetByLesson'

	if (data.LessonId) {
		url = url + '?LessonId=' + data.LessonId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(getLessonsDetailExamSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFaultLessonDetails(ffData))
		})
}
