import React, { Component } from 'react'
import { ScrollView } from '../../innerComponenets/base/scroll'

export default class AboutUs extends Component {
	render() {
		return (
			<div className="content-w3ls" style={{ minWidth: '80%' }}>
				<div id="formContent">
					<div className="col-lg-12" style={{ paddingBottom: '20px' }}>
						<ScrollView>
							<h4>Biz Kimiz?</h4>
							<p>
								Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem
								Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak
								üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri
								standardı sahte metinler olarak kullanılmıştır. Beşyüz yıl boyunca varlığını
								sürdürmekle kalmamış, aynı zamanda pek değişmeden elektronik dizgiye de
								sıçramıştır. 1960'larda Lorem Ipsum pasajları da içeren Letraset
								yapraklarının yayınlanması ile ve yakın zamanda Aldus PageMaker gibi Lorem
								Ipsum sürümleri içeren masaüstü yayıncılık yazılımları ile popüler olmuştur.
							</p>

							<br />

							<h4>Amacımız Nedir?</h4>
							<p>
								Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem
								Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak
								üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri
								standardı sahte metinler olarak kullanılmıştır. Beşyüz yıl boyunca varlığını
								sürdürmekle kalmamış, aynı zamanda pek değişmeden elektronik dizgiye de
								sıçramıştır. 1960'larda Lorem Ipsum pasajları da içeren Letraset
								yapraklarının yayınlanması ile ve yakın zamanda Aldus PageMaker gibi Lorem
								Ipsum sürümleri içeren masaüstü yayıncılık yazılımları ile popüler olmuştur.
							</p>

							<br />

							<h4>Amacımız Nedir?</h4>
							<p>
								Yaygın inancın tersine, Lorem Ipsum rastgele sözcüklerden oluşmaz. Kökleri
								M.Ö. 45 tarihinden bu yana klasik Latin edebiyatına kadar uzanan 2000 yıllık
								bir geçmişi vardır. Virginia'daki Hampden-Sydney College'dan Latince
								profesörü Richard McClintock, bir Lorem Ipsum pasajında geçen ve anlaşılması
								en güç sözcüklerden biri olan 'consectetur' sözcüğünün klasik edebiyattaki
								örneklerini incelediğinde kesin bir kaynağa ulaşmıştır. Lorm Ipsum, Çiçero
								tarafından M.Ö. 45 tarihinde kaleme alınan "de Finibus Bonorum et Malorum"
								(İyi ve Kötünün Uç Sınırları) eserinin 1.10.32 ve 1.10.33 sayılı
								bölümlerinden gelmektedir. Bu kitap, ahlak kuramı üzerine bir tezdir ve
								Rönesans döneminde çok popüler olmuştur. Lorem Ipsum pasajının ilk satırı
								olan "Lorem ipsum dolor sit amet" 1.10.32 sayılı bölümdeki bir satırdan
								gelmektedir. 1500'lerden beri kullanılmakta olan standard Lorem Ipsum
								metinleri ilgilenenler için yeniden üretilmiştir. Çiçero tarafından yazılan
								1.10.32 ve 1.10.33 bölümleri de 1914 H. Rackham çevirisinden alınan İngilizce
								sürümleri eşliğinde özgün biçiminden yeniden üretilmiştir.
							</p>

							<br />
							<div className="text-muted">
								<p className="text-sm">
									Client Company
									<b className="d-block">İhsan Yazım Inc</b>
								</p>
								<p className="text-sm">
									Project Leader
									<b className="d-block">Kaan Acar</b>
									<b className="d-block">Ahmet Eryılmaz</b>
								</p>
								<br />
							</div>
						</ScrollView>
					</div>
				</div>
			</div>
		)
	}
}
