import moment from 'moment'
import initialState from '../../redux/reducers/initialState'

export const formatDate = (date) => {
	return moment(date).format(initialState.config.dateFormat)
}

export const formatDateTime = (date) => {
	return moment(date).format(initialState.config.datetimeFormat)
}
