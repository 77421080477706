/* eslint-disable */
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import History from '@material-ui/icons/History'
import ReactTagInput from '@pathofdev/react-tag-input'
import '@pathofdev/react-tag-input/build/index.css'
import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import Tooltip from 'react-png-tooltip'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import mainConfig from '../../../configs/mainConfig'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as getQuestionWithAnswersActions from '../../../redux/actions/getQuestionWithAnswersActions'
import * as questionActions from '../../../redux/actions/questionActions'
import * as questionTypeActions from '../../../redux/actions/questionTypeActions'
import * as uploadActions from '../../../redux/actions/uploadActions'
import DropZone from '../../toolbox/DropZone'
import Editor from '../base/editor'
import { ScrollView } from '../base/scroll'

const KeyCodes = {
	comma: 188,
	enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

class QuestionsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			QuestionText: '',
			WrongAnswer1: { text: '', id: '', fileUrl: null, isRight: '' },
			WrongAnswer2: { text: '', id: '', fileUrl: null, isRight: '' },
			WrongAnswer3: { text: '', id: '', fileUrl: null, isRight: '' },
			TrueAnswer: { text: '', id: '', fileUrl: null, isRight: '' },
			QuestionTypeSelection: null,

			pictures: [],
			fileUrl: null,

			toggle: false,
			madaltitle: '',

			uploadFireB: '',
			uploadFile: '',

			upladHowActMM: '',

			QuestionTag: [],
		}

		this.onTagsChanged = this.onTagsChanged.bind(this)
	}

	componentDidMount() {
		if (this.props?.location?.state?.data?.id) {
			this.props.actions.getQuestionsWithAnswer({
				id: this.props.location.state.data.id,
			})
		}

		this.props.actions.getQuestionTypes()
		this.props.actions.resetAllStatuss('')

		this.setState({
			QuestionText: '',
			WrongAnswer1: { text: '', id: '', fileUrl: null, isRight: '' },
			WrongAnswer2: { text: '', id: '', fileUrl: null, isRight: '' },
			WrongAnswer3: { text: '', id: '', fileUrl: null, isRight: '' },
			TrueAnswer: { text: '', id: '', fileUrl: null, isRight: '' },
			QuestionTypeSelection: '',
			QuestionTag: [],
			fileUrl: null,
		})
	}

	componentWillReceiveProps(newProps) {
		if (newProps.questionsData.success) {
			var location = {
				pathname: 'Sorular',
			}

			this.props.history.push(location)
		} else if (newProps.questionsData.success === false) {
			alertifyjs.error('Hata1: ' + newProps.questionsData.messages)
		}

		if (
			newProps.questionTypesData.success &&
			this.props?.location?.state?.actionType != 'update' &&
			!this.state.QuestionTypeSelection
		) {
			this.setState({
				QuestionTypeSelection: newProps.questionTypesData.extra.filter((i) =>
					i.typeName.includes('Text SORU')
				)[0].id,
			})
		}

		if (newProps.getQuestionsWithAnswersData.success === true) {
			var falseAnswers = newProps.getQuestionsWithAnswersData.extra.answers.filter(
				function (answer) {
					return answer.isRight === false
				}
			)

			var trueAnswer = newProps.getQuestionsWithAnswersData.extra.answers.filter(
				function (answer) {
					return answer.isRight === true
				}
			)

			{
				this.props?.location?.state?.actionType === 'update'
					? this.setState({
						updatedId: newProps.location.state.data.id,
						QuestionText: newProps.getQuestionsWithAnswersData.extra.questionText,
						fileUrl: newProps.getQuestionsWithAnswersData.extra.fileUrl,
						WrongAnswer1: Object.assign(this.state.WrongAnswer1, {
							text: falseAnswers[0].answerText,
							subid: falseAnswers[0].id,
							isRight: falseAnswers[0].isRight,
							fileUrl: falseAnswers[0].fileUrl,
						}),
						WrongAnswer2: Object.assign(this.state.WrongAnswer2, {
							text: falseAnswers[1].answerText,
							subid: falseAnswers[1].id,
							isRight: falseAnswers[1].isRight,
							fileUrl: falseAnswers[1].fileUrl,
						}),
						WrongAnswer3: Object.assign(this.state.WrongAnswer3, {
							text: falseAnswers[2].answerText,
							subid: falseAnswers[2].id,
							isRight: falseAnswers[2].isRight,
							fileUrl: falseAnswers[2].fileUrl,
						}),
						TrueAnswer: Object.assign(this.state.TrueAnswer, {
							text: trueAnswer[0].answerText,
							subid: trueAnswer[0].id,
							isRight: trueAnswer[0].isRight,
							fileUrl: trueAnswer[0].fileUrl,
						}),
						QuestionTypeSelection:
								newProps.getQuestionsWithAnswersData.extra.questionTypeId,
						QuestionTag: JSON.parse(newProps.getQuestionsWithAnswersData.extra.tagText),
					  })
					: null
			}

			this.props.actions.resetQuestionWithAnswersStatuss('')
		} else if (newProps.getQuestionsWithAnswersData.success === false) {
			alertifyjs.error('Hata2: ', newProps.getQuestionsWithAnswersData.errorMessage)
			this.props.actions.resetQuestionWithAnswersStatuss('')
		}

		if (newProps.uploadsData.success === true) {
			this.setToggle()
			this.props.actions.resetAllPostUploadStatuss('')
			alertifyjs.success('Yükleme Başarılı!')

			if (this.state.upladHowActMM === 'QuestionText') {
				this.setState({
					fileUrl: mainConfig.imageUploaded + newProps.uploadsData.extra.fileNameList[0],
				})
			} else if (this.state.upladHowActMM === 'WrongAnswer1') {
				this.setState({
					WrongAnswer1: Object.assign(this.state.WrongAnswer1, {
						fileUrl: mainConfig.imageUploaded + newProps.uploadsData.extra.fileNameList[0],
					}),
				})
			} else if (this.state.upladHowActMM === 'WrongAnswer2') {
				this.setState({
					WrongAnswer2: Object.assign(this.state.WrongAnswer2, {
						fileUrl: mainConfig.imageUploaded + newProps.uploadsData.extra.fileNameList[0],
					}),
				})
			} else if (this.state.upladHowActMM === 'WrongAnswer3') {
				this.setState({
					WrongAnswer3: Object.assign(this.state.WrongAnswer3, {
						fileUrl: mainConfig.imageUploaded + newProps.uploadsData.extra.fileNameList[0],
					}),
				})
			} else {
				this.setState({
					TrueAnswer: Object.assign(this.state.TrueAnswer, {
						fileUrl: mainConfig.imageUploaded + newProps.uploadsData.extra.fileNameList[0],
					}),
				})
			}

			this.setState({ upladHowActMM: '' })
		} else if (newProps.uploadsData.success === false) {
			alertifyjs.error('Hata3: ' + newProps.getQuestionsWithAnswersData.messages)
			this.props.actions.resetAllPostUploadStatuss('')
		}
	}

	onTagsChanged(QuestionTag) {
		this.setState({ QuestionTag })
	}

	setToggle = (e) => {
		this.setState({ toggle: !this.state.toggle })

		if (e) {
			if (this.state.toggle === false) {
				if (e === 'QuestionText') {
					if (
						this.state.QuestionTypeSelection === 'da2decc7-db82-41fa-b3de-67774f168e3f'
					) {
						this.setState({
							madaltitle: 'Soru için Resim Yükleyiniz!',
							upladHowActMM: 'QuestionText',
						})
					} else {
						this.setState({
							madaltitle: 'Soru için Video Yükleyiniz!',
							upladHowActMM: 'QuestionText',
						})
					}
				} else if (e === 'WrongAnswer1') {
					this.setState({
						madaltitle: 'Yanlış Cevap-1 için Resim Yükleyiniz!',
						upladHowActMM: 'WrongAnswer1',
					})
				} else if (e === 'WrongAnswer2') {
					this.setState({
						madaltitle: 'Yanlış Cevap-2 için Resim Yükleyiniz!',
						upladHowActMM: 'WrongAnswer2',
					})
				} else if (e === 'WrongAnswer3') {
					this.setState({
						madaltitle: 'Yanlış Cevap-3 için Resim Yükleyiniz!',
						upladHowActMM: 'WrongAnswer3',
					})
				} else {
					this.setState({
						madaltitle: 'Doğru Cevap için Resim Yükleyiniz!',
						upladHowActMM: 'TrueAnswer',
					})
				}
			} else {
				this.setState({ madaltitle: '' })
			}
		} else {
			this.setState({ madaltitle: '' })
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	setOnChangeOnAnswers = (e) => {
		this.setState({
			[e.target.name]: Object.assign(this.state[e.target.name], {
				text: e.target.value,
				subid: e.target.getAttribute('subid'),
			}),
		})
	}

	mainCallbackFunction = (childData, file) => {
		this.setState({ uploadFireB: childData, uploadFile: file })
	}

	mmFunc = () => {
		this.props.actions.postDatasForUpload(this.state.uploadFile)
	}

	SetNoMM = (data) => {
		if (data === 'QuestionText') {
			this.setState({ fileUrl: null })
		} else if (data === 'WrongAnswer1') {
			this.setState({
				WrongAnswer1: Object.assign(this.state.WrongAnswer1, { fileUrl: null }),
			})
		} else if (data === 'WrongAnswer2') {
			this.setState({
				WrongAnswer2: Object.assign(this.state.WrongAnswer2, { fileUrl: null }),
			})
		} else if (data === 'WrongAnswer3') {
			this.setState({
				WrongAnswer3: Object.assign(this.state.WrongAnswer3, { fileUrl: null }),
			})
		} else {
			this.setState({
				TrueAnswer: Object.assign(this.state.TrueAnswer, { fileUrl: null }),
			})
		}
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.props.actions.resetAllStatuss('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				if (this.props?.location?.state?.actionType != 'update') {
					localStorage.setItem('question_tags', this.state.QuestionTag.join(','))
				}

				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateQuestion({
						id: this.state.updatedId,
						QuestionText: this.state.QuestionText,
						TagText: JSON.stringify(this.state.QuestionTag),
						fileUrl: this.state.fileUrl,
						Answers: [
							{
								AnswerText: this.state.WrongAnswer1.text,
								id: this.state.WrongAnswer1.subid,
								fileUrl: this.state.WrongAnswer1.fileUrl,
							},
							{
								AnswerText: this.state.WrongAnswer2.text,
								id: this.state.WrongAnswer2.subid,
								fileUrl: this.state.WrongAnswer2.fileUrl,
							},
							{
								AnswerText: this.state.WrongAnswer3.text,
								id: this.state.WrongAnswer3.subid,
								fileUrl: this.state.WrongAnswer3.fileUrl,
							},
							{
								AnswerText: this.state.TrueAnswer.text,
								id: this.state.TrueAnswer.subid,
								fileUrl: this.state.TrueAnswer.fileUrl,
								isRight: true,
							},
						],
						rightAnswer: this.state.TrueAnswer.subid,
						QuestionTypeId: this.state.QuestionTypeSelection,
					  })
					: this.props.actions.createQuestion({
						QuestionText: this.state.QuestionText,

						TagText: JSON.stringify(this.state.QuestionTag),
						fileUrl: this.state.fileUrl,
						Answers: [
							{
								AnswerText: this.state.WrongAnswer1.text,
								fileUrl: this.state.WrongAnswer1.fileUrl,
							},
							{
								AnswerText: this.state.WrongAnswer2.text,
								fileUrl: this.state.WrongAnswer2.fileUrl,
							},
							{
								AnswerText: this.state.WrongAnswer3.text,
								fileUrl: this.state.WrongAnswer3.fileUrl,
							},
							{
								AnswerText: '--- ' + this.state.TrueAnswer.text,
								fileUrl: this.state.TrueAnswer.fileUrl,
							},
						],
						QuestionTypeId: this.state.QuestionTypeSelection,
					  })
			}
		}
	}

	validationFunc() {
		if (this.state.QuestionTypeSelection == '-1') {
			alertifyjs.error('Soru Tipi Seçimi Yapılmış Olmalıdır!')
			return false
		}

		if (this.state.QuestionTag.toString().length <= 3) {
			alertifyjs.error('Soru Etiketi Minimum 4 Hane Olmalıdır!')
			return false
		}

		if (this.state.QuestionText.length <= 3) {
			alertifyjs.error('Soru Yazısı Minimum 4 Hane Olmalıdır!')
			return false
		}

		if (
			this.state.TrueAnswer.text.length <= 0 &&
			this.state.TrueAnswer.fileUrl.isEmpty()
		) {
			alertifyjs.error('Doğru Cevap Minimum 1 Hane Olmalıdır!')
			return false
		}

		if (
			this.state.WrongAnswer1.text.length <= 0 &&
			this.state.WrongAnswer1.fileUrl.isEmpty()
		) {
			alertifyjs.error('Yanlış Cevap-1 Minimum 1 Hane Olmalıdır!')
			return false
		}

		if (
			this.state.WrongAnswer2.text.length <= 0 &&
			this.state.WrongAnswer2.fileUrl.isEmpty()
		) {
			alertifyjs.error('Yanlış Cevap-2 Minimum 1 Hane Olmalıdır!')
			return false
		}

		if (
			this.state.WrongAnswer3.text.length <= 0 &&
			this.state.WrongAnswer3.fileUrl.isEmpty()
		) {
			alertifyjs.error('Yanlış Cevap-3 Minimum 1 Hane Olmalıdır!')
			return false
		}

		return true
	}

	getTags() {
		let tags = localStorage.getItem('question_tags').split(',')
		let res = []
		for (let i = 0; i < tags.length; i++) {
			res.push(
				<a
					href="javascript:;"
					onClick={() =>
						this.setState({
							QuestionTag: tags.slice(i, tags.length),
						})
					}
				>
					<div className="input-group mb-1">
						<div className="input-group-prepend">
							<span className="input-group-text">{tags.length - i}</span>
						</div>
						<label className="question-answer form-control">
							{tags.slice(0, tags.length - i).map((tag) => (
								<label
									style={{
										backgroundColor: '#e1e1e1',
										padding: 5,
										borderRadius: 5,
										margin: 2,
									}}
								>
									{tag}
								</label>
							))}
						</label>
					</div>
				</a>
			)
		}
		return res
	}

	isUpdate() {
		return this.props?.location?.state?.actionType == 'update'
	}

	changedQuestionText(e) {
		if (!this.isUpdate() && this.state.QuestionText.isEmpty() && e.content) {
			e.content = e.content.replace(/Times New Roman/g, 'Arial')
			e.content = e.content.replace(/font-size:15px/g, 'font-size:13px')
			e.content = e.content.replace(/font-size:16px/g, 'font-size:13px')
			e.content = e.content.replace(/font-size:17px/g, 'font-size:13px')
		}

		if (e.content == '<p><br /></p>') {
			return
		}

		this.setState({
			QuestionText: e.content,
		})
	}

	render() {
		const vieotypes = ['.mp4', '.avi', '.mpeg']
		const pictypes = ['.jpg', '.gif', '.png', '.gif']

		const { QuestionTag } = this.state

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>

					<Modal isOpen={this.state.toggle} toggle={this.setToggle}>
						<ModalHeader toggle={this.setToggle}>{this.state.madaltitle}</ModalHeader>
						<ModalBody>
							{/* <ImageUploader

                                withIcon={false}
                                buttonText={ this.state.QuestionTypeSelection === "da2decc7-db82-41fa-b3de-67774f168e3f" ? 'Resim Yükle' : 'Video Yükle'}
                                onChange={this.onDrop}
                                //imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={1048576}
                                singleImage={true}
                            /> */}
							<DropZone
								toggle={() => this.setToggle()}
								callback={this.mainCallbackFunction}
								callback2={this.mainCallbackFunction2}
							/>
						</ModalBody>
						{this.state.uploadFireB === true ? (
							<ModalFooter>
								<button
									type="button"
									className="btn btn-success btn-lg btn-block"
									onClick={this.mmFunc}
								>
									Yüklemeyi Onayla
								</button>
							</ModalFooter>
						) : null}
					</Modal>

					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="QuestionTypeSelection">Soru Tipi Seçimi</Label>
										<Input
											type="select"
											name="QuestionTypeSelection"
											id="QuestionTypeSelection"
											onChange={this.setOnChange}
											value={this.state.QuestionTypeSelection}
										>
											<option selected disabled value="-1"></option>
											{this.props.questionTypesData.extra.map((questionType) => {
												return (
													<option value={questionType.id} key={questionType.id}>
														{questionType.typeName}{' '}
													</option>
												)
											})}
										</Input>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="QuestionTag">
											Soru Etiketi
											{this.props?.location?.state?.actionType !== 'update' &&
												localStorage.getItem('question_tags') && (
												<OverlayTrigger
													rootClose
													rootCloseEvent="mousedown"
													trigger="click"
													placement="right"
													overlay={
														<Popover id="popover-history">
															<Popover.Title as="h3">Hızlı Etiket Ekle</Popover.Title>
															<Popover.Content>{this.getTags()}</Popover.Content>
														</Popover>
													}
												>
													<History style={{ marginLeft: 5 }} />
												</OverlayTrigger>
											)}
										</Label>
										<ReactTagInput
											tags={QuestionTag}
											onChange={(newTags) => this.onTagsChanged(newTags)}
											placeholder="Lütfen her tagden son ra enter tusuna basınız!"
										/>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="QuestionText">Soru Belirleyiniz</Label>

										<InputGroup>
											{this.state.QuestionTypeSelection ===
											'da2decc7-db82-41fa-b3de-67774f168e3f' ? (
													<div className="input-group-prepend">
														{this.state.fileUrl !== null ? (
															<Tooltip
																style={{ height: '100%' }}
																tooltip={
																	<button
																		type="button"
																		style={{ height: '100%' }}
																		className={'btn btn-success'}
																		onClick={() => this.SetNoMM('QuestionText')}
																	>
																		<CloudUploadIcon color="light" />
																	</button>
																}
															>
																<img src={this.state.fileUrl} alt="" />
															</Tooltip>
														) : (
															<button
																type="button"
																className={'btn btn-danger'}
																onClick={() => this.setToggle('QuestionText')}
															>
																<CloudUploadIcon color="light" />
															</button>
														)}
													</div>
												) : null}

											{this.state.QuestionTypeSelection ===
											'c80b8f98-51a3-4da1-9a6b-aa463ec6351a' ? (
													<div className="input-group-prepend">
														{this.state.fileUrl !== null ? (
															<Tooltip
																style={{ height: '100%' }}
																tooltip={
																	<button
																		type="button"
																		style={{ height: '100%' }}
																		className={'btn btn-success'}
																		onClick={() => this.SetNoMM('QuestionText')}
																	>
																		<CloudUploadIcon color="light" />
																	</button>
																}
															>
																<iframe
																	title="rickroll"
																	type="text/html"
																	width="100%"
																	height="400px"
																	src="https://www.youtube.com/embed/DLzxrzFCyOs?autoplay=1"
																	frameBorder="0"
																/>
															</Tooltip>
														) : (
															<button
																type="button"
																className={'btn btn-danger'}
																onClick={() => this.setToggle('QuestionText')}
															>
																<CloudUploadIcon color="light" />
															</button>
														)}
													</div>
												) : null}

											{/* <Input
												type="textarea"
												name="QuestionText"
												id="QuestionText"
												placeholder="Lütfen Soruyu Oluşturunuz!"
												value={this.state.QuestionText}
												onChange={this.setOnChange}
												style={{ height: '150px' }}
											/> */}

											<Editor
												id="QuestionText"
												placeholder="Lütfen Soruyu Oluşturunuz!"
												onChange={(e) => this.changedQuestionText(e)}
												content={this.state.QuestionText}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Doğru Cevap</Label>
										<InputGroup>
											{this.state.QuestionTypeSelection ===
											'da2decc7-db82-41fa-b3de-67774f168e3f' ? (
													<div className="input-group-prepend">
														{this.state.TrueAnswer.fileUrl !== null ? (
															<Tooltip
																style={{ height: '100%' }}
																tooltip={
																	<button
																		type="button"
																		style={{ height: '100%' }}
																		className={
																			this.state.TrueAnswer.fileUrl !== null
																				? 'btn btn-success'
																				: 'btn btn-danger'
																		}
																		onClick={() => this.SetNoMM('TrueAnswer')}
																	>
																		<CloudUploadIcon color="light" />
																	</button>
																}
															>
																<img src={this.state.TrueAnswer.fileUrl} alt="" />
															</Tooltip>
														) : (
															<button
																type="button"
																className={
																	this.state.TrueAnswer.fileUrl !== null
																		? 'btn btn-success'
																		: 'btn btn-danger'
																}
																onClick={() => this.setToggle('TrueAnswer')}
															>
																<CloudUploadIcon color="light" />
															</button>
														)}
													</div>
												) : null}
											<Input
												type="textarea"
												name="TrueAnswer"
												id="TrueAnswer"
												subid={this.state.TrueAnswer.subid}
												placeholder="Doğru Cevap Giriniz!"
												value={this.state.TrueAnswer.text}
												onChange={this.setOnChangeOnAnswers}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="WrongAnswer1">Yanlış Cevap</Label>

										<InputGroup>
											{this.state.QuestionTypeSelection ===
											'da2decc7-db82-41fa-b3de-67774f168e3f' ? (
													<div className="input-group-prepend">
														{this.state.WrongAnswer1.fileUrl !== null ? (
															<Tooltip
																style={{ height: '100%' }}
																tooltip={
																	<button
																		type="button"
																		style={{ height: '100%' }}
																		className={
																			this.state.WrongAnswer1.fileUrl !== null
																				? 'btn btn-success'
																				: 'btn btn-danger'
																		}
																		onClick={() => this.SetNoMM('WrongAnswer1')}
																	>
																		<CloudUploadIcon color="light" />
																	</button>
																}
															>
																<img src={this.state.WrongAnswer1.fileUrl} alt="" />
															</Tooltip>
														) : (
															<button
																type="button"
																className={
																	this.state.WrongAnswer1.fileUrl !== null
																		? 'btn btn-success'
																		: 'btn btn-danger'
																}
																onClick={() => this.setToggle('WrongAnswer1')}
															>
																<CloudUploadIcon color="light" />
															</button>
														)}
													</div>
												) : null}

											<Input
												type="textarea"
												name="WrongAnswer1"
												id="WrongAnswer1"
												subid={this.state.WrongAnswer1.subid}
												placeholder="Yanlış Cevap Giriniz!"
												value={this.state.WrongAnswer1.text}
												onChange={this.setOnChangeOnAnswers}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Yanlış Cevap</Label>

										<InputGroup>
											{this.state.QuestionTypeSelection ===
											'da2decc7-db82-41fa-b3de-67774f168e3f' ? (
													<div className="input-group-prepend">
														{this.state.WrongAnswer2.fileUrl !== null ? (
															<Tooltip
																style={{ height: '100%' }}
																tooltip={
																	<button
																		type="button"
																		style={{ height: '100%' }}
																		className={
																			this.state.WrongAnswer2.fileUrl !== null
																				? 'btn btn-success'
																				: 'btn btn-danger'
																		}
																		onClick={() => this.SetNoMM('WrongAnswer2')}
																	>
																		<CloudUploadIcon color="light" />
																	</button>
																}
															>
																<img src={this.state.WrongAnswer2.fileUrl} alt="" />
															</Tooltip>
														) : (
															<button
																type="button"
																className={
																	this.state.WrongAnswer2.fileUrl !== null
																		? 'btn btn-success'
																		: 'btn btn-danger'
																}
																onClick={() => this.setToggle('WrongAnswer2')}
															>
																<CloudUploadIcon color="light" />
															</button>
														)}
													</div>
												) : null}

											<Input
												type="textarea"
												name="WrongAnswer2"
												id="WrongAnswer2"
												subid={this.state.WrongAnswer2.subid}
												placeholder="Yanlış Cevap Giriniz!"
												value={this.state.WrongAnswer2.text}
												onChange={this.setOnChangeOnAnswers}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Yanlış Cevap</Label>
										<InputGroup>
											{this.state.QuestionTypeSelection ===
											'da2decc7-db82-41fa-b3de-67774f168e3f' ? (
													<div className="input-group-prepend">
														{this.state.WrongAnswer3.fileUrl !== null ? (
															<Tooltip
																style={{ height: '100%' }}
																tooltip={
																	<button
																		type="button"
																		style={{ height: '100%' }}
																		className={
																			this.state.WrongAnswer3.fileUrl !== null
																				? 'btn btn-success'
																				: 'btn btn-danger'
																		}
																		onClick={() => this.SetNoMM('WrongAnswer3')}
																	>
																		<CloudUploadIcon color="light" />
																	</button>
																}
															>
																<img src={this.state.WrongAnswer3.fileUrl} alt="" />
															</Tooltip>
														) : (
															<button
																type="button"
																className={
																	this.state.WrongAnswer3.fileUrl !== null
																		? 'btn btn-success'
																		: 'btn btn-danger'
																}
																onClick={() => this.setToggle('WrongAnswer3')}
															>
																<CloudUploadIcon color="light" />
															</button>
														)}
													</div>
												) : null}

											<Input
												type="textarea"
												name="WrongAnswer3"
												id="WrongAnswer3"
												subid={this.state.WrongAnswer3.subid}
												placeholder="Yanlış Cevap Giriniz!"
												value={this.state.WrongAnswer3.text}
												onChange={this.setOnChangeOnAnswers}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>

							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Soru'yu Güncelle
								</Button>
							) : (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Soru'yu Oluştur
								</Button>
							)}
						</Form>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		questionTypesData: state.questionTypeReducer,

		questionsData: state.questionsReducer,
		getQuestionsWithAnswersData: state.getQuestionsWithAnswersReducer,

		uploadsData: state.uploadReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			getQuestionTypes: bindActionCreators(
				questionTypeActions.getQuestionTypes,
				dispatch
			),
			getQuestionsWithAnswer: bindActionCreators(
				getQuestionWithAnswersActions.getQuestionWithAnswers,
				dispatch
			),
			createQuestion: bindActionCreators(questionActions.createQuestion, dispatch),
			resetAllStatuss: bindActionCreators(questionActions.resetAllStatuss, dispatch),
			updateQuestion: bindActionCreators(questionActions.updateQuestion, dispatch),

			resetQuestionWithAnswersStatuss: bindActionCreators(
				getQuestionWithAnswersActions.resetQuestionWithAnswersStatuss,
				dispatch
			),

			postDatasForUpload: bindActionCreators(
				uploadActions.postDatasForUpload,
				dispatch
			),
			resetAllPostUploadStatuss: bindActionCreators(
				uploadActions.resetAllPostUploadStatuss,
				dispatch
			),
		},
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(QuestionsForm)
)
