import React from 'react'
import { Button, Badge } from 'reactstrap'

const Component = (props) => {
	return (
		props.questions &&
		props.questions.map((question, i) => (
			<QuestionCardItem
				question={question}
				order={i}
				bottomComponent={props.bottomComponent}
			/>
		))
	)
}

export const QuestionCardItem = (props) => {
	const order = props.question && (props.question.orderCount || props.order)
	const question = props.question && (props.question.question || props.question)
	const answers = props.question && props.question.answers

	const questionType = question.questionType

	const navigateToExamUpdate = (row, navigation) => {
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'update',
			},
		}

		props.history.push(location)
	}

	const checkRightAnswer = (answer) => {
		return answer.id == question.rightAnswer
	}

	const getRightAnswerCssClass = (answer) =>
		checkRightAnswer(answer) ? 'alert-success' : ''

	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col">
						<h5 className="card-title">
							<div className="row">
								{!props.hideQuestionNumber && order >= 0 ? (
									<div className="col-auto">
										<strong>{order}) </strong>
									</div>
								) : null}
								<div className="col">
									<div dangerouslySetInnerHTML={{ __html: question.questionText }}></div>
									{questionType == 'Resimli SORU' && <img src={question.fileUrl} />}
								</div>
							</div>
						</h5>
						{answers &&
							answers.map((answer, i) => (
								<div className="input-group mb-1" key={`answer_${answer.id}`}>
									<div className="input-group-prepend">
										<span className={`input-group-text ${getRightAnswerCssClass(answer)}`}>
											{i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : i === 3 ? 'D' : '?'}
										</span>
									</div>
									<label
										className={`question-answer form-control ${getRightAnswerCssClass(
											answer
										)}`}
									>
										<img src={answer.fileUrl} style={{ maxHeight: 100 }} />
										{answer.answerText}
									</label>
								</div>
							))}
						<div className="row">
							<div className="col">
								<Badge>{questionType}</Badge>
								{question.timeOut ? (
									<Badge className="ml-2">{question.timeOut}sn</Badge>
								) : null}
								{question.point ? (
									<Badge className="ml-2">{question.point} Puan</Badge>
								) : null}
								{props.updateQuestionPerm && (
									<Button
										className="float-right"
										size="sm"
										color="warning"
										onClick={() =>
											navigateToExamUpdate(question.question, 'AddOrUpdateQuestions')
										}
									>
										Soru Düzenle
									</Button>
								)}
							</div>
						</div>
					</div>
					{props.bottomComponent && (
						<div className={props.bottomComponentPositon == 'right' ? 'col-12' : 'col'}>
							{props.bottomComponent}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export const QuestionCard = Component
