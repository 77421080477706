//import * as actions from './actions';
import {
	CREATE_QUESTION_SUCCESS,
	GET_FULL_QUESTION_SUCCESS,
	GET_QUESTION_SUCCESS,
	DELETE_QUESTION_SUCCESS,
	UPDATE_QUESTION_SUCCESS,
	DELETE_QUESTION_SUCCESS_STATUSS,
	RESET_STATUSS_QUESTION,
	ONFAULT_QUESTION,
	RESET_GET_FULL_QUESTION_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createQuestionSuccess = (QuestionData) => {
	return {
		type: CREATE_QUESTION_SUCCESS,
		payload: QuestionData,
	}
}

export const getQuestionSuccess = (QuestionData) => {
	return {
		type: GET_QUESTION_SUCCESS,
		payload: QuestionData,
	}
}

export const getFullQuestionsSuccess = (QuestionData) => {
	return {
		type: GET_FULL_QUESTION_SUCCESS,
		payload: QuestionData,
	}
}

export const resetGetFullQuestionSuccess = (QuestionData) => {
	return {
		type: RESET_GET_FULL_QUESTION_SUCCESS,
		payload: QuestionData,
	}
}

export const deleteQuestionSuccess = (QuestionData) => {
	return {
		type: DELETE_QUESTION_SUCCESS,
		payload: QuestionData,
	}
}

export const updateQuestionSuccess = (QuestionData) => {
	return {
		type: UPDATE_QUESTION_SUCCESS,
		payload: QuestionData,
	}
}

export const changeDeletedQuestionSuccessStatus = (data) => {
	return {
		type: DELETE_QUESTION_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_QUESTION,
		payload: data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_QUESTION,
		payload: data,
	}
}

export const createQuestion = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Questions/Add', data)
		.then((res) => {
			const AddedQuestion = res.data
			dispatch(createQuestionSuccess(AddedQuestion))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getQuestions = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Questions/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const QuestionData = res.data
			const { success } = res.data

			if (success === true) {
				// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
				dispatch(getQuestionSuccess(QuestionData))
			} else {
				const { errorMessage } = res.data
				var ffData = {
					success: false,
					messages: errorMessage,
				}
				dispatch(onFault(ffData))
			}
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullQuestions = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Questions/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullQuestionssData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullQuestionsSuccess(fullQuestionssData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteQuestion = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Questions/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedQuestion = res.data

			dispatch(deleteQuestionSuccess(deletedQuestion))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteQuestion = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Questions/FullDelete'
	if (data.id) {
		url = url + '?Id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(deleteQuestionSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateQuestion = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Questions/Update'
	axios
		.put(url, data)
		.then((res) => {
			const updated = res.data
			const { success } = res.data
			if (success === true) {
				dispatch(updateQuestionSuccess(updated))
			} else {
				const { errorMessage } = res.data
				var ffData = {
					success: false,
					messages: errorMessage,
				}
				dispatch(onFault(ffData))
			}
		})
		.catch((err) => {
			if (err.response.data) {
				var ffData11 = {
					success: false,
					messages: err.response.data.errorMessage,
				}
				dispatch(onFault(ffData11))
			} else {
				var ffData10 = {
					success: false,
					messages: 'bilinmeyen hata!',
				}
				dispatch(onFault(ffData10))
			}
		})
}
