/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as answerActions from '../../../redux/actions/answerActions'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'






class Answers extends Component {
	constructor(props) {
		super(props)

		this.state = {
			dataChart: [
				{
					name: 'Matematik',
					Adet: 5,
				},
				{
					name: 'İngilizce',
					Adet: 10,
				},
				{
					name: 'Almanca',
					Adet: 50,
				},
				{
					name: 'Fransızca',
					Adet: 20,
				},
			],

			AnswersColumns: [
				{
					name: 'Cevap',
					selector: 'answerText',
					sortable: true,
				},
				{
					name: 'QuestionId',
					selector: 'questionId',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateAnswer
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateAnswers')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteAnswer
						) === true ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
			],

			FullAnswerColumns: [
				{
					name: 'Cevap',
					selector: 'answerText',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateAnswer
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateAnswers')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteAnswer
						) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
			],
		}
	}

	componentDidMount() {
		this.props.actions.getAnswers()
		this.props.actions.getFullAnswers()

		this.setState({ AnswerListData_onQuery: this.props.answers.extra })
		this.setState({ AnswerFullListData_onQuery: this.props.fullAnswers.extra })

		this.props.actions.changeDeletedAnswerSuccessStatus('')
		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.answers.deleted) {
			alertifyjs.success('Ders Başarılı Olarak Silindi!')
			newProps.actions.changeDeletedAnswerSuccessStatus('')
			newProps.actions.resetAllStatuss('')
			newProps.actions.getAnswers()
			newProps.actions.getFullAnswers()
		} else if (newProps.answers.deleted === false) {
			alertifyjs.error('Ders Silinirken Hatalar Oluştu.')
			newProps.actions.changeDeletedAnswerSuccessStatus('')
			newProps.actions.resetAllStatuss('')
		}

		this.setState({ AnswerListData_onQuery: newProps.answers.extra })
		this.setState({ AnswerFullListData_onQuery: newProps.fullAnswers.extra })
	}

	selectNavigation = (navigation) => {
		this.props.actions.changeDeletedAnswerSuccessStatus('')
		this.props.actions.resetAllStatuss('')

		var location = {
			pathname: navigation,
			state: {},
		}

		history(location)
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var AnswerListData_onQuery = this.state.AnswerListData_onQuery.filter((item) =>
				item.answerText.like(e.target.value)
			)
			this.setState({ AnswerListData_onQuery })
		} else {
			this.setState({ AnswerListData_onQuery: this.props.answers.extra })
		}
	}

	searchOnFullList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var AnswerFullListData_onQuery = this.state.AnswerFullListData_onQuery.filter(
				(item) => item.answerText.like(e.target.value)
			)
			this.setState({ AnswerFullListData_onQuery })
		} else {
			this.setState({ AnswerFullListData_onQuery: this.props.fullAnswers.extra })
		}
	}

	handleClick = (state) => {
		alert('sfsdfsdf')
	}

	onRowClickUpdate = (row, navigation) => {
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'update',
			},
		}

		history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteAnswer(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteAnswer(data)
		}
	}

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Cevaplar</h4>
						</FormGroup>
						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.AnswersColumns}
						data={this.state.AnswerListData_onQuery}
						selectableRows
						pagination
						highlightOnHover
						progressPending={this.props.answers.extra.length > 0 ? false : true}
						subHeader
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddAnswer
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateAnswers')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindAnswer
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
							</div>
						}
						subHeaderAlign={'left'}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Cevaplar</h4>
						</FormGroup>
						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullAnswerColumns}
						data={this.state.AnswerFullListData_onQuery}
						selectableRows
						pagination
						highlightOnHover
						progressPending={this.props.fullAnswers.extra.length > 0 ? false : true}
						subHeader
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddAnswer
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateAnswers')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindFullListAnswer
								) === true ? (
										<TextField
											onChange={this.searchOnFullList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
							</div>
						}
						subHeaderAlign={'left'}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListAnswer
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListAnswer
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		answers: state.answerReducer,
		fullAnswers: state.fullAnswerReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			getAnswers: bindActionCreators(answerActions.getAnswers, dispatch),
			getFullAnswers: bindActionCreators(answerActions.getFullAnswers, dispatch),
			deleteAnswer: bindActionCreators(answerActions.deleteAnswer, dispatch),
			fullDeleteAnswer: bindActionCreators(answerActions.fullDeleteAnswer, dispatch),
			createAnswer: bindActionCreators(answerActions.createAnswer, dispatch),
			changeDeletedAnswerSuccessStatus: bindActionCreators(
				answerActions.changeDeletedAnswerSuccessStatus,
				dispatch
			),
			resetAllStatuss: bindActionCreators(answerActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Answers))
