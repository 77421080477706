import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function usersNotAssignedReducer(
  state = initialState.ManagersAssignedNot,
  action
) {
  switch (action.type) {
    case actionTypes.GET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL:
      return {
        ...state,
        success: action.payload.success,
        extra: action.payload.extra,
      }

    case actionTypes.RESET_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL:
      return {
        ...state,
        success: "",
        deleted: "",
        Updatedsuccess: "",
      }

    case actionTypes.ONFAULT_NOT_ASSIGNEDS_MANAGERS_ON_SCHOOL:
      return {
        ...state,
        success: false,
        messages: action.payload.messages,
      }

    default:
      return state
  }
}
