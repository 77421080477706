import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function messageReducer(
  state = initialState.messagesInitial,
  action
) {
  switch (action.type) {
    case actionTypes.GETED_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        successGettedMessages: action.payload.success,
        extraGettedMessages: action.payload.extra,
      }

    case actionTypes.SENDED_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        successSendedMessages: action.payload.success,
        extraSendedMessages: action.payload.extra,
      }

    case actionTypes.UPDATE_MESSAGES_SUCCESS:
      return {
        ...state,
        successUpdateMessages: action.payload.success,
      }

    case actionTypes.CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        successCreateMessage: action.payload.success,
      }

    case actionTypes.GET_ALL_RECEIVERS_SUCCESS:
      return {
        ...state,
        successReceivers: action.payload.success,
        extraReceivers: action.payload.extra,
      }

    //******************************************************* */
    case actionTypes.RESET_GETED_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        successGettedMessages: "",
      }

    case actionTypes.RESET_SENDED_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        successSendedMessages: "",
      }

    case actionTypes.RESET_UPDATE_MESSAGES_SUCCESS:
      return {
        ...state,
        successUpdateMessages: "",
      }

    case actionTypes.RESET_CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        successCreateMessage: "",
      }

    case actionTypes.RESET_GET_ALL_RECEIVERS_SUCCESS:
      return {
        ...state,
        successReceivers: "",
      }

    case actionTypes.FULL_RESET_MESSAGES:
      return {
        ...state,
        successGettedMessages: "",
        successSendedMessages: "",
        successUpdateMessages: "",
        successCreateMessage: "",
        successReceivers: "",
      }

    //******************************************************* */

    case actionTypes.GETTED_ALL_MESSAGES_FAULT:
      return {
        ...state,
        successGettedMessages: false,
        messages: action.payload.messages,
      }

    case actionTypes.SENDED_ALL_MESSAGES_FAULT:
      return {
        ...state,
        successSendedMessages: false,
        messages: action.payload.messages,
      }

    case actionTypes.UPDATE_MESSAGES_FAULT:
      return {
        ...state,
        successUpdateMessages: false,
        messages: action.payload.messages,
      }

    case actionTypes.CREATE_MESSAGE_FAULT:
      return {
        ...state,
        successCreateMessage: false,
        messages: action.payload.messages,
      }

    case actionTypes.GET_ALL_RECEIVERS_FAULT:
      return {
        ...state,
        successReceivers: false,
        messages: action.payload.messages,
      }
    //******************************************************* */
    default:
      return state
  }
}
