import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import 'font-awesome/css/font-awesome.min.css'

import Dashboard from '../root/Dashboard'
import NotAuth from './notAuth'

import * as authActions from '../../redux/actions/authActions'

console.disableYellowBox = true

class App extends Component {
	constructor(props) {
		super(props)

		this.state = {
			propsOldFont: {},
		}
	}

	componentWillMount() {
		var Color = this.props.userAuthData.settings.color
		var font = this.props.userAuthData.settings.font

		this.setState({ propsOldSettings: this.props.userAuthData.settings })

		require('../../../dist/sidenav/react-sidenav_' + Color + '_' + font + '.css')
		require('../../../dist/css/myadmin_' + font + '.css')
		require('../../../dist/login/css/loglog_' + font + '.css')
	}

	login_compdirect() {
		return <NotAuth />
	}

	directapp() {
		return (
			<div>
				<Dashboard />
			</div>
		)
	}
	/******************************************************************************** */
	render() {
		const divStyle = {
			color: 'gray',
			backgroundImage: "url('https://source.unsplash.com/collection/4917894/')",
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			width: '100vw',
			height: '100vh',
		}

		return (
			<div style={divStyle}>
				<link
					rel="stylesheet"
					href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
					integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
					crossOrigin="anonymous"
				/>

				<link
					href="https://fonts.googleapis.com/css2?family=Andika&family=Armata&family=Gloria+Hallelujah&family=Marmelad&family=Trocchi&display=swap,latin-ext"
					rel="stylesheet"
				/>

				{this.props.userAuthData.isAuthenticated === true
					? this.directapp()
					: this.login_compdirect()}
			</div>
		)
	}
}

// connect componenet with redux store
function mapStatetoProps(state) {
	return {
		userAuthData: state.authReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			resetAuthSuccessStatuss: bindActionCreators(
				authActions.resetAuthSuccessStatuss,
				dispatch
			),
		},
	}
}

export default connect(mapStatetoProps, mapDispatchToProps)(App)
