import {
	GET_ALL_MANAGERS_ON_SCHOOL_SUCCESS,
	RESET_ALL_MANAGERS_ON_SCHOOL,
	ONFAULT_ALL_MANAGERS_ON_SCHOOL,
	MANAGERS_ON_SCHOOL_ADD_SUCCESS,
	MANAGERS_ON_SCHOOL_REMOVE_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const getManagerOnSchoolSuccess = (examData) => {
	return {
		type: GET_ALL_MANAGERS_ON_SCHOOL_SUCCESS,
		payload: examData,
	}
}

export const resetManagerOnSchoolSuccess = (examData) => {
	return {
		type: RESET_ALL_MANAGERS_ON_SCHOOL,
		payload: examData,
	}
}

export const onManagerFaultSchoolSuccess = (data) => {
	return {
		type: ONFAULT_ALL_MANAGERS_ON_SCHOOL,
		payload: data,
	}
}

export const addManagerSchoolSuccess = (data) => {
	return {
		type: MANAGERS_ON_SCHOOL_ADD_SUCCESS,
		payload: data,
	}
}

export const removeManagerFromSchoolSuccess = (data) => {
	return {
		type: MANAGERS_ON_SCHOOL_REMOVE_SUCCESS,
		payload: data,
	}
}

export const getManagerOnSchool = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/GetUsersBySchool'

	if (data.id) {
		url = url + '?id=' + data.id + '&RoleId=' + data.RoleId
	}
	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getManagerOnSchoolSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}

			dispatch(onManagerFaultSchoolSuccess(ffData))
		})
}

export const addManagerSchool = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Schools/AddUser'

	if (data.AddUserId) {
		url = url + '?AddUserId=' + data.AddUserId + '&SchoolId=' + data.SchoolId
	}

	axios
		.post(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(addManagerSchoolSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onManagerFaultSchoolSuccess(ffData))
		})
}
//http://localhost:1292/api/Classrooms/AddUser?    ClassroomId=DE273DED-2F45-4A35-A376-230249128FDF   &AddUserId=016a6b18-f515-4b4b-b84c-17c62019ef44

export const removeManagerFromSchoolroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Schools/RemoveUser'

	if (data.RemoveUserId) {
		url = url + '?RemoveUserId=' + data.RemoveUserId + '&SchoolId=' + data.SchoolId
	}

	axios
		.post(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(removeManagerFromSchoolSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onManagerFaultSchoolSuccess(ffData))
		})
}
