import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function getExamsReducer(
  state = initialState.gettedExamsById,
  action
) {
  switch (action.type) {
    case actionTypes.GET_EXAM_BY_ID_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        extra: action.payload.extra,
      }

    case actionTypes.UPDATE_EXAM_BY_ID_SUCCESS:
      return {
        ...state,
        Updatedsuccess: action.payload.success,
      }

    case actionTypes.RESET_EXAM_BY_ID_SUCCESS:
      return {
        ...state,
        success: "",
        deleted: "",
        Updatedsuccess: "",
      }

    case actionTypes.ONFAULT_EXAM_BY_ID_SUCCESS:
      return {
        ...state,
        success: false,
        messages: action.payload.messages,
      }

    default:
      return state
  }
}
