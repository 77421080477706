import {
	CREATE_ANSWER_SUCCESS,
	GET_FULL_ANSWER_SUCCESS,
	GET_ANSWER_SUCCESS,
	DELETE_ANSWER_SUCCESS,
	UPDATE_ANSWER_SUCCESS,
	DELETE_ANSWER_SUCCESS_STATUSS,
	RESET_STATUSS_ANSWER,
	ONFAULT_ANSWER,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createAnswerSuccess = (AnswerData) => {
	return {
		type: CREATE_ANSWER_SUCCESS,
		payload: AnswerData,
	}
}

export const getAnswerSuccess = (AnswerData) => {
	return {
		type: GET_ANSWER_SUCCESS,
		payload: AnswerData,
	}
}

export const getFullAnswersSuccess = (AnswerData) => {
	return {
		type: GET_FULL_ANSWER_SUCCESS,
		payload: AnswerData,
	}
}

export const deleteAnswerSuccess = (AnswerData) => {
	return {
		type: DELETE_ANSWER_SUCCESS,
		payload: AnswerData,
	}
}

export const updateAnswerSuccess = (AnswerData) => {
	return {
		type: UPDATE_ANSWER_SUCCESS,
		payload: AnswerData,
	}
}

export const changeDeletedAnswerSuccessStatus = (data) => {
	return {
		type: DELETE_ANSWER_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_ANSWER,
		payload: data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_ANSWER,
		payload: data,
	}
}

export const createAnswer = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Answers/Add', data)
		.then((res) => {
			const AddedAnswer = res.data
			dispatch(createAnswerSuccess(AddedAnswer))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getAnswers = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Answers/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const AnswerData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getAnswerSuccess(AnswerData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullAnswers = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Answers/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullAnswerssData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullAnswersSuccess(fullAnswerssData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteAnswer = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Answers/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedAnswer = res.data

			dispatch(deleteAnswerSuccess(deletedAnswer))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteAnswer = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Answers/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedAnswer = res.data

			dispatch(deleteAnswerSuccess(deletedAnswer))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateAnswer = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Answers/Update'
	// if (data.id) {
	//     url = url + "?id=" + data.id;
	// }
	axios
		.put(url, data)
		.then((res) => {
			const updatedAnswer = res.data

			dispatch(updateAnswerSuccess(updatedAnswer))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
