/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as questionTypeActions from '../../../redux/actions/questionTypeActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'







class Questions extends Component {
	constructor(props) {
		super(props)

		this.state = {
			dataChart: [
				{
					name: 'Matematik',
					Adet: 5,
				},
				{
					name: 'İngilizce',
					Adet: 10,
				},
				{
					name: 'Almanca',
					Adet: 50,
				},
				{
					name: 'Fransızca',
					Adet: 20,
				},
			],

			QuestionsColumns: [
				{
					name: 'Soru Tipi',
					selector: 'typeName',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateQuestionType
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateQuestionTypes')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateQuestionType
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteQuestionType
						) === true ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteQuestionType
						) == true
							? '50px'
							: '0px',
				},
			],

			FullQuestionColumns: [
				{
					name: 'Soru Tipi',
					selector: 'typeName',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateQuestionType
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateQuestionTypes')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateQuestionType
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteQuestionType
						) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteQuestionType
						) == true
							? '65px'
							: '0px',
				},
			],
		}
	}
	//************************************************************************************* */
	//************************************************************************************* */

	componentDidMount() {
		//this.props.actions.createQuestionType("deneme");
		this.props.actions.getQuestionTypes()
		this.props.actions.getFullQuestionTypes()

		this.setState({ QuestionListData_onQuery: this.props.questionTypess.extra })
		this.setState({
			QuestionFullListData_onQuery: this.props.fullQuestionTypes.extra,
		})

		this.props.actions.changeDeletedQuestionTypeSuccessStatus('')
		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.questionTypess.deleted) {
			alertifyjs.success('Ders Başarılı Olarak Silindi!')
			newProps.actions.changeDeletedQuestionTypeSuccessStatus('')
			newProps.actions.resetAllStatuss('')
			newProps.actions.getQuestionTypes()
			newProps.actions.getFullQuestionTypes()
		} else if (newProps.questionTypess.deleted === false) {
			alertifyjs.error('Ders Silinirken Hatalar Oluştu.')
			newProps.actions.changeDeletedQuestionTypeSuccessStatus('')
			newProps.actions.resetAllStatuss('')
		}

		//************************************************************************************* */
		//************************************************************************************* */
		//************************************************************************************* */
		//************************************************************************************* */

		if (newProps.questionTypess.success) {
			this.setState({ QuestionListData_onQuery: newProps.questionTypess.extra })
			newProps.actions.resetAllStatuss('')
		} else if (newProps.questionTypess.success === false) {
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.fullQuestionTypes.deleted) {
			this.setState({
				QuestionFullListData_onQuery: newProps.fullQuestionTypes.extra,
			})
			newProps.actions.resetGetFullQuestionTypesSuccess()
		} else if (newProps.fullQuestionTypes.deleted === false) {
			newProps.actions.resetGetFullQuestionTypesSuccess('')
		}
	}

	//************************************************************************************* */
	//************************************************************************************* */

	// onPush(to2) {
	//     navigate(to2);
	// }

	selectNavigation = (navigation) => {
		this.props.actions.changeDeletedQuestionTypeSuccessStatus('')
		this.props.actions.resetAllStatuss('')

		var location = {
			pathname: navigation,
		}

		this.props.history.push(location)
	}

	handleClick = (state) => {}

	onRowClickUpdate = (row, navigation) => {
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteQuestionType(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteQuestionType(data)
		}
	}

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Sorutipi</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.QuestionsColumns}
						data={this.state.QuestionListData_onQuery}
						pagination={true}
						noHeader
						highlightOnHover={true}
						progressPending={this.props.questionTypess.extra.length > 0 ? false : true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddQuestionType
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateQuestionTypes')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Sorutipi</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullQuestionColumns}
						data={this.state.QuestionFullListData_onQuery}
						pagination={true}
						noHeader
						highlightOnHover={true}
						progressPending={this.props.fullQuestionTypes.extra.length > 0 ? false : true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddQuestionType
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateQuestionTypes')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}
							</div>
						}
						subHeaderAlign={'left'}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListQuestionType
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListQuestionType
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		questionTypess: state.questionTypeReducer,
		fullQuestionTypes: state.fullQuestionTypeReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getQuestionTypes: bindActionCreators(
				questionTypeActions.getQuestionTypes,
				dispatch
			),

			getFullQuestionTypes: bindActionCreators(
				questionTypeActions.getFullQuestionTypes,
				dispatch
			),
			resetGetFullQuestionTypesSuccess: bindActionCreators(
				questionTypeActions.resetGetFullQuestionTypesSuccess,
				dispatch
			),

			deleteQuestionType: bindActionCreators(
				questionTypeActions.deleteQuestionType,
				dispatch
			),
			fullDeleteQuestionType: bindActionCreators(
				questionTypeActions.fullDeleteQuestionType,
				dispatch
			),
			createQuestionType: bindActionCreators(
				questionTypeActions.createQuestionType,
				dispatch
			),
			changeDeletedQuestionTypeSuccessStatus: bindActionCreators(
				questionTypeActions.changeDeletedQuestionTypeSuccessStatus,
				dispatch
			),
			resetAllStatuss: bindActionCreators(
				questionTypeActions.resetAllStatuss,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Questions))
