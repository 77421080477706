import 'alertifyjs/build/css/alertify.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'plyr-react/dist/plyr.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/alertifyjs/build/css/themes/default.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './components/root/App';
import './css/custom.css';
import configureStore from './redux/reducers/configureStore';
import reportWebVitals from './reportWebVitals';
import './utils/prototypes/string/isEmpty';
import './utils/prototypes/string/search';

const store = configureStore()

ReactDOM.render(
  <BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
