import {
	GETED_ALL_MESSAGES_SUCCESS,
	SENDED_ALL_MESSAGES_SUCCESS,
	UPDATE_MESSAGES_SUCCESS,
	CREATE_MESSAGE_SUCCESS,
	GET_ALL_RECEIVERS_SUCCESS,
	RESET_GETED_ALL_MESSAGES_SUCCESS,
	RESET_SENDED_ALL_MESSAGES_SUCCESS,
	RESET_UPDATE_MESSAGES_SUCCESS,
	RESET_CREATE_MESSAGE_SUCCESS,
	RESET_GET_ALL_RECEIVERS_SUCCESS,
	GETTED_ALL_MESSAGES_FAULT,
	SENDED_ALL_MESSAGES_FAULT,
	UPDATE_MESSAGES_FAULT,
	CREATE_MESSAGE_FAULT,
	GET_ALL_RECEIVERS_FAULT,
	FULL_RESET_MESSAGES,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const gettedAllMessageSuccess = (data) => {
	return {
		type: GETED_ALL_MESSAGES_SUCCESS,
		payload: data,
	}
}

export const sendedAllMessageSuccess = (data) => {
	return {
		type: SENDED_ALL_MESSAGES_SUCCESS,
		payload: data,
	}
}

export const updateMessageSuccess = (data) => {
	return {
		type: UPDATE_MESSAGES_SUCCESS,
		payload: data,
	}
}

export const createMessageSuccess = (data) => {
	return {
		type: CREATE_MESSAGE_SUCCESS,
		payload: data,
	}
}

export const getAllReceiversSuccess = (data) => {
	return {
		type: GET_ALL_RECEIVERS_SUCCESS,
		payload: data,
	}
}

//************************************ */ RESETS

export const resetGettedAllMessageSuccess = (data) => {
	return {
		type: RESET_GETED_ALL_MESSAGES_SUCCESS,
		payload: data,
	}
}

export const resetSendedAllMessageSuccess = (data) => {
	return {
		type: RESET_SENDED_ALL_MESSAGES_SUCCESS,
		payload: data,
	}
}

export const resetUpdateMessageSuccess = (data) => {
	return {
		type: RESET_UPDATE_MESSAGES_SUCCESS,
		payload: data,
	}
}

export const resetCreateMessageSuccess = (data) => {
	return {
		type: RESET_CREATE_MESSAGE_SUCCESS,
		payload: data,
	}
}

export const ResetGetAllReceiversSuccess = (data) => {
	return {
		type: RESET_GET_ALL_RECEIVERS_SUCCESS,
		payload: data,
	}
}

export const fullResetMessages = (data) => {
	return {
		type: FULL_RESET_MESSAGES,
		payload: data,
	}
}
//************************************ */ Faults

export const gettedAllMessageFault = (data) => {
	return {
		type: GETTED_ALL_MESSAGES_FAULT,
		payload: data,
	}
}

export const sendedAllMessageFault = (data) => {
	return {
		type: SENDED_ALL_MESSAGES_FAULT,
		payload: data,
	}
}

export const updateMessageFault = (data) => {
	return {
		type: UPDATE_MESSAGES_FAULT,
		payload: data,
	}
}

export const createMessageFault = (data) => {
	return {
		type: CREATE_MESSAGE_FAULT,
		payload: data,
	}
}

export const getAllReceiversFault = (data) => {
	return {
		type: GET_ALL_RECEIVERS_FAULT,
		payload: data,
	}
}

export const gettedAllMessage = () => (dispatch) => {
	setAuthHeader()

	var url = '/api/Messages/ReceivedMessage'

	axios
		.get(url)
		.then((res) => {
			const ResData = res.data
			dispatch(gettedAllMessageSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(gettedAllMessageFault(ffData))
		})
}

export const sendedAllMessage = () => (dispatch) => {
	setAuthHeader()

	var url = '/api/Messages/SendedMessage'

	axios
		.get(url)
		.then((res) => {
			const ResData = res.data
			dispatch(sendedAllMessageSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(sendedAllMessageFault(ffData))
		})
}

export const updateMessage = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Messages/ReadMessage'

	if (data.MessageId) {
		url = url + '?MessageId=' + data.MessageId
	}

	axios
		.put(url, data)
		.then((res) => {
			const ResData = res.data
			dispatch(updateMessageSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(updateMessageFault(ffData))
		})
}

export const createMessage = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Messages/Add'

	axios
		.post(url, data)
		.then((res) => {
			const ResData = res.data

			dispatch(createMessageSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(createMessageFault(ffData))
		})
}

export const getAllReceivers = () => (dispatch) => {
	setAuthHeader()

	var url = '/api/Users/GetBySchool'

	axios
		.get(url)
		.then((res) => {
			const ResData = res.data

			dispatch(getAllReceiversSuccess(ResData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(getAllReceiversFault(ffData))
		})
}
