import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import validator from 'validator'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as roleActions from '../../../redux/actions/roleActions'
import * as usserActions from '../../../redux/actions/usserActions'
import { ScrollView } from '../base/scroll'



class UsersForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			password: '',
			passwordType: 'password',
			roleId: '',
		}
	}

	componentWillMount() {
		this.props.actions.getRoles()
	}

	componentDidMount() {
		this.props?.location?.state?.actionType === 'update'
			? this.setState({
				firstName: this.props.location.state.data.firstName,
				lastName: this.props.location.state.data.lastName,
				phone: this.props.location.state.data.phone,
				email: this.props.location.state.data.email,
				roleId: this.props.location.state.data.roleId,
			  })
			: this.setState({ firstName: '', lastName: '', phone: '', email: '' })

		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.usserData.success) {
			var location = {
				pathname: 'Kullanıcılar',
			}

			this.props.history.push(location)
		} else if (newProps.usserData.success === false) {
			alertifyjs.error('Hata: ' + newProps.usserData.messages)
		}
		if (
			newProps.roleReducerData.successGettedRoles &&
			this.props?.location?.state?.actionType != 'update'
		) {
			try {
				this.setState({
					roleId: newProps.roleReducerData.extraGettedRoles.find(
						(ur) => ur.roleName == 'Öğrenci'
					).id,
				})
			} catch (err) {
				this.setState({ roleId: '-1' })
			}

			this.props.actions.resetAllStatussRole('')
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.props.actions.resetAllStatuss()

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			var data = {
				FirstName: this.state.firstName,
				LastName: this.state.lastName,
				Email: this.state.email,
				Phone: this.state.phone,
				Password: this.state.password,
				RoleId: this.state.roleId,
			}

			this.props?.location?.state?.actionType === 'update'
				? this.props.actions.updateUsser({
					FirstName: this.state.firstName,
					LastName: this.state.lastName,
					Email: this.state.email,
					Phone: this.state.phone,
					Password: this.state.password,
					RoleId: this.state.roleId,
					id: this.props.location.state.data.id,
				  })
				: this.props.actions.createUsser(data)
		}
	}

	validationFunc() {
		if (this.state.firstName.length > 3) {
			if (this.state.lastName.length > 2) {
				if (validator.isEmail(this.state.email)) {
					return true
				} else {
					alertifyjs.error('Email Hatalı!')
					return false
				}
			} else {
				alertifyjs.error('Soyad Hatalı!')
				return false
			}
		} else {
			alertifyjs.error('Ad Hatalı!')
			return true
		}
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>
					{this.props.fullLessonData}

					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Rol</Label>
										<select
											className="form-control"
											name="roleId"
											id="roleId"
											onChange={this.setOnChange}
											value={this.state.roleId}
										>
											{this.props.roleReducerData.extraGettedRoles.map((role) => (
												<option value={role.id}>{role.roleName}</option>
											))}
										</select>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Kullanıcı Ad</Label>
										<Input
											type="text"
											name="firstName"
											id="firstName"
											placeholder="Ad"
											value={this.state.firstName}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Kullanıcı Soyad</Label>
										<Input
											type="text"
											name="lastName"
											id="lastName"
											placeholder="Soyad"
											value={this.state.lastName}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Kullanıcı Email</Label>
										<Input
											type="text"
											name="email"
											id="email"
											placeholder="Email"
											value={this.state.email}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Kullanıcı Telefon</Label>
										<Input
											type="text"
											name="phone"
											id="phone"
											placeholder="Telefon"
											value={this.state.phone}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label for="exampleEmail">Şifre</Label>
										<Input
											type={this.state.passwordType}
											name="password"
											id="password"
											value={this.state.password}
											onChange={this.setOnChange}
										/>
										<a
											href="javascript:;"
											style={{
												position: 'absolute',
												top: 37,
												right: 27,
											}}
											onClick={() =>
												this.setState({
													passwordType:
														this.state.passwordType == 'password' ? 'text' : 'password',
												})
											}
										>
											<i className="fa fa-eye-slash"></i>
										</a>
									</FormGroup>
								</Col>
							</Row>
							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Kullanıcı Güncelle
								</Button>
							) : (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Kullanıcı Oluştur
								</Button>
							)}
						</Form>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		usserData: state.usserReducer,
		roleReducerData: state.roleReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			resetAllStatussRole: bindActionCreators(roleActions.resetAllStatuss, dispatch),
			createUsser: bindActionCreators(usserActions.createUsser, dispatch),
			setUsserRegStatuss: bindActionCreators(usserActions.setUsserRegStatuss, dispatch),
			resetAllStatuss: bindActionCreators(usserActions.resetAllStatuss, dispatch),
			updateUsser: bindActionCreators(usserActions.updateUsser, dispatch),
			getRoles: bindActionCreators(roleActions.getRoles, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersForm))
