/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import jsPDF from 'jspdf'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as schoolActions from '../../../redux/actions/schoolActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'






class Schools extends Component {
	constructor(props) {
		super(props)

		this.state = {
			dataChart: [
				{
					name: 'Matematik',
					Adet: 5,
				},
				{
					name: 'İngilizce',
					Adet: 10,
				},
				{
					name: 'Almanca',
					Adet: 50,
				},
				{
					name: 'Fransızca',
					Adet: 20,
				},
			],

			SchoolsColumns: [
				{
					name: 'Okul Adı',
					selector: 'schoolName',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ListClassroom
						) == true ? (
								<Button color="dark" onClick={() => this.goOnClassRoomsWithId(row)}>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ListClassroom
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.canAddManagerToSchool
						) == true ? (
								<Button
									color="secondary"
									onClick={() => this.onRowClickAddManager(row, 'ToSchool', 'manager')}
								>
								Müdür Ata
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.canAddManagerToSchool
						) == true
							? '110px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateSchool
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateSchools')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateSchool
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteSchool
						) === true ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteSchool
						) == true
							? '50px'
							: '0px',
				},
			],

			FullSchoolsColumns: [
				{
					name: 'Okul Adı',
					selector: 'schoolName',
					sortable: true,
				},

				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ListClassroom
						) == true ? (
								<Button color="dark" onClick={() => this.goOnClassRoomsWithId(row)}>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ListClassroom
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.canAddManagerToSchool
						) == true ? (
								<Button
									color="secondary"
									onClick={() => this.onRowClickAddManager(row, 'ToSchool', 'manager')}
								>
								Müdür Ata
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.canAddManagerToSchool
						) == true
							? '110px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateSchool
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateSchools')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateSchool
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteSchool
						) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteSchool
						) == true
							? '65px'
							: '0px',
				},
			],
		}
	}

	componentWillMount() {
		this.props.actions.getSchool()
		this.props.actions.getFullSchool()
	}

	componentDidMount() {
		this.setState({ schoolListData_onQuery: this.props.schools.extra })
		this.setState({ schoolFullListData_onQuery: this.props.fullSchools.extra })

		this.props.actions.changeDeletedSchoolSuccessStatus('')
		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.schools.deleted) {
			alertifyjs.success('Ders Başarılı Olarak Silindi!')
			newProps.actions.changeDeletedSchoolSuccessStatus('')
			newProps.actions.resetAllStatuss('')
			newProps.actions.getSchool()
			newProps.actions.getFullSchool()
		} else if (newProps.schools.deleted === false) {
			alertifyjs.error('Ders Silinirken Hatalar Oluştu.')
			newProps.actions.changeDeletedSchoolSuccessStatus('')
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.schools.success === true) {
			this.setState({ schoolListData_onQuery: newProps.schools.extra })
			newProps.actions.resetAllStatuss()
		} else if (newProps.schools.success === false) {
			newProps.actions.resetAllStatuss()
		}

		if (newProps.fullSchools.success === true) {
			this.setState({ schoolFullListData_onQuery: newProps.fullSchools.extra })
			newProps.actions.resetGetFullSchoolsSuccess()
		} else if (newProps.fullSchools.success === false) {
			newProps.actions.resetGetFullSchoolsSuccess()
		}
	}

	exportPDF = (dataGElen) => {
		const unit = 'pt'
		const size = 'A4' // Use A1, A2, A3 or A4
		const orientation = 'portrait' // portrait or landscape

		const marginLeft = 40
		const doc = new jsPDF(orientation, unit, size)

		doc.setFontSize(15)

		const title = 'OKUL RAPORLAMALARI'
		const headers = [['OKUL TEXT']]

		const data = dataGElen.map((elt) => [elt.schoolName])

		let content = {
			startY: 50,
			head: headers,
			body: data,
		}

		doc.text(title, marginLeft, 40)
		doc.autoTable(content)
		doc.save('report.pdf')
	}

	goOnClassRoomsWithId = (row) => {
		this.props.history.push('Sınıflar?Id=' + row.id)
	}

	onRowClickAddManager = (data, pathname, type) => {
		var location = {
			pathname,
			state: {
				data,
				actionType: 'toSchoolManager',
				roleid: 'B04F3C2F-7424-4872-BA55-34246D03453B',
			},
		}

		this.props.history.push(location)
	}

	selectNavigation = (pathname) => {
		this.props.actions.changeDeletedSchoolSuccessStatus('')
		this.props.actions.resetAllStatuss('')

		var location = {
			pathname,
		}

		this.props.history.push(location)
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var schoolListData_onQuery = this.props.schools.extra.filter((item) =>
				item.schoolName.like(e.target.value)
			)
			this.setState({ schoolListData_onQuery })
		} else {
			this.setState({ schoolListData_onQuery: this.props.schools.extra })
		}
	}

	searchOnFullList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var schoolFullListData_onQuery = this.props.fullSchools.extra.filter((item) =>
				item.schoolName.like(e.target.value)
			)
			this.setState({ schoolFullListData_onQuery })
		} else {
			this.setState({ schoolFullListData_onQuery: this.props.fullSchools.extra })
		}
	}

	handleClick = (state) => {}

	onRowClickUpdate = (data, pathname) => {
		var location = {
			pathname,
			state: {
				data,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteSchool(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteSchool(data)
		}
	}

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Okullar</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.SchoolsColumns}
						data={this.state.schoolListData_onQuery}
						pagination={true}
						noHeader
						highlightOnHover={true}
						progressPending={this.props.schools.extra.length > 0 ? false : true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddSchool
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateSchools')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindSchool
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.schoolListData_onQuery)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Okullar</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullSchoolsColumns}
						data={this.state.schoolFullListData_onQuery}
						pagination
						noHeader
						highlightOnHover
						progressPending={this.props.fullSchools.extra.length > 0 ? false : true}
						subHeader
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddSchool
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateSchools')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindFullListSchool
								) === true ? (
										<TextField
											onChange={this.searchOnFullList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.schoolFullListData_onQuery)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListSchool
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListSchool
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		schools: state.schoolReducer,
		fullSchools: state.fullSchoolReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getSchool: bindActionCreators(schoolActions.getSchool, dispatch),

			getFullSchool: bindActionCreators(schoolActions.getFullSchool, dispatch),
			resetGetFullSchoolsSuccess: bindActionCreators(
				schoolActions.resetGetFullSchoolsSuccess,
				dispatch
			),

			deleteSchool: bindActionCreators(schoolActions.deleteSchool, dispatch),
			fullDeleteSchool: bindActionCreators(schoolActions.fullDeleteSchool, dispatch),
			createSchool: bindActionCreators(schoolActions.createSchool, dispatch),
			setSchoolRegStatuss: bindActionCreators(
				schoolActions.setSchoolRegStatuss,
				dispatch
			),
			changeDeletedSchoolSuccessStatus: bindActionCreators(
				schoolActions.changeDeletedSchoolSuccessStatus,
				dispatch
			),
			resetAllStatuss: bindActionCreators(schoolActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schools))
