import React, { Component } from 'react'

export default class Editor extends Component {
	editor
	onChangedTimeout

	constructor(props) {
		super(props)

		this.onInitialized = this.onInitialized.bind(this)
		this.onChangedContent = this.onChangedContent.bind(this)
	}

	componentDidMount() {
		var defaultConfig = {
			autosubmit: true,
			css: {
				stylesheets: [''],
				styles: [
					{ rule: 'p', text: 'block.p' },
					{ rule: 'h1', text: 'block.h1' },
					{ rule: 'h2', text: 'block.h2' },
					{ rule: 'h3', text: 'block.h3' },
					{ rule: 'h4', text: 'block.h4' },
					{ rule: 'div', text: 'block.div' },
					{ rule: 'pre', text: 'block.pre' },
				],
			},
			codeview: {
				enabled: true,
				showButton: true,
			},
			images: {
				allowLocal: true,
			},
			languages: ['tr', 'en'],
			macros: {
				allowed: ['headings', 'lists', 'semantics', 'entities', 'hr', 'link'],
			},
			ui: {
				toolbar: {
					items: [
						'undo',
						'insert',
						'style',
						'emphasis',
						'align',
						'listindent',
						'format',
						'tools',
					],
				},
			},
		}
		this.editor = window.textboxio.replace(`#${this.props.id}`, defaultConfig)

		// let options = {
		// 	fullPage: false,
		// 	language: 'tr',
		// 	// Set dark theme name.
		// 	theme: 'dark',
		// 	zIndex: 2003,
		// 	charCounterCount: true,
		// 	events: {
		// 		initialized: () => {},
		// 		contentChanged: () => {
		// 			if (self.props.onChange) {
		// 				self.props.onChange({
		// 					content: self.editor.html.get(),
		// 				})
		// 			}
		// 		},
		// 	},
		// }
		// if (this.props.placeholder) {
		// 	options['placeholderText'] = this.props.placeholder
		// }
		// // Initialize editor.
		// this.editor = new FroalaEditor(`#QuestionText`, options, function () {
		// 	// Call the method inside the initialized event.
		// 	self.editor.html.set(self.props.content)
		// })

		this.editor.events.change.addListener(this.onChangedContent)
		this.editor.events.loaded.addListener(this.onInitialized)
	}

	onInitialized() {
		this.editor.content.set(this.props.content)
	}

	onChangedContent() {
		if (this.props.onChange) {
			if (this.onChangedTimeout) clearTimeout(this.onChangedTimeout)
			this.onChangedTimeout = setTimeout(() => {
				this.props.onChange({
					content: this.editor.content.get(),
				})
			}, 500)
		}
	}

	componentWillUnmount() {
		if (this.editor) {
			this.editor.events.change.removeListener(this.onChangedContent)
			this.editor.events.loaded.removeListener(this.onInitialized)
		}
	}

	componentWillReceiveProps(newProps) {
		if (this.editor && this.editor.content) {
			let html = this.editor.content.get()
			if (newProps.content != html) {
				this.editor.content.set(newProps.content)
			}
		}
	}

	render() {
		return (
			<textarea
				style={{ width: '100%' }}
				rows={10}
				id={this.props.id}
				name={this.props.id}
			></textarea>
		)
	}
}
