import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Button,





    Col, Form,
    FormGroup,

    Input, Label,



    Row
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as schoolActions from '../../../redux/actions/schoolActions'
import { ScrollView } from '../base/scroll'




class SchoolsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			schoolName: '',
		}
	}

	// before render
	// componentWillMount() {
	// }

	//after render
	componentDidMount() {
		this.setState({ schoolName: '' })

		{
			this.props?.location?.state?.actionType === 'update'
				? this.setState({ schoolName: this.props.location.state.data.schoolName })
				: this.setState({ schoolName: '' })
		}
		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.schoolData.success) {
			var location = {
				pathname: 'Okullar',
			}

			this.props.history.push(location)
		} else if (newProps.schoolData.success === false) {
			alertifyjs.error('Hata: ' + newProps.schoolData.messages)
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()
		this.props.actions.resetAllStatuss('')
		//this.props.actions.chageSuccessStatussRole(false);

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateSchool({
						schoolName: this.state.schoolName,
						id: this.props.location.state.data.id,
					  })
					: this.props.actions.createSchool({ schoolName: this.state.schoolName })
			}
		}
	}

	validationFunc() {
		if (this.state.schoolName.length < 4) {
			alertifyjs.error('Okul Adı 5 Haneden Kısa Olamaz!')
			return false
		} else {
			return true
		}
	}

	render() {
		return (
			<ScrollView>
				<div>
					<div className="row">
						<div className="col-lg-2"></div>

						<div className="col-lg-8">
							<Form onSubmit={this.onSubmit}>
								<Row>
									<Col>
										<FormGroup>
											<Label for="exampleEmail">Okul Adı</Label>
											<Input
												type="text"
												name="schoolName"
												id="schoolName"
												placeholder="Okul Adı Belirleyiniz!"
												value={this.state.schoolName}
												onChange={this.setOnChange}
											/>
										</FormGroup>
									</Col>
								</Row>

								{this.props?.location?.state?.actionType === 'update' ? (
									<Button
										size="lg"
										type="submit"
										onClick={this.onSubmit}
										className="float-right"
										style={{ width: '200px' }}
									>
										Okulu Güncelle
									</Button>
								) : (
									<Button
										size="lg"
										type="submit"
										onClick={this.onSubmit}
										className="float-right"
										style={{ width: '200px' }}
									>
										Okulu Oluştur
									</Button>
								)}
							</Form>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		schoolData: state.schoolReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			createSchool: bindActionCreators(schoolActions.createSchool, dispatch),
			setSchoolRegStatuss: bindActionCreators(
				schoolActions.setSchoolRegStatuss,
				dispatch
			),
			resetAllStatuss: bindActionCreators(schoolActions.resetAllStatuss, dispatch),
			updateSchool: bindActionCreators(schoolActions.updateSchool, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolsForm))
