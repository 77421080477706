import Badge from '@material-ui/core/Badge'
import CloseIcon from '@material-ui/icons/Close'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	Button,
	Jumbotron,
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import TopicMaterialView from '../../../../src/components/innerComponenets/realexam/topicMaterialView'
import Desklamp from '../../../medias/desklamp.svg'
import Reward from '../../../medias/reward.svg'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as realExamActions from '../../../redux/actions/realExamActions'
import { ScrollView } from '../base/scroll'




class realexam extends Component {
	constructor(props) {
		super(props)

		this.state = {
			id: props.location.state.id,
			openLeaveModal: false,
			openMessageModal: false,
			messagesIsVisible: false,

			startStatuss: false,

			currentQuestionModalVis: false,

			questionPassOption: 'user',

			buttonOneC: 'secondary',
			buttonTwoC: 'secondary',
			buttonThreeC: 'secondary',
			buttonFourC: 'secondary',

			buttonOneText: '',
			buttonTwoText: '',
			buttonThreeText: '',
			buttonFourText: '',

			buttonOneFileUrl: '',
			buttonTwoFileUrl: '',
			buttonThreeFileUrl: '',
			buttonFourFileUrl: '',

			currentQuestion: null,
			currentQuestionorderCount: 0,
			currentQuestionText: 'deneme',
			currentQuestionenabled: true,
			currentQuestiontimeout: 1000,
			currentQuestioncount: 0,
			currentQuestioncountPositive: 0,
			timeIsOnDanger: false,
			currentExamFinished: false,

			selectedAnswerId: '',
			rightAnswerId: '',
			AnswerWasTrue: '',
		}

		this.keyPress = this.keyPress.bind(this)
	}

	componentDidMount() {
		document.addEventListener('keydown', this.keyPress, false)

		this.props.actions.getAllrealExamStart({
			ExamHistoryId: this.state.id,
		})
	}

	componentWillReceiveProps(newProps) {
		let reducer = newProps.realExamReducerData
		if (reducer.successDetailsExam === true) {
			if ((reducer.extraDetailsExam.exam.adminDescription || '').length > 1) {
				this.setState({ messagesIsVisible: true })
			}
			newProps.actions.fullResetRealExam()
			setTimeout(() => {
				this.startQuestionInterval(0)
			}, 1000)
		}
	}

	keyPress(e) {
		if (e.keyCode == 13) {
			this.nextQuestion()
		}
	}

	selectNavigation = (pathname) => {
		var location = {
			pathname,
		}

		this.props.history.push(location)
	}

	trueOrFalseAnswers() {
		var totalTrueAnswer = 0
		this.props.realExamReducerData.extraDetailsExam.questions.map((dd) => {
			if (dd.question.rightAnswer === dd.question.userAnswer) {
				totalTrueAnswer++
			}
		})
		return totalTrueAnswer
	}

	compueExamTime() {
		var totalMinute = 0
		this.props.realExamReducerData.extraDetailsExam.questions.map((dd) => {
			totalMinute += dd.timeOut
		})
		var minutes = Math.floor(totalMinute / 60)
		return minutes
	}

	interClassBack = () => {
		this.setState({
			currentQuestioncount: this.state.currentQuestioncount - 1,
			currentQuestioncountPositive: this.state.currentQuestioncountPositive + 1,
		})

		if (this.state.currentQuestioncount === 0) {
			if (this.state.selectedAnswerId.length === 0) {
				this.setState({ AnswerWasTrue: 'noanswer' })
			} else {
				this.props.realExamReducerData.extraDetailsExam.questions[
					this.state.currentQuestionorderCount - 1
				].question.answers.map((cc) => {
					if (this.state.selectedAnswerId === cc.id) {
						if (cc.isRight === true) {
							this.setState({ AnswerWasTrue: true })
						} else {
							this.setState({ AnswerWasTrue: false })
						}
					}
				})
			}

			if (
				this.props.realExamReducerData.extraDetailsExam.questions.length ===
				this.state.currentQuestionorderCount
			) {
				this.setState({ currentQuestionenabled: false })
			} else {
				if (this.state.questionPassOption === 'auto') {
					this.startQuestionInterval(this.state.currentQuestionorderCount)
					this.setAnswer(this.state.currentQuestionorderCount)
					this.setState({
						currentQuestionorderCount: this.state.currentQuestionorderCount + 1,
					})
				} else {
					this.setState({ currentQuestionenabled: false })
				}
			}
		} else if (this.state.currentQuestioncount < 11) {
			this.setState({ timeIsOnDanger: !this.state.timeIsOnDanger })
		}
	}

	manuelNextQuestion = () => {
		this.setState({
			currentQuestioncountPositive: this.state.currentQuestioncountPositive + 1,
		})

		if (
			this.state.selectedAnswerId.length === 0 ||
			this.state.selectedAnswerId === '00000000-0000-0000-0000-000000000000'
		) {
			this.setState({ AnswerWasTrue: 'noanswer' })
		} else {
			this.props.realExamReducerData.extraDetailsExam.questions[
				this.state.currentQuestionorderCount - 1
			].question.answers.map((cc) => {
				var currentPoint = ''
				if (this.state.selectedAnswerId === cc.id) {
					if (cc.isRight === true) {
						this.setState({ AnswerWasTrue: true })
					} else {
						this.setState({ AnswerWasTrue: false })
					}
				}
			})
		}

		if (
			this.props.realExamReducerData.extraDetailsExam.questions.length ===
			this.state.currentQuestionorderCount
		) {
			this.stopQuestionInterval()
		} else {
			this.setAnswer(this.state.currentQuestionorderCount)
			this.startQuestionInterval(this.state.currentQuestionorderCount)
			this.setState({
				currentQuestionorderCount: this.state.currentQuestionorderCount + 1,
			})
		}
	}

	stopQuestionInterval = () => {
		this.setState({ currentQuestionenabled: false })
	}

	startQuestionInterval = (orderCount) => {
		this.setState({
			currentQuestionenabled: true,
			currentQuestioncount: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].timeOut,
			currentQuestion: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question,
			currentQuestionText: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.questionText,

			buttonOneText: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[0].answerText,
			buttonOneFileUrl: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[0].fileUrl,
			buttonTwoText: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[1].answerText,
			buttonTwoFileUrl: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[1].fileUrl,
			buttonThreeText: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[2].answerText,
			buttonThreeFileUrl: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[2].fileUrl,
			buttonFourText: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[3].answerText,
			buttonFourFileUrl: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[3].fileUrl,
			buttonOneKey: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[0].id,
			buttonTwoKey: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[1].id,
			buttonThreeKey: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[2].id,
			buttonFourKey: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.answers[3].id,
			currentQuestioncountPositive: 0,

			rightAnswerId: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.rightAnswer,
			selectedAnswerId: this.props.realExamReducerData.extraDetailsExam.questions[
				orderCount
			].question.userAnswer,
		})
	}

	resetQuestionInterval = () => {
		this.setState({
			currentQuestioncount: 0,
			currentQuestionenabled: false,
			currentQuestioncountPositive: 0,
		})
	}

	resetAnswer = () => {
		this.setState({
			buttonOneC: 'secondary',
			buttonTwoC: 'secondary',
			buttonThreeC: 'secondary',
			buttonFourC: 'secondary',
			selectedAnswerId: '',
			AnswerWasTrue: '',
		})
	}

	setAnswer = (orderCount) => {
		let question = this.props.realExamReducerData.extraDetailsExam.questions[
			orderCount
		].question
		if (question.userAnswer === question.rightAnswer) {
			if (question.answers[0].id === question.rightAnswer) {
				this.setState({
					buttonOneC: 'success',
					buttonTwoC: 'secondary',
					buttonThreeC: 'secondary',
					buttonFourC: 'secondary',
				})
			} else if (question.answers[1].id === question.rightAnswer) {
				this.setState({
					buttonOneC: 'secondary',
					buttonTwoC: 'success',
					buttonThreeC: 'secondary',
					buttonFourC: 'secondary',
				})
			} else if (question.answers[2].id === question.rightAnswer) {
				this.setState({
					buttonOneC: 'secondary',
					buttonTwoC: 'secondary',
					buttonThreeC: 'success',
					buttonFourC: 'secondary',
				})
			} else if (question.answers[3].id === question.rightAnswer) {
				this.setState({
					buttonOneC: 'secondary',
					buttonTwoC: 'secondary',
					buttonThreeC: 'secondary',
					buttonFourC: 'success',
				})
			}
		} else {
			if (question.answers[0].id === question.rightAnswer) {
				this.setState({
					buttonOneC:
						this.state.selectedAnswerId == '00000000-0000-0000-0000-000000000000'
							? 'warning'
							: 'success',
					buttonTwoC:
						question.userAnswer === question.answers[1].id ? 'danger' : 'secondary',
					buttonThreeC:
						question.userAnswer === question.answers[2].id ? 'danger' : 'secondary',
					buttonFourC:
						question.userAnswer === question.answers[3].id ? 'danger' : 'secondary',
				})
			} else if (question.answers[1].id === question.rightAnswer) {
				this.setState({
					buttonOneC:
						question.userAnswer === question.answers[0].id ? 'danger' : 'secondary',
					buttonTwoC:
						this.state.selectedAnswerId == '00000000-0000-0000-0000-000000000000'
							? 'warning'
							: 'success',
					buttonThreeC:
						question.userAnswer === question.answers[2].id ? 'danger' : 'secondary',
					buttonFourC:
						question.userAnswer === question.answers[3].id ? 'danger' : 'secondary',
				})
			} else if (question.answers[2].id === question.rightAnswer) {
				this.setState({
					buttonOneC:
						question.userAnswer === question.answers[0].id ? 'danger' : 'secondary',
					buttonTwoC:
						question.userAnswer === question.answers[1].id ? 'danger' : 'secondary',
					buttonThreeC:
						this.state.selectedAnswerId == '00000000-0000-0000-0000-000000000000'
							? 'warning'
							: 'success',
					buttonFourC:
						question.userAnswer === question.answers[3].id ? 'danger' : 'secondary',
				})
			} else if (question.answers[3].id === question.rightAnswer) {
				this.setState({
					buttonOneC:
						question.userAnswer === question.answers[0].id ? 'danger' : 'secondary',
					buttonTwoC:
						question.userAnswer === question.answers[1].id ? 'danger' : 'secondary',
					buttonThreeC:
						question.userAnswer === question.answers[2].id ? 'danger' : 'secondary',
					buttonFourC:
						this.state.selectedAnswerId == '00000000-0000-0000-0000-000000000000'
							? 'warning'
							: 'success',
				})
			}
		}
	}

	startExam = () => {
		this.toggleCurrentQuestionModal()
		this.setState({ startStatuss: !this.state.startStatuss })

		this.startQuestionInterval(this.state.currentQuestionorderCount)

		this.setAnswer(this.state.currentQuestionorderCount)
		this.setState({
			currentQuestionorderCount: this.state.currentQuestionorderCount + 1,
		})
	}

	nextQuestion = () => {
		if (
			this.props.realExamReducerData.extraDetailsExam.questions.length ===
			this.state.currentQuestionorderCount
		) {
			this.setState({ currentExamFinished: true, currentQuestionenabled: false })
		} else {
			this.startQuestionInterval(this.state.currentQuestionorderCount)
			this.setAnswer(this.state.currentQuestionorderCount)
			this.setState({
				currentQuestionorderCount: this.state.currentQuestionorderCount + 1,
			})
		}
	}

	goQuestion = (questionIndex) => {
		this.startQuestionInterval(questionIndex)
		this.setAnswer(questionIndex)
		this.setState({ currentQuestionorderCount: questionIndex + 1 })
	}

	toggleCurrentQuestionModal = () => {
		this.setState({ currentQuestionModalVis: !this.state.currentQuestionModalVis })
	}

	option = (color, onClick, fileUrl, text, id) => (
		<div className="col-lg-3" style={{ marginTop: 10 }}>
			<Button
				size="lg"
				block
				color={color}
				id={id}
				onClick={onClick}
				style={{ wordBreak: 'break-word' }}
			>
				{fileUrl && <img src={fileUrl} style={{ maxHeight: 100 }} />}
				{text}
			</Button>
		</div>
	)

	currentQuestionModal() {
		return (
			<div>
				<Modal
					isOpen={this.state.currentQuestionModalVis}
					style={{ maxWidth: '1600px', width: '96%' }}
				>
					{this.state.currentQuestionenabled === true ? (
						<div className="modal-header">
							<div className="row col-lg-12">
								<Badge
									color="secondary"
									overlap="circle"
									style={{
										zoom: 1.5,
										position: 'absolute',
										top: -5,
										left: 0,
										boxShadow: '0 0 5px rgba(0,0,0,.3)',
									}}
									badgeContent={this.state.currentQuestionorderCount}
								/>
								<h5 className="modal-title" style={{ width: '100%' }}>
									<div
										dangerouslySetInnerHTML={{ __html: this.state.currentQuestionText }}
									></div>{' '}
									<br />
									{this.state.currentQuestion && this.state.currentQuestion.fileUrl && (
										<img src={this.state.currentQuestion.fileUrl} />
									)}
								</h5>
							</div>

							<span
								style={{
									position: 'absolute',
									right: -5,
									top: -5,
									zoom: 1.5,
									boxShadow: '0 0 5px rgba(0,0,0,.3)',
								}}
								className={
									this.state.timeIsOnDanger === true
										? 'badge badge-danger'
										: 'badge badge-secondary'
								}
							>
								Süre: {this.state.currentQuestioncount}
							</span>
						</div>
					) : null}

					<ModalBody>
						{this.state.currentQuestionenabled === false ? (
							<div className="row">
								<div className="col-sm-4" style={{ textAlign: 'center', marginBottom: 20 }}>
									{this.state.currentExamFinished === true ? (
										<img src={Reward} alt="Exam Logo" width={135} />
									) : (
										<img src={Desklamp} alt="Exam Logo" width={135} />
									)}
								</div>

								<div
									className="col-sm-8"
									style={{ width: '100%', height: '100%', textAlign: 'center' }}
								>
									{this.state.currentExamFinished === true ? (
										<h3
											style={{
												textAlign: 'center',
												left: '50%',
												top: '50%',
												width: '100%',
												height: '100%',
												marginTop: '10px',
											}}
										>
											Tebrikler!
											<br /> Testinizi bitirdiniz!
										</h3>
									) : (
										<h3
											style={{
												textAlign: 'center',
												left: '50%',
												top: '50%',
												width: '100%',
												height: '100%',
												marginTop: '10px',
											}}
										>
											Bir sonraki soruya geçmeye hazır mısın?
											{this.state.AnswerWasTrue === 'noanswer' ? (
												<div>
													<br />
													Soruyu Boş Bıraktınız!
												</div>
											) : this.state.AnswerWasTrue === true ? (
												<div>
													<br />
													Tebrikler Cevabınız Doğru!
												</div>
											) : (
												<div>
													<br />
													Üzgünüz Yanlış Bir Cevap Verdin!
												</div>
											)}
										</h3>
									)}
								</div>
							</div>
						) : (
							<div>
								{this.state.selectedAnswerId == '00000000-0000-0000-0000-000000000000' && (
									<p className="alert alert-warning">Bu soru pas geçildi.</p>
								)}
								<div className="row">
									{this.option(
										this.state.buttonOneC,
										this.selectAnswer,
										this.state.buttonOneFileUrl,
										this.state.buttonOneText,
										'buttonOneC'
									)}
									{this.option(
										this.state.buttonTwoC,
										this.selectAnswer,
										this.state.buttonTwoFileUrl,
										this.state.buttonTwoText,
										'buttonTwoC'
									)}
									{this.option(
										this.state.buttonThreeC,
										this.selectAnswer,
										this.state.buttonThreeFileUrl,
										this.state.buttonThreeText,
										'buttonThreeC'
									)}
									{this.option(
										this.state.buttonFourC,
										this.selectAnswer,
										this.state.buttonFourFileUrl,
										this.state.buttonFourText,
										'buttonFourC'
									)}
								</div>
							</div>
						)}
					</ModalBody>
					<ModalFooter>
						{this.state.currentQuestionenabled === true ? (
							<div className="col-lg-12 row">
								<div className="align-self-center col-lg-8">
									<select
										className="form-control"
										value={this.state.currentQuestionorderCount - 1}
										style={{ marginRight: 5, width: '100%', marginTop: 10 }}
										onChange={(ev) => this.goQuestion(parseInt(ev.currentTarget.value))}
									>
										{this.props.realExamReducerData.extraDetailsExam.questions.map((aa, i) => (
											<option key={`page_${i + 1}`} value={i}>
												{i + 1}
											</option>
										))}
									</select>
								</div>
								<div className="col-lg-2 col-sm-12">
									<Button
										color="danger mr-1"
										className="float-left"
										onClick={this.toggleLeaveExam}
										style={{ width: '100%', marginTop: 10 }}
									>
										Ayrıl
									</Button>
								</div>
								<div className="col-lg-2 col-sm-12">
									<Button
										color="success"
										className="float-right"
										style={{ width: '100%', marginTop: 10 }}
										onClick={this.nextQuestion}
									>
										Devam
									</Button>
								</div>
							</div>
						) : this.state.currentExamFinished === false ? (
							<div>
								<Button color="danger mr-1" onClick={this.toggleLeaveExam}>
									Ayrıl
								</Button>
								<Button color="success" onClick={this.nextQuestion}>
									İleri
								</Button>
							</div>
						) : (
							<Button color="success" onClick={() => this.selectNavigation('Profile')}>
								Testi Bitir
							</Button>
						)}
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	toggleLeaveExam = () => {
		this.setState({ openLeaveModal: !this.state.openLeaveModal })
	}

	exitConfirmModal() {
		return (
			<Modal
				isOpen={this.state.openLeaveModal}
				style={{ overlay: { background: 'black' } }}
			>
				<ModalBody>Testten ayrılmak istediğinize eminmisiniz?</ModalBody>
				<ModalFooter>
					<Button color="info mr-1" onClick={this.toggleLeaveExam}>
						Teste Devam Et
					</Button>
					<Button color="danger" onClick={() => this.selectNavigation('Profile')}>
						Ayrıl
					</Button>
				</ModalFooter>
			</Modal>
		)
	}

	toggleLeaveMessages = () => {
		this.setState({ openMessageModal: !this.state.openMessageModal })
	}

	messageModal() {
		let exam = this.props.realExamReducerData.extraDetailsExam.exam
		return (
			<Modal
				isOpen={this.state.openMessageModal}
				style={{ overlay: { background: 'black' } }}
			>
				<ModalBody>
					<p className="lead">{exam.adminDescription}</p>
					<hr className="my-2" />
					<p className="lead">{exam.userDescription}</p>
				</ModalBody>
				<ModalFooter>
					<Button color="success" onClick={this.toggleLeaveMessages}>
						Okudum Anladım!
					</Button>
				</ModalFooter>
			</Modal>
		)
	}

	render() {
		return (
			<ScrollView>
				<link href="/realexam.css" type="text/css" rel="stylesheet" />
				<div
					className="realExamOverlay"
					style={{ backgroundColor: '#e9ecef', overflow: 'scroll' }}
				>
					<div className="bodyExam">
						<div className="row">
							<div className="col-lg-12">
								{this.currentQuestionModal()}
								{this.exitConfirmModal()}
								{this.messageModal()}

								<Button color="link" className="closeExam" onClick={this.toggleLeaveExam}>
									<CloseIcon color="action"></CloseIcon>
								</Button>

								{this.state.messagesIsVisible === true ? (
									<Button
										color="link"
										className="MessageExam"
										onClick={this.toggleLeaveMessages}
									>
										<Badge color="secondary" badgeContent={'!'}>
											<MailOutlineIcon color="action" />
										</Badge>
									</Button>
								) : null}

								<Jumbotron className="row" style={{ height: '100vh' }}>
									<div className="col-lg-6">
										{this.state.startStatuss === false ? (
											<h1 className="display-3" style={{ fontSize: '4rem' }}>
												Test Detayları!
											</h1>
										) : null}

										<hr className="my-2" />

										{this.state.startStatuss === false ? (
											<div>
												<p className="lead">
													{' '}
													<strong className="text-bold">Okul:</strong>{' '}
													{this.props.realExamReducerData.extraDetailsExam.school.schoolName}{' '}
												</p>
												<p className="lead">
													<strong className="text-bold">Sınıf:</strong>{' '}
													{this.props.realExamReducerData.extraDetailsExam.classroom.className}{' '}
												</p>
												<p className="lead">
													<strong className="text-bold">Ders:</strong>{' '}
													{this.props.realExamReducerData.extraDetailsExam.lesson.lessonName}{' '}
												</p>
												<p className="lead">
													<strong className="text-bold">Konu:</strong>{' '}
													{this.props.realExamReducerData.extraDetailsExam.topic.topicName}{' '}
												</p>
												{this.props.realExamReducerData.extraDetailsExam.exam
													.userDescription && (
													<p className="lead">
														<strong className="text-bold">Öğretmen Açıklaması:</strong>{' '}
														{this.props.realExamReducerData.extraDetailsExam.exam.userDescription}{' '}
													</p>
												)}
												<hr className="my-2" />

												<p className="lead">
													<strong className="text-bold">Toplam Soru:</strong>{' '}
													{this.props.realExamReducerData.extraDetailsExam.questions.length} Adet{' '}
												</p>
												<p className="lead">
													<strong className="text-bold">Doğru/Yanlış Cevaplar:</strong>{' '}
													{this.trueOrFalseAnswers()} /{' '}
													{this.props.realExamReducerData.extraDetailsExam.questions.length -
														this.trueOrFalseAnswers()}{' '}
												</p>
												<p className="lead">
													<strong className="text-bold">Toplam Süre:</strong>{' '}
													{this.compueExamTime()} Dakika
												</p>

												{this.state.startStatuss === false ? (
													<Button
														color="danger"
														className="float-right btn btn-danger btn-lg"
														size="lg"
														style={{ fontSize: '2rem', marginBottom: 20, marginTop: 10 }}
														onClick={this.startExam}
													>
														Cevapları Kontrol Et!
													</Button>
												) : null}
											</div>
										) : null}
									</div>
									<div className="col-lg-6" style={{ backgroundColor: '#e9ecef' }}>
										{this.props.realExamReducerData.extraDetailsExam.topic.id &&
											this.props.realExamReducerData.extraDetailsExam.exam.showMaterial && (
											<TopicMaterialView
												topicId={this.props.realExamReducerData.extraDetailsExam.topic.id}
											/>
										)}
									</div>
								</Jumbotron>
							</div>
						</div>
					</div>
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		realExamReducerData: state.realExamReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getAllrealExamStart: bindActionCreators(realExamActions.getExamDetails, dispatch),
			resetGetAllrealExamStartSuccess: bindActionCreators(
				realExamActions.resetGetExamDetailsSuccess,
				dispatch
			),

			postAnswerRealEXam: bindActionCreators(
				realExamActions.postAnswerRealEXam,
				dispatch
			),
			resetPostAnswerRealEXamSuccess: bindActionCreators(
				realExamActions.resetPostAnswerRealEXamSuccess,
				dispatch
			),

			finishRealExam: bindActionCreators(realExamActions.finishRealExam, dispatch),
			resetFinishRealExamSuccess: bindActionCreators(
				realExamActions.resetFinishRealExamSuccess,
				dispatch
			),
			fullResetRealExam: bindActionCreators(
				realExamActions.fullResetRealExam,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(realexam))
