import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function toggleReducer(state = initialState.toggleStatuss, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_STATUSS:
      return action.payload

    default:
      return state
  }
}
