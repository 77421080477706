import axios from 'axios'

import jwt_decode from 'jwt-decode'
import {
	SET_CURRENT_USER,
	SET_CURRENT_USER_ONFAULT,
	RESET_AUTH_SUCCESS_STATUSS,
	CHANGE_SETTINGS_SUCCESS,
} from './actionTypes'
import { EXIT_ACTION } from './actionTypes'

import mainConfigStates from '../../configs/mainConfig'
axios.defaults.baseURL = mainConfigStates.host

// KULLANICIYI REDUCER'A GÖNDERİYORUZ
export const setCurrentUser = (userAuthData) => {
	return {
		type: SET_CURRENT_USER,
		payload: userAuthData,
	}
}

// KULLANICIYI REDUCER'A GÖNDERİYORUZ
export const setCurrentUserOnFault = (userAuthData) => {
	return {
		type: SET_CURRENT_USER_ONFAULT,
		payload: userAuthData,
	}
}

export const resetAuthSuccessStatuss = (userAuthData) => {
	return {
		type: RESET_AUTH_SUCCESS_STATUSS,
		payload: userAuthData,
	}
}

export const changeSettingsSuccess = (userAuthData) => {
	return {
		type: CHANGE_SETTINGS_SUCCESS,
		payload: userAuthData,
	}
}

//**************************************************************** */

// LOGIN -- componenet içinden atesliyoruz
export const loginUser = (userData) => (dispatch) => {
	axios
		.post('/api/Login/Accesstoken', userData)
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz

			const { token, refreshToken, expiration } = res.data
			if (token.length) {
				const decoded = jwt_decode(token)

				// Token'i localStorage'da saklıyoruz
				localStorage.setItem('jwtToken', token)
				localStorage.setItem('jwtDecodedToken', decoded)
				localStorage.setItem('refreshToken', refreshToken)
				localStorage.setItem(
					'id',
					decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
				)
				localStorage.setItem(
					'role',
					decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
				)
				localStorage.setItem(
					'name',
					decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
				)
				localStorage.setItem('expiration', expiration)
				localStorage.setItem('Permissions', decoded.Permissions)
				localStorage.setItem('email', decoded.email)
				//localStorage.setItem('settings', decoded.Settings)
				// Daha önce yazdığımız fonksiyon ile gelecek istekler için        kaydediyoruz

				//setAuthToken(token)
				// Token'i Çözümlüyoruz
				var userAuthData = {
					jwtToken: token,
					jwtDecodedToken: decoded,
					refreshToken: refreshToken,
					expiration: expiration,
					permissions: decoded.Permissions,
					email: decoded.email,
					//settings: { font: 'Marmelad', color: 'Red', fontnamefull: "'Armata', sans-serif" }
				}

				// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
				dispatch(setCurrentUser(userAuthData))
			} else {
				localStorage.clear()
				dispatch(setCurrentUser(''))
			}
		})
		.catch((err) => {
			var errData = {
				message: err.response.data,
			}
			dispatch(setCurrentUserOnFault(errData))
		})
}

//************************************************************************* */
export const changeSettings = (data) => (dispatch) => {
	localStorage.setItem('settings', JSON.stringify(data))
	dispatch(changeSettingsSuccess(data))
}

export const deleteToken = () => (dispatch) => {
	localStorage.removeItem('jwtToken')
	localStorage.clear()
	var userAuthData = []

	dispatch(exitFromSession(userAuthData))
}

export function exitFromSession(userAuthData) {
	return { type: EXIT_ACTION, payload: userAuthData }
}
//************************************************************************* */
