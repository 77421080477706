import {
	POST_UPLOAD_SUCCESS,
	POST_UPLOAD_RESET_ALL_STATUSS,
	ONFAULT_POST_UPLOAD,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const postUploadSuccess = (Data) => {
	return {
		type: POST_UPLOAD_SUCCESS,
		payload: Data,
	}
}

export const resetAllPostUploadStatuss = (Data) => {
	return {
		type: POST_UPLOAD_RESET_ALL_STATUSS,
		payload: Data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_POST_UPLOAD,
		payload: data,
	}
}

// {
//     "success": false,
//     "errorMessage": "Soru bulunurken bir hata meydana geldi:Object reference not set to an instance of an object.",
//     "extra": null
// }

export const postDatasForUpload = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Uploads/File'
	const config = {
		headers: {
			'content-type': 'multipart/form-data',
		},
	}
	//********************************************* */

	// if (data.id) {
	//      url = url + "?id=" + data.id;
	// }
	const formData = new FormData()
	formData.append('files', data)

	axios
		.post(url, formData, config)
		.then((res) => {
			const getted = res.data

			dispatch(postUploadSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
