import Badge from '@material-ui/core/Badge'
import CloseIcon from '@material-ui/icons/Close'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import React, { Component } from 'react'
import ReactInterval from 'react-interval'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	Button,




	ButtonGroup, Jumbotron,
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import TopicMaterialView from '../../../../src/components/innerComponenets/realexam/topicMaterialView'
import Desklamp from '../../../medias/desklamp.svg'
import Reward from '../../../medias/reward.svg'
import ExamSvg from '../../../medias/test.svg'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as realExamActions from '../../../redux/actions/realExamActions'
import { ScrollView } from '../base/scroll'





class realexam extends Component {
	constructor(props) {
		super(props)

		this.state = {
			id: props.location.state.id,
			openLeaveModal: false,
			openMessageModal: false,
			messagesIsVisible: false,

			startStatuss: false,

			currentQuestionModalVis: false,

			questionPassOption: 'user',

			buttonOneC: 'link',
			buttonTwoC: 'link',
			buttonThreeC: 'link',
			buttonFourC: 'link',

			buttonOneText: '',
			buttonTwoText: '',
			buttonThreeText: '',
			buttonFourText: '',

			buttonOneFileUrl: '',
			buttonTwoFileUrl: '',
			buttonThreeFileUrl: '',
			buttonFourFileUrl: '',

			exam: null,
			question: null,
			currentQuestionorderCount: 0,
			currentQuestionText: 'deneme',
			currentQuestionenabled: true,
			currentQuestiontimeout: 1000,
			currentQuestioncount: 0,
			currentQuestioncountPositive: 0,
			timeIsOnDanger: false,
			currentExamFinished: false,

			selectedAnswerId: '',
			AnswerWasTrue: '',

			resultType: 1,
		}
	}

	componentDidMount() {
		this.props.actions.getAllrealExamStart({
			ExamHistoryId: this.state.id,
		})
	}

	componentWillUnmount() {
		window.onbeforeunload = null
	}

	componentWillReceiveProps(newProps) {
		let data = newProps.realExamReducerData
		if (data.successGetRealExam === true) {
			let exam = data.extraGetRealExam.exam
			if (exam.solved) {
				window.location.replace('/')
			}

			this.setState({
				resultType: exam.resultType,
				questionPassOption: exam.resultType == 1 ? 'auto' : 'user',
			})

			if ((exam.adminDescription || '').length > 1) {
				this.setState({ messagesIsVisible: true })
			}

			newProps.actions.fullResetRealExam()
		}
	}

	selectNavigation = (pathname) => {
		var location = {
			pathname,
		}

		this.props.history.push(location)
	}

	goToPreview = () => {
		this.props.history.push({
			pathname: 'PreviewExam',
			state: {
				id: this.state.id,
			},
		})
	}

	compueExamTime() {
		var totalMinute = 0
		this.props.realExamReducerData.extraGetRealExam.questions.map((dd) => {
			totalMinute += dd.timeOut
		})
		var minutes = Math.floor(totalMinute / 60)
		return minutes
	}

	interClassBack = () => {
		this.setState({
			currentQuestioncount: this.state.currentQuestioncount - 1,
			currentQuestioncountPositive: this.state.currentQuestioncountPositive + 1,
		})

		if (this.state.currentQuestioncount === 0) {
			if (this.state.selectedAnswerId.length === 0) {
				this.setState({ AnswerWasTrue: 'noanswer' })

				this.props.actions.postAnswerRealEXam({
					examHistoryId: this.state.id,
					questionId: this.props.realExamReducerData.extraGetRealExam.questions[
						this.state.currentQuestionorderCount - 1
					].questionId,
					answerId: '00000000-0000-0000-0000-000000000000',
					answerTime: this.state.currentQuestioncountPositive,
					questionPoint: this.props.realExamReducerData.extraGetRealExam.questions[
						this.state.currentQuestionorderCount - 1
					].point,
				})
			} else {
				this.props.realExamReducerData.extraGetRealExam.questions[
					this.state.currentQuestionorderCount - 1
				].question.answers.map((cc) => {
					if (this.state.selectedAnswerId === cc.id) {
						if (cc.isRight === true) {
							this.setState({ AnswerWasTrue: true })
						} else {
							this.setState({ AnswerWasTrue: false })
						}
					}
				})

				this.props.actions.postAnswerRealEXam({
					examHistoryId: this.state.id,
					questionId: this.props.realExamReducerData.extraGetRealExam.questions[
						this.state.currentQuestionorderCount - 1
					].questionId,
					answerId: this.state.selectedAnswerId,
					answerTime: this.state.currentQuestioncountPositive,
					questionPoint: this.props.realExamReducerData.extraGetRealExam.questions[
						this.state.currentQuestionorderCount - 1
					].point,
				})
			}

			if (
				this.props.realExamReducerData.extraGetRealExam.questions.length ===
				this.state.currentQuestionorderCount
			) {
				if (this.state.questionPassOption !== 'auto') {
					this.setState({ currentQuestionenabled: false, currentExamFinished: true })
				}

				this.props.actions.finishRealExam({
					ExamHistoryId: this.state.id,
				})
			} else {
				if (this.state.questionPassOption === 'auto') {
					this.startQuestionInterval(this.state.currentQuestionorderCount)
					this.setState({
						currentQuestionorderCount: this.state.currentQuestionorderCount + 1,
					})
					this.resetAnswer()
				} else {
					this.setState({ currentQuestionenabled: false })
				}
			}
		} else if (this.state.currentQuestioncount < 11) {
			this.setState({ timeIsOnDanger: !this.state.timeIsOnDanger })
		}
	}

	manuelNextQuestion = () => {
		this.setState({
			currentQuestioncount: this.state.currentQuestioncount - 1,
			currentQuestioncountPositive: this.state.currentQuestioncountPositive + 1,
		})

		if (this.state.selectedAnswerId.length === 0) {
			this.setState({ AnswerWasTrue: 'noanswer' })

			this.props.actions.postAnswerRealEXam({
				examHistoryId: this.state.id,
				questionId: this.props.realExamReducerData.extraGetRealExam.questions[
					this.state.currentQuestionorderCount - 1
				].questionId,
				answerId: '00000000-0000-0000-0000-000000000000',
				answerTime: this.state.currentQuestioncountPositive,
				questionPoint: this.props.realExamReducerData.extraGetRealExam.questions[
					this.state.currentQuestionorderCount - 1
				].point,
			})
		} else {
			this.props.realExamReducerData.extraGetRealExam.questions[
				this.state.currentQuestionorderCount - 1
			].question.answers.map((cc) => {
				var currentPoint = ''
				if (this.state.selectedAnswerId === cc.id) {
					if (cc.isRight === true) {
						this.setState({ AnswerWasTrue: true })
					} else {
						this.setState({ AnswerWasTrue: false })
					}

					this.props.actions.postAnswerRealEXam({
						examHistoryId: this.state.id,
						questionId: this.props.realExamReducerData.extraGetRealExam.questions[
							this.state.currentQuestionorderCount - 1
						].questionId,
						answerId: this.state.selectedAnswerId,
						answerTime: this.state.currentQuestioncountPositive,
						questionPoint: this.props.realExamReducerData.extraGetRealExam.questions[
							this.state.currentQuestionorderCount - 1
						].point,
					})
				}
			})
		}

		if (
			this.props.realExamReducerData.extraGetRealExam.questions.length ===
			this.state.currentQuestionorderCount
		) {
			this.setState({ currentQuestionenabled: false, currentExamFinished: true })

			this.props.actions.finishRealExam({
				ExamHistoryId: this.state.id,
			})
		} else {
			if (this.state.questionPassOption === 'auto') {
				this.startQuestionInterval(this.state.currentQuestionorderCount)
				this.setState({
					currentQuestionorderCount: this.state.currentQuestionorderCount + 1,
				})
				this.resetAnswer()
			} else {
				this.setState({ currentQuestionenabled: false })
			}
		}
	}

	stopQuestionInterval = () => {
		this.setState({ currentQuestionenabled: false })
	}

	startQuestionInterval = (orderCount) => {
		let exam = this.props.realExamReducerData.extraGetRealExam.questions[orderCount]
		let question = exam.question
		this.setState({
			currentQuestionenabled: true,
			exam,
			question,
			currentQuestioncount: exam.timeOut,
			currentQuestionText: question.questionText,

			buttonOneFileUrl: question.answers[0].fileUrl,
			buttonTwoFileUrl: question.answers[1].fileUrl,
			buttonThreeFileUrl: question.answers[2].fileUrl,
			buttonFourFileUrl: question.answers[3].fileUrl,

			buttonOneText: question.answers[0].answerText,
			buttonTwoText: question.answers[1].answerText,
			buttonThreeText: question.answers[2].answerText,
			buttonFourText: question.answers[3].answerText,

			buttonOneKey: question.answers[0].id,
			buttonTwoKey: question.answers[1].id,
			buttonThreeKey: question.answers[2].id,
			buttonFourKey: question.answers[3].id,
			currentQuestioncountPositive: 0,
		})
	}

	resetQuestionInterval = () => {
		this.setState({
			currentQuestioncount: 0,
			currentQuestionenabled: false,
			currentQuestioncountPositive: 0,
		})
	}

	selectAnswer = (e) => {
		if (e.target.id === 'buttonOneC') {
			this.setState({
				buttonOneC: 'info',
				buttonTwoC: 'secondary',
				buttonThreeC: 'secondary',
				buttonFourC: 'secondary',
				selectedAnswerId: this.state.buttonOneKey,
			})
		} else if (e.target.id === 'buttonTwoC') {
			this.setState({
				buttonOneC: 'secondary',
				buttonTwoC: 'info',
				buttonThreeC: 'secondary',
				buttonFourC: 'secondary',
				selectedAnswerId: this.state.buttonTwoKey,
			})
		} else if (e.target.id === 'buttonThreeC') {
			this.setState({
				buttonOneC: 'secondary',
				buttonTwoC: 'secondary',
				buttonThreeC: 'info',
				buttonFourC: 'secondary',
				selectedAnswerId: this.state.buttonThreeKey,
			})
		} else if (e.target.id === 'buttonFourC') {
			this.setState({
				buttonOneC: 'secondary',
				buttonTwoC: 'secondary',
				buttonThreeC: 'secondary',
				buttonFourC: 'info',
				selectedAnswerId: this.state.buttonFourKey,
			})
		}
	}

	resetAnswer = () => {
		this.setState({
			buttonOneC: 'secondary',
			buttonTwoC: 'secondary',
			buttonThreeC: 'secondary',
			buttonFourC: 'secondary',
			selectedAnswerId: '',
			AnswerWasTrue: '',
		})
	}

	startExam = () => {
		this.toggleCurrentQuestionModal()
		this.setState({ startStatuss: !this.state.startStatuss })

		this.startQuestionInterval(this.state.currentQuestionorderCount)
		this.setState({
			currentQuestionorderCount: this.state.currentQuestionorderCount + 1,
		})
		this.resetAnswer()
	}

	onUnload = (e) => {
		const confirmationMessage =
			'Testi tamamlamadan çıkmak üzeresiniz. Eğer çıkarsanız testiniz tamamlanmış sayılacak ve bu teste devam edemeyeceksiniz. Yine de çıkmak istiyor musunuz?'
		e.returnValue = confirmationMessage // Gecko, Trident, Chrome 34+
		return confirmationMessage // Gecko, WebKit, Chrome <34
	}

	nextQuestion = () => {
		if (
			this.props.realExamReducerData.extraGetRealExam.questions.length ===
			this.state.currentQuestionorderCount
		) {
			this.setState({
				currentExamFinished: !this.state.currentExamFinished,
				currentQuestionenabled: false,
			})
			window.removeEventListener('beforeunload', this.onUnload)
		} else {
			window.addEventListener('beforeunload', this.onUnload)
			this.startQuestionInterval(this.state.currentQuestionorderCount)
			this.setState({
				currentQuestionorderCount: this.state.currentQuestionorderCount + 1,
			})
			this.resetAnswer()
		}
	}

	toggleCurrentQuestionModal = () => {
		this.setState({ currentQuestionModalVis: !this.state.currentQuestionModalVis })
	}

	option = (color, onClick, fileUrl, text, id) => (
		<div className="col-lg-3" style={{ marginTop: 10 }}>
			<Button size="lg" block color={color} id={id} onClick={onClick}>
				{fileUrl && <img src={fileUrl} style={{ maxHeight: 100 }} />}
				{text}
			</Button>
		</div>
	)

	currentQuestionModal() {
		return (
			<Modal
				isOpen={this.state.currentQuestionModalVis}
				style={{ maxWidth: '1600px', width: '96%' }}
			>
				<ReactInterval
					timeout={this.state.currentQuestiontimeout}
					enabled={this.state.currentQuestionenabled}
					callback={this.interClassBack}
				/>

				{this.state.currentQuestionenabled === true ? (
					<div className="modal-header">
						<Badge
							color="secondary"
							overlap="circle"
							style={{
								zoom: 1.5,
								position: 'absolute',
								top: 3,
								left: 3,
								boxShadow: '0 0 5px rgba(0,0,0,.3)',
							}}
							badgeContent={this.state.currentQuestionorderCount}
						/>
						<h5 className="modal-title" style={{ width: '100%' }}>
							<div
								dangerouslySetInnerHTML={{ __html: this.state.currentQuestionText }}
							></div>
							{this.state.question && this.state.question.fileUrl && (
								<img src={this.state.question.fileUrl} />
							)}
						</h5>

						<span
							style={{
								position: 'absolute',
								right: -5,
								top: -5,
								zoom: 1.5,
								boxShadow: '0 0 5px rgba(0,0,0,.3)',
							}}
							className={
								this.state.timeIsOnDanger === true
									? 'badge badge-danger'
									: 'badge badge-secondary'
							}
						>
							Süre: {this.state.currentQuestioncount}
						</span>
					</div>
				) : null}

				<ModalBody>
					{this.state.currentQuestionenabled === false ? (
						<div className="row">
							<div className="col-lg-4" style={{ textAlign: 'center' }}>
								{this.state.currentExamFinished === true ? (
									<img src={Reward} alt="Exam Logo" width={100} />
								) : (
									<img src={Desklamp} alt="Exam Logo" width={100} />
								)}
							</div>

							<div
								className="col-lg-8"
								style={{ width: '100%', height: '100%', textAlign: 'center' }}
							>
								{this.state.currentExamFinished === true ? (
									<h3
										style={{
											textAlign: 'center',
											left: '50%',
											top: '50%',
											width: '100%',
											height: '100%',
											marginTop: '50px',
										}}
									>
										Tebrikler! Testinizi bitirdiniz!
									</h3>
								) : (
									<h3
										style={{
											textAlign: 'center',
											left: '50%',
											top: '50%',
											width: '100%',
											height: '100%',
											marginTop: '50px',
										}}
									>
										Bir sonraki soruya geçmeye hazır mısın?
										{this.state.resultType === 1 ? (
											this.state.AnswerWasTrue === 'noanswer' ? (
												<div>Soruyu Boş Bıraktınız!</div>
											) : this.state.AnswerWasTrue === true ? (
												<div>Tebrikler Cevabınız Doğru!</div>
											) : (
												<div>Üzgünüz Yanlış Bir Cevap Verdin!</div>
											)
										) : (
											<div>Sonraki Soruya Geçebilirsiniz!</div>
										)}
									</h3>
								)}
							</div>
						</div>
					) : (
						<div>
							<div className="row">
								{this.option(
									this.state.buttonOneC,
									this.selectAnswer,
									this.state.buttonOneFileUrl,
									this.state.buttonOneText,
									'buttonOneC'
								)}
								{this.option(
									this.state.buttonTwoC,
									this.selectAnswer,
									this.state.buttonTwoFileUrl,
									this.state.buttonTwoText,
									'buttonTwoC'
								)}
								{this.option(
									this.state.buttonThreeC,
									this.selectAnswer,
									this.state.buttonThreeFileUrl,
									this.state.buttonThreeText,
									'buttonThreeC'
								)}
								{this.option(
									this.state.buttonFourC,
									this.selectAnswer,
									this.state.buttonFourFileUrl,
									this.state.buttonFourText,
									'buttonFourC'
								)}
							</div>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					{this.state.currentQuestionenabled === true ? (
						<div>
							<ButtonGroup>
								<Button color="success" onClick={this.manuelNextQuestion}>
									Devam
								</Button>
							</ButtonGroup>
						</div>
					) : this.state.currentExamFinished === false ? (
						<div>
							<Button color="danger mr-1" onClick={this.toggleLeaveExam}>
								Ayrıl
							</Button>
							<Button color="success" onClick={this.nextQuestion}>
								İleri
							</Button>
						</div>
					) : (
						<Button
							color="success"
							onClick={() =>
								this.state.resultType == 1
									? this.goToPreview()
									: this.selectNavigation('Profile')
							}
						>
							Testi Bitir
						</Button>
					)}
				</ModalFooter>
			</Modal>
		)
	}

	toggleLeaveExam = () => {
		this.setState({ openLeaveModal: !this.state.openLeaveModal })
	}

	exitConfirmModal() {
		return (
			<Modal isOpen={this.state.openLeaveModal}>
				<ModalBody>Testten ayrılmak istediğinize eminmisiniz?</ModalBody>
				<ModalFooter>
					<Button color="info mr-1" onClick={this.toggleLeaveExam}>
						Teste Devam Et
					</Button>
					<Button color="danger" onClick={() => this.selectNavigation('Profile')}>
						Ayrıl
					</Button>
				</ModalFooter>
			</Modal>
		)
	}

	toggleLeaveMessages = () => {
		this.setState({ openMessageModal: !this.state.openMessageModal })
	}

	messageModal() {
		let exam = this.props.realExamReducerData.extraGetRealExam.exam
		return (
			<Modal isOpen={this.state.openMessageModal}>
				<ModalBody>
					<p className="lead">{exam.adminDescription}</p>
					<hr className="my-2" />
					<p className="lead">{exam.userDescription}</p>
				</ModalBody>
				<ModalFooter>
					<Button color="success" onClick={this.toggleLeaveMessages}>
						Okudum Anladım!
					</Button>
				</ModalFooter>
			</Modal>
		)
	}

	render() {
		return (
			<ScrollView>
				<link href="/realexam.css" type="text/css" rel="stylesheet" />
				<div
					className="realExamOverlay"
					style={{ backgroundColor: '#e9ecef', overflow: 'scroll' }}
				>
					<div className="bodyExam">
						<div className="row">
							<div className="col-lg-12">
								{this.currentQuestionModal()}
								{this.exitConfirmModal()}
								{this.messageModal()}

								<Button color="link" className="closeExam" onClick={this.toggleLeaveExam}>
									<CloseIcon color="action"></CloseIcon>
								</Button>

								{this.state.messagesIsVisible === true ? (
									<Button
										color="link"
										className="MessageExam"
										onClick={this.toggleLeaveMessages}
									>
										<Badge color="secondary" badgeContent={'!'}>
											<MailOutlineIcon color="action" />
										</Badge>
									</Button>
								) : null}

								<Jumbotron style={{ marginBottom: 0 }} className="row">
									{this.state.startStatuss === false && (
										<React.Fragment>
											<div className="col-lg-6" style={{ marginBottom: 20 }}>
												<h1 className="display-3" style={{ fontSize: '3rem' }}>
													{this.props.realExamReducerData.extraGetRealExam.exam.examName} Testi
												</h1>

												<hr className="my-2" />

												<div>
													<p className="lead">
														{' '}
														<strong className="text-bold">Okul:</strong>{' '}
														{this.props.realExamReducerData.extraGetRealExam.school.schoolName}{' '}
													</p>
													<p className="lead">
														<strong className="text-bold">Sınıf:</strong>{' '}
														{this.props.realExamReducerData.extraGetRealExam.classroom.className}{' '}
													</p>
													<p className="lead">
														<strong className="text-bold">Ders:</strong>{' '}
														{this.props.realExamReducerData.extraGetRealExam.lesson.lessonName}{' '}
													</p>
													<p className="lead">
														<strong className="text-bold">Konu:</strong>{' '}
														{this.props.realExamReducerData.extraGetRealExam.topic.topicName}{' '}
													</p>

													{this.props.realExamReducerData.extraGetRealExam.exam
														.userDescription && (
														<p className="lead">
															<strong className="text-bold">Öğretmen Açıklaması:</strong>{' '}
															{this.props.realExamReducerData.extraGetRealExam.exam.userDescription}{' '}
														</p>
													)}

													<hr className="my-2" />

													<p className="lead">
														<strong className="text-bold">Toplam Soru:</strong>{' '}
														{this.props.realExamReducerData.extraGetRealExam.questions.length} Adet{' '}
													</p>
													<p className="lead">
														<strong className="text-bold">Toplam Süre:</strong>{' '}
														{this.compueExamTime()} Dakika
													</p>
												</div>

												<img
													src={ExamSvg}
													alt="Exam Logo"
													className="imgExam d-none d-md-block"
													width={100}
												/>

												<Button
													color="danger"
													size="lg"
													className="float-right"
													style={{ fontSize: '2rem' }}
													onClick={this.startExam}
												>
													Teste Başla
												</Button>
											</div>
											<div className="col-lg-6" style={{ zIndex: 0 }}>
												{this.props.realExamReducerData.extraGetRealExam.topic.id &&
													this.props.realExamReducerData.extraGetRealExam.exam.showMaterial && (
													<TopicMaterialView
														topicId={this.props.realExamReducerData.extraGetRealExam.topic.id}
													/>
												)}
											</div>
										</React.Fragment>
									)}
								</Jumbotron>
							</div>
						</div>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		realExamReducerData: state.realExamReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getAllrealExamStart: bindActionCreators(
				realExamActions.getAllrealExamStart,
				dispatch
			),
			resetGetAllrealExamStartSuccess: bindActionCreators(
				realExamActions.resetGetAllrealExamStartSuccess,
				dispatch
			),

			postAnswerRealEXam: bindActionCreators(
				realExamActions.postAnswerRealEXam,
				dispatch
			),
			resetPostAnswerRealEXamSuccess: bindActionCreators(
				realExamActions.resetPostAnswerRealEXamSuccess,
				dispatch
			),

			finishRealExam: bindActionCreators(realExamActions.finishRealExam, dispatch),
			resetFinishRealExamSuccess: bindActionCreators(
				realExamActions.resetFinishRealExamSuccess,
				dispatch
			),
			fullResetRealExam: bindActionCreators(
				realExamActions.fullResetRealExam,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(realexam))
