import {
	GET_EXAM_RESULT_SUCCESS,
	GET_USERS_GET_ASSIGNED_USERS_SUCCESS,
	GET_EXAM_RESULT_FAULT,
	GET_EXAM_JOINED_USERS_SUCCESS,
	GET_EXAM_QUESTION_USER_REPORT_SUCCESS,
	REMOVE_STUDENT_TEST_RESULT_SUCCESS,
	RESET_STATUSS_EXAM_RESULT,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

/** <ExamResult */
export const faultOnActsExamResult = (payload) => {
	return {
		type: GET_EXAM_RESULT_FAULT,
		payload,
	}
}

export const getExamsJoinedUsersSuccess = (payload) => {
	return {
		type: GET_EXAM_JOINED_USERS_SUCCESS,
		payload,
	}
}

export const getExamQuestionUserReportSuccess = (payload) => {
	return {
		type: GET_EXAM_QUESTION_USER_REPORT_SUCCESS,
		payload,
	}
}

export const resetAllExamResultStatus = (payload) => {
	return {
		type: RESET_STATUSS_EXAM_RESULT,
		payload,
	}
}

export const removeStudentTestResultSuccess = (payload) => {
	return {
		type: REMOVE_STUDENT_TEST_RESULT_SUCCESS,
		payload,
	}
}
/** ExamResult> */

export const getGetJoinedUsers = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/JoinedUsers'

	if (data.ExamId) {
		url = url + '?examId=' + data.ExamId + '&classRoomId=' + data.ClassRoomId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getExamsJoinedUsersSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultOnActsExamResult(ffData))
		})
}

export const getExamQuestionUserReport = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetQuestionsWithUser'

	if (data.ExamId) {
		url = url + '?examId=' + data.ExamId + '&classroomId=' + data.ClassRoomId
	}

	axios
		.post(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getExamQuestionUserReportSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultOnActsExamResult(ffData))
		})
}

export const studentTestReset = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/StudentTestReset'
	axios
		.post(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(removeStudentTestResultSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultOnActsExamResult(ffData))
		})
}
