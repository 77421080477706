import {
	CREATE_SCHOOL_SUCCESS,
	GET_FULL_SCHOOL_SUCCESS,
	GET_SCHOOL_SUCCESS,
	SET_SCHOOL_REG_STATUSS,
	DELETE_SCHOOL_SUCCESS,
	UPDATE_SCHOOL_SUCCESS,
	DELETE_SCHOOL_SUCCESS_STATUSS,
	RESET_STATUSS_SCHOOL,
	ONFAULT_SCHOOL,
	RESET_GET_FULL_SCHOOL_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createSchoolSuccess = (lessonData) => {
	return {
		type: CREATE_SCHOOL_SUCCESS,
		payload: lessonData,
	}
}

export const getSchoolSuccess = (lessonData) => {
	return {
		type: GET_SCHOOL_SUCCESS,
		payload: lessonData,
	}
}

export const getFullSchoolSuccess = (lessonData) => {
	return {
		type: GET_FULL_SCHOOL_SUCCESS,
		payload: lessonData,
	}
}

export const setSchoolRegStatuss = (lessonData) => {
	return {
		type: SET_SCHOOL_REG_STATUSS,
		payload: lessonData,
	}
}

export const deleteSchoolSuccess = (lessonData) => {
	return {
		type: DELETE_SCHOOL_SUCCESS,
		payload: lessonData,
	}
}

export const updateSchoolSuccess = (lessonData) => {
	return {
		type: UPDATE_SCHOOL_SUCCESS,
		payload: lessonData,
	}
}

export const changeDeletedSchoolSuccessStatus = (data) => {
	return {
		type: DELETE_SCHOOL_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetGetFullSchoolsSuccess = (data) => {
	return {
		type: RESET_GET_FULL_SCHOOL_SUCCESS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_SCHOOL,
		payload: data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_SCHOOL,
		payload: data,
	}
}

export const createSchool = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Schools/Add', data)
		.then((res) => {
			const AddedLesson = res.data
			dispatch(createSchoolSuccess(AddedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getSchool = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Schools/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const schooldata = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getSchoolSuccess(schooldata))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullSchool = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Schools/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullSchoolssData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullSchoolSuccess(fullSchoolssData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteSchool = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Schools/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedLesson = res.data

			dispatch(deleteSchoolSuccess(deletedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteSchool = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Schools/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(deleteSchoolSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateSchool = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Schools/Update'
	// if (data.id) {
	//     url = url + "?id=" + data.id;
	// }
	axios
		.put(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(updateSchoolSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
