import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, FormGroup } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ScrollView } from '../base/scroll'

import alertifyjs from 'alertifyjs'

import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'

import * as managerNotAssignedActions from '../../../redux/actions/managerNotAssignedActions'
import * as managerOnSchoolsActions from '../../../redux/actions/managerOnSchoolsActions'

import Icon3 from '@material-ui/icons/ImportExport'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import initialState from '../../../redux/reducers/initialState'
import { Accordion, Card } from 'react-bootstrap'

class ExamForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: new Date().toISOString().substring(0, 10),

			StudentsListData_onQuery: [],
			AddedManagerListData_onQuery: [],

			ToggState: false,
			onActModal: { questionText: '', questionTypeId: '', fileUrl: '' },

			addedUser: '',
			removedUser: '',

			SchoolHasUsersColumn: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.canRemoveManagerToSchool
						) === true ? (
								<Button color="light" onClick={() => this.removeFromClassRoom(row)}>
									<HighlightOffIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
				{
					name: 'Soru Text',
					selector: 'firstName',
					sortable: true,
					width: '20rem',
				},
				{
					name: 'Soru Zamanı',
					selector: 'lastName',
					sortable: true,
					width: '20rem',
				},
			],

			allNOTAssignedUSersColumn: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.canAddManagerToSchool
						) === true ? (
								<Button color="light" onClick={() => this.addToClassroom(row)}>
									<AddCircleOutlineIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
				{
					name: 'Soru Text',
					selector: 'firstName',
					sortable: true,
					width: '20rem',
				},
				{
					name: 'Soru Zamanı',
					selector: 'lastName',
					sortable: true,
					width: '20rem',
				},
			],
		}
	}

	componentDidMount() {
		this.props.actions.getManagersNotAssigned({
			RoleId: this.props.location.state.roleid,
		})

		this.props.actions.getManagerOnSchool({
			id: this.props.location.state.data.id,
			RoleId: this.props.location.state.roleid,
		})

		this.props.actions.resetManagersNotAssignedSuccess('')
		this.props.actions.resetManagerOnSchoolSuccess('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.managersNotAssignedReducerData.success) {
			newProps.actions.resetManagersNotAssignedSuccess('')

			if (newProps.managersOnClassroomReducerData.success) {
				var datamm = []

				newProps.managersNotAssignedReducerData.extra.map((aa) => {
					datamm.push(aa)
				})

				this.setState({ StudentsListData_onQuery: datamm })

				this.setState({
					AddedManagerListData_onQuery: newProps.managersOnClassroomReducerData.extra,
				})

				newProps.actions.resetManagerOnSchoolSuccess('')
			}
		}

		if (newProps.managersOnClassroomReducerData.Addedsuccess) {
			var dataMid = this.state.AddedManagerListData_onQuery.concat([
				this.state.addedUser,
			])
			this.setState({ AddedManagerListData_onQuery: dataMid })

			var StudentsListData_onQuery = this.state.StudentsListData_onQuery.filter(
				(value) => value.id !== this.state.addedUser.id
			)
			this.setState({ StudentsListData_onQuery })
			newProps.actions.resetManagerOnSchoolSuccess('')
		} else if (newProps.managersOnClassroomReducerData.Addedsuccess === false) {
			alertifyjs.error('Hata: ' + newProps.managersOnClassroomReducerData.errorMessage)
			newProps.actions.resetManagerOnSchoolSuccess('')
		}

		if (newProps.managersOnClassroomReducerData.removedSucces) {
			var dataMid = this.state.StudentsListData_onQuery.concat([this.state.removedUser])
			this.setState({ StudentsListData_onQuery: dataMid })

			var AddedManagerListData_onQuery = this.state.AddedManagerListData_onQuery.filter(
				(value) => value.id !== this.state.removedUser.id
			)
			this.setState({ AddedManagerListData_onQuery })
			newProps.actions.resetManagerOnSchoolSuccess('')
		} else if (newProps.managersOnClassroomReducerData.removedSucces === false) {
			alertifyjs.error('Hata: ' + newProps.managersOnClassroomReducerData.errorMessage)
			newProps.actions.resetManagerOnSchoolSuccess('')
		}
	}

	removeFromClassRoom = (data) => {
		var datdat = {
			RemoveUserId: data.id,
			SchoolId: this.props.location.state.data.id,
		}
		this.props.actions.removeManagerFromSchoolroom(datdat)
		this.setState({ removedUser: data })
	}

	addToClassroom = (data) => {
		var datdat = {
			AddUserId: data.id,
			SchoolId: this.props.location.state.data.id,
		}
		this.props.actions.addManagerSchool(datdat)
		this.setState({ addedUser: data })
	}

	renderAllTable1() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Atanabilirler</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.allNOTAssignedUSersColumn}
						data={this.state.StudentsListData_onQuery}
						pagination
						paginationPerPage={5}
						highlightOnHover
						grow={10}
						dense={false}
						direction={'auto'}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderAllTable2() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Atanmışlar</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.SchoolHasUsersColumn}
						data={this.state.AddedManagerListData_onQuery}
						pagination
						highlightOnHover
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.renderAllTable1()}

							{this.renderAllTable2()}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		userAuthData: state.authReducer,

		managersNotAssignedReducerData: state.managerNotAssignedReduces,
		managersOnClassroomReducerData: state.managerOnSchoolsReducers,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getManagersNotAssigned: bindActionCreators(
				managerNotAssignedActions.getManagersNotAssigned,
				dispatch
			),
			getManagerOnSchool: bindActionCreators(
				managerOnSchoolsActions.getManagerOnSchool,
				dispatch
			),

			resetManagersNotAssignedSuccess: bindActionCreators(
				managerNotAssignedActions.resetManagersNotAssignedSuccess,
				dispatch
			),
			resetManagerOnSchoolSuccess: bindActionCreators(
				managerOnSchoolsActions.resetManagerOnSchoolSuccess,
				dispatch
			),

			addManagerSchool: bindActionCreators(
				managerOnSchoolsActions.addManagerSchool,
				dispatch
			),
			removeManagerFromSchoolroom: bindActionCreators(
				managerOnSchoolsActions.removeManagerFromSchoolroom,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamForm))
