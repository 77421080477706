import {
	CREATE_CLASSROOM_SUCCESS,
	GET_FULL_CLASSROOM_SUCCESS,
	GET_CLASSROOM_SUCCESS,
	CHANGE_CLASSROOM_STATUSS_SCHOOL,
	DELETE_CLASSROOM_SUCCESS,
	UPDATE_CLASSROOM_SUCCESS,
	DELETE_CLASSROOM_SUCCESS_STATUSS,
	RESET_STATUSS_CLASSROOM,
	ONFAULT_CLASSROOM,
	RESET_FULL_STATUSS_CLASSROOM,
	FIND_CLASSROOM_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createClassroomSuccess = (lessonData) => {
	return {
		type: CREATE_CLASSROOM_SUCCESS,
		payload: lessonData,
	}
}

export const getClassroomSuccess = (lessonData) => {
	return {
		type: GET_CLASSROOM_SUCCESS,
		payload: lessonData,
	}
}

export const findClassroomSuccess = (payload) => {
	return {
		type: FIND_CLASSROOM_SUCCESS,
		payload,
	}
}

export const getFullClassroomSuccess = (lessonData) => {
	return {
		type: GET_FULL_CLASSROOM_SUCCESS,
		payload: lessonData,
	}
}

export const resetFullStatussClassrooms = (lessonData) => {
	return {
		type: RESET_FULL_STATUSS_CLASSROOM,
		payload: lessonData,
	}
}

export const setClassroomRegStatuss = (lessonData) => {
	return {
		type: CHANGE_CLASSROOM_STATUSS_SCHOOL,
		payload: lessonData,
	}
}

export const deleteClassroomSuccess = (lessonData) => {
	return {
		type: DELETE_CLASSROOM_SUCCESS,
		payload: lessonData,
	}
}

export const updateClassroomSuccess = (lessonData) => {
	return {
		type: UPDATE_CLASSROOM_SUCCESS,
		payload: lessonData,
	}
}

export const changeDeletedClassroomSuccessStatus = (data) => {
	return {
		type: DELETE_CLASSROOM_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_CLASSROOM,
		payload: data,
	}
}

export const onFault = (data) => {
	return {
		type: ONFAULT_CLASSROOM,
		payload: data,
	}
}

export const createClassroom = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Classrooms/Add', data)
		.then((res) => {
			const AddedLesson = res.data
			dispatch(createClassroomSuccess(AddedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getClassroom = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Classrooms/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const schooldata = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getClassroomSuccess(schooldata))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullClassroom = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Classrooms/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullClassroomssData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullClassroomSuccess(fullClassroomssData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Classrooms/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedLesson = res.data

			dispatch(deleteClassroomSuccess(deletedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Classrooms/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(deleteClassroomSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Classrooms/Update'
	// if (data.id) {
	//     url = url + "?id=" + data.id;
	// }
	axios
		.put(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(updateClassroomSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const findClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = `/api/Classrooms/Find?id=${data.id}`
	axios
		.get(url)
		.then((res) => {
			const updated = res.data

			dispatch(findClassroomSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
