import {
	CREATE_LESSON_SUCCESS,
	GET_FULL_LESSON_SUCCESS,
	GET_LESSON_SUCCESS,
	DELETE_LESSON_SUCCESS,
	UPDATE_LESSON_SUCCESS,
	DELETE_LESSON_SUCCESS_STATUSS,
	RESET_CREATE_LESSON_SUCCESS,
	RESET_GET_LESSON_SUCCESS,
	RESET_GET_FULL_LESSON_SUCCESS,
	RESET_UPDATE_LESSON_SUCCESS,
	RESET_DELETE_LESSON_SUCCESS,
	RESET_DELETE_LESSON_SUCCESS_STATUSS,
	RESET_STATUSS_LESSON,
	ONFAULT_LESSON,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createLessonSuccess = (lessonData) => {
	return {
		type: CREATE_LESSON_SUCCESS,
		payload: lessonData,
	}
}

export const getLessonSuccess = (lessonData) => {
	return {
		type: GET_LESSON_SUCCESS,
		payload: lessonData,
	}
}

export const getFullLessonsSuccess = (lessonData) => {
	return {
		type: GET_FULL_LESSON_SUCCESS,
		payload: lessonData,
	}
}

export const deleteLessonSuccess = (lessonData) => {
	return {
		type: DELETE_LESSON_SUCCESS,
		payload: lessonData,
	}
}

export const updateLessonSuccess = (lessonData) => {
	return {
		type: UPDATE_LESSON_SUCCESS,
		payload: lessonData,
	}
}

export const changeDeletedLessonSuccessStatus = (data) => {
	return {
		type: DELETE_LESSON_SUCCESS_STATUSS,
		payload: data,
	}
}

//******************************************************** */ RESET

export const resetCreateLessonSuccess = (data) => {
	return {
		type: RESET_CREATE_LESSON_SUCCESS,
		payload: data,
	}
}

export const resetGetLessonSuccess = (data) => {
	return {
		type: RESET_GET_LESSON_SUCCESS,
		payload: data,
	}
}

export const resetGetFullLessonSuccess = (data) => {
	return {
		type: RESET_GET_FULL_LESSON_SUCCESS,
		payload: data,
	}
}

export const resetUpdateLessonSuccess = (data) => {
	return {
		type: RESET_UPDATE_LESSON_SUCCESS,
		payload: data,
	}
}

export const resetDeleteLessonSuccess = (data) => {
	return {
		type: RESET_DELETE_LESSON_SUCCESS,
		payload: data,
	}
}

export const resetDeleteLessonSuccessStatuss = (data) => {
	return {
		type: RESET_DELETE_LESSON_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: RESET_STATUSS_LESSON,
		payload: data,
	}
}

//******************************************************** */  FAULT

export const onFault = (data) => {
	return {
		type: ONFAULT_LESSON,
		payload: data,
	}
}

export const createLesson = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Lessons/Add', data)
		.then((res) => {
			const AddedLesson = res.data
			dispatch(createLessonSuccess(AddedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getLessons = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Lessons/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const lessonData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getLessonSuccess(lessonData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getFullLessons = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Lessons/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullLessonssData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullLessonsSuccess(fullLessonssData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteLesson = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Lessons/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedLesson = res.data

			dispatch(deleteLessonSuccess(deletedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteLesson = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Lessons/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(deleteLessonSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const UpdateLesson = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Lessons/Update'
	// if (data.id) {
	//     url = url + "?id=" + data.id+ '&' + "lessonName=" + data.lessonName;
	// }
	var cardddata = { Id: data.id, LessonName: data.LessonName }
	axios
		.put(url, cardddata)
		.then((res) => {
			const updatedLesson = res.data

			dispatch(updateLessonSuccess(updatedLesson))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
