/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import $ from 'jquery'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Input } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as getQuestionWithAnswersActions from '../../../redux/actions/getQuestionWithAnswersActions'
import * as questionActions from '../../../redux/actions/questionActions'
import * as questionTypeActions from '../../../redux/actions/questionTypeActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'









const ref = React.createRef()

class Questions extends Component {
	find_in_object(array1, my_criteria) {
		return array1.map((obj) => {
			if (obj.id === my_criteria) {
				return obj.typeName
			}
		})
	}

	constructor(props) {
		super(props)

		this.state = {
			QuestionTypeSelectionFull: '',
			QuestionTypeSelection: '',

			QuestionFullListData_onQuery: [],
			QuestionListData_onQuery: [],

			dataChart: [
				{
					name: 'Matematik',
					Adet: 5,
				},
				{
					name: 'İngilizce',
					Adet: 10,
				},
				{
					name: 'Almanca',
					Adet: 50,
				},
				{
					name: 'Fransızca',
					Adet: 20,
				},
			],

			QuestionsColumns: [
				{
					name: 'Soru Text',
					selector: 'questionText',
					sortable: true,
					cell: (row) => (
						<div
							dangerouslySetInnerHTML={{
								__html: row.questionText,
							}}
						></div>
					),
				},
				{
					name: 'Soru Tipi',
					selector: 'questionType',
					sortable: true,
					maxWidth: '140px',
				},
				{
					name: 'Oluşturulma Zamanı',
					selector: 'createdDate',
					sortable: true,
					maxWidth: '120px',
					cell: (row) => row.createDate.split('T')[0],
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
					width: '70px',
				},
				{
					cell: (row) => (
						<i
							className="fa fa-file-pdf-o fa-2x"
							onClick={() => this.createOnlyQuestionPdf(row)}
						/>
					),
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '50px',
				},
				{
					cell: (row) =>
						(this.props.userAuthData.permissions.includes(
							initialState.staticPerms.adminPermsId
						) ||
							(this.props.userAuthData.permissions.includes(
								initialState.staticPerms.UpdateQuestion
							) &&
								row.createdBy == localStorage.getItem('id'))) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateQuestions')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateQuestion
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						(this.props.userAuthData.permissions.includes(
							initialState.staticPerms.adminPermsId
						) ||
							(this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullDeleteQuestion
							) &&
								row.createdBy == localStorage.getItem('id'))) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteQuestion
						) == true
							? '50px'
							: '0px',
				},
			],

			FullQuestionColumns: [
				{
					name: 'Soru Text',
					selector: 'questionText',
					sortable: true,
					cell: (row) => (
						<div dangerouslySetInnerHTML={{ __html: row.questionText }}></div>
					),
				},
				{
					name: 'Soru Tipi',
					selector: 'questionType',
					sortable: true,
					maxWidth: '140px',
				},
				{
					name: 'Oluşturulma Zamanı',
					selector: 'createdDate',
					sortable: true,
					maxWidth: '120px',
					cell: (row) => row.createDate.split('T')[0],
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
					width: '70px',
				},
				{
					cell: (row) => (
						<i
							className="fa fa-file-pdf-o fa-2x"
							onClick={() => this.createOnlyQuestionPdf(row)}
						/>
					),
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '50px',
				},
				{
					cell: (row) =>
						(this.props.userAuthData.permissions.includes(
							initialState.staticPerms.adminPermsId
						) ||
							(this.props.userAuthData.permissions.includes(
								initialState.staticPerms.UpdateQuestion
							) &&
								row.createdBy == localStorage.getItem('id'))) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateQuestions')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateQuestion
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						(this.props.userAuthData.permissions.includes(
							initialState.staticPerms.adminPermsId
						) ||
							(this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullDeleteQuestion
							) &&
								row.createdBy == localStorage.getItem('id'))) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteQuestion
						) == true
							? '65px'
							: '0px',
				},
			],
		}
	}

	componentWillMount() {
		this.props.actions.getQuestionTypes()
	}

	componentDidMount() {
		this.props.actions.getQuestions()
		this.props.actions.getFullQuestions()

		this.setState({ QuestionListData_onQuery: this.props.questionsData.extra })
		this.setState({
			QuestionFullListData_onQuery: this.props.fullQuestionData.extra,
		})

		this.props.actions.changeDeletedQuestionSuccessStatus('')
		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.questionsData.deleted) {
			alertifyjs.success('Soru Başarılı Olarak Silindi!')
			newProps.actions.changeDeletedQuestionSuccessStatus('')
			newProps.actions.resetAllStatuss('')
			newProps.actions.getQuestions()
			newProps.actions.getFullQuestions()
		} else if (newProps.questionsData.deleted === false) {
			alertifyjs.error('Soru Silinirken Hatalar Oluştu.')
			newProps.actions.changeDeletedQuestionSuccessStatus('')
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.getQuestionsWithAnswersReducerData.success === true) {
			this.exportPDFOnlyOneQuestion(newProps.getQuestionsWithAnswersReducerData.extra)
			this.props.actions.resetQuestionWithAnswersStatuss()
		}

		if (newProps.questionsData.success) {
			this.setState({ QuestionListData_onQuery: newProps.questionsData.extra })
			newProps.actions.resetAllStatuss('')
		} else if (newProps.questionsData.success === false) {
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.fullQuestionData.success) {
			this.setState({
				QuestionFullListData_onQuery: newProps.fullQuestionData.extra,
			})
			newProps.actions.resetGetFullQuestionSuccess('')
		} else if (newProps.fullQuestionData.success === false) {
			newProps.actions.resetGetFullQuestionSuccess('')
		}
	}

	exportPDF = (dataGElen) => {
		const unit = 'pt'
		const size = 'A4' // Use A1, A2, A3 or A4
		const orientation = 'portrait' // portrait or landscape

		const marginLeft = 40
		const doc = new jsPDF(orientation, unit, size)

		doc.setFontSize(15)

		const title = 'SORU RAPORLAMALARI'
		const headers = [['SORU TEXT']]

		const data = dataGElen.map((elt) => [elt.questionText])

		let content = {
			startY: 50,
			head: headers,
			body: data,
		}

		doc.text(title, marginLeft, 40)
		doc.autoTable(content)
		doc.save('report.pdf')
	}

	exportPDFOnlyOneQuestion = (dataGElen) => {
		const unit = 'pt'
		const size = 'A3' // Use A1, A2, A3 or A4
		const orientation = 'portrait' // portrait or landscape

		const marginLeft = 50
		const doc = new jsPDF(orientation, unit, size)

		doc.setFontSize(9)

		const title = 'SORU RAPORLAMALARI'

		var linecount = 40

		doc.text(title, marginLeft, linecount)

		doc.setFontType('bold')
		var atitle = doc.splitTextToSize('SORU: ' + dataGElen.questionText, 300)
		var linecount = linecount + atitle.length * 10
		doc.text(marginLeft, linecount, atitle)
		doc.setFontType('normal')

		if (dataGElen.rightAnswer === dataGElen.answers[0].id) {
			doc.setTextColor(0, 100, 0)
		} else {
			doc.setTextColor(0, 0, 0)
		}
		var a1 = doc.splitTextToSize(dataGElen.answers[0].answerText, 300)
		var linecount = linecount + 20 + a1.length * 10
		doc.text(marginLeft, linecount, a1)

		if (dataGElen.rightAnswer === dataGElen.answers[1].id) {
			doc.setTextColor(0, 100, 0)
		} else {
			doc.setTextColor(0, 0, 0)
		}
		var a2 = doc.splitTextToSize(dataGElen.answers[1].answerText, 300)
		var linecount = linecount + 20 + a2.length * 10
		doc.text(marginLeft, linecount, a2)

		if (dataGElen.rightAnswer === dataGElen.answers[2].id) {
			doc.setTextColor(0, 100, 0)
		} else {
			doc.setTextColor(0, 0, 0)
		}
		var a3 = doc.splitTextToSize(dataGElen.answers[2].answerText, 300)
		var linecount = linecount + 20 + a3.length * 10
		doc.text(marginLeft, linecount, a3)

		if (dataGElen.rightAnswer === dataGElen.answers[3].id) {
			doc.setTextColor(0, 100, 0)
		} else {
			doc.setTextColor(0, 0, 0)
		}
		var a4 = doc.splitTextToSize(dataGElen.answers[3].answerText, 300)
		var linecount = linecount + 20 + a4.length * 10
		doc.text(marginLeft, linecount, a4)

		// doc.autoTable(content);
		doc.save('report.pdf')
	}

	createOnlyQuestionPdf = (row, navigation) => {
		var data = {
			id: row.id,
		}

		this.props.actions.getQuestionsWithAnswer(data)
	}

	selectNavigation = (navigation) => {
		this.props.actions.changeDeletedQuestionSuccessStatus('')
		this.props.actions.resetAllStatuss('')

		var location = {
			pathname: navigation,
		}

		this.props.history.push(location)
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		var questtype_data = $('#QuestionTypeSelection').val()
		var search_data = $('#outlined-basic').val()

		if (questtype_data.length > 3) {
			if (search_data.length > 0) {
				var QuestionListData_onQuery = this.props.questionsData.extra.filter(
					(item) =>
						(item.questionTypeId.like(questtype_data) &&
							item.questionText.like(search_data)) ||
						item.tagText.like(search_data) ||
						item.createDate.like(search_data)
				)
				this.setState({ QuestionListData_onQuery })
			} else {
				var QuestionListData_onQuery = this.props.questionsData.extra.filter((item) =>
					item.questionTypeId.like(questtype_data)
				)
				this.setState({ QuestionListData_onQuery })
			}
		} else {
			if (search_data.length > 0) {
				var QuestionListData_onQuery = this.props.questionsData.extra.filter(
					(item) =>
						item.questionText.like(search_data) ||
						item.tagText.like(search_data) ||
						item.createDate.like(search_data)
				)
				this.setState({ QuestionListData_onQuery })
			} else {
				this.setState({
					QuestionListData_onQuery: this.props.questionsData.extra,
				})
			}
		}
	}

	searchOnFullList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		var questtype_data = $('#QuestionTypeSelectionFull').val()
		var search_data = $('#outlined-basic-full').val()

		if (questtype_data.length > 3) {
			if (search_data.length > 0) {
				var QuestionFullListData_onQuery = this.props.fullQuestionData.extra.filter(
					(item) =>
						(item.questionTypeId.like(questtype_data) &&
							item.questionText.like(search_data)) ||
						item.tagText.like(search_data) ||
						item.createDate.like(search_data)
				)
				this.setState({ QuestionFullListData_onQuery })
			} else {
				var QuestionFullListData_onQuery = this.props.fullQuestionData.extra.filter(
					(item) => item.questionTypeId.like(questtype_data)
				)
				this.setState({ QuestionFullListData_onQuery })
			}
		} else {
			if (search_data.length > 0) {
				var QuestionFullListData_onQuery = this.props.fullQuestionData.extra.filter(
					(item) =>
						item.questionText.like(search_data) ||
						item.tagText.like(search_data) ||
						item.createDate.like(search_data)
				)
				this.setState({ QuestionFullListData_onQuery })
			} else {
				this.setState({
					QuestionFullListData_onQuery: this.props.fullQuestionData.extra,
				})
			}
		}
	}

	handleClick = (state) => {}

	onRowClickUpdate = (row, navigation) => {
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteQuestion(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteQuestion(data)
		}
	}

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Sorular</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.QuestionsColumns}
						data={this.state.QuestionListData_onQuery}
						pagination={true}
						noHeader
						highlightOnHover={true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddQuestion
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateQuestions')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindQuestion
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
											placeholder="Text, Date..."
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.QuestionListData_onQuery)}
								/>

								<FormGroup className="float-right">
									<Input
										type="select"
										name="QuestionTypeSelection"
										id="QuestionTypeSelection"
										onChange={this.searchOnList}
										value={this.state.QuestionTypeSelection}
										style={{ margin: '0px 10px' }}
									>
										<option value="-1" key="-1">
											Seçiniz
										</option>
										{this.props.questionTypeReducerData.extra.map((questionType) => {
											return (
												<option value={questionType.id} key={questionType.id}>
													{questionType.typeName}{' '}
												</option>
											)
										})}
									</Input>
								</FormGroup>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Sorular</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullQuestionColumns}
						data={this.state.QuestionFullListData_onQuery}
						pagination
						noHeader
						highlightOnHover
						subHeader
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddQuestion
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateQuestions')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindFullListQuestion
								) === true ? (
										<TextField
											onChange={this.searchOnFullList}
											id="outlined-basic-full"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
											placeholder="Text, Date..."
										/>
									) : null}

								<i
									className="fa fa-file-pdf-o fa-2x"
									onClick={() => this.exportPDF(this.state.QuestionFullListData_onQuery)}
								/>

								<FormGroup>
									<Input
										type="select"
										name="QuestionTypeSelectionFull"
										id="QuestionTypeSelectionFull"
										onChange={this.searchOnFullList}
										value={this.state.QuestionTypeSelectionFull}
										style={{ margin: '0px 10px' }}
									>
										<option value="-1" key="-1">
											Seçiniz
										</option>
										{this.props.questionTypeReducerData.extra.map((questionType) => {
											return (
												<option value={questionType.id} key={questionType.id}>
													{questionType.typeName}{' '}
												</option>
											)
										})}
									</Input>
								</FormGroup>
							</div>
						}
						subHeaderAlign={'left'}
						fixedHeader={false}
						fixedHeaderScrollHeight="300px"
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	render() {
		//************************************************** */
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListQuestion
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListQuestion
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		questionTypeReducerData: state.questionTypeReducer,

		getQuestionsWithAnswersReducerData: state.getQuestionsWithAnswersReducer,

		questionsData: state.questionsReducer,
		fullQuestionData: state.fullQuestionsReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getQuestionsWithAnswer: bindActionCreators(
				getQuestionWithAnswersActions.getQuestionWithAnswers,
				dispatch
			),
			resetQuestionWithAnswersStatuss: bindActionCreators(
				getQuestionWithAnswersActions.resetQuestionWithAnswersStatuss,
				dispatch
			),

			getQuestionTypes: bindActionCreators(
				questionTypeActions.getQuestionTypes,
				dispatch
			),

			getQuestions: bindActionCreators(questionActions.getQuestions, dispatch),

			getFullQuestions: bindActionCreators(questionActions.getFullQuestions, dispatch),
			resetGetFullQuestionSuccess: bindActionCreators(
				questionActions.resetGetFullQuestionSuccess,
				dispatch
			),

			deleteQuestion: bindActionCreators(questionActions.deleteQuestion, dispatch),
			fullDeleteQuestion: bindActionCreators(
				questionActions.fullDeleteQuestion,
				dispatch
			),
			createQuestion: bindActionCreators(questionActions.createQuestion, dispatch),
			changeDeletedQuestionSuccessStatus: bindActionCreators(
				questionActions.changeDeletedQuestionSuccessStatus,
				dispatch
			),
			resetAllStatuss: bindActionCreators(questionActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Questions))
