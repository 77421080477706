import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function getQuestionsReducer(
	state = initialState.questionTypes,
	action
) {
	switch (action.type) {
		case actionTypes.CREATE_QUESTIONTYPE_SUCCESS:
			return {
				...state,
				success: action.payload.success,
				extra: action.payload.extra,
			}

		case actionTypes.DELETE_QUESTIONTYPE_SUCCESS:
			if (action.payload == 'Başarıyla silindi.') {
				return {
					...state,
					deleted: true,
				}
			} else {
				return {
					...state,
					deleted: false,
				}
			}

		case actionTypes.GET_QUESTIONTYPE_SUCCESS:
			return action.payload

		case actionTypes.UPDATE_QUESTIONTYPE_SUCCESS:
			return action.payload

		case actionTypes.RESET_STATUSS_QUESTIONTYPE:
			return {
				...state,
				success: '',
				deleted: '',
				messages: '',
			}

		case actionTypes.ONFAULT_QUESTIONTYPE:
			return {
				...state,
				success: false,
				messages: action.payload.messages,
			}

		default:
			return state
	}
}
