import {
	CREATE_TOPIC_MATERIAL_SUCCESS,
	GET_TOPIC_MATERIAL_SUCCESS,
	DELETE_TOPIC_MATERIAL_SUCCESS,
	UPDATE_TOPIC_MATERIAL_SUCCESS,
	DELETE_TOPIC_MATERIAL_SUCCESS_STATUSS,
	RESET_STATUSS_TOPIC_MATERIAL,
	ONFAULT_TOPIC_MATERIAL,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const createTopicMaterialSuccess = (payload) => {
	return {
		type: CREATE_TOPIC_MATERIAL_SUCCESS,
		payload,
	}
}

export const getTopicMaterialSuccess = (payload) => {
	return {
		type: GET_TOPIC_MATERIAL_SUCCESS,
		payload,
	}
}

export const deleteTopicMaterialSuccess = (payload) => {
	return {
		type: DELETE_TOPIC_MATERIAL_SUCCESS,
		payload,
	}
}

export const updateTopicMaterialSuccess = (payload) => {
	return {
		type: UPDATE_TOPIC_MATERIAL_SUCCESS,
		payload,
	}
}

export const changeDeletedTopicSuccessStatus = (payload) => {
	return {
		type: DELETE_TOPIC_MATERIAL_SUCCESS_STATUSS,
		payload,
	}
}

export const resetAllStatuss = (payload) => {
	return {
		type: RESET_STATUSS_TOPIC_MATERIAL,
		payload,
	}
}

export const onFault = (payload) => {
	return {
		type: ONFAULT_TOPIC_MATERIAL,
		payload,
	}
}

export const createTopicMaterial = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/TopicMaterials/Add', data)
		.then((res) => {
			const AddedTopic = res.data
			dispatch(createTopicMaterialSuccess(AddedTopic))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const getTopicMaterials = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/TopicMaterials/GetByTopic'

	if (data.TopicId) {
		url = url + '?TopicId=' + data.TopicId
	}

	axios
		.get(url, data)
		.then((res) => {
			const TopicData = res.data
			dispatch(getTopicMaterialSuccess(TopicData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteTopicMaterial = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/TopicMaterials/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedTopic = res.data

			dispatch(deleteTopicMaterialSuccess(deletedTopic))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateTopicMaterial = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/TopicMaterials/Update'

	axios
		.put(url, data)
		.then((res) => {
			const updated = res.data

			dispatch(updateTopicMaterialSuccess(updated))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
