/* eslint-disable */
import TextField from '@material-ui/core/TextField'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Badge, Breadcrumb, BreadcrumbItem, Button } from 'reactstrap'
import {
	Bar, BarChart, CartesianGrid,


	Legend, ResponsiveContainer,



	Tooltip, XAxis,
	YAxis
} from 'recharts'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as classroomActions from '../../../redux/actions/classroomActions'
import * as examActions from '../../../redux/actions/examActions'
import * as usersOnClassroomActions from '../../../redux/actions/usersOnClassroomActions'
import initialState from '../../../redux/reducers/initialState'
import { formatDateTime } from '../../../utils/helpers/date'
import { ScrollView } from '../base/scroll'






class ClassroomDetails extends Component {
	constructor(props) {
		super(props)

		console.log('this.props.location.state', this.props.location.state)

		this.state = {
			id: props.location.state.id,
			classroom: {},
			addedExam: '',
			removedExam: '',

			AddedStudentsListData_onQuery: [],
			fullExamByClassroom: [],
			notAssignByClassroom: [],

			ClassroomHasUsersColumn: [
				{
					name: 'Ad',
					selector: 'firstName',
					sortable: true,
				},
				{
					name: 'Soyad',
					selector: 'lastName',
					sortable: true,
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanSeeUserDetails
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.onRowClickUSerDetails(row, 'UserDetails')}
								>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					right: true,
				},
			],

			ClassroomExamColumns: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ClassAssignExam
						) === true ? (
								<Button color="light" onClick={() => this.addExamToClassroom(row)}>
									<AddCircleOutlineIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ClassAssignExam
						) == true
							? '10rem'
							: '0px',
				},
				{
					name: 'Test',
					selector: 'examName',
					sortable: true,
					cell: (row) => (
						<Link
							to={{
								pathname: '/ExamDetails',
								state: {
									...row,
								},
							}}
						>
							{row.examName}
						</Link>
					),
				},
				{
					name: 'Ders',
					selector: 'lessonName',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
				{
					name: 'Konu',
					selector: 'topicName',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},
				{
					name: 'Konu Materyalleri',
					selector: 'materialCount',
					sortable: true,
					width: '100px',
					cell: (row) => (
						<Button
							style={{ width: '100px' }}
							color="dark"
							onClick={() => this.goToTopicDetails(row.topicId)}
						>
							{row.materialCount}
						</Button>
					),
				},
			],

			ClassroomExamColumns2: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ClassNotAssignExam
						) === true ? (
								<Button color="light" onClick={() => this.removeExamToClassroom(row)}>
									<HighlightOffIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ClassNotAssignExam
						) == true
							? '10rem'
							: '0px',
				},
				{
					name: 'Tarih',
					selector: 'AssignedToClassDateTime',
					sortable: true,
					cell: (row) => formatDateTime(row.assignedToClassDateTime),
				},
				{
					name: 'Test',
					selector: 'examName',
					sortable: true,
					cell: (row) => (
						<Link
							to={{
								pathname: '/ExamDetails',
								state: {
									...row,
								},
							}}
						>
							{row.examName}
						</Link>
					),
				},
				{
					name: 'Ders',
					selector: 'timeOut',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
				{
					name: 'Konu',
					selector: 'topicName',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},
				{
					name: 'Video Gösterimi',
					selector: 'showMaterial',
					sortable: true,
					width: '100px',
					cell: (row) => (row.showMaterial ? 'Evet' : 'Hayır'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamResults
						) == true ? (
								<Link
									className="btn btn-success"
									to={{
										pathname: '/ExamResults',
										state: {
											...row,
										},
									}}
								>
								Sonuçlar
								</Link>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamResults
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamDetails
						) == true ? (
								<Link
									className="btn btn-dark"
									to={{
										pathname: '/ExamDetails',
										state: {
											...row,
										},
									}}
								>
								Detaylar
								</Link>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamDetails
						) == true
							? '95px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamDetails
						) == true ? (
								<Button
									color={row.showMaterial ? 'danger' : 'success'}
									onClick={() =>
										this.props.actions.changeShowMaterial({
											ClassroomId: this.state.id,
											ExamId: row.id,
											ShowMaterial: !row.showMaterial,
										})
									}
								>
									{row.showMaterial ? 'Video Gizle' : 'Video Göster'}
								</Button>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.ViewExamDetails
						) == true
							? '130px'
							: '0px',
				},
			],
		}
	}

	find_in_object(array1, my_criteria) {
		return array1.map((obj) => {
			if (obj.id === my_criteria) {
				return obj.schoolName
			}
		})
	}

	getExamsByClassRoom() {
		this.props.actions.getExamByClassroom({
			id: this.state.id,
		})
	}

	componentDidMount() {
		this.props.actions.getUsersOnClassroom({
			id: this.state.id,
			RoleId: this.props.location.state.roleid,
		})

		this.getExamsByClassRoom()

		this.props.actions.getExamByNotAssign({
			id: this.state.id,
		})

		this.props.actions.findClassroom({
			id: this.state.id,
		})

		this.setState({
			AddedStudentsListData_onQuery: this.props.usersOnClassroomReducerData.extra,
		})
	}

	searchOnListExamsNotAssigned = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var notAssignByClassroom = this.props.examData.extraNotAssign.filter((item) =>
				item.examName.like(e.target.value)
			)
			this.setState({ notAssignByClassroom })
		} else {
			this.setState({ notAssignByClassroom: this.props.examData.extraNotAssign })
		}
	}

	searchOnListExamsAssigned = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var fullExamByClassroom = this.props.examData.extraByClassroom.filter((item) =>
				item.examName.like(e.target.value)
			)
			this.setState({ fullExamByClassroom })
		} else {
			this.setState({ fullExamByClassroom: this.props.examData.extraByClassroom })
		}
	}

	searchOnListStudents = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var AddedStudentsListData_onQuery = this.props.usersOnClassroomReducerData.extra.filter(
				(item) => item.fullName.like(e.target.value)
			)
			this.setState({ AddedStudentsListData_onQuery })
		} else {
			this.setState({
				AddedStudentsListData_onQuery: this.props.usersOnClassroomReducerData.extra,
			})
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.usersOnClassroomReducerData.success) {
			this.setState({
				AddedStudentsListData_onQuery: newProps.usersOnClassroomReducerData.extra,
			})
			newProps.actions.resetUSersOnClassroomSuccess('')
		}
		if (newProps.classrooms.success) {
			this.setState({
				classroom: newProps.classrooms.extra[0],
			})
			newProps.actions.resetAllStatussClassrooms('')
		}

		if (newProps.examData.successNotAssign) {
			this.setState({ notAssignByClassroom: newProps.examData.extraNotAssign })
			newProps.actions.resetAllStatussExam()
		}

		if (newProps.examData.successByClassroom) {
			this.setState({ fullExamByClassroom: newProps.examData.extraByClassroom })
			newProps.actions.resetAllStatussExam()
		}

		if (newProps.examData.successAddedExamToClass) {
			var dataMid = this.state.fullExamByClassroom.concat([this.state.addedExam])
			this.setState({ fullExamByClassroom: dataMid })

			var notAssignByClassroom = this.state.notAssignByClassroom.filter(
				(value) => value.id !== this.state.addedExam.id
			)
			this.setState({ notAssignByClassroom })

			newProps.actions.resetAllStatussExam('')
		}
		if (newProps.examData.successChangeShowMaterial) {
			this.getExamsByClassRoom()
			newProps.actions.resetAllStatussExam('')
		}

		if (newProps.examData.successRemoveExamToClass) {
			var dataMid = this.state.notAssignByClassroom.concat([this.state.removedExam])
			this.setState({ notAssignByClassroom: dataMid })

			var fullExamByClassroom = this.state.fullExamByClassroom.filter(
				(value) => value.id !== this.state.removedExam.id
			)
			this.setState({ fullExamByClassroom })
			newProps.actions.resetAllStatussExam('')
		}
	}

	onRowClickUSerDetails = (row, navigation) => {
		var location = {
			pathname: navigation,
			state: {
				data: row,
				actionType: 'userDetails',
			},
		}

		this.props.history.push(location)
	}

	goToTopicDetails = (topicId) => {
		this.props.history.push({
			pathname: `TopicDetails`,
			state: {
				topicId,
			},
		})
	}

	addExamToClassroom(row) {
		this.props.actions.addExamToClassroom({
			ClassroomId: this.state.id,
			ExamId: row.id,
			showMaterial: confirm(
				'Bu test için tanımlanmış videoların teste başlanmadan önce gösterilmesini ister misiniz?'
			),
		})
		this.setState({ addedExam: row })
	}

	removeExamToClassroom(row) {
		this.props.actions.removeExamToClassroom({
			ClassroomId: this.state.id,
			ExamId: row.id,
		})
		this.setState({ removedExam: row })
	}

	renderFullList() {
		return (
			<Accordion>
				<Card>
					<Accordion.Toggle eventKey="0" as={Card.Header}>
						<h4>
							Öğrenciler{' '}
							<Badge>{this.state.AddedStudentsListData_onQuery.length} Adet</Badge>{' '}
						</h4>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<DataTable
								id="table1"
								name="table1"
								columns={this.state.ClassroomHasUsersColumn}
								data={this.state.AddedStudentsListData_onQuery}
								pagination
								highlightOnHover
								progressPending={this.props.classrooms.extra.length > 0 ? false : true}
								subHeader
								subHeaderComponent={
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{this.props.userAuthData.permissions.includes(
											initialState.staticPerms.FindClassroom
										) === true ? (
												<TextField
													onChange={this.searchOnListStudents}
													id="outlined-basic"
													label="Arama"
													variant="outlined"
													size="small"
													style={{ margin: '5px' }}
												/>
											) : null}
									</div>
								}
								subHeaderAlign={'left'}
								fixedHeader={false}
								fixedHeaderScrollHeight="300px"
								noHeader
							/>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	}

	render() {
		const data = [
			{
				name: 'Page A',
				uv: 4000,
				pv: 2400,
			},
			{
				name: 'Page B',
				uv: 3000,
				pv: 1398,
			},
			{
				name: 'Page C',
				uv: 2000,
				pv: 9800,
			},
			{
				name: 'Page D',
				uv: 2780,
				pv: 3908,
			},
			{
				name: 'Page E',
				uv: 1890,
				pv: 4800,
			},
			{
				name: 'Page F',
				uv: 2390,
				pv: 3800,
			},
			{
				name: 'Page G',
				uv: 3490,
				pv: 4300,
			},
		]

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<div>
							<Breadcrumb>
								<BreadcrumbItem>
									{this.find_in_object(
										this.props.fullSchools.extra,
										this.state.classroom.schoolId
									)}
								</BreadcrumbItem>
								<BreadcrumbItem active>
									{this.props.location.state.className} Sınıfı
								</BreadcrumbItem>
							</Breadcrumb>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-12">
						{this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskUserByClassroom
						) === true
							? this.renderFullList()
							: null}
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<Accordion>
							<Card>
								<Accordion.Toggle eventKey="3" as={Card.Header}>
									<h4>Test Ortalama (Son 5)</h4>
								</Accordion.Toggle>

								<Accordion.Collapse eventKey="3">
									<Card.Body>
										<ResponsiveContainer width="95%" height={250}>
											<BarChart
												width={this.props.toggleStatus ? 1120 : 1250}
												height={250}
												data={data}
											>
												<CartesianGrid strokeDasharray="3 3" />
												<XAxis dataKey="name" />
												<YAxis />
												<Tooltip />
												<Legend />
												<Bar dataKey="pv" fill="#d1d1d1" />
												<Bar dataKey="uv" fill="#999999" />
												<Bar dataKey="uv" fill="#5e5e5e" />
											</BarChart>
										</ResponsiveContainer>
									</Card.Body>
								</Accordion.Collapse>
							</Card>

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.NotAssignedExamsByClassRoom
							) === true ? (
									<Card>
										<Accordion.Toggle eventKey="1" as={Card.Header}>
											<h4>Atanabilir Testler</h4>
										</Accordion.Toggle>

										<Accordion.Collapse eventKey="1">
											<Card.Body>
												<DataTable
													id="table3"
													name="table3"
													columns={this.state.ClassroomExamColumns}
													data={this.state.notAssignByClassroom}
													pagination={true} // paging
													highlightOnHover={true}
													progressPending={
														this.state.notAssignByClassroom.length > 0 ? false : true
													}
													subHeader={true}
													subHeaderComponent={
														<div style={{ display: 'flex', alignItems: 'center' }}>
															{this.props.userAuthData.permissions.includes(
																initialState.staticPerms.FindClassroom
															) === true ? (
																	<TextField
																		onChange={this.searchOnListExamsNotAssigned}
																		id="outlined-basic"
																		label="Arama"
																		variant="outlined"
																		size="small"
																		style={{ margin: '5px' }}
																	/>
																) : null}
														</div>
													}
													subHeaderAlign={'left'}
													fixedHeader={false}
													fixedHeaderScrollHeight="300px"
													noHeader
												/>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								) : null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ExamAskByClassroom
							) === true ? (
									<Card>
										<Accordion.Toggle eventKey="0" as={Card.Header}>
											<h4>Atanmış Testler</h4>
										</Accordion.Toggle>

										<Accordion.Collapse eventKey="0">
											<Card.Body>
												<DataTable
													id="table2"
													name="table2"
													columns={this.state.ClassroomExamColumns2}
													data={this.state.fullExamByClassroom}
													pagination={true} // paging
													highlightOnHover={true}
													progressPending={
														this.state.fullExamByClassroom.length > 0 ? false : true
													}
													subHeader={true}
													subHeaderComponent={
														<div style={{ display: 'flex', alignItems: 'center' }}>
															{this.props.userAuthData.permissions.includes(
																initialState.staticPerms.FindClassroom
															) === true ? (
																	<TextField
																		onChange={this.searchOnListExamsAssigned}
																		id="outlined-basic"
																		label="Arama"
																		variant="outlined"
																		size="small"
																		style={{ margin: '5px' }}
																	/>
																) : null}
														</div>
													}
													subHeaderAlign={'left'}
													fixedHeader={false}
													fixedHeaderScrollHeight="300px"
													noHeader
												/>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								) : null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		classrooms: state.classroomReducer,
		fullClassrooms: state.fullClassroomReducer,
		usersOnClassroomReducerData: state.usersOnClassroomReducer,
		fullSchools: state.fullSchoolReducer,

		examData: state.examReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			getUsersOnClassroom: bindActionCreators(
				usersOnClassroomActions.getUsersOnClassroom,
				dispatch
			),
			resetUSersOnClassroomSuccess: bindActionCreators(
				usersOnClassroomActions.resetUSersOnClassroomSuccess,
				dispatch
			),
			getExamByClassroom: bindActionCreators(examActions.getExamByClassroom, dispatch),
			getExamByNotAssign: bindActionCreators(examActions.getExamByNotAssign, dispatch),
			resetAllStatussExam: bindActionCreators(examActions.resetAllStatuss, dispatch),

			addExamToClassroom: bindActionCreators(examActions.addExamToClassroom, dispatch),
			findClassroom: bindActionCreators(classroomActions.findClassroom, dispatch),
			resetAllStatussClassrooms: bindActionCreators(
				classroomActions.resetAllStatuss,
				dispatch
			),
			removeExamToClassroom: bindActionCreators(
				examActions.removeExamToClassroom,
				dispatch
			),
			changeShowMaterial: bindActionCreators(examActions.changeShowMaterial, dispatch),
		},
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ClassroomDetails)
)
