import React, { Component } from 'react'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Badge } from 'reactstrap'

import { withRouter } from 'react-router-dom'
import { ScrollView } from '../base/scroll'

import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as lessonDetailsActions from '../../../redux/actions/lessonDetailsActions'

import TextField from '@material-ui/core/TextField'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Accordion, Card } from 'react-bootstrap'
import initialState from '../../../redux/reducers/initialState'
import { Grid } from '../base/datatable'

class LessonDetails extends Component {
	constructor(props) {
		super(props)

		this.state = {
			addedExam: '',
			removedExam: '',

			lessonTopicsData: [],
			LessonExamsDAta: [],

			TopicsTableColumns: [
				{
					name: 'Kanu',
					selector: 'topicName',
					sortable: true,
					width: '100%',
				},
			],

			ExamsTableColumns: [
				{
					name: 'Test',
					selector: 'examName',
					sortable: true,
					width: '100%',
				},
			],
		}
	}

	find_in_object(array1, my_criteria) {
		return array1.map((obj) => {
			if (obj.id === my_criteria) {
				return obj.schoolName
			}
		})
	}

	componentDidMount() {
		this.props.actions.getLessonsDetailExam({
			LessonId: this.props.location.state.data.id,
		})

		this.props.actions.getLessonsDetailTopic({
			LessonId: this.props.location.state.data.id,
		})
	}

	componentWillReceiveProps(newProps) {
		if (newProps.lessonDetailsReducerData.successlessonTopics) {
			this.setState({
				lessonTopicsData: newProps.lessonDetailsReducerData.lessonTopics,
			})
			newProps.actions.resetLessonDetailsSuccess('')
		}

		if (newProps.lessonDetailsReducerData.successlessonExams) {
			this.setState({
				LessonExamsDAta: newProps.lessonDetailsReducerData.lessonExams,
			})
			newProps.actions.resetLessonDetailsSuccess('')
		}
	}

	renderFullList() {
		return (
			<Accordion>
				<Card>
					<Accordion.Toggle eventKey="0" as={Card.Header}>
						<h4>
							Konular <Badge>{this.state.lessonTopicsData.length} Adet</Badge>{' '}
						</h4>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<Grid
								columns={this.state.TopicsTableColumns}
								data={this.state.lessonTopicsData}
								subHeaderComponent={
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									</div>
								}
							/>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	}

	render() {
		const data = [
			{
				name: 'Page A',
				uv: 4000,
				pv: 2400,
			},
			{
				name: 'Page B',
				uv: 3000,
				pv: 1398,
			},
			{
				name: 'Page C',
				uv: 2000,
				pv: 9800,
			},
			{
				name: 'Page D',
				uv: 2780,
				pv: 3908,
			},
			{
				name: 'Page E',
				uv: 1890,
				pv: 4800,
			},
			{
				name: 'Page F',
				uv: 2390,
				pv: 3800,
			},
			{
				name: 'Page G',
				uv: 3490,
				pv: 4300,
			},
		]

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<div>
							<Breadcrumb>
								<BreadcrumbItem active>
									{this.props.location.state.data.lessonName}
								</BreadcrumbItem>
							</Breadcrumb>
						</div>
					</div>
				</div>

				<div className="row">
					<div
						className={
							this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ExamAskByLesson
							) === true
								? 'col-lg-6'
								: 'col-lg-12'
						}
					>
						{this.renderFullList()}
					</div>

					<div
						className={
							this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ExamAskByLesson
							) === true
								? 'col-lg-6'
								: 'col-lg-0'
						}
					>
						<Accordion>
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ExamAskByLesson
							) === true ? (
									<Card>
										<Accordion.Toggle eventKey="0" as={Card.Header}>
											<h4>
											Ders Testleri <Badge>{this.state.LessonExamsDAta.length} Adet</Badge>{' '}
											</h4>
										</Accordion.Toggle>

										<Accordion.Collapse eventKey="0">
											<Card.Body>
												<DataTable
													columns={this.state.ExamsTableColumns}
													data={this.state.LessonExamsDAta}
													pagination
													highlightOnHover
													subHeader
													subHeaderComponent={
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<TextField
																onChange={this.searchOnList}
																id="outlined-basic"
																label="Arama"
																variant="outlined"
																size="small"
																style={{ margin: '5px' }}
															/>
														</div>
													}
													subHeaderAlign={'left'}
												/>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								) : null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		lessonDetailsReducerData: state.lessonDetailsReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getLessonsDetailExam: bindActionCreators(
				lessonDetailsActions.getLessonsDetailExam,
				dispatch
			),
			getLessonsDetailTopic: bindActionCreators(
				lessonDetailsActions.getLessonsDetailTopic,
				dispatch
			),

			resetLessonDetailsSuccess: bindActionCreators(
				lessonDetailsActions.resetLessonDetailsSuccess,
				dispatch
			),
		},
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LessonDetails)
)
