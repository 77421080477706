import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Button,





    Col, Form,
    FormGroup,

    Input, Label,



    Row
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as lessonActions from '../../../redux/actions/lessonActions'
import * as topicActions from '../../../redux/actions/topicActions'
import { ScrollView } from '../base/scroll'



class TopicsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			TopicName: '',
			LessonSelection: '-1',
		}
	}

	componentWillMount() {
		this.props.actions.getLessons()
	}

	componentDidMount() {
		{
			this.props?.location?.state?.actionType === 'update'
				? this.setState({
					TopicName: this.props.location.state.data.topicName,
					LessonSelection: this.props.location.state.data.lessonId,
				  })
				: this.setState({ TopicName: '', LessonSelection: '-1' })
		}

		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.topicData.success) {
			var location = {
				pathname: 'Konular',
			}

			this.props.history.push(location)
		} else if (newProps.topicData.success === false) {
			alertifyjs.error('Hata: ' + newProps.topicData.messages)
		}
	}

	setOnChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onSubmit = (e) => {
		e.preventDefault()

		//this.props.actions.chageSuccessStatussRole(false);
		this.props.actions.resetAllStatuss('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateTopic({
						TopicName: this.state.TopicName,
						LessonId: this.state.LessonSelection,
						id: this.props.location.state.data.id,
					  })
					: this.props.actions.createTopic({
						TopicName: this.state.TopicName,
						LessonId: this.state.LessonSelection,
					  })
			}
		}
	}

	validationFunc() {
		if (this.state.LessonSelection !== '-1') {
			if (this.state.TopicName.length > 1) {
				return true
			} else {
				alertifyjs.error('Sınıf Adı 2 Haneden Kısa Olamaz!')
				return false
			}
		} else {
			alertifyjs.error('Okul Seçimini yapmış olmalısınız!')
			return false
		}
	}

	onChange(event) {
		const { name, value } = event.target
		// setProduct(previousProduct => ({
		//   ...previousProduct,
		//   [name]: name === "categoryId" ? parseInt(value, 10) : value
		// }));

		// validate(name,value);
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>

					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="LessonSelection">Ders Seçimi</Label>
										<Input
											type="select"
											name="LessonSelection"
											id="LessonSelection"
											onChange={this.setOnChange}
											value={this.state.LessonSelection}
										>
											<option value="-1" key="-1">
												Seçiniz
											</option>
											{this.props.lessonsReducerData.extraGetted.map((lesson) => {
												return (
													<option value={lesson.id} key={lesson.id}>
														{lesson.lessonName}{' '}
													</option>
												)
											})}
										</Input>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label for="TopicName">Konu Adı</Label>
										<Input
											type="text"
											name="TopicName"
											id="TopicName"
											placeholder="Konu Adı Belirleyiniz!"
											value={this.state.TopicName}
											onChange={this.setOnChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Konuyu Güncelle
								</Button>
							) : (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Konuyu Oluştur
								</Button>
							)}
						</Form>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		topicData: state.topicReducer,

		lessonsReducerData: state.lessonsReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getLessons: bindActionCreators(lessonActions.getLessons, dispatch),
			createTopic: bindActionCreators(topicActions.createTopic, dispatch),
			resetAllStatuss: bindActionCreators(topicActions.resetAllStatuss, dispatch),
			updateTopic: bindActionCreators(topicActions.updateTopic, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopicsForm))
