import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function getRolesReducer(state = initialState.Initialroles, action) {
	switch (action.type) {
		case actionTypes.CREATE_ROLE_SUCCESS:
			return {
				...state,
				successCreated: action.payload.success,
			}

		case actionTypes.DELETE_ROLE_SUCCESS:
			if (action.payload === 'Başarıyla silindi.') {
				return {
					...state,
					successDeleted: true,
				}
			} else {
				return {
					...state,
					successDeleted: false,
				}
			}

		case actionTypes.GET_ROLE_SUCCESS:
			return {
				...state,
				successGettedRoles: action.payload.success,
				extraGettedRoles: action.payload.extra,
			}

		case actionTypes.UPDATE_ROLE_SUCCESS:
			return {
				...state,
				successUpdated: action.payload.success,
			}

		case actionTypes.CHANGE_SUCCESS_STATUSS_ROLE:
			return {
				...state,
				successChanged: false,
			}

		case actionTypes.GET_USER_ROLE:
			return {
				...state,
				successGettedRole: action.payload.success,
				dataUserRole: action.payload.extra,
			}

		case actionTypes.ASSIGNED_ROLE_TO_USER:
			return {
				...state,
				successAsignedRole: action.payload.success,
			}

		case actionTypes.GET_ROLES_PERMISSIONS:
			return {
				...state,
				successGetUSerRole: action.payload.success,
				extraUserRole: action.payload.extra,
			}

		case actionTypes.SET_ROLES_PERMISSIONS:
			return {
				...state,
				successSetUSerRole: action.payload.success,
			}

		//******************************************************* */  RESETS

		case actionTypes.RESET_CREATE_ROLE_SUCCESS:
			return {
				...state,
				successGetUSerRole: '',
			}

		case actionTypes.RESET_GET_ROLE_SUCCESS:
			return {
				...state,
				successGettedRoles: '',
			}

		case actionTypes.RESET_UPDATE_ROLE_SUCCESS:
			return {
				...state,
				successGetUSerRole: '',
			}

		case actionTypes.RESET_CHANGE_SUCCESS_STATUSS_ROLE:
			return {
				...state,
				successGetUSerRole: '',
			}

		case actionTypes.RESET_DELETE_ROLE_SUCCESS:
			return {
				...state,
				successGetUSerRole: '',
			}

		case actionTypes.RESET_DELETE_ROLE_SUCCESS_STATUSS:
			return {
				...state,
				successGetUSerRole: '',
			}

		case actionTypes.RESET_GET_ROLES_PERMISSIONS:
			return {
				...state,
				successGetUSerRole: '',
			}

		case actionTypes.RESET_SET_ROLES_PERMISSIONS:
			return {
				...state,
				successSetUSerRole: '',
			}

		case actionTypes.RESET_GET_USER_ROLE:
			return {
				...state,
				successGetUSerRole: '',
			}

		case actionTypes.RESET_ASSIGNED_ROLE_TO_USER:
			return {
				...state,
				successGetUSerRole: '',
			}

		case actionTypes.FULL_RESET_STATUSS_ROLE:
			return {
				...state,
				successCreated: '',
				successDeleted: '',
				successGettedRoles: '',
				successUpdated: '',
				successChanged: '',
				successGettedRole: '',
				successAsignedRole: '',
				successGetUSerRole: '',
				successSetUSerRole: '',
			}

		//******************************************************* */  Fault
		case actionTypes.ONFAULT_ROLE:
			return {
				...state,
				success: false,
				messages: action.payload.messages,
			}

		default:
			return state
	}
}
