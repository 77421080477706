import { Link } from '@reach/router'
import 'font-awesome/css/font-awesome.min.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import { ScrollView } from '../innerComponenets/base/scroll'
import AboutUs from '../outerComponenets/aboutus/AboutUs'
import Contact from '../outerComponenets/contact/Contact'
import LoginBOard from '../outerComponenets/login/LoginBoard'


class notAuth extends Component {
	setOnNavChange(navigation) {
		this.props.history.push(navigation)
	}

	render() {
		return (
			<Container className="App">
				<ScrollView isLogin>
					<div className="layer">
						<div className="bottom-grid">
							<div className="logo d-none d-md-block">
								<h1>
									{' '}
									<a href="index.html">
										<img src={require('../../medias/logo.png')} width="70" /> Okul 7x24
									</a>
								</h1>
							</div>
							<div className="links">
								<ul className="links-unordered-list">
									<li className="">
										{' '}
										{/* active */}
										<Link to="/" onClick={() => this.setOnNavChange('/')}>
											Giriş Yap
										</Link>
										{/* <a href="/" className="">Giriş Yap</a> */}
									</li>
									<li className="">
										<Link to="/AboutUs" onClick={() => this.setOnNavChange('/AboutUs')}>
											Biz Kimiz?
										</Link>
										{/* <a href="/AboutUs" className="">Biz Kimiz?</a> */}
									</li>
									{/* <li className="">
		  <a href="#" className="">Kayıt</a>
		</li> */}
									<li className="">
										<Link to="/Contact" onClick={() => this.setOnNavChange('/Contact')}>
											İletişim
										</Link>
										{/* <a href="/Contact" className="">İletişim</a> */}
									</li>
								</ul>
							</div>
						</div>

						<Switch>
							<Route path="/" exact component={LoginBOard} />

							<Route path="/AboutUs">
								<AboutUs />
							</Route>

							<Route path="/Contact">
								<Contact />
							</Route>

							<Route component={LoginBOard} />
						</Switch>

						<div
							className="bottom-grid1"
							style={{ textAlign: 'center', flexDirection: 'column' }}
						>
							{/* <div className="links">
	  <ul className="links-unordered-list">
		<li className="">
		  <a href="#" className="">Biz Kimiz?</a>
		</li>
		<li className="">
		  <a href="#" className="">Privacy Policy</a>
		</li>
		<li className="">
		  <a href="#" className="">Terms of Use</a>
		</li>
	  </ul>
	</div> */}
							<div className="copyright">
								<p>
									Tüm hakları &nbsp;
									<a href="http://ihsan.com.tr/">İhsan Bilgisayar Yazılım</a>
									'a aittir.
								</p>
							</div>
						</div>
					</div>
				</ScrollView>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.authReducer,
})

export default withRouter(connect(mapStateToProps)(notAuth))
