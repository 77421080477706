/* eslint-disable */
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Icon3 from '@material-ui/icons/ImportExport'
import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
	Button,








	CustomInput, Modal,

	ModalBody,
	ModalFooter, ModalHeader
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as roleActions from '../../../redux/actions/roleActions'
import initialState from '../../../redux/reducers/initialState'
import { ScrollView } from '../base/scroll'








class Roles extends Component {
	constructor(props) {
		super(props)

		this.state = {
			extraPermsData: [],

			selectedRoles: [],
			ModalStatuss: false,

			dataChart: [
				{
					name: 'Admin',
					Adet: 5,
				},
				{
					name: 'Müdür',
					Adet: 10,
				},
				{
					name: 'Öğretmen',
					Adet: 50,
				},
				{
					name: 'Öğrenci',
					Adet: 2780,
				},
			],

			rolesColumns: [
				{
					name: 'Role Adı',
					selector: 'roleName',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskRolePermissions
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.toggleModalPermission(row, 'AddOrUpdateRoles')}
								>
								Yetki Ata
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskRolePermissions
						) == true
							? '100px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateRole
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateRoles')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateRole
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteRole
						) === true ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteRole
						) == true
							? '50px'
							: '0px',
				},
			],

			FullRolesColumns: [
				{
					name: 'Role Adı',
					selector: 'roleName',
					sortable: true,
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					cell: (row) => (row.isActive === true ? 'Aktif' : 'Pasif'),
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskRolePermissions
						) == true ? (
								<Button
									color="dark"
									onClick={() => this.toggleModalPermission(row, 'AddOrUpdateRoles')}
								>
								Yetki Ata
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanAskRolePermissions
						) == true
							? '100px'
							: '0px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateRole
						) == true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickUpdate(row, 'AddOrUpdateRoles')}
								>
								Güncelle
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateRole
						) == true
							? '95px'
							: '0px',
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteRole
						) == true ? (
								<Button color="danger" onClick={() => this.onFullDeleteClick(row)}>
								F-Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width:
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.FullDeleteRole
						) == true
							? '60px'
							: '0px',
				},
			],
		}
	}

	componentWillMount() {
		this.props.actions.getRoles()
		this.props.actions.getFullRoles()
	}

	componentDidMount() {
		this.setState({ roleListData_onQuery: this.props.roles.extraGettedRoles })
		this.setState({
			roleFullListData_onQuery: this.props.fullRoles.extraGettedFullRoles,
		})

		this.props.actions.changeDeletedRoleSuccessStatus('')
		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.roles.successDeleted === true) {
			alertifyjs.success('Role Başarılı Olarak Silindi!')
			newProps.actions.changeDeletedRoleSuccessStatus('')
			newProps.actions.resetAllStatuss('')
			newProps.actions.getRoles()
			newProps.actions.getFullRoles()
		} else if (newProps.roles.successDeleted === false) {
			alertifyjs.error('Role Silinirken Hatalar Oluştu.')
			newProps.actions.changeDeletedRoleSuccessStatus('')
			newProps.actions.resetAllStatuss('')
		}

		if (newProps.roles.successGetUSerRole === true) {
			this.setState({
				ModalStatuss: !this.state.ModalStatuss,
				extraPermsData: newProps.roles.extraUserRole,
			})
			this.props.actions.resetGetRolePermissionsSuccess()
		} else if (newProps.roles.successGetUSerRole === false) {
			this.props.actions.resetGetRolePermissionsSuccess()
		}

		if (newProps.roles.successSetUSerRole === true) {
			this.setState({ ModalStatuss: !this.state.ModalStatuss, extraPermsData: [] })
			this.props.actions.resetSetRolePermissionsSuccess()
		} else if (newProps.roles.successSetUSerRole === false) {
			this.props.actions.resetSetRolePermissionsSuccess()
		}

		if (newProps.roles.successGettedRoles === true) {
			this.setState({ roleListData_onQuery: newProps.roles.extraGettedRoles })
			this.props.actions.resetGetRoleSuccess()
		} else if (newProps.roles.successGettedRoles === false) {
			this.props.actions.resetGetRoleSuccess()
		}

		if (newProps.fullRoles.successGettedFullRoles === true) {
			this.setState({
				roleFullListData_onQuery: newProps.fullRoles.extraGettedFullRoles,
			})
			this.props.actions.resetGetFullRoleSuccess()
		} else if (newProps.fullRoles.successGettedFullRoles === false) {
			this.props.actions.resetGetFullRoleSuccess()
		}
	}

	selectNavigation = (navigation) => {
		this.props.actions.chageSuccessStatussRole(false)
	}

	searchOnList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var roleListData_onQuery = this.props.roles.extraGettedRoles.filter((item) =>
				item.roleName.like(e.target.value)
			)
			this.setState({ roleListData_onQuery })
		} else {
			this.setState({ roleListData_onQuery: this.props.roles.extraGettedRoles })
		}
	}

	searchOnFullList = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var roleFullListData_onQuery = this.props.fullRoles.extraGettedFullRoles.filter(
				(item) => item.roleName.like(e.target.value)
			)
			this.setState({ roleFullListData_onQuery })
		} else {
			this.setState({
				roleFullListData_onQuery: this.props.fullRoles.extraGettedFullRoles,
			})
		}
	}

	handleClick = (state) => {}

	onRowClickUpdate = (data, pathname) => {
		var location = {
			pathname,
			state: {
				data,
				actionType: 'update',
			},
		}

		this.props.history.push(location)
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteRole(data)
		}
	}

	onFullDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.fullDeleteRole(data)
		}
	}

	renderRolesList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="0" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Roller</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="0">
					<DataTable
						columns={this.state.rolesColumns}
						data={this.state.roleListData_onQuery}
						pagination={true}
						noHeader
						highlightOnHover={true}
						progressPending={this.props.roles.extraGettedRoles.length > 0 ? false : true}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddRole
								) === true ? (
										<Link
											className="btn btn-outline-success"
											to={{
												pathname: '/AddOrUpdateRoles',
											}}
											onClick={() => this.selectNavigation()}
										>
											<i className="fa fa-plus"></i>
										</Link>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindRole
								) === true ? (
										<TextField
											onChange={this.searchOnList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
							</div>
						}
						subHeaderAlign={'left'}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	renderFullList() {
		return (
			<Card>
				<Accordion.Toggle eventKey="1" as={Card.Header}>
					<div className="d-flex justify-content-between">
						<FormGroup row>
							<h4>Full Roller</h4>
						</FormGroup>

						<FormGroup row>
							<Icon3 style={{ margin: '5px' }} color="action" />
						</FormGroup>
					</div>
				</Accordion.Toggle>

				<Accordion.Collapse eventKey="1">
					<DataTable
						columns={this.state.FullRolesColumns}
						data={this.state.roleFullListData_onQuery}
						pagination={true}
						noHeader
						highlightOnHover={true}
						progressPending={
							this.props.fullRoles.extraGettedFullRoles.length > 0 ? false : true
						}
						subHeader={true}
						subHeaderComponent={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.AddRole
								) === true ? (
										<button
											type="button"
											className="btn btn-outline-success"
											onClick={() => this.selectNavigation('AddOrUpdateRoles')}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : null}

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.FindFullListRole
								) === true ? (
										<TextField
											onChange={this.searchOnFullList}
											id="outlined-basic"
											label="Arama"
											variant="outlined"
											size="small"
											style={{ margin: '5px' }}
										/>
									) : null}
							</div>
						}
						subHeaderAlign={'left'}
					/>
				</Accordion.Collapse>
			</Card>
		)
	}

	setSelected = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	toggleModalPermission = (row) => {
		this.setState({ actRow: row })

		this.props.actions.getRolePermissions({ RoleId: row.id })
	}

	toggleModalPermissionDirect = (row) => {
		this.setState({ ModalStatuss: !this.state.ModalStatuss })
	}

	setPermsStatuss = (e) => {
		var newExtraPermsData = this.state.extraPermsData.map((ff) => {
			if (ff.id === e.target.id) {
				ff.isActive = !ff.isActive
				return ff
			} else {
				return ff
			}
		})

		this.setState({ extraPermsData: newExtraPermsData })
	}

	updatePermissions = () => {
		this.props.actions.setRolePermissions({
			id: this.state.actRow.id,
			roleName: this.state.actRow.roleName,
			PermissionDtos: this.state.extraPermsData,
		})
	}

	render() {
		createTheme('solarized', {
			text: {
				primary: '#268bd2',
				secondary: '#2aa198',
			},
			background: {
				default: '#002b36',
			},
			context: {
				background: '#cb4b16',
				text: '#FFFFFF',
			},
			divider: {
				default: '#073642',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		})

		return (
			<ScrollView>
				<Modal
					isOpen={this.state.ModalStatuss}
					toggle={this.toggleModalPermissionDirect}
					size="lg"
				>
					<ModalHeader toggle={this.toggleModalPermissionDirect}>
						Yetki İşlemleri
					</ModalHeader>
					<ModalBody>
						<div className="row">
							{this.state.extraPermsData.map((c) => {
								return (
									<div className="col-lg-4">
										<CustomInput
											type="switch"
											id={c.id}
											name={c.id}
											value={c.isActive}
											checked={c.isActive}
											label={c.permissionName}
											onChange={this.setPermsStatuss}
										/>
									</div>
								)
							})}
						</div>
					</ModalBody>
					<ModalFooter>
						{this.props.userAuthData.permissions.includes(
							initialState.staticPerms.CanUpdateRolePermissions
						) === true ? (
								<Button color="primary" onClick={this.updatePermissions}>
								Atamayı Onayla
								</Button>
							) : null}

						<Button color="secondary" onClick={this.toggleModalPermissionDirect}>
							Vazgeç
						</Button>
					</ModalFooter>
				</Modal>

				<div className="row">
					{/* <div className="col-lg-12">

                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    <FormGroup row>
                                        <h4>Rol Kullanım Ortalamaları</h4>
                                    </FormGroup>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="0">



                                    <ResponsiveContainer width="100%" height={250}>
                                        <AreaChart data={this.state.dataChart}
                                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip />
                                            <ReferenceLine x="Page C" stroke="red" label="Min PAGE" />
                                            <ReferenceLine y={6000} label="Max" stroke="orange" strokeDasharray="3 3" />
                                            <Area type="monotone" dataKey="Adet" stroke="black" fill="#db3d44" />
                                        </AreaChart>
                                    </ResponsiveContainer>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>


                    </div> */}

					<div className="col-lg-12">
						<Accordion defaultActiveKey="0">
							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.FullListRole
							) === true
								? this.renderFullList()
								: null}

							{this.props.userAuthData.permissions.includes(
								initialState.staticPerms.ListRole
							) === true
								? this.renderRolesList()
								: null}
						</Accordion>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		roles: state.roleReducer,
		fullRoles: state.fullRoleReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			getRoles: bindActionCreators(roleActions.getRoles, dispatch),
			resetGetRoleSuccess: bindActionCreators(
				roleActions.resetGetRoleSuccess,
				dispatch
			),

			getFullRoles: bindActionCreators(roleActions.getFullRoles, dispatch),
			resetGetFullRoleSuccess: bindActionCreators(
				roleActions.resetGetFullRoleSuccess,
				dispatch
			),

			chageSuccessStatussRole: bindActionCreators(
				roleActions.chageSuccessStatussRole,
				dispatch
			),
			resetChangeSuccessStatussRole: bindActionCreators(
				roleActions.resetChangeSuccessStatussRole,
				dispatch
			),

			deleteRole: bindActionCreators(roleActions.deleteRole, dispatch),
			fullDeleteRole: bindActionCreators(roleActions.fullDeleteRole, dispatch),
			resetDeleteRoleSuccess: bindActionCreators(
				roleActions.resetDeleteRoleSuccess,
				dispatch
			),

			changeDeletedRoleSuccessStatus: bindActionCreators(
				roleActions.changeDeletedRoleSuccessStatus,
				dispatch
			),

			getRolePermissions: bindActionCreators(roleActions.getRolePermissions, dispatch),
			resetGetRolePermissionsSuccess: bindActionCreators(
				roleActions.resetGetRolePermissionsSuccess,
				dispatch
			),

			setRolePermissions: bindActionCreators(roleActions.setRolePermissions, dispatch),
			resetSetRolePermissionsSuccess: bindActionCreators(
				roleActions.resetSetRolePermissionsSuccess,
				dispatch
			),

			resetAllStatuss: bindActionCreators(roleActions.resetAllStatuss, dispatch),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roles))
