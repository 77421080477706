import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function usersOnClassroomReducer(
	state = initialState.ManagersOnClassroom,
	action
) {
	switch (action.type) {
	case actionTypes.GET_ALL_MANAGERS_ON_SCHOOL_SUCCESS:
		return {
			...state,
			success: action.payload.success,
			extra: action.payload.extra,
		}

	case actionTypes.RESET_ALL_MANAGERS_ON_SCHOOL:
		return {
			...state,
			success: '',
			deleted: '',
			Updatedsuccess: '',
			Addedsuccess: '',
			removedSucces: '',
		}

		//****************************************************************** */
	case actionTypes.MANAGERS_ON_SCHOOL_ADD_SUCCESS:
		return {
			...state,
			Addedsuccess: action.payload.success,
		}

	case actionTypes.MANAGERS_ON_SCHOOL_REMOVE_SUCCESS:
		return {
			...state,
			removedSucces: action.payload.success,
		}
		//****************************************************************** */

	case actionTypes.ONFAULT_ALL_MANAGERS_ON_SCHOOL:
		return {
			...state,
			success: false,
			messages: action.payload.messages,
		}

	default:
		return state
	}
}
