import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function getExamsReducer(state = initialState.exams, action) {
	switch (action.type) {
		case actionTypes.CREATE_EXAM_SUCCESS:
			return {
				...state,
				success: action.payload.success,
				extra: action.payload.extra,
			}

		case actionTypes.CREATE_EXAM_WITH_QUESTIONS_SUCCESS:
			return {
				...state,
				success: action.payload.success,
			}

		case actionTypes.DELETE_EXAM_SUCCESS:
			if (action.payload === 'Başarıyla silindi.') {
				return {
					...state,
					deleted: true,
				}
			} else {
				return {
					...state,
					deleted: false,
				}
			}

		case actionTypes.GET_EXAM_SUCCESS:
			return action.payload

		case actionTypes.UPDATE_EXAM_SUCCESS:
			return action.payload

		case actionTypes.RESET_STATUSS_EXAM:
			return {
				...state,
				success: '',
				deleted: '',
				successByClassroom: '',
				successNotAssign: '',

				successAddedExamToClass: '',
				successRemoveExamToClass: '',

				successNotSolvedGetted: '',
				extraNotSolved: '',

				successSolvedGetted: '',
				successChangeShowMaterial: '',
			}

		case actionTypes.ONFAULT_EXAM:
			return {
				...state,
				success: false,
				messages: action.payload.messages,
			}

			//****************************************************** */

		case actionTypes.GET_EXAM_BY_CLASSROOM_SUCCESS:
			return {
				...state,
				successByClassroom: action.payload.success,
				extraByClassroom: action.payload.extra,
			}

		case actionTypes.GET_NOTASSIGN_EXAM_BY_CLASSROOM_STATUSS:
			return {
				...state,
				successNotAssign: action.payload.success,
				extraNotAssign: action.payload.extra,
			}

			//****************************************************** */

		case actionTypes.ADD_EXAM_BY_CLASSROOM_SUCCESS:
			return {
				...state,
				successAddedExamToClass: action.payload.success,
			}

		case actionTypes.CHANGE_EXAM_SHOW_MATERIAL_SUCCESS:
			return {
				...state,
				successChangeShowMaterial: action.payload.success,
			}

		case actionTypes.REMOVE_EXAM_BY_CLASSROOM_SUCCESS:
			return {
				...state,
				successRemoveExamToClass: action.payload.success,
			}

			//****************************************************** */

		case actionTypes.GET_EXAM_NOTSOLVED_SUCCESS:
			return {
				...state,
				successNotSolvedGetted: action.payload.success,
				extraNotSolved: action.payload.extra,
			}

		case actionTypes.GET_EXAM_SOLVED_SUCCESS:
			var lastFiveExam = action.payload.extra.slice(-5)
			return {
				...state,
				successSolvedGetted: action.payload.success,
				extraSolved: action.payload.extra,
				extraSolvedLastFive: lastFiveExam,
			}
		//****************************************************** */
		default:
			return state
	}
}
