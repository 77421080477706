import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function lessonDetailsReducer(
  state = initialState.gettedExamsById,
  action
) {
  switch (action.type) {
    case actionTypes.GET_LESSONS_DETAILS_TOPICS_SUCCESS:
      return {
        ...state,
        successlessonTopics: action.payload.success,
        lessonTopics: action.payload.extra,
      }

    case actionTypes.GET_LESSONS_DETAILS_EXAM_SUCCESS:
      return {
        ...state,
        successlessonExams: action.payload.success,
        lessonExams: action.payload.extra,
      }

    case actionTypes.RESET_LESSONS_DETAILS:
      return {
        ...state,
        successlessonExams: "",
        successlessonTopics: "",
      }

    case actionTypes.ONFAULT_LESSONS_DETAILS:
      return {
        ...state,
        successlessonExams: false,
        successlessonTopics: false,
        messages: action.payload.messages,
      }

    default:
      return state
  }
}
