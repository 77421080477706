/* eslint-disable */
import TextField from '@material-ui/core/TextField'
import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
	Badge, Breadcrumb, BreadcrumbItem, Button,
	Modal,

	ModalBody,
	ModalFooter, ModalHeader
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as examActions from '../../../redux/actions/examActions'
import * as topicActions from '../../../redux/actions/topicActions'
import * as topicDetailActions from '../../../redux/actions/topicDetailActions'
import * as topicMaterialActions from '../../../redux/actions/topicMaterialActions'
import initialState from '../../../redux/reducers/initialState'
import { popupCenter } from '../../../utils/helpers/window'
import { ScrollView } from '../base/scroll'
import { VideoPlayer } from '../base/video'





class TopicDetails extends Component {
	constructor(props) {
		super(props)

		this.CToggle = this.CToggle.bind(this)

		this.state = {
			topic: null,
			id: props.location.state.topicId,
			ToggState: false,
			addedExam: '',
			removedExam: '',

			TopicDetailsExam: [],
			fullExamByClassroom: [],
			TopicMaterials: [],

			TopicDetailsExamColumns: [
				{
					name: 'Test',
					selector: 'examName',
					sortable: true,
				},
				{
					name: 'Text',
					selector: 'textCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Resimli',
					selector: 'imageCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Ses',
					selector: 'audioCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Video',
					selector: 'videoCount',
					sortable: true,
					width: '60px',
				},
				{
					name: 'Toplam',
					selector: 'totalCount',
					sortable: true,
					width: '60px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateExam
						) == true ? (
								<Link
									className="btn btn-dark"
									to={{
										pathname: '/ExamDetails',
										state: {
											...row,
										},
									}}
								>
								Detaylar
								</Link>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '95px',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteExam
						) == true &&
						(this.props.userAuthData.permissions.includes(
							initialState.staticPerms.adminPermsId
						) ||
							row.createdBy == localStorage.getItem('id')) ? (
								<Button color="danger" onClick={() => this.onDeleteClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '50px',
				},
			],

			TopicMaterialsColumns: [
				{
					name: 'Başlık',
					selector: 'title',
					sortable: true,
					cell: (row) => {
						return row.materialType == 1 ? (
							<a href="javascript:;" onClick={() => this.showVideo(row)}>
								{row.title}
							</a>
						) : row.materialType == 2 ? (
							<a href="javascript:;" onClick={() => this.showPdf(row)}>
								{row.title}
							</a>
						) : (
							''
						)
					},
				},
				{
					name: 'Tür',
					selector: 'materialTypeText',
					sortable: true,
				},
				{
					name: 'Sıra',
					selector: 'order',
					sortable: true,
					width: '50px',
				},
				{
					name: 'Aktiflik',
					selector: 'isActive',
					sortable: true,
					right: true,
					width: '100px',
					cell: (row) => {
						return row.isActive === true ? 'Aktif' : 'Pasif'
					},
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UpdateTopicMaterial
						) == true ? (
								<Button
									color="dark"
									onClick={() =>
										this.gotoTopicMaterialDetails('AddOrUpdateTopicMaterials', row)
									}
								>
								Detaylar
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: this.props.userAuthData.permissions.includes(
						initialState.staticPerms.UpdateTopicMaterial
					)
						? '95px'
						: '0',
				},
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.DeleteTopicMaterial
						) === true ? (
								<Button color="danger" onClick={() => this.onDeleteTopicMaterialClick(row)}>
								Sil
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: this.props.userAuthData.permissions.includes(
						initialState.staticPerms.DeleteTopicMaterial
					)
						? '50px'
						: '0',
				},
			],
		}
	}

	onDeleteClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteExam(data)
		}
	}

	onDeleteTopicMaterialClick = (state) => {
		if (confirm('Silmek istediğinize emin misiniz?')) {
			var data = { id: state.id }
			this.props.actions.deleteTopicMaterial(data)
		}
	}

	gotoTopicMaterialDetails = (pathname, row) => {
		var location = {
			pathname,
			state: {
				actionType: 'update',
				data: {
					topic: this.state.topic,
					topicMaterial: row,
				},
			},
		}

		this.props.history.push(location)
	}

	find_in_object(array1, my_criteria) {
		return array1.map((obj) => {
			if (obj.id === my_criteria) {
				return obj.schoolName
			}
		})
	}

	componentDidMount() {
		if (localStorage.getItem('role') != 'Öğrenci') {
			this.props.actions.getTopicDetail({
				TopicId: this.state.id,
			})
		}
		this.props.actions.getTopicMaterials({
			TopicId: this.state.id,
		})
		this.props.actions.findTopic({
			id: this.state.id,
		})
		this.setState({ TopicDetailsExam: this.props.topicDetailReducersData.extra })
	}

	componentWillReceiveProps(newProps) {
		if (newProps.topicDetailReducersData.success) {
			this.setState({ TopicDetailsExam: newProps.topicDetailReducersData.extra })
			newProps.actions.resetTopicDetailSuccess('')
		}

		if (newProps.topicReducerData.success) {
			this.setState({ topic: newProps.topicReducerData.extra[0] })
			newProps.actions.resetTopicStatus('')
		}

		if (newProps.topicMaterialReducersData.success) {
			this.setState({ TopicMaterials: newProps.topicMaterialReducersData.extra })
			newProps.actions.resetAllTopicMaterialStatuss('')
		}

		if (newProps.topicDetailReducersData.deleted) {
			newProps.actions.changeDeletedExamSuccessStatus('')
			newProps.actions.resetAllStatuss('')
			alertifyjs.success('Test Başarılı Olarak Silindi!')
			newProps.actions.getTopicDetail({
				TopicId: this.state.id,
			})
		}

		if (newProps.topicMaterialReducersData.deleted) {
			newProps.actions.resetAllStatuss('')
			alertifyjs.success('Materyal Başarılı Olarak Silindi!')
			newProps.actions.getTopicMaterials({
				TopicId: this.state.id,
			})
		}
	}

	renderFullList() {
		return (
			localStorage.getItem('role') != 'Öğrenci' && (
				<Accordion>
					<Card>
						<Accordion.Toggle eventKey="0" as={Card.Header}>
							<h4>
								Konu Testleri <Badge>{this.state.TopicDetailsExam.length} Adet</Badge>
							</h4>
						</Accordion.Toggle>

						<Accordion.Collapse eventKey="0">
							<Card.Body>
								<DataTable
									noHeader
									columns={this.state.TopicDetailsExamColumns}
									data={this.state.TopicDetailsExam}
									pagination={true}
									highlightOnHover={true}
									subHeader={true}
									subHeaderComponent={
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<TextField
												onChange={this.searchOnListExam}
												id="outlined-basic"
												label="Arama"
												variant="outlined"
												size="small"
												style={{ margin: '5px' }}
											/>
										</div>
									}
									subHeaderAlign={'left'}
								/>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			)
		)
	}

	selectNavigation = (pathname, data) => {
		this.props.actions.resetAllTopicMaterialStatuss('')

		var location = {
			pathname,
			state: {
				data,
			},
		}

		this.props.history.push(location)
	}

	searchOnListMaterial = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var TopicMaterials = this.props.topicMaterialReducersData.extra.filter((item) =>
				item.title.like(e.target.value)
			)
			this.setState({ TopicMaterials })
		} else {
			this.setState({ TopicMaterials: this.props.topicMaterialReducersData.extra })
		}
	}

	searchOnListExam = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		if (e.target.value) {
			var TopicDetailsExam = this.props.topicDetailReducersData.extra.filter((item) =>
				item.examName.like(e.target.value)
			)
			this.setState({ TopicDetailsExam })
		} else {
			this.setState({ TopicDetailsExam: this.props.topicDetailReducersData.extra })
		}
	}

	showVideo(video) {
		this.setState({
			selectedVideo: video,
			ToggState: true,
		})
	}

	showPdf(pdf) {
		popupCenter({
			url: pdf.value,
			title: pdf.title,
		})
	}

	CToggle() {
		this.setState({
			ToggState: !this.state.ToggState,
		})
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-12">
						<div>
							<Breadcrumb>
								<BreadcrumbItem active>
									{this.state.topic && this.state.topic.topicName}
								</BreadcrumbItem>
							</Breadcrumb>
						</div>
					</div>
				</div>

				<div className="row">
					{this.props.userAuthData.permissions.includes(
						initialState.staticPerms.ExamAskByTopic
					) === true ? (
							<div className="col-lg-12">
								{this.renderFullList()}
								<Accordion>
									<Card>
										<Accordion.Toggle eventKey="0" as={Card.Header}>
											<h4>
											Eğitim Materyalleri <Badge>{this.state.TopicMaterials.length} Adet</Badge>
											</h4>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
												<DataTable
													noHeader
													columns={this.state.TopicMaterialsColumns}
													data={this.state.TopicMaterials}
													pagination
													highlightOnHover
													subHeader
													subHeaderAlign="left"
													subHeaderComponent={
														<div style={{ display: 'flex', alignItems: 'center' }}>
															{this.props.userAuthData.permissions.includes(
																initialState.staticPerms.AddTopicMaterial
															) === true ? (
																	<button
																		type="button"
																		className="btn btn-outline-success"
																		onClick={() =>
																			this.selectNavigation('AddOrUpdateTopicMaterials', {
																				topic: this.state.topic,
																			})
																		}
																	>
																		<i className="fa fa-plus"></i>
																	</button>
																) : null}
															<TextField
																onChange={this.searchOnListMaterial}
																id="outlined-basic"
																label="Arama"
																variant="outlined"
																size="small"
																style={{ margin: '5px' }}
																placeholder="Text, Date..."
															/>
														</div>
													}
												/>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						) : null}
				</div>
				<Modal isOpen={this.state.ToggState} toggle={this.CToggle} size="lg">
					<ModalHeader toggle={this.CToggle}>
						{this.state.selectedVideo && this.state.selectedVideo.title}
					</ModalHeader>
					<ModalBody>
						{this.state.selectedVideo && (
							<VideoPlayer
								video={this.state.selectedVideo.value}
								materialType={this.state.selectedVideo.materialType}
								visible
							/>
						)}
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.CToggle}>
							Vazgeç
						</Button>
					</ModalFooter>
				</Modal>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,
		topicDetailReducersData: state.topicDetailReducers,
		topicReducerData: state.topicReducer,
		topicMaterialReducersData: state.topicMaterialReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			deleteExam: bindActionCreators(examActions.deleteExam, dispatch),
			getTopicDetail: bindActionCreators(topicDetailActions.getTopicDetail, dispatch),
			findTopic: bindActionCreators(topicActions.findTopic, dispatch),
			resetTopicStatus: bindActionCreators(topicActions.resetAllStatuss, dispatch),
			resetTopicDetailSuccess: bindActionCreators(
				topicDetailActions.resetTopicDetailSuccess,
				dispatch
			),
			resetAllTopicMaterialStatuss: bindActionCreators(
				topicMaterialActions.resetAllStatuss,
				dispatch
			),
			resetAllStatuss: bindActionCreators(topicDetailActions.resetAllStatuss, dispatch),
			changeDeletedExamSuccessStatus: bindActionCreators(
				topicDetailActions.changeDeletedExamSuccessStatus,
				dispatch
			),
			getTopicMaterials: bindActionCreators(
				topicMaterialActions.getTopicMaterials,
				dispatch
			),
			deleteTopicMaterial: bindActionCreators(
				topicMaterialActions.deleteTopicMaterial,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopicDetails))
