import alertifyjs from 'alertifyjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as topicMaterialActions from '../../../redux/actions/topicMaterialActions'
import { ScrollView } from '../base/scroll'



class TopicMaterialsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			title: '',
			order: 0,
			isActive: true,
			value: '',
			materialType: 1 /* Youtube Video */,
		}
	}

	componentWillMount() {}

	componentDidMount() {
		this.props?.location?.state?.actionType === 'update' &&
			this.setState({
				title: this.props.location.state.data.topicMaterial.title,
				order: this.props.location.state.data.topicMaterial.order,
				isActive: this.props.location.state.data.topicMaterial.isActive,
				value: this.props.location.state.data.topicMaterial.value,
			})

		this.props.actions.resetAllStatuss('')
	}

	componentWillReceiveProps(newProps) {
		if (newProps.topicMaterialReducersData.success) {
			this.props.history.goBack()
		} else if (newProps.topicMaterialReducersData.success === false) {
			alertifyjs.error('Hata: ' + newProps.topicData.messages)
		}
	}

	setOnChange = (e) => {
		let val = ''
		if (e.target.name === 'isActive') {
			val = e.target.checked
		} else {
			val = e.target.value
		}
		this.setState({ [e.target.name]: val })
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.props.actions.resetAllStatuss('')

		var vatidationVal = this.validationFunc()
		if (vatidationVal) {
			{
				this.props?.location?.state?.actionType === 'update'
					? this.props.actions.updateTopicMaterial({
						title: this.state.title,
						order: this.state.order,
						isActive: this.state.isActive,
						materialType: this.state.materialType,
						topicId: this.props.location.state.data.topic.id,
						id: this.props.location.state.data.topicMaterial.id,
						value: this.state.value,
					  })
					: this.props.actions.createTopicMaterial({
						title: this.state.title,
						order: this.state.order,
						isActive: this.state.isActive,
						materialType: this.state.materialType,
						value: this.state.value,
						topicId: this.props.location.state.data.topic.id,
					  })
			}
		}
	}

	validationFunc() {
		if (this.state.title) {
			return true
		} else {
			alertifyjs.error('Başlık girmelisiniz!')
			return false
		}
	}

	onChange(event) {
		const { name, value } = event.target
	}

	render() {
		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-2"></div>

					<div className="col-lg-8">
						<Form onSubmit={this.onSubmit}>
							<Row>
								<Col>
									<FormGroup>
										<Label for="title">Tür</Label>
										<select
											className="form-control"
											name="materialType"
											id="materialType"
											value={this.state.materialType}
											onChange={this.setOnChange}
										>
											<option value={1}>Youtube Video</option>
											<option value={2}>PDF</option>
										</select>
									</FormGroup>
									<FormGroup>
										<Label for="title">Başlık</Label>
										<Input
											type="text"
											name="title"
											id="title"
											placeholder="Başlık giriniz..."
											value={this.state.title}
											onChange={this.setOnChange}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="order">Sıra</Label>
										<Input
											type="number"
											name="order"
											id="order"
											placeholder="Sıra..."
											value={this.state.order}
											onChange={this.setOnChange}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="value">
											{this.state.materialType == 1
												? 'Youtube'
												: this.state.materialType == '2'
													? 'PDF'
													: '???'}{' '}
											Linki
										</Label>
										<Input
											type="text"
											name="value"
											id="value"
											placeholder={
												this.state.materialType == 1
													? 'https://youtube.com/watch?v=xxxxx'
													: this.state.materialType == 2
														? 'https://example.com/dosyaadi.pdf'
														: '???'
											}
											value={this.state.value}
											onChange={this.setOnChange}
										/>
									</FormGroup>
									<div className="form-group form-check">
										<input
											type="checkbox"
											className="form-check-input"
											id="isActive"
											name="isActive"
											onChange={this.setOnChange}
											checked={this.state.isActive}
										/>
										<label className="form-check-label" htmlFor="isActive">
											Aktif mi?
										</label>
									</div>
								</Col>
							</Row>
							{this.props?.location?.state?.actionType === 'update' ? (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Güncelle
								</Button>
							) : (
								<Button
									size="lg"
									type="submit"
									onClick={this.onSubmit}
									className="float-right"
									style={{ width: '200px' }}
								>
									Oluştur
								</Button>
							)}
						</Form>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</ScrollView>
		)
	}
}

//connect componenet with redux store
function mapStateToProps(state) {
	return {
		currentNavigation: state.changeNavigationReducer,
		topicMaterialReducersData: state.topicMaterialReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),

			createTopicMaterial: bindActionCreators(
				topicMaterialActions.createTopicMaterial,
				dispatch
			),
			resetAllStatuss: bindActionCreators(
				topicMaterialActions.resetAllStatuss,
				dispatch
			),
			updateTopicMaterial: bindActionCreators(
				topicMaterialActions.updateTopicMaterial,
				dispatch
			),
		},
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TopicMaterialsForm)
)
