import {
	CREATE_ROLE_SUCCESS,
	GET_ROLE_SUCCESS,
	GET_FULL_ROLE_SUCCESS,
	UPDATE_ROLE_SUCCESS,
	CHANGE_SUCCESS_STATUSS_ROLE,
	DELETE_ROLE_SUCCESS,
	DELETE_ROLE_SUCCESS_STATUSS,
	GET_ROLES_PERMISSIONS,
	SET_ROLES_PERMISSIONS,
	GET_USER_ROLE,
	ASSIGNED_ROLE_TO_USER,
	RESET_CREATE_ROLE_SUCCESS,
	RESET_GET_ROLE_SUCCESS,
	RESET_GET_FULL_ROLE_SUCCESS,
	RESET_UPDATE_ROLE_SUCCESS,
	RESET_CHANGE_SUCCESS_STATUSS_ROLE,
	RESET_DELETE_ROLE_SUCCESS,
	RESET_DELETE_ROLE_SUCCESS_STATUSS,
	RESET_GET_ROLES_PERMISSIONS,
	RESET_SET_ROLES_PERMISSIONS,
	RESET_GET_USER_ROLE,
	RESET_ASSIGNED_ROLE_TO_USER,
	FULL_RESET_STATUSS_ROLE,
	ONFAULT_ROLE,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

//******************************************************************************* */ ACTS

export const getRoleSuccess = (roleData) => {
	return {
		type: GET_ROLE_SUCCESS,
		payload: roleData,
	}
}

export const getFullRoleSuccess = (roleData) => {
	return {
		type: GET_FULL_ROLE_SUCCESS,
		payload: roleData,
	}
}

export const createRoleSuccess = (roleData) => {
	return {
		type: CREATE_ROLE_SUCCESS,
		payload: roleData,
	}
}

export const updateRoleSuccess = (roleData) => {
	return {
		type: UPDATE_ROLE_SUCCESS,
		payload: roleData,
	}
}

export const chageSuccessStatussRole = (data) => {
	return {
		type: CHANGE_SUCCESS_STATUSS_ROLE,
		payload: data,
	}
}

export const deleteRoleSuccess = (data) => {
	return {
		type: DELETE_ROLE_SUCCESS,
		payload: data,
	}
}

export const changeDeletedRoleSuccessStatus = (data) => {
	return {
		type: DELETE_ROLE_SUCCESS_STATUSS,
		payload: data,
	}
}

export const getUserRoleSuccess = (data) => {
	return {
		type: GET_USER_ROLE,
		payload: data,
	}
}

export const assignedRoleToUserSuccess = (data) => {
	return {
		type: ASSIGNED_ROLE_TO_USER,
		payload: data,
	}
}

export const getRolePermissionsSuccess = (data) => {
	return {
		type: GET_ROLES_PERMISSIONS,
		payload: data,
	}
}

export const setRolePermissionsSuccess = (data) => {
	return {
		type: SET_ROLES_PERMISSIONS,
		payload: data,
	}
}

//************************************************************** */ RESETS

export const resetCreateRoleSuccess = (data) => {
	return {
		type: RESET_CREATE_ROLE_SUCCESS,
		payload: data,
	}
}

export const resetGetRoleSuccess = (data) => {
	return {
		type: RESET_GET_ROLE_SUCCESS,
		payload: data,
	}
}

export const resetGetFullRoleSuccess = (data) => {
	return {
		type: RESET_GET_FULL_ROLE_SUCCESS,
		payload: data,
	}
}

export const resetUpdateRoleSuccess = (data) => {
	return {
		type: RESET_UPDATE_ROLE_SUCCESS,
		payload: data,
	}
}

export const resetChangeSuccessStatussRole = (data) => {
	return {
		type: RESET_CHANGE_SUCCESS_STATUSS_ROLE,
		payload: data,
	}
}

export const resetDeleteRoleSuccess = (data) => {
	return {
		type: RESET_DELETE_ROLE_SUCCESS,
		payload: data,
	}
}

export const resetDeleteRoleSuccessStatuss = (data) => {
	return {
		type: RESET_DELETE_ROLE_SUCCESS_STATUSS,
		payload: data,
	}
}

export const resetGetRolePermissionsSuccess = (data) => {
	return {
		type: RESET_GET_ROLES_PERMISSIONS,
		payload: data,
	}
}

export const resetSetRolePermissionsSuccess = (data) => {
	return {
		type: RESET_SET_ROLES_PERMISSIONS,
		payload: data,
	}
}

export const resetGetUserRoleSuccess = (data) => {
	return {
		type: RESET_GET_USER_ROLE,
		payload: data,
	}
}

export const resetAssignedRoleToUserSuccess = (data) => {
	return {
		type: RESET_ASSIGNED_ROLE_TO_USER,
		payload: data,
	}
}

export const resetAllStatuss = (data) => {
	return {
		type: FULL_RESET_STATUSS_ROLE,
		payload: data,
	}
}
//************************************ */

export const onFault = (data) => {
	return {
		type: ONFAULT_ROLE,
		payload: data,
	}
}

export const getRoles = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Roles/List')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const rolesData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getRoleSuccess(rolesData))
		})
		.catch((err) => {
			try {
				var ffData = {
					success: false,
					messages: err.response.data.errorMessage,
				}
			} catch (err) {
				var ffData = {
					success: false,
					messages: err,
				}
			}

			dispatch(onFault(ffData))
		})
}

export const getFullRoles = () => (dispatch) => {
	setAuthHeader()

	axios
		.get('/api/Roles/FullList')
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const fullRolesData = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(getFullRoleSuccess(fullRolesData))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const createRole = (data) => (dispatch) => {
	setAuthHeader()

	axios
		.post('/api/Roles/Add', data)
		.then((res) => {
			// Gelen token'i kullanmak için değişken olarak saklıyoruz
			const AddedRole = res.data
			// ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
			dispatch(createRoleSuccess(AddedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const deleteRole = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Roles/Delete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, { id: data.id })
		.then((res) => {
			const deletedRole = res.data
			dispatch(deleteRoleSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const fullDeleteRole = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Roles/FullDelete'
	if (data.id) {
		url = url + '?id=' + data.id
	}
	axios
		.delete(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(deleteRoleSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const updateRole = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Roles/Update'

	axios
		.put(url, data)
		.then((res) => {
			const updatedRole = res.data
			dispatch(updateRoleSuccess(updatedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

//********************************************************************************** */

export const getUserRole = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Roles/GetUserRole'
	if (data.UserId) {
		url = url + '?UserId=' + data.UserId
	}

	axios
		.get(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(getUserRoleSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const assignedRoleToUser = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Roles/AssignToUser'
	if (data.UserId) {
		url = url + '?UserId=' + data.UserId + '&RoleId=' + data.RoleId
	}

	axios
		.post(url, data)
		.then((res) => {
			const deletedRole = res.data

			dispatch(assignedRoleToUserSuccess(deletedRole))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

//********************************************************************************** */

export const getRolePermissions = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Roles/GetRolePermissions'
	if (data.RoleId) {
		url = url + '?RoleId=' + data.RoleId
	}

	axios
		.get(url, data)
		.then((res) => {
			const data = res.data

			dispatch(getRolePermissionsSuccess(data))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}

export const setRolePermissions = (data) => (dispatch) => {
	setAuthHeader()

	var url = '/api/Roles/UpdateRolePermissions'

	axios
		.post(url, data)
		.then((res) => {
			const data = res.data

			dispatch(setRolePermissionsSuccess(data))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onFault(ffData))
		})
}
