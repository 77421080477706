import {
	GET_EXAMS_WİTH_QUESTİONS_SUCCESS,
	GET_USERS_GET_ASSIGNED_USERS_SUCCESS,
	GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS_SUCCESS,
	RESET_GET_EXAMS_WİTH_QUESTİONS_SUCCESS,
	RESET_GET_USERS_GET_ASSIGNED_USERS_SUCCESS,
	RESET_GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS,
	FULL_RESET_ON_ACTS_EXAM_DETAILS,
	FAULT_ON_ACTS_EXAM_DETAILS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

//******************************************************************************* */ ACTS

export const getExamsWithQuestionsSuccess = (examData) => {
	return {
		type: GET_EXAMS_WİTH_QUESTİONS_SUCCESS,
		payload: examData,
	}
}

export const getUsersGetAssignedUsersSuccess = (examData) => {
	return {
		type: GET_USERS_GET_ASSIGNED_USERS_SUCCESS,
		payload: examData,
	}
}

export const getClassroomsGetAssignedClassroomsSuccess = (examData) => {
	return {
		type: GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS_SUCCESS,
		payload: examData,
	}
}

//****************************** */  RESETS

export const resetGetExamsWithQuestionsSuccess = (examData) => {
	return {
		type: RESET_GET_EXAMS_WİTH_QUESTİONS_SUCCESS,
		payload: examData,
	}
}

export const resetGetUsersGetAssignedUsersSuccess = (examData) => {
	return {
		type: RESET_GET_USERS_GET_ASSIGNED_USERS_SUCCESS,
		payload: examData,
	}
}

export const resetGetClassroomsGetAssignedClassroomsSuccess = (examData) => {
	return {
		type: RESET_GET_CLASSROOMS_GET_ASSIGNED_CLASSROOMS,
		payload: examData,
	}
}

export const fullResetsOnActsExamDetails = (examData) => {
	return {
		type: FULL_RESET_ON_ACTS_EXAM_DETAILS,
		payload: examData,
	}
}

export const faultOnActsExamsDetails = (examData) => {
	return {
		type: FAULT_ON_ACTS_EXAM_DETAILS,
		payload: examData,
	}
}

export const getExamsWithQuestions = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Exams/GetWithQuestions'

	if (data.ExamId) {
		url = url + '?Id=' + data.ExamId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(getExamsWithQuestionsSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultOnActsExamsDetails(ffData))
		})
}

export const getUsersGetAssignedUsers = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/GetAssignedUsers'

	if (data.ExamId) {
		url = url + '?ExamId=' + data.ExamId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getUsersGetAssignedUsersSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultOnActsExamsDetails(ffData))
		})
}

export const getClassroomsGetAssignedClassrooms = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Classrooms/GetAssignedClassrooms'

	if (data.ExamId) {
		url = url + '?ExamId=' + data.ExamId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(getClassroomsGetAssignedClassroomsSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(faultOnActsExamsDetails(ffData))
		})
}
