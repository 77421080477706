import {
	GET_USERS_ON_CLASSROOM_SUCCESS,
	ON_CLASSROOM_RESET_ALL_STATUSS,
	ONFAULT_USERS_ON_CLASSROOM,
	CLASSROOM_ADD_USER_SUCCESS,
	CLASSROOM_REMOVE_USER_SUCCESS,
} from './actionTypes'
import axios from 'axios'

import mainConfigStates from '../../configs/mainConfig'

function setAuthHeader() {
	var token = localStorage.getItem('jwtToken')
	axios.defaults.baseURL = mainConfigStates.host
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const getUSersOnClassroomSuccess = (examData) => {
	return {
		type: GET_USERS_ON_CLASSROOM_SUCCESS,
		payload: examData,
	}
}

export const resetUSersOnClassroomSuccess = (examData) => {
	return {
		type: ON_CLASSROOM_RESET_ALL_STATUSS,
		payload: examData,
	}
}

export const onUSersFaultOnClassroomSuccess = (data) => {
	return {
		type: ONFAULT_USERS_ON_CLASSROOM,
		payload: data,
	}
}

export const addUserClassroomSuccess = (data) => {
	return {
		type: CLASSROOM_ADD_USER_SUCCESS,
		payload: data,
	}
}

export const removeUSerFromClassSuccess = (data) => {
	return {
		type: CLASSROOM_REMOVE_USER_SUCCESS,
		payload: data,
	}
}

export const getUsersOnClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Users/GetUsersByClassroom'

	if (data.id) {
		url = url + '?id=' + data.id + '&RoleId=' + data.RoleId
	}

	axios
		.get(url, data)
		.then((res) => {
			const getted = res.data
			dispatch(getUSersOnClassroomSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onUSersFaultOnClassroomSuccess(ffData))
		})
}

export const addUserClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Classrooms/AddUser'

	if (data.AddUserId) {
		url = url + '?AddUserId=' + data.AddUserId + '&ClassroomId=' + data.ClassroomId
	}

	axios
		.post(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(addUserClassroomSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onUSersFaultOnClassroomSuccess(ffData))
		})
}
//http://localhost:1292/api/Classrooms/AddUser?    ClassroomId=DE273DED-2F45-4A35-A376-230249128FDF   &AddUserId=016a6b18-f515-4b4b-b84c-17c62019ef44

export const removeUSerFromClassroom = (data) => (dispatch) => {
	setAuthHeader()
	var url = '/api/Classrooms/RemoveUser'

	if (data.RemoveUserId) {
		url =
			url + '?RemoveUserId=' + data.RemoveUserId + '&ClassroomId=' + data.ClassroomId
	}

	axios
		.post(url, data)
		.then((res) => {
			const getted = res.data

			dispatch(removeUSerFromClassSuccess(getted))
		})
		.catch((err) => {
			var ffData = {
				success: false,
				messages: err.response.data.errorMessage,
			}
			dispatch(onUSersFaultOnClassroomSuccess(ffData))
		})
}
