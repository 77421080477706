import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState'

export default function getQuestionsReducer(state = initialState.questions, action) {
	switch (action.type) {
		case actionTypes.CREATE_QUESTION_SUCCESS:
			return {
				...state,
				success: action.payload.success,
				extra: action.payload.extra,
			}

		case actionTypes.DELETE_QUESTION_SUCCESS:
			if (action.payload === 'Başarıyla silindi.') {
				return {
					...state,
					deleted: true,
				}
			} else {
				return {
					...state,
					deleted: false,
				}
			}

		case actionTypes.GET_QUESTION_SUCCESS:
			return action.payload

		case actionTypes.UPDATE_QUESTION_SUCCESS:
			return {
				...state,
				success: action.payload.success,
			}

		case actionTypes.RESET_STATUSS_QUESTION:
			return {
				...state,
				success: '',
				deleted: '',
				messages: '',
			}

		case actionTypes.ONFAULT_QUESTION:
			return {
				...state,
				success: false,
				messages: action.payload.messages,
			}

		default:
			return state
	}
}
