import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function getLessonReducer(state = initialState.uploadFiles, action) {
  switch (action.type) {
    case actionTypes.POST_UPLOAD_SUCCESS:
      return action.payload

    case actionTypes.POST_UPLOAD_RESET_ALL_STATUSS:
      return {
        ...state,
        success: "",
        deleted: "",
        messages: "",
      }

    case actionTypes.ONFAULT_POST_UPLOAD:
      return {
        ...state,
        success: false,
        messages: action.payload.messages,
      }

    default:
      return state
  }
}
