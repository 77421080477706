import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function getFullLessonsReducer(
  state = initialState.InitialfullLessons,
  action
) {
  switch (action.type) {
    case actionTypes.GET_FULL_LESSON_SUCCESS:
      return {
        ...state,
        successFullGettedLesson: action.payload.success,
        extraFullGettedLesson: action.payload.extra,
      }

    case actionTypes.RESET_GET_FULL_LESSON_SUCCESS:
      return {
        ...state,
        successFullGettedLesson: "",
      }
    default:
      return state
  }
}
