import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function usersNotAssignedReducer(
  state = initialState.usersAssignedNot,
  action
) {
  switch (action.type) {
    case actionTypes.GET_USERS_NOT_ASSIGNEDS_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        extra: action.payload.extra,
      }

    case actionTypes.USERS_NOT_ASSIGNEDS_RESET_ALL_STATUSS:
      return {
        ...state,
        success: "",
        deleted: "",
        Updatedsuccess: "",
      }

    case actionTypes.ONFAULT_USERS_NOT_ASSIGNEDS:
      return {
        ...state,
        success: false,
        messages: action.payload.messages,
      }

    default:
      return state
  }
}
