import React, { Component } from 'react'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Badge } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import { ScrollView } from '../base/scroll'

import * as changeNavigationAction from '../../../redux/actions/changeNavigationAction'
import * as examActions from '../../../redux/actions/examActions'
import * as userDetailsExamActions from '../../../redux/actions/userDetailsExamActions'

import alertifyjs from 'alertifyjs'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import { Accordion, Card } from 'react-bootstrap'
import initialState from '../../../redux/reducers/initialState'
import TextField from '@material-ui/core/TextField'

class Profile extends Component {
	constructor(props) {
		super(props)

		let permExamResult = this.props.userAuthData.permissions.includes(
			initialState.staticPerms.ViewExamResults
		)

		this.state = {
			NotSolvedExamColumns_onQuery: [],
			SolvedExamColumns_onQuery: [],
			CanAssignedExamsDataList: [],

			cozulmemisSinavlarStudent: [
				{
					name: 'Test',
					selector: 'exam',
					sortable: true,
					cell: (row) => row.exam.examName,
				},
				{
					name: 'Konu',
					selector: 'topic',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},

				{
					name: 'Ders',
					selector: 'lesson',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
				{
					name: 'Sınıf',
					selector: 'class',
					sortable: true,
					width: '60px',
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'daec2156-8e63-45b2-9c08-a4b7b72df24a'
						) === true
							? row.classroom.className
							: null,
				},

				{
					name: 'Ad Soyad',
					selector: 'class',
					sortable: true,
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'daec2156-8e63-45b2-9c08-a4b7b72df24a'
						) === true
							? row.userDto.fullName
							: null,
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'10ee4ac9-b100-435c-c116-ba32de3d3f21'
						) === true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickClassroomDetails(row, 'ClassroomDetails')}
								>
								Katıl
								</Button>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
			],

			cozulmemisSinavlarNotStudent: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'10EE4AC9-B100-435C-B116-BA32DE3D3F21'
						) === false &&
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UserNotAssignExam
						) === true ? (
								<Button color="light" onClick={() => this.removeFromNotSolvedExams(row)}>
									<HighlightOffIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
				{
					name: 'Test',
					selector: 'exam',
					sortable: true,
					cell: (row) => row.exam.examName,
				},
				{
					name: 'Konu',
					selector: 'topic',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},

				{
					name: 'Ders',
					selector: 'lesson',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
				{
					name: 'Sınıf',
					selector: 'class',
					sortable: true,
					width: '60px',
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'daec2156-8e63-45b2-9c08-a4b7b72df24a'
						) === true
							? row.classroom.className
							: null,
				},

				{
					name: 'Ad Soyad',
					selector: 'class',
					sortable: true,
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'daec2156-8e63-45b2-9c08-a4b7b72df24a'
						) === true
							? row.userDto.fullName
							: null,
				},

				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'10ee4ac9-b100-435c-c116-ba32de3d3f21'
						) === true ? (
								<Button
									color="warning"
									onClick={() => this.onRowClickClassroomDetails(row, 'ClassroomDetails')}
								>
								Katıl
								</Button>
							) : null,

					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
			],

			atanabilirSinavlarColumns: [
				{
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'10EE4AC9-B100-435C-B116-BA32DE3D3F21'
						) === false &&
						this.props.userAuthData.permissions.includes(
							initialState.staticPerms.UserAssignExam
						) === true ? (
								<Button color="light" onClick={() => this.addToCanAssignedExams(row)}>
									<AddCircleOutlineIcon color="secondary" />
								</Button>
							) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
				{
					name: 'Test',
					selector: 'examName',
					sortable: true,
				},
				{
					name: 'Konu',
					selector: 'topic',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},

				{
					name: 'Ders',
					selector: 'lesson',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},
			],

			SolvedExamColumns: [
				{
					name: 'Test',
					selector: 'exam',
					sortable: true,
					cell: (row) => row.exam.examName,
				},
				{
					name: 'Konu',
					selector: 'topic',
					sortable: true,
					cell: (row) => row.topic.topicName,
				},

				{
					name: 'Ders',
					selector: 'lesson',
					sortable: true,
					cell: (row) => row.lesson.lessonName,
				},

				{
					name: 'Sınıf',
					selector: 'class',
					sortable: true,
					width: '60px',
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'daec2156-8e63-45b2-9c08-a4b7b72df24a'
						) === true
							? row.classroom.className
							: null,
				},

				{
					name: 'Ad Soyad',
					selector: 'class',
					sortable: true,
					cell: (row) =>
						this.props.userAuthData.permissions.includes(
							'daec2156-8e63-45b2-9c08-a4b7b72df24a'
						) === true
							? row.userDto.fullName
							: null,
				},

				{
					cell: (row) => (
						<Link
							className="btn btn-dark"
							to={{
								pathname: '/ExamDetails',
								state: {
									...row,
									id: row.examId,
									topicId: row.topic.id,
									examName: row.exam.examName,
									deadLine: row.exam.deadLine,
								},
							}}
						>
							Detaylar
						</Link>
					),
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: '95px',
				},

				{
					cell: (row) =>
						permExamResult ? (
							<Link
								className="btn btn-success"
								to={{
									pathname: '/ExamResults',
									state: {
										...row,
										studentId: row.userId,
										studentName: row.userDto.fullName,
										classroomId: row.classroom.id,
										id: row.exam.id,
									},
								}}
							>
								Sonuçlar
							</Link>
						) : null,
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					width: permExamResult ? '95px' : 0,
				},
			],
		}
	}

	componentDidMount() {
		this.props.actions.getExamsSolved({
			UserId: this.props.location.state.data.id,
			ExamId: '00000000-0000-0000-0000-000000000000',
		})
		this.props.actions.getExamsNotSolved({
			UserId: this.props.location.state.data.id,
			ExamId: '00000000-0000-0000-0000-000000000000',
		})

		this.props.actions.getUSerDetailsAssignedExams({
			UserId: this.props.location.state.data.id,
		})
	}

	componentWillReceiveProps(newProps) {
		if (newProps.examsData.successNotSolvedGetted === true) {
			this.setState({
				NotSolvedExamColumns_onQuery: newProps.examsData.extraNotSolved,
			})
		}

		if (newProps.examsData.successSolvedGetted === true) {
			this.setState({ SolvedExamColumns_onQuery: newProps.examsData.extraSolved })
		}

		if (newProps.userDetailsExamReducerData.successGet === true) {
			this.setState({
				CanAssignedExamsDataList: newProps.userDetailsExamReducerData.extra,
			})
			newProps.actions.resetUSerDetailsExams('')
		}

		if (newProps.userDetailsExamReducerData.successAdd === true) {
			this.props.actions.getExamsSolved({
				UserId: this.props.location.state.data.id,
				ExamId: '00000000-0000-0000-0000-000000000000',
			})
			this.props.actions.getExamsNotSolved({
				UserId: this.props.location.state.data.id,
				ExamId: '00000000-0000-0000-0000-000000000000',
			})
			this.props.actions.getUSerDetailsAssignedExams({
				UserId: this.props.location.state.data.id,
			})

			newProps.actions.resetUSerDetailsExams('')
		} else if (newProps.userDetailsExamReducerData.successAdd === false) {
			if (newProps.userDetailsExamReducerData.message) {
				alertifyjs.error('Hata: ' + newProps.userDetailsExamReducerData.message)
			}
			newProps.actions.resetUSerDetailsExams('')
		}

		if (newProps.userDetailsExamReducerData.successRemove === true) {
			this.props.actions.getExamsSolved({
				UserId: this.props.location.state.data.id,
				ExamId: '00000000-0000-0000-0000-000000000000',
			})
			this.props.actions.getExamsNotSolved({
				UserId: this.props.location.state.data.id,
				ExamId: '00000000-0000-0000-0000-000000000000',
			})
			this.props.actions.getUSerDetailsAssignedExams({
				UserId: this.props.location.state.data.id,
			})

			newProps.actions.resetUSerDetailsExams('')
		} else if (newProps.userDetailsExamReducerData.successRemove === false) {
			if (newProps.userDetailsExamReducerData.message) {
				alertifyjs.error('Hata: ' + newProps.userDetailsExamReducerData.message)
			}

			newProps.actions.resetUSerDetailsExams('')
		}
	}

	removeFromNotSolvedExams = (data) => {
		this.props.actions.resetAllStatuss('')

		var datdat = {
			ExamId: data.examId,
			UserId: this.props.location.state.data.id,
		}
		this.props.actions.removeUserDetailsAssignedExamsToNotSolved(datdat)
	}

	addToCanAssignedExams = (data) => {
		this.props.actions.resetAllStatuss('')

		var datdat = { ExamId: data.id, UserId: this.props.location.state.data.id }
		this.props.actions.addUserDetailsAssignedExamsToNotSolved(datdat)
	}

	render() {
		const data = [
			{
				name: 'Page A',
				uv: 4000,
				pv: 2400,
			},
			{
				name: 'Page B',
				uv: 3000,
				pv: 1398,
			},
			{
				name: 'Page C',
				uv: 2000,
				pv: 9800,
			},
			{
				name: 'Page D',
				uv: 2780,
				pv: 3908,
			},
			{
				name: 'Page E',
				uv: 1890,
				pv: 4800,
			},
			{
				name: 'Page F',
				uv: 2390,
				pv: 3800,
			},
			{
				name: 'Page G',
				uv: 3490,
				pv: 4300,
			},
		]

		const data3 = [
			{
				name: 'Page A',
				uv: 4000,
				pv: 2400,
				amt: 2400,
			},
			{
				name: 'Page B',
				uv: 3000,
				pv: 1398,
				amt: 2210,
			},
			{
				name: 'Page C',
				uv: -1000,
				pv: 9800,
				amt: 2290,
			},
			{
				name: 'Page D',
				uv: 500,
				pv: 3908,
				amt: 2000,
			},
			{
				name: 'Page E',
				uv: -2000,
				pv: 4800,
				amt: 2181,
			},
			{
				name: 'Page F',
				uv: -250,
				pv: 3800,
				amt: 2500,
			},
			{
				name: 'Page G',
				uv: 3490,
				pv: 4300,
				amt: 2100,
			},
		]

		const gradientOffset = () => {
			const dataMax = Math.max(...data3.map((i) => i.uv))
			const dataMin = Math.min(...data3.map((i) => i.uv))

			if (dataMax <= 0) {
				return 0
			}
			if (dataMin >= 0) {
				return 1
			}

			return dataMax / (dataMax - dataMin)
		}

		const off = gradientOffset()

		return (
			<ScrollView>
				<div className="row">
					<div className="col-lg-3">
						<div className="card card-warning card-outline">
							<div className="card-body box-profile">
								<div className="text-center">
									<img
										className="profile-user-img img-fluid img-circle"
										src={
											this.props.location.state.data.avatarUrl ||
											'https://clipartart.com/images/clipart-profile-2.jpg'
										}
										alt={this.props.location.state.data.fullName}
									/>
								</div>

								<h3 className="profile-username text-center">
									{this.props.location.state.data.fullName}
								</h3>

								<p className="text-muted text-center">
									{this.props.location.state.data.roleName}
								</p>
								<p className="text-muted text-center">
									{this.props.location.state.data.email}
								</p>
								{this.props.location.state.data.phone && (
									<p className="text-muted text-center">
										{this.props.location.state.data.phone}
									</p>
								)}

								<ul className="list-group list-group-unbordered mb-3">
									<li className="list-group-item">
										<b>Çözülmemiş Test</b>{' '}
										<a className="float-right">
											{this.state.NotSolvedExamColumns_onQuery.length}
										</a>
									</li>
									<li className="list-group-item">
										<b>Çözülmüş Test</b>{' '}
										<a className="float-right">
											{this.state.SolvedExamColumns_onQuery.length}
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="col-lg-9">
						<div className="col-lg-12">
							<Accordion>
								<Card>
									<Accordion.Toggle eventKey="2" as={Card.Header}>
										<h4>
											Çözülmemiş Testler{' '}
											<Badge>{this.state.NotSolvedExamColumns_onQuery.length} Adet</Badge>{' '}
										</h4>
									</Accordion.Toggle>

									<Accordion.Collapse eventKey="2">
										<Card.Body>
											<DataTable
												columns={
													this.props.userAuthData.permissions.includes(
														'10EE4AC9-B100-435C-B116-BA32DE3D3F21'
													) === false
														? this.state.cozulmemisSinavlarNotStudent
														: this.state.cozulmemisSinavlarStudent
												}
												data={this.state.NotSolvedExamColumns_onQuery}
												pagination
												highlightOnHover
												progressPending={false}
												subHeader
												noHeader
												subHeaderComponent={
													<div style={{ display: 'flex', alignItems: 'center' }}>
														{this.props.userAuthData.permissions.includes(
															initialState.staticPerms.FindClassroom
														) === true ? (
																<TextField
																	onChange={this.searchOnList}
																	id="outlined-basic"
																	label="Arama"
																	variant="outlined"
																	size="small"
																	style={{ margin: '5px' }}
																/>
															) : null}
													</div>
												}
												subHeaderAlign={'left'}
											/>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle eventKey="3" as={Card.Header}>
										<h4>
											Çözülmüş Testler{' '}
											<Badge>{this.state.SolvedExamColumns_onQuery.length} Adet</Badge>{' '}
										</h4>
									</Accordion.Toggle>

									<Accordion.Collapse eventKey="3">
										<Card.Body>
											<DataTable
												columns={this.state.SolvedExamColumns}
												data={this.state.SolvedExamColumns_onQuery}
												pagination
												highlightOnHover
												progressPending={false}
												noHeader
												subHeader
												subHeaderComponent={
													<div style={{ display: 'flex', alignItems: 'center' }}>
														{this.props.userAuthData.permissions.includes(
															initialState.staticPerms.FindClassroom
														) === true ? (
																<TextField
																	onChange={this.searchOnList}
																	id="outlined-basic"
																	label="Arama"
																	variant="outlined"
																	size="small"
																	style={{ margin: '5px' }}
																/>
															) : null}
													</div>
												}
												subHeaderAlign={'left'}
											/>
										</Card.Body>
									</Accordion.Collapse>
								</Card>

								{this.props.userAuthData.permissions.includes(
									initialState.staticPerms.NotAssignedExamsByUser
								) === true ? (
										<Card>
											<Accordion.Toggle eventKey="4" as={Card.Header}>
												<h4>
												Atanabilir Testler{' '}
													<Badge>{this.state.CanAssignedExamsDataList.length} Adet</Badge>{' '}
												</h4>
											</Accordion.Toggle>

											<Accordion.Collapse eventKey="4">
												<Card.Body>
													<DataTable
														columns={this.state.atanabilirSinavlarColumns}
														data={this.state.CanAssignedExamsDataList}
														pagination
														highlightOnHover
														progressPending={false}
														subHeader
														noHeader
														subHeaderComponent={
															<div style={{ display: 'flex', alignItems: 'center' }}>
																{this.props.userAuthData.permissions.includes(
																	initialState.staticPerms.FindClassroom
																) === true ? (
																		<TextField
																			onChange={this.searchOnList}
																			id="outlined-basic"
																			label="Arama"
																			variant="outlined"
																			size="small"
																			style={{ margin: '5px' }}
																		/>
																	) : null}
															</div>
														}
														subHeaderAlign={'left'}
													/>
												</Card.Body>
											</Accordion.Collapse>
										</Card>
									) : null}
							</Accordion>
						</div>

						<div className="col-lg-12"></div>
					</div>
				</div>
			</ScrollView>
		)
	}
}

function mapStateToProps(state) {
	return {
		toggleStatus: state.toggleReducer,
		userAuthData: state.authReducer,
		currentNavigation: state.changeNavigationReducer,

		examsData: state.examReducer,
		userDetailsExamReducerData: state.userDetailsExamReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			changeNavigation: bindActionCreators(
				changeNavigationAction.changeNavigation,
				dispatch
			),
			getExamsNotSolved: bindActionCreators(examActions.getExamsNotSolved, dispatch),
			getExamsSolved: bindActionCreators(examActions.getExamsSolved, dispatch),
			resetAllStatuss: bindActionCreators(examActions.resetAllStatuss, dispatch),

			getUSerDetailsAssignedExams: bindActionCreators(
				userDetailsExamActions.getUSerDetailsAssignedExams,
				dispatch
			),
			addUserDetailsAssignedExamsToNotSolved: bindActionCreators(
				userDetailsExamActions.addUserDetailsAssignedExamsToNotSolved,
				dispatch
			),
			removeUserDetailsAssignedExamsToNotSolved: bindActionCreators(
				userDetailsExamActions.removeUserDetailsAssignedExamsToNotSolved,
				dispatch
			),
			resetUSerDetailsExams: bindActionCreators(
				userDetailsExamActions.resetUSerDetailsExams,
				dispatch
			),
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
